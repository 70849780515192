import { ROIStoreData } from 'interfaces/roi';
import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as types from './types';

type RootAction = ActionType<typeof actions>;

export default createReducer<ROIStoreData, RootAction>({
  roiList: {
    items: [],
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
  roi: undefined,
  roiTemplates: [],
  templatePreview: '',
  roiReport: undefined,
  roiMeltwaterData: undefined,
})
  .handleType([types.SET_ROI_LIST], (state, action) => ({
    ...state,
    roiList: action.payload,
  }))
  .handleType([types.CREATE_ROI], (state, action) => ({
    ...state,
    roiList: {
      items: [action.payload, ...state.roiList.items],
      meta: { ...state.roiList.meta, totalItems: state.roiList.meta.totalItems + 1 },
    },
  }))
  .handleType([types.DELETE_ROI], (state, action) => ({
    ...state,
    roiList: {
      items: state.roiList.items.filter(item => !action.payload.includes(item.id)),
      meta: {
        ...state.roiList.meta,
        totalItems: state.roiList.meta.totalItems - action.payload.length,
      },
    },
  }))
  .handleType([types.SET_ROI], (state, action) => ({
    ...state,
    roi: action.payload,
    roiList: {
      ...state.roiList,
      items: state.roiList.items.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      }),
    },
  }))
  .handleType([types.SET_ROI_TEMPLATES], (state, action) => ({
    ...state,
    roiTemplates: action.payload,
  }))
  .handleType([types.SET_ROI_TEMPLATE_PREVIEW], (state, action) => ({
    ...state,
    templatePreview: action.payload,
  }))
  .handleType([types.SET_ROI_TEMPLATES_ITEM], (state, action) => ({
    ...state,
    roiTemplates: state.roiTemplates.map(item =>
      item.id === action.payload.id ? action.payload : item,
    ),
  }))
  .handleType([types.TOGGLE_ROI_TEMPLATES_DISABLE], (state, action) => ({
    ...state,
    roiTemplates: state.roiTemplates.map(item =>
      item.id === action.payload ? { ...item, disabled: !item.disabled } : item,
    ),
  }))
  .handleType([types.SET_ROI_REPORT], (state, action) => ({
    ...state,
    roiReport: action.payload,
  }))
  .handleType([types.SET_ROI_MELTWATER_DATA], (state, action) => ({
    ...state,
    roiMeltwaterData: action.payload,
  }));
