import React, { ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';
import ArrowSVG from 'assets/images/icons/arrow-down.svg';
import { ReactSVG } from 'react-svg';
import { FieldInputProps } from 'react-final-form';

interface Props {
  title: string;
  input?: FieldInputProps<boolean>;
  children?: ReactNode;
}

const FormCollapsible = ({ title, input, children }: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [showState, setShowState] = useState(true);

  const handleToggleShow = () => {
    if (input) return input.onChange(!input.value);
    setShowState(!showState);
  };

  const show = input ? input.value : showState;

  return (
    <Root>
      <Title onClick={handleToggleShow}>
        {title}
        <ArrowIcon $show={show} src={ArrowSVG} />
      </Title>
      <Content
        ref={contentRef}
        $height={contentRef.current?.scrollHeight || 0}
        $show={show}
      >
        {children}
      </Content>
    </Root>
  );
};

const Root = styled.div``;

const Title = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin-bottom: 24px;
  cursor: pointer;
`;

const Content = styled.div<{ $show: boolean; $height: number }>`
  transition: all 0.5s;
  overflow: hidden;

  max-height: ${({ $show, $height }) => ($show ? `${$height}px` : '0px')};
  margin-bottom: ${({ $show }) => ($show ? `24px` : '0px')};
`;

const ArrowIcon = styled(ReactSVG)<{ $show: boolean }>`
  margin-left: 8px;
  display: inline-block;
  transition: transform 0.5s;
  transform: ${({ $show }) => ($show ? 'rotate(180deg)' : 'rotate(360deg)')};
`;

export default FormCollapsible;
