import { StateProps } from 'store/interfaces';

export const getRecommendationsList = (state: StateProps) =>
  state.issueMapping.recommendationsList;

export const getRecommendation = (state: StateProps) => state.issueMapping.recommendation;

export const getRecommendationPublished = (state: StateProps) =>
  state.issueMapping.recommendationPublished;

export const getRecommendationTeams = (state: StateProps) =>
  state.issueMapping.recommendationTeams.map(item => ({
    ...item,
    name: item.organizationName,
  }));
