import { Field } from 'components/FinalForm';
import {
  FieldSingleSelect,
  FieldTextArea,
  FieldTextInput,
  FiledMultiSelectWithCheckBoxes,
  MaskedMoneyTextInput,
} from 'components/FormFields';
import arrayMutators from 'final-form-arrays';
import { ROIData } from 'interfaces/roi';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router';
import { Button, ButtonGroup } from './styled';
import {
  FormCollapsible,
  FormRow,
  FormTitle,
  FormTitleSecondary,
} from 'components/UI/form';
import FormBody from 'components/UI/form/FormBody';
import IconTooltip from 'components/IconTooltip';
import styled from 'styled-components';
import {
  integerValidator,
  maxLength500,
  percentageOneHundredValidator,
} from 'utils/validation';
import { formatValueEnum } from 'components/UI/interfaces';
import { composeValidators } from 'utils';
import { set } from 'lodash';

interface Props {
  roi: ROIData;
  onSubmit: (values: ROIData) => void;
}

export const onlyNumberLowercaseAndCommaValidator = (value: string) => {
  if (!value) return undefined;
  return /^[a-z0-9., ]+$/.test(value) ? undefined : 'Example: 2.1,2.2';
};

const PartnerDataIntakeForm = ({ onSubmit, roi }: Props) => {
  const { go } = useHistory();

  const formInitialValues = useMemo(() => {
    const values = { ...roi };
    set(
      values,
      'socialMedia.wouldYouLikeToSubmitAdditionalSocialMediaMetricsForThisPartnership',
      true,
    );
    set(values, 'partnerActivation.ownedMedia.isOwnedMedia', true);
    set(values, 'partnerActivation.paidMedia.isPaidMedia', true);
    set(values, 'paidMedia.isPaidMedia', true);
    set(values, 'ownedMedia.isOwnedMedia', true);
    return values;
  }, [roi]);

  return (
    <Form<ROIData>
      initialValues={formInitialValues}
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={values => {
        onSubmit(values);
      }}
      render={({ handleSubmit }) => (
        <FormBody>
          <FormTitle>Company Social Spend</FormTitle>
          <FormTitleSecondary>Total Giving</FormTitleSecondary>
          <FormRow>
            <Field
              name="fundraising.totalFundsRaisedFromConsumers"
              label="Cash (Consumers) ($)"
              component={MaskedMoneyTextInput}
              hidePrefix
            />
            <Field
              name="fundraising.totalFundsRaisedFromEmployees"
              label="Cash (Employees) ($)"
              component={MaskedMoneyTextInput}
              hidePrefix
            />
            <Field
              name="fundraising.totalFundsRaisedFromCompany"
              label={
                <TooltipLabelWrapper>
                  Cash ($)
                  <IconTooltipBlue>Total Funds Raised from Company</IconTooltipBlue>
                </TooltipLabelWrapper>
              }
              component={MaskedMoneyTextInput}
              hidePrefix
            />
            <Field
              name="fundraising.totalUnderwritingOrAdditionalFunds"
              label={
                <TooltipLabelWrapper>
                  Non-Cash ($)
                  <IconTooltipBlue>
                    Total Underwriting or Additional Funds
                  </IconTooltipBlue>
                </TooltipLabelWrapper>
              }
              component={MaskedMoneyTextInput}
              hidePrefix
            />
          </FormRow>
          <FormTitleSecondary>
            Good Beyond Giving (Workforce Engagement)
          </FormTitleSecondary>
          <FormRow>
            <Field
              name="givingParticipationRate"
              label="Giving Participation Rate (%)"
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
            />
            <Field
              name="typesOfFundsRaised"
              label="Types of Funds Raised"
              component={FiledMultiSelectWithCheckBoxes}
              formatValue={formatValueEnum.BASE_ARRAY}
              options={[
                { value: 'Event', label: 'Event' },
                { value: 'Payroll Deduction', label: 'Payroll Deduction' },
                { value: 'Matching Donation', label: 'Matching Donation' },
                { value: 'Dollars for Doers', label: 'Dollars for Doers' },
                { value: 'Other', label: 'Other' },
              ]}
            />
            <Field
              name="volunteeringParticipationRate"
              label="Volunteering Participation Rate (%)"
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
            />
            <Field
              name="typesOfVolunteering"
              label="Types of Volunteering"
              component={FiledMultiSelectWithCheckBoxes}
              formatValue={formatValueEnum.BASE_ARRAY}
              options={[
                { value: 'Event', label: 'Event' },
                { value: 'Time', label: 'Time' },
                { value: 'Talent', label: 'Talent' },
              ]}
            />
          </FormRow>
          <FormTitle>Business Value</FormTitle>
          <FormTitleSecondary>Constituent Engagement </FormTitleSecondary>
          <FormRow>
            <Field
              name="ambassadors.didYouHaveAnyAmbassadors"
              label="Engagement from ambassadors, executives, or influencers "
              component={FieldSingleSelect}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
            />

            <Field
              name="partnerActivation.accessToAgencies"
              label="Access to agencies, vendors or other portfolio brands"
              component={FieldSingleSelect}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
            />
            <Field
              name="partnerActivation.executiveChampions"
              label="Executive Champions"
              component={FieldSingleSelect}
              options={[
                { label: 'No Involvement', value: 'No Involvement' },
                { label: 'Somewhat Involved', value: 'Somewhat Involved' },
                { label: 'Very Involved', value: 'Very Involved' },
              ]}
            />

            <Field
              name="partnerActivation.celebrityEngagement"
              label="Celebrity Engagement"
              component={FieldSingleSelect}
              options={[
                { label: 'No Involvement', value: 'No Involvement' },
                { label: 'Somewhat Involved', value: 'Somewhat Involved' },
                { label: 'Very Involved', value: 'Very Involved' },
              ]}
            />

            <Field
              name="partnerActivation.employeeEngagement"
              label="Employee Engagement"
              component={FieldSingleSelect}
              options={[
                { label: 'No Involvement', value: 'No Involvement' },
                { label: 'Somewhat Involved', value: 'Somewhat Involved' },
                { label: 'Very Involved', value: 'Very Involved' },
              ]}
            />
          </FormRow>
          <FormTitleSecondary>Brand Reputation</FormTitleSecondary>
          <FormRow>
            <Field
              name="employeeAwareness"
              label="Employee Awareness (%)"
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
            />

            <Field
              name="employeeSentiment"
              label="Employee Sentiment (%)"
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
            />

            <Field
              name="consumerAwareness"
              label="Consumer Awareness (%)"
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
            />
          </FormRow>

          <Field name="partnerActivation.paidMedia.isPaidMedia" initialValue={true}>
            {() => (
              <FormCollapsible title="Non-Profit Activation: Paid Media Impressions">
                <FormRow>
                  <Field
                    name="partnerActivation.paidMedia.numberOfPrintPublicationImpressionsnumberOfPrintPublicationImpressions"
                    label="Number of print publication impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.paidMedia.totalSpentOnPrintMediatotalSpentOnPrintMedia"
                    label="Total spent on print media ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="partnerActivation.paidMedia.numberOfTelevisionImpressionsnumberOfTelevisionImpressions"
                    label="Number of television impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.paidMedia.totalSpentOnTelevisiontotalSpentOnTelevision"
                    label="Total spent on television ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="partnerActivation.paidMedia.numberOfOutOfHomeImpressionsnumberOfOutOfHomeImpressions"
                    label="Number of out-of-home impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.paidMedia.totalSpentOnOutOfHomeImpressionstotalSpentOnOutOfHomeImpressions"
                    label="Total spent on out-of-home impressions ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="partnerActivation.paidMedia.numberOfDigitalImpressionsnumberOfDigitalImpressions"
                    label="Number of digital impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.paidMedia.totalSpentOnDigitalMediatotalSpentOnDigitalMedia"
                    label="Total spent on digital media  ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="partnerActivation.paidMedia.numberOfRadioImpressionsnumberOfRadioImpressions"
                    label="Number of radio impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.paidMedia.totalSpentOnRadiototalSpentOnRadio"
                    label="Total spent on radio ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
              </FormCollapsible>
            )}
          </Field>
          <Field name="partnerActivation.ownedMedia.isOwnedMedia" initialValue={true}>
            {() => (
              <FormCollapsible title="Non-Profit Activation: Owned Media Impressions">
                <FormRow>
                  <Field
                    name="partnerActivation.ownedMedia.numberOfMagazineImpressionsnumberOfMagazineImpressions"
                    label="Number of magazine impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.ownedMedia.numberOfWebsiteImpressionsnumberOfWebsiteImpressions"
                    label="Number of website impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="partnerActivation.ownedMedia.numberOfInOfficeImpressionsnumberOfInOfficeImpressions"
                    label="Number of in-office impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.ownedMedia.numberOfECommunicationsImpressionsnumberOfECommunicationsImpressions"
                    label="Number of e-communications impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="partnerActivation.ownedMedia.numberOfCollateralImpressionsnumberOfCollateralImpressions"
                    label="Number of collateral impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.ownedMedia.numberOfInStoreImpressionsnumberOfInStoreImpressions"
                    label="Number of in-store impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="partnerActivation.ownedMedia.numberOfEventParticipantsnumberOfEventParticipants"
                    label="Number of event participants"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.ownedMedia.numberOfEmployeeImpressionsnumberOfEmployeeImpressions"
                    label="Number of employee impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
              </FormCollapsible>
            )}
          </Field>
          <Field name="paidMedia.isPaidMedia" initialValue={true}>
            {() => (
              <FormCollapsible title="Company Activation: Paid Media Impressions">
                <FormRow>
                  <Field
                    name="paidMedia.numberOfPrintPublicationImpressionsnumberOfPrintPublicationImpressions"
                    label="Number of print publication impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="paidMedia.totalSpentOnPrintMediatotalSpentOnPrintMedia"
                    label="Total spent on print media ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="paidMedia.numberOfTelevisionImpressionsnumberOfTelevisionImpressions"
                    label="Number of television impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="paidMedia.totalSpentOnTelevisiontotalSpentOnTelevision"
                    label="Total spent on television ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="paidMedia.numberOfOutOfHomeImpressionsnumberOfOutOfHomeImpressions"
                    label="Number of out-of-home impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="paidMedia.totalSpentOnOutOfHomeImpressionstotalSpentOnOutOfHomeImpressions"
                    label="Total spent on out-of-home impressions ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="paidMedia.numberOfDigitalImpressionsnumberOfDigitalImpressions"
                    label="Number of digital impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="paidMedia.totalSpentOnDigitalMediatotalSpentOnDigitalMedia"
                    label="Total spent on digital media  ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="paidMedia.numberOfRadioImpressionsnumberOfRadioImpressions"
                    label="Number of radio impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="paidMedia.totalSpentOnRadiototalSpentOnRadio"
                    label="Total spent on radio ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
              </FormCollapsible>
            )}
          </Field>
          <Field name="ownedMedia.isOwnedMedia" initialValue={true}>
            {() => (
              <FormCollapsible title="Company Activation: Owned Media Impressions">
                <FormRow>
                  <Field
                    name="ownedMedia.numberOfMagazineImpressionsnumberOfMagazineImpressions"
                    label="Number of magazine impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="ownedMedia.numberOfWebsiteImpressionsnumberOfWebsiteImpressions"
                    label="Number of website impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="ownedMedia.numberOfInOfficeImpressionsnumberOfInOfficeImpressions"
                    label="Number of in-office impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="ownedMedia.numberOfECommunicationsImpressionsnumberOfECommunicationsImpressions"
                    label="Number of e-communications impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="ownedMedia.numberOfCollateralImpressionsnumberOfCollateralImpressions"
                    label="Number of collateral impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="ownedMedia.numberOfInStoreImpressionsnumberOfInStoreImpressions"
                    label="Number of in-store impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="ownedMedia.numberOfEventParticipantsnumberOfEventParticipants"
                    label="Number of event participants"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="ownedMedia.numberOfEmployeeImpressionsnumberOfEmployeeImpressions"
                    label="Number of employee impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
              </FormCollapsible>
            )}
          </Field>

          <Field
            name="socialMedia.wouldYouLikeToSubmitAdditionalSocialMediaMetricsForThisPartnership"
            initialValue={true}
          >
            {() => (
              <FormCollapsible title="Social Media Impressions">
                <FormRow>
                  <Field
                    name="socialMedia.totalNumberOfAdditionalSocialMediaImpressions"
                    label="Additional impressions through Social Media"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
              </FormCollapsible>
            )}
          </Field>
          <FormTitleSecondary>Bottom Line Impact</FormTitleSecondary>
          <FormRow>
            <Field
              name="customerLoyalty"
              label="Customer Loyalty (%)"
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
            />
            <Field
              name="customerParticipation"
              label="Customer Participation (%)"
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
            />

            <Field
              name="employeeLoyalty"
              label="Employee Loyalty (%)"
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
            />

            <Field
              name="employeeParticipation"
              label="Employee Participation (%)"
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
            />

            <Field
              name="salesDriven"
              label="Sales Driven ($)"
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(integerValidator)}
            />
            <Field
              name="savedInMitigatedRisks"
              label="Saved in Mitigated Risks ($)"
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(integerValidator)}
            />
          </FormRow>

          <FormTitle>Additional Partnership Benefits</FormTitle>
          <FormRow>
            <Field
              name="stewardship.DidYourPartnerSupportYouWithAnyOfTheFollowingOpportunities"
              label="Stewardship Elements (multi-select)"
              component={FiledMultiSelectWithCheckBoxes}
              formatValue={formatValueEnum.BASE_ARRAY}
              options={[
                { label: 'Stewardship', value: 'Stewardship' },
                { label: 'Communication Plans', value: 'Communication Plans' },
              ]}
            />
            <Field
              name="stewardship.stewardshipitems"
              label="Stewardship Activities (multi-select)"
              component={FiledMultiSelectWithCheckBoxes}
              formatValue={formatValueEnum.BASE_ARRAY}
              options={[
                { label: 'Annual Kick-Off', value: 'Annual Kick-Off' },
                {
                  label: 'Monthly Partner Newsletter',
                  value: 'Monthly Partner Newsletter',
                },
                {
                  label: 'Partner Feature in Monthly Partner Newsletter',
                  value: 'Partner Feature in Monthly Partner Newsletter',
                },
                {
                  label: 'Monthly Partner Meeting',
                  value: 'Monthly Partner Meeting',
                },
                {
                  label: 'Quarterly Partner Meeting',
                  value: 'Quarterly Partner Meeting',
                },
                { label: 'Partner Toolkit', value: 'Partner Toolkit' },
                { label: 'VIP Event', value: 'VIP Event' },
                {
                  label: 'Speaking Engagement at VIP Event',
                  value: 'Speaking Engagement at VIP Event',
                },
                {
                  label: 'Partner Appreciation Event',
                  value: 'Partner Appreciation Event',
                },
                {
                  label: 'Annual Partner Satisfaction Survey',
                  value: 'Annual Partner Satisfaction Survey',
                },
                {
                  label: 'Personalized Executive Thank You',
                  value: 'Personalized Executive Thank You',
                },
                { label: 'Annual Review Meeting', value: 'Annual Review Meeting' },
                {
                  label: 'Additional Custom Stewardship Activation',
                  value: 'Additional Custom Stewardship Activation',
                },
              ]}
            />
          </FormRow>

          <FormTitle>Social Value</FormTitle>
          <FormTitleSecondary>Qualitative</FormTitleSecondary>
          <Field
            name="missionImpact"
            label={
              <TooltipLabelWrapper>
                Mission Impact
                <IconTooltipBlue>
                  Explain how this partnership has served its mission to impact the
                  intended audience (i.e. lives saved, people fed, acres cleaned, dollars
                  invested on research)
                </IconTooltipBlue>
              </TooltipLabelWrapper>
            }
            component={FieldTextArea}
            placeholder="Maxium limit: 500 characters"
            hidePrefix
            validate={maxLength500}
          />
          <Field
            name="missionAdvancement"
            label={
              <TooltipLabelWrapper>
                Mission Advancement (%)
                <IconTooltipBlue>
                  Over the last 12 months, what positive change did your organization
                  make?
                </IconTooltipBlue>
              </TooltipLabelWrapper>
            }
            component={MaskedMoneyTextInput}
            hidePrefix
            validate={composeValidators(integerValidator, percentageOneHundredValidator)}
          />
          <FormRow>
            <Field
              name="scopeOfImpact"
              label="Scope of Impact"
              component={FiledMultiSelectWithCheckBoxes}
              formatValue={formatValueEnum.BASE_ARRAY}
              options={[
                { label: 'Global', value: 'Global' },
                {
                  label: 'International',
                  value: 'International',
                },
                {
                  label: 'National',
                  value: 'National',
                },
                { label: 'Regional', value: 'Regional' },
                { label: 'State/Province-wide', value: 'State/Province-wide' },
              ]}
            />
            <Field
              name="sdgGoals"
              label="SDG Goals"
              component={FiledMultiSelectWithCheckBoxes}
              formatValue={formatValueEnum.BASE_ARRAY}
              options={[
                { label: '(1) No Poverty', value: 'noPoverty' },
                { label: '(2) Zero Hunger', value: 'zeroHunger' },
                {
                  label: '(3) Good Health & Well-Being',
                  value: 'goodHealthAndWellBeing',
                },
                { label: '(4) Quality Education', value: 'qualityEducation' },
                { label: '(5) Gender Equality', value: 'genderEquality' },
                {
                  label: '(6) Clean Water & Sanitation',
                  value: 'cleanWaterAndSanitation',
                },
                {
                  label: '(7) Affordable & Clean Energy',
                  value: 'affordableAndCleanEnergy',
                },
                {
                  label: '(8) Decent Work & Economic Growth',
                  value: 'decentWorkAndEconomicGrowth',
                },
                {
                  label: '(9) Industry, Innovation & Infrastructure',
                  value: 'industryInnovationAndInfrastructure',
                },
                { label: '(10) Reduced Inequality', value: 'reducedInequalities' },
                {
                  label: '(11) Sustainable Cities & Communities',
                  value: 'sustainableCitiesAndCommunities',
                },
                {
                  label: '(12) Responsible Consumption & Production',
                  value: 'responsibleConsumptionAndProduction',
                },
                { label: '(13) Climate Action', value: 'climateAction' },
                { label: '(14) Life Below Water', value: 'lifeBelowWater' },
                { label: '(15) Life on Land', value: 'lifeOnLand' },
                {
                  label: '(16) Peace & Justice Strong Institutions',
                  value: 'peaceJusticeAndStrongInstitutions',
                },
                {
                  label: '(17) Partnerships to Achieve the Goal',
                  value: 'partnershipsForTheGoals',
                },
              ]}
            />
            <Field
              name="sdgTargets"
              label="SDG Targets"
              component={FieldTextInput}
              validate={onlyNumberLowercaseAndCommaValidator}
            />
            <Field
              name="sdgIndicators"
              label="SDG Indicators"
              component={FieldTextInput}
              validate={onlyNumberLowercaseAndCommaValidator}
            />
          </FormRow>

          <ButtonGroup>
            <Button title="Cancel" modifiers="secondary" handleClick={() => go(-1)} />
            <Button title="Save" modifiers="secondary" handleClick={handleSubmit} />
          </ButtonGroup>
        </FormBody>
      )}
    />
  );
};

const TooltipLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconTooltipBlue = styled(IconTooltip)`
  margin-left: 6px;

  svg {
    width: 14px;
    height: 14px;
  }

  svg * {
    fill: var(--blue);
  }
`;

export default PartnerDataIntakeForm;
