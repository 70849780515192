/* eslint-disable react-hooks/exhaustive-deps */
import Jumbotron from 'components/Jumbotron';
import { useOperation } from 'hooks/common';
import { CompanyWrapper } from 'layouts';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCompany } from 'store/ducks/companies/operations';
import { getCompanyById } from 'store/ducks/companies/selectors';
import { fetchROIListByCompany } from 'store/ducks/roi/operations';
import { getROIList } from 'store/ducks/roi/selectors';
import ROIView from '../components/ROIView';

const CompanyROI = () => {
  const [onFetchCompany, isLoading] = useOperation(fetchCompany);
  const [onFetchROIList] = useOperation(fetchROIListByCompany);

  const params: { companyId: string } = useParams();

  const company = useSelector(getCompanyById(params.companyId));
  const { items, meta } = useSelector(getROIList);

  useEffect(() => {
    onFetchCompany(params.companyId);
    onFetchROIList({ page: 1, companyId: params.companyId });
  }, [params.companyId]);

  return (
    <CompanyWrapper
      companyId={params.companyId}
      isLoading={isLoading}
      header={<Jumbotron name={company?.name || ''} back backPath="/companies" />}
    >
      {company && (
        <ROIView
          company={company}
          roiList={items}
          roiMeta={meta}
          handleFetchROIList={onFetchROIList}
        />
      )}
    </CompanyWrapper>
  );
};

export default CompanyROI;
