import styled from 'styled-components';

interface Props {
  type?: 'horizontal' | 'vertical';
  height?: number;
}

const UIDivider = styled.div<Props>`
  background-color: #dcdcdc;

  ${({ type = 'vertical', height }) => {
    switch (type) {
      case 'vertical':
        return `
        height: ${height || 24}px;
        width: 1px;
        margin: 0 8px;
        `;
      case 'horizontal':
        return `
        width: 100%;
        height: ${height || 1}px;
        margin: 24px 0;
        `;
    }
  }}
`;

export default UIDivider;
