import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { BaseMultiSelectProps } from 'components/UI/interfaces';
import { MultiSelectWithCheckBoxes } from 'components/UI/select';
import { ISelectProps } from 'react-multi-select-component/dist/lib/interfaces';
import FieldSection from '../FieldSection';

type Props = BaseMultiSelectProps & ISelectProps & FieldRenderProps<any>;

const FiledMultiSelectWithCheckBoxes = ({
  input,
  label,
  meta,
  ...selectProps
}: Props) => {
  const handleClick = useCallback(
    valueType => {
      input.onChange(valueType);
    },
    [input],
  );

  return (
    <FieldSection input={input} meta={meta} label={label}>
      <MultiSelectWithCheckBoxes
        values={input.value}
        changeHandler={handleClick}
        {...selectProps}
      />
    </FieldSection>
  );
};

export default FiledMultiSelectWithCheckBoxes;
