import React from 'react';
import { ReactSVG } from 'react-svg';
import useDropdown from 'use-dropdown';
import styled from 'styled-components';

import TooltipIcon from 'assets/images/icons/info.svg';

export interface TooltipProps {
  children: React.ReactNode;
  className?: string;
}

const Tooltip = ({ children, className }: TooltipProps) => {
  const [containerRef, isOpen, open, close] = useDropdown();

  return (
    <Wrapper ref={containerRef} className={className}>
      <Trigger onMouseOver={open} onMouseOut={close}>
        <ReactSVG className="text-tooltip__trigger" src={TooltipIcon} />
      </Trigger>

      {isOpen && (
        <Body className="body">
          <Inner>{children}</Inner>
        </Body>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Body = styled.div`
  position: absolute;
  width: 252px;
  left: -114px;
  bottom: 36px;
  z-index: 10;
`;

const Trigger = styled.div`
  cursor: help;
`;

const Inner = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  font-size: 12px;
  line-height: 150%;
  padding: 9px 14px;
  text-align: center;
  box-shadow: 0px 2px 20px rgba(40, 31, 61, 0.16);
  z-index: 9;
  &:after {
    content: '';
    position: absolute;
    left: 116px;
    bottom: -8px;
    width: 20px;
    height: 20px;
    z-index: -1;
    background: #ffffff;
    border-radius: 4px;
    transform: rotate(45deg);
  }
`;

export default Tooltip;
