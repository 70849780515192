import React, { useState } from 'react';
import styled from 'styled-components';
import { PricingData } from 'interfaces/pricing';
import { format } from 'date-fns';
import ValuationStatusTag from './ValuationStatusTag';
import { Link, useParams } from 'react-router-dom';
import { UIButton } from 'components/UI';
import { useModal } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
import { ValuationStatusEnum } from 'constants/pricing';

interface Props {
  rows: PricingData[];
  headCells: string[];
  handleFetchDeleteValuation: (id: string) => Promise<unknown>;
}

interface TableRowProps {
  row: PricingData;
  handleFetchDeleteValuation: (id: string) => Promise<unknown>;
}

const userTableWidth = {
  column1: 360,
  column2: 240,
  column3: 240,
  column4: 200,
  column5: 100,
} as const;

const { column1, column2, column3, column4, column5 } = userTableWidth;

const TableRowWrapper = ({ row, handleFetchDeleteValuation }: TableRowProps) => {
  const { id, name, dateSubmitted, dateCompleted, status } = row;
  const params = useParams<{ teamId: string; valuationId: string }>();

  const [isModalLoading, setIsModalLoading] = useState(false);

  const [showConfirmModal, hideConfirmModal] = useModal(
    () => (
      <ConfirmModal
        accessHandler={() => {
          setIsModalLoading(true);
          handleFetchDeleteValuation(row.id).then(() => {
            setIsModalLoading(false);
            hideConfirmModal();
          });
        }}
        isLoading={isModalLoading}
        cancelHandler={hideConfirmModal}
        name="Delete Valuation"
        description="Are you sure to delete this valuation?"
        textAccessBtn="Delete"
        onClose={hideConfirmModal}
      />
    ),
    [isModalLoading],
  );

  return (
    <TableRow>
      <TableRowCell>
        <TextLink
          to={
            status === ValuationStatusEnum.Completed
              ? `/pricing/report/${params.teamId}/${id}`
              : `/pricing/edit/${params.teamId}/${id}`
          }
        >
          {name}
        </TextLink>
      </TableRowCell>

      <TableRowCell>
        {dateSubmitted ? format(new Date(dateSubmitted), 'MMM dd, yyyy') : 'N/A'}
      </TableRowCell>

      <TableRowCell>
        {dateCompleted ? format(new Date(dateCompleted), 'MMM dd, yyyy') : 'N/A'}
      </TableRowCell>

      <TableRowCell>
        <ValuationStatusTag type={status} />
      </TableRowCell>

      <TableRowCell>
        <RemoveBtn modifiers="danger" handleClick={showConfirmModal} title="Delete" />
      </TableRowCell>

      <TableRowCell />
    </TableRow>
  );
};

const PricingTable = ({ rows, headCells, handleFetchDeleteValuation }: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${column1}px` }} />
          <col span={1} style={{ width: `${column2}px` }} />
          <col span={1} style={{ width: `${column3}px` }} />
          <col span={1} style={{ width: `${column4}px` }} />
          <col span={1} style={{ width: `${column5}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            {headCells.map((item, index) => (
              <TableHeadCell key={index}>{item}</TableHeadCell>
            ))}
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(item => (
            <TableRowWrapper
              key={item.id}
              row={item}
              handleFetchDeleteValuation={handleFetchDeleteValuation}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  height: 440px;
  margin-bottom: 30px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  background: #f4f4f4;
  position: sticky;
  top: 0;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

const TextLink = styled(Link)`
  color: var(--blue);
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
`;

const RemoveBtn = styled(UIButton)`
  width: 56px;
  font-size: 12px;
  padding: 3px 0;
`;

export default PricingTable;
