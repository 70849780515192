/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MainWrapper } from 'layouts';
import { useOperation } from 'hooks/common';
import {
  fetchUser,
  fetchUserUpdate,
  fetchChangePassword,
  fetchUserSetAsOwner,
} from 'store/ducks/user/operations';
import { getUserById } from 'store/ducks/user/selectors';
import { StateProps } from 'store/interfaces';
import { User, PasswordData } from 'interfaces/user';
import UserProfileForm from 'components/UserProfileForm';
import PasswordForm from 'components/PasswordForm';
import Jumbotron from 'components/Jumbotron';
import History from 'services/History';
import { fetchTeamsSuggested } from 'store/ducks/team/operations';
import { getTeamsSuggested } from 'store/ducks/team/selectors';
import { TeamData } from 'interfaces/team';
import { debounce } from 'lodash';

const EditTeamsUser = () => {
  const [onfetchUser, isLoading] = useOperation(fetchUser);
  const [onfetchChangePassword] = useOperation(fetchChangePassword);
  const [onFetchUserSetAsOwner] = useOperation(fetchUserSetAsOwner);
  const [onSearchTeams] = useOperation(fetchTeamsSuggested);

  const params: { userId: string } = useParams();

  const user = useSelector<StateProps, User | undefined>(getUserById(params.userId));
  const teamsSearchList = useSelector<StateProps, TeamData[]>(getTeamsSuggested);

  useEffect(() => {
    onfetchUser(params.userId);
  }, [params.userId]);

  const [updatedUserInfo] = useOperation(fetchUserUpdate);

  const onUpdateUser = useCallback((values: User) => {
    return updatedUserInfo({ id: params.userId, data: values }).then(() => {
      History.back();
    });
  }, []);

  const onChangePassword = useCallback((values: PasswordData) => {
    return onfetchChangePassword({ id: params.userId, data: values }).then(() => {
      History.back();
    });
  }, []);

  const handleSearchTeams = (val: string) => {
    return onSearchTeams(val);
  };

  const handleSearchTeamsDebounce = useCallback(debounce(handleSearchTeams, 800), []);

  return (
    <MainWrapper
      isLoading={isLoading}
      withOutNav
      header={<Jumbotron name="Teams User Profile" back />}
    >
      {user && (
        <>
          <UserProfileForm
            initialValues={user}
            onSubmit={onUpdateUser}
            teamsSearchList={teamsSearchList}
            handleGetTeams={handleSearchTeamsDebounce}
            handleSetAsOwner={onFetchUserSetAsOwner}
          />
          <PasswordForm onSubmit={onChangePassword} />
        </>
      )}
    </MainWrapper>
  );
};

export default EditTeamsUser;
