import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { UICheckbox } from 'components/UI';
import styled from 'styled-components';
import { CRSFocusItem } from 'components/UI/interfaces';
import { CRSFocus } from 'store/interfaces';

interface FieldCheckBoxGroupByIdProps extends FieldRenderProps<CRSFocus[]> {
  options: CRSFocusItem[];
}

const FieldCheckBoxGroupById = ({
  input,
  options,
  ...props
}: FieldCheckBoxGroupByIdProps) => {
  const { value, onChange } = input;

  const onSetValue = useCallback(
    val => {
      const isValue = value && !!value.find((item: CRSFocus) => item.id === val.id);

      if (isValue) return onChange(value.filter((item: CRSFocus) => item.id !== val.id));

      return onChange(value.length > 0 ? value.concat(val) : [val]);
    },
    [value, onChange],
  );

  return (
    <Field>
      <CheckboxList>
        {options.map(({ id, name }: CRSFocusItem, index: number) => {
          const isCheck = value && !!value.find((item: CRSFocus) => item.id === id);
          return (
            <CheckItem
              {...props}
              key={index}
              check={isCheck}
              label={`${index + 1}. ${name}`}
              handleClick={() => onSetValue({ id, name })}
            />
          );
        })}
      </CheckboxList>
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const CheckboxList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CheckItem = styled(UICheckbox)`
  margin-bottom: 16px;
  width: 50%;
`;

export default FieldCheckBoxGroupById;
