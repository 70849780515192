import { Field } from 'components/FinalForm';
import { FieldDatePicker, FieldTextArea, FieldTextInput } from 'components/FormFields';
import { UIDivider } from 'components/UI';
import { TrackingStatusEnum } from 'constants/roi';
import { format, isAfter, subMonths } from 'date-fns';
import { ROIData, ROIMeltwaterData } from 'interfaces/roi';
import React, { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { getUTCAsLocalDate } from 'utils';
import { notQuote, required } from 'utils/validation';
import {
  Asterisk,
  Button,
  ButtonGroup,
  FieldDoubleRow,
  FieldWrapper,
  RightButtonGroup,
} from './styled';

interface Props {
  disabled?: boolean;
  roi: ROIData;
  meltwaterData?: ROIMeltwaterData;
  onTrack: (values: ROIData) => Promise<unknown>;
  onRevert: () => Promise<unknown>;
}

const MeasureForm = ({ roi, meltwaterData, disabled, onTrack, onRevert }: Props) => {
  const { go } = useHistory();
  const [isTrackingLoading, setIsTrackingLoading] = useState(false);

  const getSubmitData = (values: ROIData) => {
    return {
      ...values,
      startDate: values.startDate && values.startDate.split('T')[0],
      endDate: values.endDate && values.endDate.split('T')[0],
    };
  };

  const createQueryStr = useCallback((roi: ROIData): ROIData['generatedQuery'] => {
    const companyPart = roi.companyName && `"${roi.companyName}"`;

    const partnerSocialHandlesPart =
      roi.partnerSocialHandles
        ?.split(',')
        .filter(item => item)
        .map((item, i) => {
          const word = item.trim().replace(/@/g, '');
          if (i) return `OR @${word} OR from:"${word}" `;
          return `@${word} OR from:"${word}" `;
        })
        .join('')
        .trim() || '';

    let companyQuery = [companyPart, partnerSocialHandlesPart].reduce((prev, curr) => {
      if (!curr) return prev;
      if (!prev) return curr;
      return `${prev} OR ${curr}`;
    }, '');

    if (companyQuery) companyQuery = `(${companyQuery})`;

    const campaignPart =
      roi.campaignKeywords
        ?.split(',')
        .filter(item => item)
        .map((item, i) => {
          const word = item.trim().replace(/@/g, '');
          if (i) return `OR "${word}" `;
          return `"${word}" `;
        })
        .join('')
        .trim() || '';

    const hashPart =
      roi.campaignSpecificHashtags
        ?.split(',')
        .filter(item => item)
        .map((item, i) => {
          const word = item.trim().replace(/#/g, '');
          if (i) return `OR hashtag:"${word}" `;
          return `hashtag:"${word}" `;
        })
        .join('')
        .trim() || '';

    const handlePart =
      [
        ...(roi.nonprofitSocialHandles?.split(',') || []),
        ...(roi.influencerHandles?.split(',') || []),
      ]
        .filter(item => item)
        .map((item, i) => {
          const word = item.trim().replace(/@/g, '');
          if (i) return `OR @${word} OR from:"${word}" `;
          return `@${word} OR from:"${word}" `;
        })
        .join('')
        .trim() || '';

    let otherQuery = [campaignPart, hashPart, handlePart].reduce((prev, curr) => {
      if (!curr) return prev;
      if (!prev) return curr;
      return `${prev} OR ${curr}`;
    }, '');

    if (otherQuery) otherQuery = `(${otherQuery})`;

    return [companyQuery, otherQuery].reduce((prev, curr) => {
      if (!curr) return prev;
      if (!prev) return curr;
      return `${companyQuery} AND ${otherQuery}`;
    }, '');
  }, []);

  return (
    <Form
      initialValues={{
        ...roi,
        startDate:
          roi.startDate && format(getUTCAsLocalDate(roi.startDate), 'yyyy-MM-dd'),
        endDate: roi.endDate && format(getUTCAsLocalDate(roi.endDate), 'yyyy-MM-dd'),
        generatedQuery:
          roi.trackingStatus !== TrackingStatusEnum.PendingAdmin
            ? roi.generatedQuery
            : createQueryStr(roi),
      }}
      onSubmit={(values: ROIData) => {
        setIsTrackingLoading(true);
        onTrack(getSubmitData(values)).then(() => setIsTrackingLoading(false));
      }}
      render={({ handleSubmit, values, form }) => (
        <>
          <FieldWrapper>
            <Field
              name="campaignKeywords"
              label="Campaign Keywords"
              component={FieldTextInput}
              placeholder="separate keywords with comma"
              placeholderSize={13}
              validate={notQuote}
              disabled={disabled}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              name="influencerHandles"
              label="Influencer Handles (include celebrities, bloggers, experts, etc.) "
              component={FieldTextInput}
              placeholder="separate handles with comma"
              placeholderSize={13}
              validate={notQuote}
              disabled={disabled}
            />
          </FieldWrapper>
          <FieldDoubleRow>
            <Field
              name="partnerSocialHandles"
              label="Partner Social Handles (include all networks) "
              component={FieldTextInput}
              placeholder="separate handles with comma"
              placeholderSize={13}
              validate={notQuote}
              disabled={disabled}
            />
            <Field
              name="nonprofitSocialHandles"
              label="Nonprofit Social Handles (include all networks)"
              component={FieldTextInput}
              placeholder="separate handles with comma"
              placeholderSize={13}
              validate={notQuote}
              disabled={disabled}
            />
          </FieldDoubleRow>

          <FieldDoubleRow>
            <Field name="startDate" validate={required}>
              {({ input, meta }) => (
                <FieldDatePicker
                  label="Start Date"
                  input={{
                    ...input,
                    onChange: value => {
                      const isStartAfterEnd = isAfter(
                        new Date(value),
                        new Date(String(values.endDate)),
                      );

                      if (isStartAfterEnd) {
                        form.change('endDate', undefined);
                      }
                      input.onChange(value);
                    },
                  }}
                  meta={meta}
                  disabled={disabled}
                  showMonthDropdown
                  dateFormat="dd MMM yyyy"
                  minDate={subMonths(new Date(), 13)}
                />
              )}
            </Field>
            <Field
              name="endDate"
              label="End Date"
              component={FieldDatePicker}
              placeholder="select"
              disabled={disabled}
              showMonthDropdown
              validate={required}
              dateFormat="dd MMM yyyy"
              minDate={new Date(String(values.startDate))}
            />
          </FieldDoubleRow>
          <UIDivider type="horizontal" />
          <Tip>*Admin only</Tip>
          <FieldWrapper>
            <Field
              name="generatedQuery"
              label={
                <>
                  <span>Generated Query</span>
                  <Asterisk />
                </>
              }
              component={FieldTextArea}
              validate={required}
              disabled={disabled}
            />
          </FieldWrapper>

          <ButtonGroup>
            <Button title="Cancel" modifiers="secondary" handleClick={() => go(-1)} />
            <Button
              title="Re-generate Query"
              modifiers="secondary"
              handleClick={() => form.change('generatedQuery', createQueryStr(values))}
            />
            <TrackingBtn
              title={
                meltwaterData || roi.trackingStatus === TrackingStatusEnum.Complete
                  ? 'Re-generate Tracking'
                  : 'Generate Tracking'
              }
              modifiers="primary"
              handleClick={handleSubmit}
              disabled={disabled}
              isLoading={isTrackingLoading}
            />
            {roi.trackingStatus === TrackingStatusEnum.PendingAdmin && (
              <RightButtonGroup>
                <Button
                  title="Revert"
                  modifiers="danger"
                  disabled={disabled}
                  handleClick={onRevert}
                />
              </RightButtonGroup>
            )}
          </ButtonGroup>
        </>
      )}
    />
  );
};

const Tip = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;

  color: #e92d30;
`;

const TrackingBtn = styled(Button)`
  width: 218px;
`;

export default MeasureForm;
