import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import InfoSVG from 'assets/images/icons/info.svg';
import { ReactSVG } from 'react-svg';

interface Props {
  type: 'info';
  className?: string;
}

const UIAlert: FC<Props> = ({ children, className, type }) => {
  const icon = useMemo(() => {
    switch (type) {
      case 'info':
        return InfoSVG;
      default:
        return InfoSVG;
    }
  }, [type]);
  return (
    <Root className={className}>
      <Icon src={icon} />
      {children}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  padding: 24px;
  background-color: #dbf2fb;
  border-radius: 6px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
`;

const Icon = styled(ReactSVG)`
  margin: 0 18px 0 24px;

  svg * {
    fill: #2baee0;
  }
`;

export default UIAlert;
