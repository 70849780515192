import { EinListRes } from 'interfaces/campaign';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import { fetchErrorHandler } from 'utils/errorHandlers';
import * as actions from './actions';
import * as api from './api';

export const fetchEinList = (name: string) => async (dispatch: Dispatch) => {
  return api
    .fetchEinList(name)
    .then((data: EinListRes) => {
      dispatch(actions.setEinSuggest(data));
      return data;
    })
    .catch(err => {
      Toast.error('Error get list');
      throw err;
    });
};

export const fetchChangePpgf = (params: {
  paypalGivingFundId: string;
  teamId: string;
}) => async () => {
  return api.fetchChangePpgf(params).catch(fetchErrorHandler);
};
