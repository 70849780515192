import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import { PaginationProps } from 'interfaces/common';
import { usePagination } from 'hooks/common';
import ArrowLeftIcon from 'assets/images/icons/arrow-left.svg';

const Pagination = (props: PaginationProps) => {
  const {
    handleNext,
    handlePrev,
    firstNumToPage,
    secondNumToPage,
    isFirstPage,
    isLastPage,
  } = usePagination(props);

  if (props.total === 0) return null;

  return (
    <Root>
      {!isFirstPage && (
        <ButtonAction onClick={handlePrev}>
          <ArrowLeftIconWrapper src={ArrowLeftIcon} />
        </ButtonAction>
      )}

      <PaginationPage>
        {`${firstNumToPage}-${secondNumToPage} of ${props.total}`}
      </PaginationPage>

      {!isLastPage && (
        <ButtonAction onClick={handleNext}>
          <ArrowRightIcon src={ArrowLeftIcon} />
        </ButtonAction>
      )}
    </Root>
  );
};

const ButtonAction = styled.button``;

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const PaginationPage = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  cursor: default;
`;

const ArrowLeftIconWrapper = styled(ReactSVG)`
  margin-right: 18px;
`;

const ArrowRightIcon = styled(ReactSVG)`
  transform: rotate(180deg);
  margin-left: 18px;
`;

export default Pagination;
