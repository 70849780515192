/* eslint-disable react-hooks/exhaustive-deps */
import Jumbotron from 'components/Jumbotron';
import { useOperation } from 'hooks/common';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchROI } from 'store/ducks/roi/operations';
import { getROI } from 'store/ducks/roi/selectors';
import styled from 'styled-components';
import Measure from '../components/Measure';

const ROIEdit = () => {
  const params = useParams<{ teamId: string; roiId: string }>();

  const [onFetchROI] = useOperation(fetchROI);

  const roi = useSelector(getROI);

  useEffect(() => {
    if (!params.roiId) return;
    onFetchROI(params.roiId);
  }, [params]);

  return (
    <ContentBlockWrapper
      header={<Jumbotron name={roi?.name} back backPath={`/roi/view/${params.teamId}`} />}
    >
      <Body>{roi && <Measure />}</Body>
    </ContentBlockWrapper>
  );
};

const Body = styled.div`
  max-width: 1095px;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  margin-bottom: 24px;
  display: flex;

  & > * {
    cursor: pointer;
  }
`;

export default ROIEdit;
