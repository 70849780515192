/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { callOperationAsync } from 'utils';

type isExecuting = {} & boolean;

interface Operation<TPayload, TResponse> {
  (args: TPayload, ...rest: any): (dispatch, getState?) => Promise<TResponse>;
}

export default <TPayload, TResponse>(operation: Operation<TPayload, TResponse>) => {
  const [isExecuting, setIsExecuting] = useState<isExecuting>(true);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const execute = useCallback(
    (args: TPayload, ...rest: any) =>
      callOperationAsync<TResponse>(
        () => dispatch(operation(args, ...rest)),
        setIsExecuting,
        setIsLoading,
      ),
    [],
  );

  return [execute, isExecuting, isLoading] as const;
};
