import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CropText, UIButton } from 'components/UI';
import { ReactSVG } from 'react-svg';
import { Company } from 'interfaces/companies';
import { parseLink } from 'utils';
import { useModal } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
import DoneIcon from 'assets/images/icons/done.svg';
import NotAvailableIcon from 'assets/images/icons/not-available.svg';

interface Props {
  rows: Company[];
  headCells: string[];
  handleRemoveCompany: (id: string) => void;
}

interface TableRowProps {
  row: Company;
  handleRemoveCompany: (id: string) => void;
}

const userTableWidth = {
  column1: 170,
  column2: 200,
  column3: 200,
  column4: 120,
  column5: 120,
  column6: 120,
  column7: 180,
  column8: 20,
  column9: 100,
} as const;

const {
  column1,
  column2,
  column3,
  column4,
  column5,
  column6,
  column7,
  column8,
  column9,
} = userTableWidth;

const renderAvailable = (val: boolean) => {
  if (val) {
    return (
      <AvailableWrapper>
        <ReactSVG src={DoneIcon} />
      </AvailableWrapper>
    );
  }
  return (
    <AvailableWrapper>
      <ReactSVG src={NotAvailableIcon} />
    </AvailableWrapper>
  );
};

const TableRowWrapper = ({ row, handleRemoveCompany }: TableRowProps) => {
  const {
    id,
    name,
    phone,
    website,
    country,
    state,
    city,
    street,
    employeeContributions,
    cashContributions,
    annualContributions,
    inKindContributions,
    sdgGoals,
    partnershipLink,
    typesOfInvestment,
  } = row;

  const [showModal, hideModal] = useModal(() => {
    const deleteHandler = () => {
      handleRemoveCompany(id);
      hideModal();
    };

    return (
      <ConfirmModal
        name={'Delete company'}
        textAccessBtn="Delete"
        description={'Are you sure you want to delete the company?'}
        accessHandler={deleteHandler}
        cancelHandler={hideModal}
        onClose={hideModal}
        hideCloseIcon
      />
    );
  });

  const isContributions = !!(
    employeeContributions ||
    cashContributions ||
    annualContributions ||
    inKindContributions
  );

  const isGoals = sdgGoals && sdgGoals.length > 0;
  const isTypesOfInvestment = typesOfInvestment && typesOfInvestment.length > 0;
  const isAvailable =
    isContributions || isGoals || isTypesOfInvestment || partnershipLink;

  const link = parseLink(website);

  return (
    <TableRow>
      <TableRowCell style={{ width: `${column1}px` }}>
        <TableLinkWrapper width={column1 - 30}>
          <TableLink to={`/company/${id}`}>{name || 'Profile'}</TableLink>
        </TableLinkWrapper>
      </TableRowCell>

      <TableRowCell style={{ width: `${column2}px` }}>
        <TableLinkWrapper width={column2 - 10}>
          {phone ? <TableLinkA href={`tel:${phone}`}>{phone}</TableLinkA> : '-'}
        </TableLinkWrapper>
      </TableRowCell>

      <TableRowCell style={{ width: `${column3}px` }}>
        <TableLinkWrapper width={column3 - 10}>
          {website ? (
            <TableLinkA href={link} target="_blank">
              {website}
            </TableLinkA>
          ) : (
            '-'
          )}
        </TableLinkWrapper>
      </TableRowCell>

      <TableRowCell style={{ width: `${column4}px` }}>
        <CropText width={column4 - 20} text={country || '-'} />
      </TableRowCell>

      <TableRowCell style={{ width: `${column5}px` }}>
        <CropText width={column5 - 20} text={state || '-'} />
      </TableRowCell>
      <TableRowCell style={{ width: `${column6}px` }}>
        <CropText width={column6 - 20} text={city || '-'} />
      </TableRowCell>
      <TableRowCell style={{ width: `${column7}px` }}>
        <p>{street || '-'}</p>
      </TableRowCell>
      <TableRowCell style={{ width: `${column8}px` }}>
        {renderAvailable(!!isAvailable)}
      </TableRowCell>

      <TableRowCell style={{ width: `${column9}px` }}>
        <RemoveBtn modifiers="danger" handleClick={showModal} title="Delete" />
      </TableRowCell>
    </TableRow>
  );
};

const CompaniesTable = ({ rows, headCells, handleRemoveCompany }: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${column1}px` }} />
          <col span={1} style={{ width: `${column2}px` }} />
          <col span={1} style={{ width: `${column3}px` }} />
          <col span={1} style={{ width: `${column4}px` }} />
          <col span={1} style={{ width: `${column5}px` }} />
          <col span={1} style={{ width: `${column6}px` }} />
          <col span={1} style={{ width: `${column7}px` }} />
          <col span={1} style={{ width: `${column8}px` }} />
          <col span={1} style={{ width: `${column9}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            {headCells.map((item, index) => {
              const width = userTableWidth[`column${index + 1}`];

              return (
                <TableHeadCell key={index} style={{ width: `${width}px` }}>
                  {item}
                </TableHeadCell>
              );
            })}
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 &&
            rows.map(item => (
              <TableRowWrapper
                key={item.id}
                row={item}
                handleRemoveCompany={handleRemoveCompany}
              />
            ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const AvailableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const RemoveBtn = styled(UIButton)`
  width: 100%;
  margin-left: 20px;
  font-size: 12px;
  padding: 3px 0;
`;

const TableLinkWrapper = styled.div<{ width?: string | number }>`
  display: block;
  ${({ width }) => width && `width: ${width}px;`}
`;

const TableLink = styled(Link)`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  &:hover {
    text-decoration: underline;
  }
`;

const TableLinkA = styled.a`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

const TableWrapper = styled.div`
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  display: block;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
  width: 100%;
  display: block;
`;

const TableHeadRow = styled.tr`
  height: 39px;
  display: block;
`;

const TableBody = styled.tbody`
  background: #ffffff;
  display: block;
  height: 100%;
  overflow: scroll;
  max-height: calc(100vh - 372px);
  width: 100%;
`;
const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-right: 24px;
  }
`;

const TableRow = styled.tr`
  display: flex;
`;

const TableRowCell = styled.td`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

export default CompaniesTable;
