import React from 'react';
import styled from 'styled-components';
import { Range } from 'react-range';
import { IProps as InjectedProps } from 'react-range/lib/types';
import RangeTrack from './RangeTrack';
import RangeThumb from './RangeThumb';
import { RangeSliderProps } from '../interfaces';

const STEP = 1;
const MIN = 18;
const MAX = 100;

type Props = RangeSliderProps & Partial<InjectedProps>;

const UIRangeSlider = ({
  values,
  step = STEP,
  min = MIN,
  max = MAX,
  onChange,
  ...rest
}: Props) => {
  const handleChange = (vals: number[]) => {
    onChange?.(vals);
  };

  return (
    <Root>
      <Range
        values={values?.length ? values : []}
        step={step}
        min={min}
        max={max}
        onChange={handleChange}
        {...rest}
        renderTrack={({ props, children }) => (
          <RangeTrack
            values={values?.length ? values : []}
            min={min}
            max={max}
            trackProps={props}
          >
            {children}
          </RangeTrack>
        )}
        renderThumb={params => (
          <RangeThumb key={params.index} {...params} thumbProps={params.props} />
        )}
      />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  padding: 0 35px;
`;

export default UIRangeSlider;
