import React, { ChangeEvent, useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { UITextArea } from 'components/UI';
import { UIButton } from 'components/UI';
import styled from 'styled-components';

const FieldTextAriaGroup = ({
  input,
  label,
}: FieldRenderProps<Array<{ text: string }>>) => {
  const { value, onChange } = input;
  const handleChangeValue = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>, index: number) => {
      const newValue = [...value];

      newValue[index] = { text: e.target.value };

      return onChange(newValue);
    },
    [value, onChange],
  );

  const onAddItem = useCallback(() => {
    const newValue = value.length > 0 ? value.concat({ text: '' }) : [{ text: '' }];

    return onChange(newValue);
  }, [value, onChange]);

  const onRemoveItem = useCallback(
    (i: number) => {
      const newValue = value.filter((item, index) => index !== i);
      return onChange(newValue);
    },
    [value, onChange],
  );

  return (
    <Field>
      <List>
        {value &&
          value.map((item: { text: string }, index: number) => {
            return (
              <Item key={index}>
                <LabelWrapper>
                  {label && <Label>{label}</Label>}
                  <Remove onClick={() => onRemoveItem(index)}>Delete</Remove>
                </LabelWrapper>

                <TextArea
                  onChange={e => handleChangeValue(e, index)}
                  value={item?.text}
                />
              </Item>
            );
          })}
      </List>
      {value.length < 3 && (
        <Btn modifiers="third" title="Add" handleClick={onAddItem} type="button" />
      )}
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const Label = styled.label`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
`;

const Remove = styled.span`
  font-size: 12px;
  line-height: 150%;
  color: var(--red);
  cursor: pointer;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const List = styled.div`
  width: 100%;
`;

const Item = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const TextArea = styled(UITextArea)`
  margin-bottom: 16px;
`;

const Btn = styled(UIButton)`
  width: 100%;
`;

export default FieldTextAriaGroup;
