export enum SdgGoals {
  NO_POVERTY = 'noPoverty',
  ZERO_HUNGER = 'zeroHunger',
  GOOD_HEALTH_AND_WELL_BEING = 'goodHealthAndWellBeing',
  QUALITY_EDUCATION = 'qualityEducation',
  GENDER_EQUALITY = 'genderEquality',
  CLEAN_WATER_AND_SANITATION = 'cleanWaterAndSanitation',
  AFFORDABLE_AND_CLEAN_ENERGY = 'affordableAndCleanEnergy',
  DECENT_WORK_AND_ECONOMIC_GROWTH = 'decentWorkAndEconomicGrowth',
  INDUSTRY_INNOVATION_AND_INFRASTRUCTURE = 'industryInnovationAndInfrastructure',
  REDUCED_INEQUALITIES = 'reducedInequalities',
  SUSTAINABLE_CITIES_AND_COMMUNITIES = 'sustainableCitiesAndCommunities',
  RESPONSIBLE_CONSUMPTION_AND_PRODUCTION = 'responsibleConsumptionAndProduction',
  CLIMATE_ACTION = 'climateAction',
  LIFE_BELOW_WATER = 'lifeBelowWater',
  LIFE_ON_LAND = 'lifeOnLand',
  PEACE_JUSTICE_AND_STRONG_INSTITUTIONS = 'peaceJusticeAndStrongInstitutions',
  PARTNERSHIPS_FOR_THE_GOALS = 'partnershipsForTheGoals',
}
