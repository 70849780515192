/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { SingleSelect } from 'components/UI';
import styled from 'styled-components';
import { FormError } from 'components/UI';
import { SingleSelectProps } from 'components/UI/interfaces';
import { FormLabel } from 'components/UI/form';

interface FieldSelectProps
  extends SingleSelectProps,
    FieldRenderProps<string | number | { value: string | number } | null> {
  objectField: boolean;
  onFieldChange?: (value: FieldSelectProps['options'][0]['value']) => void;
}

const FieldSingleSelect = ({
  input,
  label,
  meta,
  objectField = false,
  placeholder = 'Select',
  onFieldChange,
  ...selectProps
}: FieldSelectProps) => {
  const { error, submitError, touched, dirtySinceLastSubmit } = meta;
  const isError = (error && touched) || (submitError && !dirtySinceLastSubmit);

  const handleClick = useCallback(
    valueType => {
      input.onChange(objectField ? valueType : valueType?.value);
      onFieldChange?.(objectField ? valueType : valueType?.value);
    },
    [input],
  );
  const val = typeof input.value === 'object' ? (input.value as any).value : input.value;

  return (
    <Field>
      {label && <FormLabel>{label}</FormLabel>}
      <SingleSelect
        {...selectProps}
        {...{ objectField: objectField }}
        placeholder={placeholder}
        value={val}
        handleSelect={handleClick}
      />
      {isError && <Error meta={meta} />}
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const Error = styled(FormError)`
  position: absolute;
`;

export default FieldSingleSelect;
