import React, { useCallback, useEffect } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { ReactSVG } from 'react-svg';
import { FormError, UISuggest } from 'components/UI';
import styled from 'styled-components';
import { UISuggestProps } from 'components/UI/interfaces';
import CloseIcon from 'assets/images/icons/close.svg';
import { FormLabel } from 'components/UI/form';

interface FieldSuggestProps extends UISuggestProps, FieldRenderProps<[]> {
  handleGetSuggestions: (val: string) => void;
}

const FieldSuggest = ({
  input,
  label,
  meta,
  handleGetSuggestions,
  onChangeValue,
  initVal,
  ...props
}: FieldSuggestProps) => {
  const { value, onChange, onBlur } = input;
  const { error, submitError, touched, dirtySinceLastSubmit } = meta;
  const isError = (error && touched) || (submitError && !dirtySinceLastSubmit);
  const onSetValue = useCallback(
    val => {
      const isValue = value && !!value.find((item: any) => item.id === val.id);

      if (isValue) {
        const data = value.filter(item => item !== val.id);
        if (typeof onChangeValue === 'function') {
          onChangeValue(data);
        }

        return onChange(data);
      }
      if (typeof onChangeValue === 'function') {
        onChangeValue(value.length > 0 ? value.concat(val) : [val]);
      }

      return onChange(value.length > 0 ? value.concat(val) : [val]);
    },
    [value, onChange],
  );
  useEffect(() => {
    if (initVal) {
      onChange(initVal);
    }
  }, [initVal]);
  const onRemoveValue = useCallback(
    index => {
      if (typeof onChangeValue === 'function') {
        onChangeValue(value.filter((_, i) => i !== index));
      }
      return onChange(value.filter((_, i) => i !== index));
    },
    [value, onChange],
  );
  useEffect(() => {}, []);
  return (
    <Field>
      {label && <FormLabel>{label}</FormLabel>}
      <UISuggest
        {...props}
        handleGetSuggestions={handleGetSuggestions}
        handleSetValue={onSetValue}
        handleBlur={onBlur}
        error={isError}
      />
      {isError && <Error meta={meta} />}
      {value?.length > 0 && (
        <SuggestedList>
          {value.map(({ name }, i) => (
            <SuggestedItem key={i}>
              {name} <Remove onClick={() => onRemoveValue(i)} src={CloseIcon} />
            </SuggestedItem>
          ))}
        </SuggestedList>
      )}
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const SuggestedList = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
`;

const SuggestedItem = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #caf0ff;
  font-size: 12px;
  line-height: 150%;
  border-radius: 6px;
  padding: 5px 10px;
  margin-bottom: 8px;
  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const Remove = styled(ReactSVG)`
  margin-left: 7px;
  cursor: pointer;
  & svg {
    width: 11px;
    height: 11px;
    & path {
      stroke: var(--black);
    }
  }
`;

const Error = styled(FormError)`
  position: absolute;
`;

export default FieldSuggest;
