import { clientApiUrl, fetch } from 'services/ApiService';
import StorageService from 'services/Storage';
import { paramsToQueryString } from 'utils';

export const uploadFile = (file: File, type: string) => {
  const data = new FormData();
  data.append('file', file, file?.name);

  return fetch<{ status: string; fileKey: string }>(
    'post',
    `utility/${type}`,
    {
      host: clientApiUrl,
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchSignedUrl = (key: string) =>
  fetch<{ result: string }>('post', `auth/get_signed_url`, {
    host: clientApiUrl,
    payload: { key },
  });

export const fetchImage2Base64 = (imgKey: string) =>
  fetch<string>(
    'post',
    `utility/image2Base64${paramsToQueryString({ imageKey: imgKey })}`,
    { host: clientApiUrl },
  );
