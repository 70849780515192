/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { UIButton, UITextInput } from 'components/UI';
import { useSelector } from 'react-redux';
import { MainWrapper } from 'layouts';
import { headTableTeamsUsers } from 'constants/tableHeads';
import Pagination from 'components/Pagination';
import Jumbotron from 'components/Jumbotron';
import { useOperation } from 'hooks/common';
import {
  fetchUserChangeStatus,
  fetchUserDelete,
  fetchUsersSearchTS,
} from 'store/ducks/user/operations';
import { getUsers, getUsersMeta } from 'store/ducks/user/selectors';
import { StateProps } from 'store/interfaces';
import { User } from 'interfaces/user';
import { PaginationMeta } from 'interfaces/common';
import UsersTeamsTable from 'components/Tables/TeamsnapTable';

type SearchQuery = {
  email?: string;
  name?: string;
  team?: string;
};

const UserTeamsList = () => {
  const [onfetchUsers, isLoading] = useOperation(fetchUsersSearchTS);
  const [onFetchDeleteUser] = useOperation(fetchUserDelete);
  const [onFetchUserChangeStatus] = useOperation(fetchUserChangeStatus);
  const [emailQuery, setEmailQuery] = useState('');
  const [nameQuery, setNameQuery] = useState('');
  const [teamQuery, setTeamQuery] = useState('');
  const { location, push } = useHistory();
  const users = useSelector<StateProps, User[]>(getUsers);

  const meta = useSelector<StateProps, PaginationMeta>(getUsersMeta);
  const { currentPage, totalItems, itemsPerPage } = meta;
  const queryObj: SearchQuery = {};

  const getInitialState = () => {
    //const filtersString = queryString.parse(location.search)?.q;
    const pageString = queryString.parse(location.search)?.page;

    // if (filtersString) {
    //   const param = JSON.parse(window.atob(filtersString?.toString()));
    //   onfetchUsers({ page: Number(pageString) || 1, q: param });
    //   return setInitialValues(param);
    // } else {
    //setInitialValues('');
    return onfetchUsers({ page: Number(pageString) || 1 });
    //}
  };

  useEffect(() => {
    getInitialState();
  }, [location]);

  const handleSearchUsers = (email, name, team) => {
    if (email) queryObj.email = email;
    if (name) queryObj.name = name;
    if (team) queryObj.team = team;

    const pageString = queryString.parse(location.search)?.page;
    const serializedQuery = JSON.stringify(queryObj);
    console.log(serializedQuery);

    //const base64 = window.btoa(JSON.stringify(queryObj));
    //const page = queryString.parse(location.search)?.page;

    // Construct the query string based on the provided parameters
    //const queryStringParams = { q: base64 };
    //if (page) queryStringParams.page = page;

    //push(`${location.pathname}?${queryString.stringify(queryStringParams)}`);
    return onfetchUsers({ page: Number(pageString) || 1, q: serializedQuery });
  };

  const submitHandlerPagination = (page: number) => {
    if (queryObj) {
      const serializedQuery = JSON.stringify(queryObj);
      return onfetchUsers({ page, q: serializedQuery });
    } else {
      return onfetchUsers({ page });
    }
  };

  const handleSearchUsersDebounce = useCallback(
    debounce((email, name, team) => handleSearchUsers(email, name, team), 800),
    [emailQuery, nameQuery, teamQuery],
  );

  return (
    <MainWrapper
      isLoading={isLoading}
      header={<Jumbotron name="Teams"></Jumbotron>}
      contentWithoutLoading={
        <SuggestWrapper>
          <UITextInput
            value={emailQuery}
            onChange={({ target: { value } }) => {
              setEmailQuery(value);
              handleSearchUsersDebounce(value, nameQuery, teamQuery);
            }}
            placeholder="Search users by email"
            disableTrim
          />
          <UITextInput
            value={nameQuery}
            onChange={({ target: { value } }) => {
              setNameQuery(value);
              handleSearchUsersDebounce(emailQuery, value, teamQuery);
            }}
            placeholder="Search users by name"
            disableTrim
          />
          <UITextInput
            value={teamQuery}
            onChange={({ target: { value } }) => {
              setTeamQuery(value);
              handleSearchUsersDebounce(emailQuery, nameQuery, value);
            }}
            placeholder="Search users by team"
            disableTrim
          />
        </SuggestWrapper>
      }
    >
      <>
        <PaginationWrapper>
          {totalItems > 0 && (
            <Pagination
              total={totalItems}
              pageLimit={Number(itemsPerPage)}
              page={Number(currentPage)}
              submitHandler={(page: number) => submitHandlerPagination(page)}
            />
          )}
        </PaginationWrapper>
        <UsersTeamsTable
          rows={users}
          headCells={headTableTeamsUsers}
          handleDeleteUser={onFetchDeleteUser}
          handleChangeStatus={onFetchUserChangeStatus}
        />
      </>
    </MainWrapper>
  );
};

const Btn = styled(UIButton)`
  width: 160px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const SuggestWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  input:not(:last-child) {
    margin-right: 20px;
  }
`;

export default UserTeamsList;
