import React from 'react';
import { ReactSVG } from 'react-svg';
import { Link, useHistory } from 'react-router-dom';
import { parseLink } from 'utils';
import UploadDocument from 'components/UploadDocument';
import SubscriptionForm from 'components/SubscriptionForm';
import AddIcon from 'assets/images/icons/add-icon.svg';

import {
  Root,
  Body,
  Header,
  HeaderInfo,
  Image,
  OrganizationTitle,
  OrganizationType,
  SocialLink,
  EditButton,
  Desc,
  DescTitle,
  DescSection,
  PartnersWrapper,
  ContributionsWrapper,
  DescLabel,
  DescValue,
  PartnersList,
  PartnersItem,
  PartnersImg,
  ContactItem,
  ContactIcon,
  ContactLink,
  AlignmentWrapper,
  AlignmentList,
  AlignmentItem,
  InfoWrapper,
  InfoTitle,
  InfoValue,
  OpportunitiesValueWrapper,
  OpportunitiesValue,
  MissionWrapper,
  MissionTitle,
  MissionSubTitle,
  MissionValue,
  SocialWrapper,
  EmptyAlignment,
  EmptyPartners,
  EmptyPartnersImgWrapper,
  PartnersEmptyImg,
  Content,
  ProgramBlock,
  ProgramExpenseRatioText,
  ProgramExpenseRatioValue,
  GuidestarBlock,
  GuidestarText,
  GuidestarLink,
  GuidestarValue,
  GuidestartWrapper,
  LinkGroup,
  IconWrapper,
  ContactMoreLink,
} from './styled';
import UserIcon from 'assets/images/icons/user.svg';
import PhoneIcon from 'assets/images/icons/phone.svg';
import EmailIcon from 'assets/images/icons/email.svg';
import WebSiteIcon from 'assets/images/icons/website.svg';

import LinkedInIcon from 'assets/images/icons/linkedIn.svg';
import TwitterIcon from 'assets/images/icons/twitter.svg';

import { TeamData, SubscriptionProps, TeamContact } from 'interfaces/team';
import { News, TypesOfInvestment } from 'interfaces/companies';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { goalAlignmentOptions } from 'constants/goalAlignment';

import { CropText, UIDollarNumber, UIPercentageNumber } from 'components/UI';
import EmptyCompany from 'assets/images/icons/empty-company.svg';
import EmptyPartnerIcon from 'assets/images/empty-partner-img.svg';
import DownloadFileTag from 'components/UI/DownloadFileTag';
import ProfileSideBar from './ProfileSideBar';
import { NewsData } from 'interfaces/news';
import TeamContactsTable from 'pages/team/Team/TeamContactsTable';
import { moneyNumberFormat } from 'utils/formatters';

export interface ProfileViewProps {
  team: TeamData;
  handleUpdateTeam: (values: TeamData) => OnSubmitReturnType;
  handleUpdateSubscription: (values: SubscriptionProps) => OnSubmitReturnType;
  handleRemoveSubscription: (id: string) => void;
  handleUploadDocument?: (file: File) => Promise<string | void | number>;
  subscription?: SubscriptionProps;
  news: News[];
  contacts: TeamContact[];
  onNewsCreate: (param: Partial<NewsData>) => void;
  onNewsUpdate: (param: Partial<NewsData>) => void;
  onNewsDelete: (id: string) => void;
}

const ProfileView = ({
  team,
  handleUpdateTeam,
  handleUpdateSubscription,
  handleRemoveSubscription,
  handleUploadDocument,
  subscription,
  news,
  contacts,
  onNewsCreate,
  onNewsUpdate,
  onNewsDelete,
}: ProfileViewProps) => {
  const {
    id,
    organizationName,
    twitterLink,
    linkedinLink,
    annualTotalContributions,
    numberConstituents,
    contactName,
    contactPhone,
    address,
    contactWebsite,
    goalAlignment,
    employeeEngagementOpportunities,
    opportunities,
    impactStatements,
    impactStory,
    zip,
    teamPartnerCompanies,
    aieldActivity,
    email,
    primaryMissionFocus,
    secondaryMissionFocus,
    logoKey,
    typesOfInvestment,
    income,
    age,
    brands,
    additionalMaterials,
    numberOfSocialFollowers,
    minimumCommitment,
    einStatus,
    programs,
    programExpenseRatio,
    guidestarLink,
    guidestarTransparencyRanking,
    missionDescription,
    impact,
    programFocus,
    costPerOutcome,
    beneficiaries,
  } = team;

  const isUploadDocument = !!income || !!age || !!brands;

  const cropWebSite = contactWebsite && parseLink(contactWebsite);
  const programFocusAndCostPerOutcome =
    programFocus !== ''
      ? programFocus?.split(';').map((item, i) => ({
          costPerOutcome: costPerOutcome?.split(',')[i],
          programFocus: item,
        }))
      : [];

  const { push } = useHistory();
  const getBeneficiariesValue = (beneficiariesList: string[], type: number) => {
    if (beneficiariesList) {
      if (type === 1) {
        const filteredList = beneficiariesList.filter(value =>
          ['Men/Boys', 'Non-binary', 'Women/Girls'].includes(value),
        );
        const result = filteredList.join(', ');
        return result;
      }
      if (type === 2) {
        const filteredList = beneficiariesList.filter(value =>
          [
            'Adolescents (Secondary)',
            'Adults',
            'Birth to Young Children (Pre-K)',
            'Children (Elementary school)',
            'Early Adolescents (Middle school)',
            'Elderly',
            'Young Adults (College/Post-Secondary)',
          ].includes(value),
        );
        const result = filteredList.join(', ');
        return result;
      }
      if (type === 3) {
        const filteredList = beneficiariesList.filter(value =>
          [
            'Asian or Pacific Islander',
            'Black',
            'Hispanic/Latinx',
            'Indigenous',
            'Other Race/Ethnicity',
          ].includes(value),
        );
        const result = filteredList.join(', ');
        return result;
      }

      if (type === 4) {
        const filteredList = beneficiariesList.filter(value =>
          [
            'Active Military and/or Military Families',
            'English Language Learners (ELL)',
            'First-Generation College Students',
            'Homeless',
            'Incarcerated / formerly incarcerated',
            'Individuals with Disabilities',
            'Individuals with Mental Health Condition(s)',
            'Individuals with Physical Health Condition(s)',
            'LGBTQ+',
            'Low-Income or Economically Disadvantaged',
            'Military Veterans and/or Veteran Families',
            'Newcomers, Immigrants, and/or Refugees',
            'Unemployed',
          ].includes(value),
        );
        const result = filteredList.join(', ');
        return result;
      }
    }
  };

  return (
    <Root>
      <Header>
        <HeaderInfo>
          <Image imgKey={logoKey} />
          <div>
            <OrganizationTitle>{organizationName || 'No information'}</OrganizationTitle>
            <OrganizationType>{aieldActivity || 'No information'}</OrganizationType>
            <SocialWrapper>
              {twitterLink && (
                <SocialLink href={parseLink(twitterLink)} target="_blank">
                  <ReactSVG src={TwitterIcon} />
                </SocialLink>
              )}

              {linkedinLink && (
                <SocialLink href={parseLink(linkedinLink)} target="_blank">
                  <ReactSVG src={LinkedInIcon} />
                </SocialLink>
              )}
            </SocialWrapper>
          </div>
        </HeaderInfo>

        <Link to={`/team/edit/${team.id}`}>
          <EditButton modifiers="blue" title="Edit" />
        </Link>
      </Header>

      <Body>
        <Content>
          <Desc>
            <DescSection>
              <DescTitle>Organization</DescTitle>

              <ContributionsWrapper>
                <DescLabel>Annual Total Contributions</DescLabel>
                <DescValue>
                  <CropText
                    width={200}
                    text={
                      annualTotalContributions ? (
                        <UIDollarNumber value={annualTotalContributions} />
                      ) : (
                        'No information'
                      )
                    }
                  />
                </DescValue>
              </ContributionsWrapper>

              <ContributionsWrapper>
                <DescLabel>Number of Constituents</DescLabel>
                <DescValue> {numberConstituents || 'No information'}</DescValue>
              </ContributionsWrapper>

              <div>
                <DescLabel>EIN</DescLabel>
                <DescValue> {einStatus || 'No information'}</DescValue>
              </div>
            </DescSection>

            <PartnersWrapper>
              <DescTitle>Partners</DescTitle>

              {teamPartnerCompanies && teamPartnerCompanies.length > 0 ? (
                <PartnersList>
                  {teamPartnerCompanies.slice(0, 9).map(partner => (
                    <PartnersItem key={partner.id}>
                      {partner?.company?.logo ? (
                        <PartnersImg
                          title={partner.companyName}
                          imgKey={partner.company.logo}
                        />
                      ) : (
                        <PartnersEmptyImg
                          title={partner.companyName}
                          style={{
                            backgroundImage: `url(${EmptyCompany})`,
                          }}
                        />
                      )}
                    </PartnersItem>
                  ))}
                </PartnersList>
              ) : (
                <EmptyPartners>
                  <EmptyPartnersImgWrapper>
                    <img src={EmptyPartnerIcon} alt="empth partner icon" />
                  </EmptyPartnersImgWrapper>
                  <p>No partners yet</p>
                </EmptyPartners>
              )}
            </PartnersWrapper>
          </Desc>
          <DescSection>
            <DescTitle>Contact</DescTitle>
            <ul>
              <ContactItem>
                <ContactIcon src={UserIcon} />
                {contactName || 'No Fullname'}
              </ContactItem>
              <ContactItem>
                <ContactIcon src={PhoneIcon} />
                {contactPhone ? (
                  <ContactLink href={`tel:${contactPhone}`}>{contactPhone}</ContactLink>
                ) : (
                  <CropText width={200} text="No phone number" />
                )}
              </ContactItem>

              <ContactItem>
                <ContactIcon src={EmailIcon} />
                {email ? (
                  <ContactLink href={`mailto:${email}`}>{email}</ContactLink>
                ) : (
                  <CropText width={200} text="No email" />
                )}
              </ContactItem>

              <ContactItem>
                <ContactIcon src={WebSiteIcon} />
                {contactWebsite ? (
                  <ContactLink href={cropWebSite} target="_blank">
                    {cropWebSite}
                  </ContactLink>
                ) : (
                  <CropText width={200} text="No website" />
                )}
              </ContactItem>
            </ul>
          </DescSection>

          <SubscriptionForm
            onSubmit={handleUpdateSubscription}
            handleRemoveSubscription={() => handleRemoveSubscription(id)}
            initialValues={subscription}
          />

          {handleUploadDocument && (
            <InfoWrapper>
              <InfoTitle>Constituent Demographics</InfoTitle>
              <UploadDocument
                isUploadDocument={isUploadDocument}
                handleUpload={handleUploadDocument}
              />
            </InfoWrapper>
          )}

          {goalAlignment && goalAlignment.length > 0 && (
            <AlignmentWrapper>
              <DescTitle>SDG Goal Alignment</DescTitle>

              {goalAlignment && goalAlignment.length > 0 ? (
                <AlignmentList>
                  {goalAlignment
                    .sort(
                      (g1, g2) =>
                        goalAlignmentOptions[g1].id - goalAlignmentOptions[g2].id,
                    )
                    .map(sdg => (
                      <AlignmentItem
                        key={sdg}
                        src={goalAlignmentOptions[sdg].img}
                        alt={goalAlignmentOptions[sdg].title}
                      />
                    ))}
                </AlignmentList>
              ) : (
                <EmptyAlignment>No selected goal</EmptyAlignment>
              )}
            </AlignmentWrapper>
          )}

          {(programExpenseRatio || programs) && (
            <AlignmentWrapper>
              <DescTitle>
                Program
                {programExpenseRatio && (
                  <ProgramExpenseRatioText>
                    <ProgramExpenseRatioValue>
                      <UIPercentageNumber value={programExpenseRatio} />
                    </ProgramExpenseRatioValue>
                    Program Expense Ratio
                  </ProgramExpenseRatioText>
                )}
              </DescTitle>
              {(programs?.filter(item => item) || []).map((item, i) => (
                <ProgramBlock key={i}>{item.text}</ProgramBlock>
              ))}
            </AlignmentWrapper>
          )}

          {(guidestarLink || guidestarTransparencyRanking) && (
            <InfoWrapper>
              <InfoTitle>Guidestar</InfoTitle>
              <GuidestartWrapper>
                {guidestarLink && (
                  <GuidestarBlock>
                    <GuidestarText>Guidestar Link</GuidestarText>
                    <GuidestarLink href={guidestarLink} target="_blank">
                      {guidestarLink}
                    </GuidestarLink>
                  </GuidestarBlock>
                )}
                {guidestarTransparencyRanking && (
                  <GuidestarBlock>
                    <GuidestarText>Guidestar Transparency Ranking</GuidestarText>
                    <GuidestarValue>{guidestarTransparencyRanking}</GuidestarValue>
                  </GuidestarBlock>
                )}
              </GuidestartWrapper>
            </InfoWrapper>
          )}

          <InfoWrapper>
            <InfoTitle>Beneficiaries</InfoTitle>
            <InfoValue>
              <>
                <MissionSubTitle>
                  {getBeneficiariesValue(beneficiaries, 1) ? 'Gender' : ''}
                </MissionSubTitle>
                <MissionValue>{getBeneficiariesValue(beneficiaries, 1)}</MissionValue>
                <MissionSubTitle>
                  {getBeneficiariesValue(beneficiaries, 2) ? 'Age' : ''}
                </MissionSubTitle>
                <MissionValue>{getBeneficiariesValue(beneficiaries, 2)}</MissionValue>
                <MissionSubTitle>
                  {getBeneficiariesValue(beneficiaries, 3) ? 'Race/Ethnicity' : ''}
                </MissionSubTitle>
                <MissionValue>{getBeneficiariesValue(beneficiaries, 3)}</MissionValue>
                <MissionSubTitle>
                  {getBeneficiariesValue(beneficiaries, 4) ? 'Other' : ''}
                </MissionSubTitle>
                <MissionValue>{getBeneficiariesValue(beneficiaries, 4)}</MissionValue>
              </>
            </InfoValue>
          </InfoWrapper>

          <InfoWrapper>
            <InfoTitle>Program Focus</InfoTitle>
            <InfoValue>
              {programFocusAndCostPerOutcome?.map(item => (
                <>
                  {item.programFocus && (
                    <div style={{ marginBottom: '8px', fontWeight: 500 }}>
                      {item.programFocus}
                    </div>
                  )}
                  {item.costPerOutcome && (
                    <div style={{ marginBottom: '12px' }}>
                      Cost per Outcome: {moneyNumberFormat(item.costPerOutcome)}
                    </div>
                  )}
                </>
              ))}
            </InfoValue>
          </InfoWrapper>

          <InfoWrapper>
            <InfoTitle>
              Contacts
              <LinkGroup>
                <IconWrapper>
                  <ReactSVG
                    src={AddIcon}
                    onClick={() => push(`/edit-contact-team/${id}`)}
                  />
                </IconWrapper>
                <ContactMoreLink to={`/team-contacts/${id}`}>see more</ContactMoreLink>
              </LinkGroup>
            </InfoTitle>
            <InfoValue>
              <TeamContactsTable
                rows={contacts ? [...contacts].slice(0, 3) : []}
                teamId={id}
              />
            </InfoValue>
          </InfoWrapper>

          <InfoWrapper>
            <InfoTitle>Address</InfoTitle>
            <InfoValue>
              {address || 'No information'}
              {zip && (
                <>
                  <br />
                  Postal Code: {zip || 'No information'}
                </>
              )}
            </InfoValue>

            <DescTitle>Types Of Investment</DescTitle>

            {typesOfInvestment && typesOfInvestment.length > 0 ? (
              <MissionValue>
                {typesOfInvestment.map(({ title }: TypesOfInvestment, index) => (
                  <span key={index}>
                    {title}
                    {index + 1 < typesOfInvestment.length && `, `}
                  </span>
                ))}
              </MissionValue>
            ) : (
              <MissionValue>No Information</MissionValue>
            )}

            {numberOfSocialFollowers && (
              <>
                <InfoTitle>Social Followers Number</InfoTitle>
                <InfoValue>{numberOfSocialFollowers}</InfoValue>
              </>
            )}

            {minimumCommitment && (
              <>
                <InfoTitle>Minimum Commitment</InfoTitle>
                <InfoValue>${minimumCommitment}</InfoValue>
              </>
            )}
            {/* <AffinityForm team={team} handleUpdateTeam={handleUpdateTeam} /> */}

            {(primaryMissionFocus ||
              (secondaryMissionFocus && secondaryMissionFocus.length > 0)) && (
              <MissionWrapper>
                <MissionTitle>Mission</MissionTitle>
                {primaryMissionFocus && (
                  <>
                    <MissionSubTitle>Primary</MissionSubTitle>
                    <MissionValue>{primaryMissionFocus.label}</MissionValue>
                  </>
                )}

                {secondaryMissionFocus && secondaryMissionFocus.length > 0 && (
                  <>
                    <MissionSubTitle>Secondary</MissionSubTitle>
                    <MissionValue>
                      {secondaryMissionFocus?.map(
                        (item: { value: string | number; label: string }, index) => (
                          <span key={index}>
                            {item.label}
                            {index + 1 < secondaryMissionFocus.length && `, `}
                          </span>
                        ),
                      )}
                    </MissionValue>
                  </>
                )}
                {missionDescription && missionDescription.length > 0 && (
                  <>
                    <MissionSubTitle>Mission Description</MissionSubTitle>
                    <MissionValue>{missionDescription}</MissionValue>
                  </>
                )}
              </MissionWrapper>
            )}
            <InfoTitle>Employee Engagement Opportunities</InfoTitle>
            <InfoValue>{employeeEngagementOpportunities ? 'Yes' : 'No'}</InfoValue>

            {opportunities && opportunities.length > 0 && (
              <>
                <InfoTitle>Opportunities</InfoTitle>
                <OpportunitiesValueWrapper>
                  {opportunities.map((item: { text: string }, index) => (
                    <OpportunitiesValue key={index}>{item.text}</OpportunitiesValue>
                  ))}
                </OpportunitiesValueWrapper>
              </>
            )}
            {impact && impact.length > 0 && (
              <>
                <InfoTitle>Impact</InfoTitle>
                <OpportunitiesValueWrapper>
                  {impact
                    .filter(item => item)
                    .map((item: { text: string }, index) => (
                      <OpportunitiesValue key={index}>{item.text}</OpportunitiesValue>
                    ))}
                </OpportunitiesValueWrapper>
              </>
            )}
            {impactStatements && impactStatements.length > 0 && (
              <>
                <InfoTitle>Impact Statements</InfoTitle>
                <OpportunitiesValueWrapper>
                  {impactStatements.map((item: { text: string }, index) => (
                    <OpportunitiesValue key={index}>{item.text}</OpportunitiesValue>
                  ))}
                </OpportunitiesValueWrapper>
              </>
            )}

            {impactStory && (
              <>
                <InfoTitle>Impact Story</InfoTitle>
                <InfoValue>{impactStory}</InfoValue>
              </>
            )}

            {additionalMaterials && (
              <>
                <InfoTitle>Additional Materials</InfoTitle>
                <InfoValue>
                  {additionalMaterials.map(item => (
                    <DownloadFileTag key={item} fileKey={item} />
                  ))}
                </InfoValue>
              </>
            )}
          </InfoWrapper>
        </Content>
        <ProfileSideBar
          teamId={id}
          news={news}
          onNewsCreate={onNewsCreate}
          onNewsUpdate={onNewsUpdate}
          onNewsDelete={onNewsDelete}
        />
      </Body>
    </Root>
  );
};

export default ProfileView;
