import styled from 'styled-components';

const FormTitleSecondary = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 24px;
  color: var(--black);
`;

export default FormTitleSecondary;
