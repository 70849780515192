/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MainWrapper } from 'layouts';
import { useOperation } from 'hooks/common';
import {
  fetchCompanyCreate,
  fetchSuggestCompaniesSearch,
} from 'store/ducks/companies/operations';
import { Company } from 'interfaces/companies';
import { useModal } from 'hooks/common';
import CompanyProfileForm from 'components/CompanyProfileForm';
import Jumbotron from 'components/Jumbotron';
import { CompanyExist } from 'components/Modals';
import History from 'services/History';
import { StateProps, CRSFocus, AdditionalInfoProps } from 'store/interfaces';
import { getCrsFocus } from 'store/ducks/user/selectors';
import {
  getAdditionalInfoSelect,
  getSuggestCompanies,
} from 'store/ducks/companies/selectors';
import { debounce } from 'lodash';

const NewCompany = () => {
  const [createCompanies] = useOperation(fetchCompanyCreate);
  const [onSearchCompanies] = useOperation(fetchSuggestCompaniesSearch);

  const crsFocus = useSelector<StateProps, CRSFocus[]>(getCrsFocus);

  const companiesSearchList = useSelector<StateProps, Company[]>(getSuggestCompanies);

  const additionalInfo = useSelector<StateProps, AdditionalInfoProps>(
    getAdditionalInfoSelect(),
  );

  const [showModal, hideModal] = useModal(() => {
    return <CompanyExist onClose={hideModal} />;
  });

  const onSubmit = useCallback((values: Company) => {
    return createCompanies(values)
      .then(() => {
        History.back();
      })
      .catch(() => {
        showModal();
      });
  }, []);

  const handleSearchCompanies = (val: string) => {
    return onSearchCompanies({ q: val, page: 1, limit: 12 });
  };

  const handleSearchCompaniesDebounce = useCallback(
    debounce(handleSearchCompanies, 800),
    [],
  );

  return (
    <MainWrapper withOutNav header={<Jumbotron name="New Company" back />}>
      <CompanyProfileForm
        initialValues={{ employeeEngagementOpportunities: true }}
        onSubmit={onSubmit}
        crsFocus={crsFocus}
        industryTree={additionalInfo.industriesTree}
        naicsCodeTree={additionalInfo.naicsCodeTree}
        handleGetCompanies={handleSearchCompaniesDebounce}
        companiesSearchList={companiesSearchList}
      />
    </MainWrapper>
  );
};

export default NewCompany;
