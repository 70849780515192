import { ActionType, createReducer } from 'typesafe-actions';

import { AdminData, AuthStatuses } from 'interfaces/auth';

import * as types from './types';
import * as actions from './actions';

type RootAction = ActionType<typeof actions>;

export default createReducer<AdminData, RootAction>({
  admin: {},
})
  .handleType([types.SET_ADMIN], (state, action) => ({
    ...state,
    admin: { ...action.payload, authStatus: AuthStatuses.AUTHOREZED },
  }))
  .handleType([types.LOG_OUT], state => ({
    ...state,
    admin: {
      authStatus: AuthStatuses.UNATHORIZED,
    },
  }));
