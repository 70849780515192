import { ROIMeltwaterData } from 'interfaces/roi';
import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import geoJson from 'assets/mapjson/world-110m.json';
import ReactTooltip from 'react-tooltip';

interface Props {
  data?: ROIMeltwaterData['analyticsData']['analytics']['top_countries'];
}

const MapChartC = ({ data }: Props) => {
  const [tipContent, setTipContent] = useState('');

  if (!data) return null;

  return (
    <div style={{ width: '540px', height: '300px', margin: '-50px auto 0' }}>
      <ComposableMap
        data-tip=""
        projectionConfig={{
          scale: 120,
        }}
        projection="geoEquirectangular"
      >
        <Geographies geography={geoJson}>
          {({ geographies }) =>
            geographies.map(geo => {
              const country = data.find(
                item => item.country_code === geo.properties.ISO_A2,
              );
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    setTipContent(`${country?.document_count || 0}`);
                  }}
                  onMouseLeave={() => {
                    setTipContent('');
                  }}
                  fill={
                    country
                      ? country.document_count >
                        data[Math.floor(data.length / 3)].document_count
                        ? '#f6921e'
                        : '#facb57'
                      : '#f5e5bb'
                  }
                  style={{
                    default: {
                      outline: 'none',
                      stroke: '#868686',
                      strokeWidth: 0.2,
                    },
                    hover: {
                      outline: 'none',
                      stroke: 'black',
                    },
                    pressed: {
                      outline: 'none',
                      stroke: 'black',
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
      <ReactTooltip>{tipContent}</ReactTooltip>
    </div>
  );
};

export default MapChartC;
