import { AudienceData, AudienceListReq } from 'interfaces/audience';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import { setAudienceList } from './actions';
import * as api from './api';

export const fetchAudienceList = (param: AudienceListReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchAudienceList(param)
    .then(value => dispatch(setAudienceList(value)))
    .catch(() => Toast.error('Error get audience list'));
};

export const fetchAudienceUpdate = (params: AudienceData) => async () => {
  return api
    .fetchAudienceUpdate(params)
    .then(() => Toast.success('Update successfully'))
    .catch(() => Toast.error('Error update audience'));
};
