export default `
.Toastify__toast-container {
  padding: 0;
  width: 456px;
}
.Toastify__toast {
  background: #EAEAEA;
  border-radius: 6px;
  color: #122434;
  padding: 11px 20px;
  min-height: 58px;
}
.Toastify__toast--error {}
.Toastify__toast--warning {}
.Toastify__toast--success {}
.Toastify__toast-body {}
.Toastify__progress-bar {}
`;
