import styled from 'styled-components';

const FormArrayDeleteIcon = styled.img`
  width: 24px;
  height: 24px;

  &:hover {
    border: 1px solid var(--blue);
    border-radius: 4px;
  }
`;

export default FormArrayDeleteIcon;
