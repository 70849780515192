/* eslint-disable react-hooks/exhaustive-deps */
import Jumbotron from 'components/Jumbotron';
import { InviteUser, UserTable } from 'components/team';
import { UISpinner } from 'components/UI';
import { headTableUsers } from 'constants/team';
import { useOperation } from 'hooks/common';
import { TeamData } from 'interfaces/team';
import { User } from 'interfaces/user';
import { TeamWrapper } from 'layouts';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchAddTeamMembers,
  fetchTeam,
  fetchTeamMembers,
  fetchDeleteTeamMembers,
  fetchInviteLinkMember,
  fetchSubscriptionTeams,
  fetchTeamMemberChangeCanva,
} from 'store/ducks/team/operations';
import { getSubscription, getTeamById, getTeamMembers } from 'store/ducks/team/selectors';
import { fetchCreateOktaUser } from 'store/ducks/user/operations';
import { StateProps } from 'store/interfaces';
import styled from 'styled-components';

const TeamMembers = () => {
  const [onfetchTeam, isLoading] = useOperation(fetchTeam);

  const params: { teamId: string } = useParams();

  const [loadTeamMembers] = useOperation(fetchTeamMembers);
  const [onFetchAddTeamMembers] = useOperation(fetchAddTeamMembers);
  const [onFetchSubscriptionTeams] = useOperation(fetchSubscriptionTeams);
  const [onFetchCreateOktaUser, , isCreateOktaUserLoading] = useOperation(
    fetchCreateOktaUser,
  );
  const [onfetchTeamMemberChangeCanva, , isMemberUpdateLoading] = useOperation(
    fetchTeamMemberChangeCanva,
  );

  const users = useSelector<StateProps, User[]>(getTeamMembers);
  const team = useSelector<StateProps, TeamData | undefined>(getTeamById(params.teamId));
  const subscription = useSelector(getSubscription);

  useEffect(() => {
    onfetchTeam(params.teamId);
    loadTeamMembers(params.teamId);
    onFetchSubscriptionTeams(params.teamId);
  }, [params.teamId]);

  return (
    <TeamWrapper
      teamId={params.teamId}
      isLoading={isLoading}
      header={<Jumbotron name={team?.organizationName || ''} back backPath="/teams" />}
    >
      <Root>
        {(isMemberUpdateLoading || isCreateOktaUserLoading) && <Spinner />}

        <Header>User Management</Header>
        <InviteUser
          onSubmit={email => onFetchAddTeamMembers({ email, teamId: params.teamId })}
          fetchInviteLinkOperation={fetchInviteLinkMember}
          inviteId={params.teamId}
        />
        <UserTable
          rows={users}
          headCells={headTableUsers}
          showCanvaColumn={!!subscription?.isCanva}
          onChangeUser={user => {
            if (user.isCanva) {
              onFetchCreateOktaUser(user.id).then(() => {
                onfetchTeamMemberChangeCanva({ userId: user.id, isCanva: user.isCanva });
              });
              return;
            }
            onfetchTeamMemberChangeCanva({ userId: user.id, isCanva: user.isCanva });
          }}
          deleteMemberOperation={fetchDeleteTeamMembers}
        />
      </Root>
    </TeamWrapper>
  );
};

const Root = styled.div`
  background: #fff;
  position: relative;
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  padding: 24px 0 0 24px;
`;

const Spinner = styled(UISpinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export default TeamMembers;
