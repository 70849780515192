import Pagination from 'components/Pagination';
import { AudienceData, AudienceListRes } from 'interfaces/audience';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import AudienceListTable from '../../../components/audience/AudienceTable';
import useSortStatus from 'hooks/common/useSortStatus';
import TotalRow from 'components/TotalRow';
import { useFileUrl, useModal, useOperation } from 'hooks/common';
import { PDFPreviewModal } from 'components/Modals';
import { Company } from 'interfaces/companies';
import { useParams } from 'react-router';
import { fetchAudienceList, fetchAudienceUpdate } from 'store/ducks/audience/operations';
import { getAudienceList } from 'store/ducks/audience/selectors';
import { useSelector } from 'react-redux';
import { StateProps } from 'store/interfaces';
import queryString from 'query-string';

interface Props {
  company: Company;
}

const PersonaAnalysis: FC<Props> = ({ company }) => {
  const params: { companyId: string } = useParams();
  const { fileUrl, load } = useFileUrl({ imgKey: undefined });

  const [onFetchAudienceList] = useOperation(fetchAudienceList);
  const [onFetchAudienceUpdate] = useOperation(fetchAudienceUpdate);

  const { sortStatus, handleSwitch } = useSortStatus([], {
    isSort: false,
  });

  const { items, meta } = useSelector<StateProps, AudienceListRes>(getAudienceList);

  useEffect(() => {
    if (!sortStatus.name) return;
    const pageString = queryString.parse(window.location.search)?.page;
    const page = (pageString && Number(pageString)) || 1;
    onFetchAudienceList({
      page: page,
      id: params.companyId,
      orderType: sortStatus.status,
      order: sortStatus.name,
      type: 'company',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  const [showPDFPreviewModal, hidePDFPreviewModal] = useModal(
    () => <PDFPreviewModal onClose={hidePDFPreviewModal} fileUrl={fileUrl} />,
    [fileUrl],
  );

  const handleShowPDF = (fileKey: string) => {
    load(fileKey);
    showPDFPreviewModal();
  };

  const handleAudienceUpdate = (params: AudienceData) =>
    onFetchAudienceUpdate(params).then(() =>
      onFetchAudienceList({
        id: company.id,
        page: Number(meta.currentPage),
        type: 'company',
      }),
    );

  useEffect(() => {
    onFetchAudienceList({ page: 1, id: params.companyId, type: 'company' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Body>
        <TotalRow
          maxWidth="1138px"
          title={`${meta.totalItems} Report${meta.totalItems > 1 ? 's' : ''}`}
        >
          <Pagination
            page={Number(meta.currentPage)}
            pageLimit={Number(meta.itemsPerPage)}
            total={meta.totalItems}
            submitHandler={page =>
              onFetchAudienceList({ id: company.id, page, type: 'team' })
            }
          />
        </TotalRow>
        <AudienceListTable
          headCells={['File', 'Persona Analysis', '', 'Raw Data File', 'Upload Date']}
          handleAudienceUpdate={handleAudienceUpdate}
          rows={items}
          onSort={handleSwitch}
          sortStatus={sortStatus}
          handleShowPDFPreview={handleShowPDF}
        />
      </Body>
    </>
  );
};

const Body = styled.section`
  background-color: #fff;
  width: 100%;
  height: 558px;
  padding: 24px 36px;
`;

export default PersonaAnalysis;
