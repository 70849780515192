import { fetch } from 'services/ApiService';
import { AudienceData, AudienceListReq, AudienceListRes } from 'interfaces/audience';
import { paramsToQueryString } from 'utils';

export const fetchAudienceList = ({ limit = 12, ...params }: AudienceListReq) =>
  fetch<AudienceListRes>(
    'get',
    `audience/${params.id}${paramsToQueryString({ limit, ...params })}`,
  );

export const fetchAudienceUpdate = (params: AudienceData) =>
  fetch('patch', 'audience', { payload: params });

export const fetchAudienceDownloadExcel = (id: string) =>
  fetch<{ name: string; file: string }>('get', `audience/downloadAttachment/${id}`);
