import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { UIRadioBtn } from 'components/UI';
import styled from 'styled-components';

interface BaseProps {
  label: string;
}

type Props = BaseProps & FieldRenderProps<number>;

const priorities = [...Array(11).keys()];

const FiledPriorityRadioButton = ({ input, label }: Props) => {
  const { value, onChange } = input;

  return (
    <Field>
      <Label>{label}</Label>
      <RadioBtnList>
        {priorities.map(priority => (
          <RadioBtn
            key={priority}
            isChecked={value === priority}
            label={priority}
            handleCheck={() => onChange(priority)}
          />
        ))}
      </RadioBtnList>
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const Label = styled.label`
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  height: 21px;
  align-self: flex-end;
`;

const RadioBtn = styled(UIRadioBtn)`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-right: 62px;

    &::after {
      content: '';
      position: absolute;
      width: 62px;
      height: 2px;
      background: #e8e8e8;
      transform: translate(39px, 9px);
    }
  }

  & > div {
    order: 2;
    margin-right: 0;
    width: 16px;
    height: 16px;
    border-color: #e8e8e8;

    &:after {
      width: 10px;
      height: 10px;
    }
  }

  & > p {
    order: 1;
    font-size: 12px;
    line-height: 150%;
    color: var(--black);
  }
`;

const RadioBtnList = styled.article`
  display: flex;
`;

export default FiledPriorityRadioButton;
