import React from 'react';
import { WrapperProps } from 'interfaces/common';
import styled from 'styled-components';
import LogoImg from 'assets/images/authLogo.svg';
import AuthBg1x from 'assets/images/authBg.jpg';
import AuthBg2x from 'assets/images/authBg@2x.jpg';
import AuthBg3x from 'assets/images/authBg@3x.jpg';

function GuestWrapper({ children }: WrapperProps) {
  return (
    <Root>
      <Header>
        <Logo src={LogoImg} alt="Accelerist" />
      </Header>
      <Body>{children}</Body>
    </Root>
  );
}

const Root = styled.div`
  width: 100%;
  height: 100%;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background: var(--black);
`;

const Logo = styled.img`
  width: 100%;
  max-width: 200px;
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 73px;
  min-height: calc(100% - 80px);
  background-image: url(${AuthBg2x});
  background-image: -webkit-image-set(
    url(${AuthBg1x}) 1x,
    url(${AuthBg2x}) 2x,
    url(${AuthBg3x}) 3x
  );
  background-size: cover;
  background-position: 100% 100%;
`;

export default GuestWrapper;
