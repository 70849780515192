import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import Dropdown from 'components/Dropdown/Dropdown';
import { SelectOption } from 'components/UI/interfaces';

interface Props {
  title: ReactNode;
  options: SelectOption[];
  disableArrow?: boolean;
  onClick: (value: SelectOption['value']) => void;
  className?: string;
  style?: CSSProperties;
}

const DropdownList = ({
  title,
  options,
  disableArrow,
  onClick,
  style,
  className,
}: Props) => {
  return (
    <Dropdown
      title={title}
      disableArrow={disableArrow}
      style={style}
      className={className}
    >
      <Items>
        {options.map(item => (
          <Item
            key={String(item.value)}
            className="dropdown-list-item"
            onClick={() => onClick(item.value)}
          >
            {item.label}
          </Item>
        ))}
      </Items>
    </Dropdown>
  );
};

const Items = styled.div``;

const Item = styled.div`
  display: flex;
  padding: 8px 24px;
  white-space: nowrap;
  color: #737373;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: #122434;
    background-color: var(--secondaryBlue);
  }
`;

export default DropdownList;
