import { ObjNoteCreateReq, ObjNoteData } from 'interfaces/objNote';
import { fetch } from 'services/ApiService';

export const fetchObjNoteList = (objectId: string) =>
  fetch<ObjNoteData[]>('get', `objNote/listByObjectId/${objectId}`);

export const fetchObjNoteCreate = (param: ObjNoteCreateReq) =>
  fetch<ObjNoteData>('post', 'objNote', { payload: param });

export const fetchObjNoteGet = (id: string) => fetch<ObjNoteData>('get', `objNote/${id}`);

export const fetchObjNoteUpdate = (param: ObjNoteData) =>
  fetch<ObjNoteData>('patch', 'objNote', { payload: param });

export const fetchObjNoteDelete = (id: string) =>
  fetch<ObjNoteData>('delete', `objNote/${id}`);
