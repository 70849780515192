import { clientApiUrl, fetch } from 'services/ApiService';
import {
  Company,
  Companies,
  CompanyContact,
  News,
  AddCompanyMembersReq,
  SubscriptionCompany,
  SocialImpactLogItem,
} from 'interfaces/companies';
import { paramsToQueryString } from 'utils';
import StorageService from 'services/Storage';
import { User } from 'interfaces/user';
import { searchCompaniesInfo } from 'interfaces/auth';
import { SearchJobTitle } from 'interfaces/companies';

export const fetchCompanies = () => fetch<Company[]>('get', `companies`);

export const fetchCompany = (id: string) => fetch<Company>('get', `companies/${id}`);

export const fetchCompanyUpdate = (id: string, data: Company) =>
  fetch<Company>('patch', `companies/${id}`, {
    payload: data,
  });

export const fetchCompanyCreate = (data: Company) =>
  fetch<Company>('post', `companies`, {
    payload: data,
  });

export const fetchCompaniesSearch = (
  page: number,
  q?: string,
  limit = 12,
  location?: string[],
  revenue?: string[],
  jobTitle?: string[],
) =>
  fetch<Companies>(
    'get',
    `companies/suggested${paramsToQueryString({
      page,
      limit,
      q,
      location,
      revenue,
      jobTitle,
    })}`,
  );
export const fetchCompanyDestroy = (companyId: string) =>
  fetch<string>('delete', `companies/${companyId}`);

export const fetchFileUpload = ({ file, id }: { file: File; id: string }) => {
  const data = new FormData() as any;
  data.append('file', file, file?.name);

  return fetch(
    'post',
    `companies/${id}/upload-excel`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
  );
};

export const fetchCompanyContacts = (id: string) =>
  fetch<CompanyContact[]>('get', `companies/${id}/contacts`);

export const fetchCompanyContactCreate = (companyId: string, data: Company) =>
  fetch<CompanyContact>('post', `companies/${companyId}/contacts`, { payload: data });

export const fetchCompanyContactsUpdate = (
  companyId: string,
  contactId: string,
  data: Company,
) =>
  fetch<CompanyContact>('patch', `companies/${companyId}/contacts/${contactId}`, {
    payload: data,
  });

export const fetchCompanyContactsDelete = (companyId: string, contactId: string) =>
  fetch<CompanyContact>('delete', `companies/${companyId}/contacts/${contactId}`);

export const fetchCompanyLogoUpload = (companyId: string, file: File) => {
  const data = new FormData();
  data.append('file', file, file?.name);

  return fetch<{ status: string }>(
    'post',
    `companies/${companyId}/logo_upload`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};
export const fetchCompanyNews = (id: string) =>
  fetch<News[]>('get', `companies/${id}/news`);

export const fetchCompanyMembersList = (companyId: string) =>
  fetch<User[]>('get', `companies/members/${companyId}`);

export const fetchCompanyMembersAdd = (param: AddCompanyMembersReq) =>
  fetch('post', 'companies/members', { payload: param });

export const fetchCompanyMembersDelete = (id: string) =>
  fetch('delete', `companies/members/${id}`);

export const fetchCompanyMembersInvite = (companyId: string) =>
  fetch('post', `companies/invite_link/${companyId}`);

export const fetchCompanySubscription = (companyId: string) =>
  fetch<SubscriptionCompany>('get', `companies/${companyId}/subscription`);

export const fetchCompanySubscriptionUpdate = (
  companyId: string,
  data: SubscriptionCompany,
) =>
  fetch<SubscriptionCompany>('patch', `companies/${companyId}/subscription`, {
    payload: data,
  });

export const fetchCompanySubscriptionDelete = (companyId: string) =>
  fetch<SubscriptionCompany>('delete', `companies/${companyId}/subscription`);

export const fetchSocialImpactLog = (companyId: string) =>
  fetch<SocialImpactLogItem[]>('get', `companies/${companyId}/social-impact-log`);

export const fetchExportSocialImpactLog = (companyId: string) =>
  fetch<{ name: string; file: string }>(
    'get',
    `companies/${companyId}/export-social-impact-log`,
  );

export const fetchExportByFilters = (location: any) =>
  fetch<{ name: string; file: string }>(
    'get',
    `companies/exportByFilters${paramsToQueryString(location)}`,
  );

export const getCompaniesInfo = () => {
  const options = {
    host: clientApiUrl,
  };
  return fetch<searchCompaniesInfo>('get', 'admin/companies/filterInfo', options);
};

export const fetchSearchJobTitle = (q: string) =>
  fetch<SearchJobTitle[]>(
    'get',
    `companies/getJobTitles${paramsToQueryString({
      q,
    })}`,
  );

export const downloadImportTemplate = () =>
  fetch<{ name: string; file: string }>('get', 'companies/downloadImportTemplate');

export const fetchUploadCompaniesXLSX = (file: File) => {
  const data = new FormData();
  data.append('file', file, file?.name);
  // const queryParams = new URLSearchParams({ name });

  return fetch(
    'post',
    `companies/uploadImportExcel`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

// export const fetchImportCompanies = (data: any) =>
//   fetch('post', `companies/saveImportExcel`, {
//     payload: data,
//   });

export const fetchImportCompanies = (file: File) => {
  const data = new FormData();
  data.append('file', file, file?.name);
  return fetch(
    'post',
    `companies/saveImportExcel`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchGetImportTask = () =>
  fetch<{ code: string; msg: string }>('get', 'companies/getImportTask');
