import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { FieldInviteInput } from 'components/FormFields';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { UIButton } from 'components/UI';
import { required } from 'utils/validation';
import { InviteData } from '../interfaces';

import styled from 'styled-components';
import { Field } from 'components/FinalForm';

export interface FormProps {
  onSubmit: (values: InviteData) => OnSubmitReturnType;
  fetchInviteLinkOperation: (
    teamId: string,
  ) => () => Promise<string | object | boolean | undefined>;
  inviteId: string;
}

const InviteForm = ({ onSubmit, fetchInviteLinkOperation, inviteId }: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
      }: FormRenderProps<InviteData>) => {
        return (
          <FormStyled>
            <Field
              name="email"
              component={FieldInviteInput}
              placeholder="Enter email or copy the link"
              validate={required}
              fetchInviteLinkOperation={fetchInviteLinkOperation}
              inviteId={inviteId}
            />

            <Btn
              modifiers="primary"
              title="Invite"
              handleClick={handleSubmit}
              type="submit"
              disabled={pristine || submitting || hasValidationErrors}
              isLoading={submitting}
            />
          </FormStyled>
        );
      }}
    />
  );
};

const FormStyled = styled.form`
  display: flex;
  align-items: center;
`;

const Btn = styled(UIButton)`
  width: 114px;
  border-radius: 0 6px 6px 0;
`;

export default InviteForm;
