import * as api from './api';
import { RequestProps } from 'interfaces/common';
import { AddTeamMembersReq, SubscriptionProps } from 'interfaces/team';
import * as actions from './actions';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import axios from 'axios';
import * as userApi from '../user/api';
import { fetchErrorHandler } from 'utils/errorHandlers';
import { UserChangeCanvaReq } from 'interfaces/user';

export const fetchTeamsSearch = ({
  page,
  q,
  limit,
  sdgGoals,
  missionFocus,
  location,
}: RequestProps) => async (dispatch: Dispatch) => {
  return api
    .fetchTeamsSearch(page, q, limit, sdgGoals, missionFocus, location)
    .then(teams => {
      dispatch(actions.setTeams(teams));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchTeam = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchTeam(id)
    .then(team => {
      dispatch(actions.setTeam(team));
      return team;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchTeamsSuggested = (query: string) => async (dispatch: Dispatch) => {
  return api
    .fetchTeamsSuggested(query)
    .then(({ items }) => {
      dispatch(actions.setTeamsSuggested(items));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchTeamUpdate = ({ id, data }) => async (dispatch: Dispatch) => {
  return api
    .fetchTeamUpdate(id, data)
    .then(team => {
      Toast.success('Organization successfully updated');
      dispatch(actions.setTeam(team));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchTeamCreate = data => async (dispatch: Dispatch) => {
  return api
    .fetchTeamCreate(data)
    .then(team => {
      Toast.success('Organization successfully created');
      dispatch(actions.setTeam(team));
      return team;
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        Toast.error(err.response.data.message);
        throw err;
      }
      Toast.error('Error create organization');
      throw err;
    });
};

export const fetchSubscriptionTeams = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchSubscriptionTeams(id)
    .then(subscription => {
      dispatch(actions.setTeamSubscription(subscription));
    })
    .catch(error => {
      throw error;
    });
};

export const fetchSubscriptionUpdate = (
  {
    id,
    data,
  }: {
    id: string;
    data: SubscriptionProps;
  },
  hideToast: boolean,
) => async (dispatch: Dispatch) => {
  return api
    .fetchSubscriptionUpdate(id, data)
    .then(subscription => {
      dispatch(actions.setTeamSubscription(subscription));
      !hideToast && Toast.success('Subscription successfully updated');
    })
    .catch(error => {
      throw error;
    });
};

export const fetchSubscriptionDestroy = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchSubscriptionDestroy(id)
    .then(teamId => {
      dispatch(actions.removeTeamSubscription(teamId));
      Toast.success('Subscription successfully removed');
    })
    .catch(error => {
      throw error;
    });
};

export const fetchFileUpload = ({ file, id }: { file: File; id: string }) => async () => {
  return api
    .fetchFileUpload({ file, id })
    .then(() => {
      return Toast.success('File successfully uploaded');
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};
export const fetchTeamLogoUpload = ({
  file,
  teamId,
}: {
  file: File;
  teamId: string;
}) => async () => {
  return api
    .fetchTeamLogoUpload({ file, teamId })
    .then(() => {
      return Toast.success('File successfully uploaded');
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchTeamDelete = (teamId: string) => async (dispatch: Dispatch) => {
  return api
    .fetchTeamDelete(teamId)
    .then(({ status }) => {
      if (status !== 'success') {
        return;
      }
      dispatch(actions.deleteTeam(teamId));
    })
    .catch(() => Toast.error('Error delete Organization'));
};

export const fetchAddTeamMembers = (param: AddTeamMembersReq) => async () => {
  try {
    await api.fetchAddTeamMembers(param);

    Toast.success('Email sent successfully');
  } catch (error) {
    if (axios.isAxiosError(error)) {
      error.response?.data.message === 'Validation failed' &&
        Toast.error('Email must be a valid email');
    }
  }
};

export const fetchInviteLinkMember = (teamId: string) => async () => {
  try {
    const link = await api.fetchInviteLinkTeam(teamId);

    Toast.success('Invite link was generated successfully');

    return link;
  } catch (error) {
    console.error(error);
  }
};

export const fetchDeleteTeamMembers = (id: string) => async (dispatch: Dispatch) => {
  try {
    await api.fetchDeleteTeamMembers(id);
    dispatch(actions.deleteTeamMembers(id));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      Toast.error(error.response?.data.message);
    }
  }
};

export const fetchTeamMembers = (teamId: string) => async (dispatch: Dispatch) => {
  try {
    const data = await api.fetchTeamMembers(teamId);
    dispatch(actions.getTeamMembers(data));
  } catch (error) {
    console.log(error);
  }
};

export const fetchTeamNews = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchTeamNews(id)
    .then(news => {
      dispatch(actions.setTeamNews(news));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchTeamContacts = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchTeamContacts(id)
    .then(contacts => {
      dispatch(actions.setContacts(contacts));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchTeamContactCreate = ({ teamId, data }) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchTeamContactCreate(teamId, data)
    .then(contact => {
      Toast.success('Contact successfully created');
      dispatch(actions.setContact(contact));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchTeamContactsUpdate = ({ teamId, contactId, data }) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchTeamContactsUpdate(teamId, contactId, data)
    .then(contact => {
      Toast.success('Contact successfully updated');
      dispatch(actions.setContact(contact));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchTeamContactsDelete = ({ teamId, contactId }) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchTeamContactsDelete(teamId, contactId)
    .then(() => {
      dispatch(actions.deleteContact(contactId));
    })
    .catch(() => Toast.error('Error delete organization contact'));
};

export const fetchTeamMemberUpdate = ({ id, data }) => async (dispatch: Dispatch) => {
  return userApi
    .fetchUserUpdate(id, data)
    .then(user => {
      dispatch(actions.setTeamMember(user));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchTeamMemberChangeCanva = (params: UserChangeCanvaReq) => async (
  dispatch: Dispatch,
) => {
  return userApi
    .fetchUserChangeCanva(params)
    .then(() => {
      dispatch(actions.setTeamMemberIsCanva(params));
    })
    .catch(err => {
      fetchErrorHandler(err);
    });
};

export const fetchPayPalGivingFundCanUpdate = (teamId: string) => async (
  dispatch: Dispatch,
) => {
  return api.fetchPayPalGivingFundCanUpdate(teamId).then(bool => {
    dispatch(actions.setTeamPayPalGivingFundCanUpdate(bool));
  });
};

export const fetchPayPalGivingFundIfConfirm = (teamId: string) => async (
  dispatch: Dispatch,
) => {
  return api.fetchPayPalGivingFundIfConfirm(teamId).then(bool => {
    dispatch(actions.setTeamPayPalGivingFundIfConfirm(bool));
  });
};

export const fetchImpactGenome = () => async (dispatch: Dispatch) => {
  return api
    .fetchImpactGenome()
    .then(data => {
      dispatch(actions.setImpactGenome(data));
    })
    .catch(fetchErrorHandler);
};

export const fetchImportCompanies = (data: any) => async (dispatch: Dispatch) => {
  return api
    .fetchImportCompanies(data)
    .then(company => {
      Toast.success('Organizations successfully created');
      // dispatch(actions.setCompany(company));
    })
    .catch(fetchErrorHandler);
};

export const fetchGetImportTask = () => async () => {
  return api.fetchGetImportTask().catch(fetchErrorHandler);
};

export const fetchGetTaskInfo = (Id: string) => async () => {
  return api.fetchGetTaskInfo(Id).catch(fetchErrorHandler);
};
