import DownloadIcon from 'assets/images/icons/download.svg';
import { UISpinner } from 'components/UI';
import { ActionButtonProps as InjectedProps } from 'interfaces/common';
import React, { ChangeEvent } from 'react';
import { ReactSVG } from 'react-svg';
import Toast from 'services/Toast';
import styled, { CSSProp } from 'styled-components';
import { lessThan5MB } from 'utils/validation';
import { FileListByList, FileListByThumbnail } from './FileListRenders';

interface BaseProps {
  onUpload: (files: File[]) => void;
  styleType?: 'button' | 'label';
  multiple?: boolean;
  filesLimit?: number;
  accept?: string;
  acceptValidator?: (filename: string) => boolean;
  inputId?: string;
  disabled?: boolean;
  desc?: string;
  filelist?: { label: string | undefined; value: string; filesize?: number }[];
  onDeleteFile: (fileKey: string) => void;
  areaHeight?: number;
  itemWidth?: number;
  descWidth?: number;
  showFileListType?: 'list' | 'thumbnail';
  isRequired?: boolean;
  fileSizeValidator?: (bytes: number) => string | undefined;
}

type Props = BaseProps & Partial<InjectedProps>;

const UploadFileArea = ({
  onUpload,
  onDeleteFile,
  name = 'Upload',
  icon = DownloadIcon,
  iconCss,
  isloading,
  styleType = 'button',
  multiple,
  filesLimit = 10,
  accept,
  acceptValidator,
  inputId = 'upload-button-input',
  disabled = false,
  desc,
  filelist = [],
  areaHeight,
  itemWidth,
  descWidth,
  showFileListType = 'list',
  isRequired,
  fileSizeValidator = lessThan5MB,
}: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files?.length) return;

    const sizeError = [...files].some(file => {
      const validationMessage = fileSizeValidator(file.size);
      if (validationMessage) Toast.error(validationMessage);
      return validationMessage !== undefined;
    });
    if (sizeError) return;

    if (files.length > filesLimit) {
      return Toast.warn(`You may upload up to ${filesLimit} files per time`);
    }

    if (acceptValidator && [...files].some(file => !acceptValidator(file.name))) return;

    onUpload([...files]);
    e.target.value = '';
  };

  return (
    <Root>
      <UploadWrapper
        areaHeight={areaHeight}
        style={{
          background: isRequired ? '#FFF2F2' : '',
          border: isRequired ? '1px dashed var(--red)' : '',
        }}
      >
        <UploadBtnWrapper>
          {isloading && <Spinner />}
          {styleType === 'label' ? (
            <Label htmlFor={disabled ? '' : inputId} disabled={disabled}>
              <Icon css={iconCss} src={icon} />
              <Text isloading={isloading}>{name}</Text>
            </Label>
          ) : (
            <Btn htmlFor={disabled ? '' : inputId} disabled={disabled}>
              {!isloading && name}
            </Btn>
          )}

          <Input
            id={inputId}
            multiple={multiple}
            type="file"
            onChange={handleChange}
            accept={accept || '.xlsx'}
          />
        </UploadBtnWrapper>
        <Desc style={{ width: `${descWidth || 100}%` }}>{desc}</Desc>
      </UploadWrapper>
      {showFileListType === 'list' && (
        <FileListByList
          filelist={filelist}
          itemWidth={itemWidth}
          disabled={disabled}
          onDeleteFile={onDeleteFile}
        />
      )}
      {showFileListType === 'thumbnail' && (
        <FileListByThumbnail
          filelist={filelist}
          disabled={disabled}
          onDeleteFile={onDeleteFile}
        />
      )}
    </Root>
  );
};

const Root = styled.div`
  margin-bottom: 24px;
`;

const UploadBtnWrapper = styled.button`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  transition: 0.5s ease;
  position: relative;
`;

const UploadWrapper = styled.div<{ areaHeight?: number }>`
  height: ${({ areaHeight }) => `${areaHeight || 203}px`};
  border: 1px dashed #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Desc = styled.div`
  color: #979797;
  line-height: 145%;
  text-align: center;
  margin-top: 18px;
  font-size: 14px;
`;

const Icon = styled(ReactSVG)<{ css?: CSSProp }>`
  margin-right: 12px;
  transform: rotate(180deg);

  ${({ css }) => css}
`;

const Text = styled.span<{ isloading?: boolean }>`
  ${({ isloading }) => isloading && 'opacity: 0.5'};

  &:hover {
    text-shadow: 0 0 0.01px var(--black), 0 0 0.01px var(--black);
  }
`;

const Spinner = styled(UISpinner)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  svg {
    height: 20px;
    width: 20px;
  }
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
  `}
`;

const Btn = styled.label<{ disabled: boolean }>`
  padding: 0 16px;
  height: 36px;
  background-color: var(--secondaryBlue);
  font-size: 12px;
  line-height: 150%;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
  `}
`;

export default UploadFileArea;
