import React, { ReactNode } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

interface Props {
  icon: string;
  children?: ReactNode;
  className?: string;
}
const UITipIconText = ({ icon, children, className }: Props) => {
  return (
    <Root className={className}>
      <Icon src={icon} />
      <Text>{children}</Text>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 150%;
  color: #103958;
  font-weight: 400;
`;

const Icon = styled(ReactSVG)`
  margin-right: 10px;
`;

const Text = styled.span``;

export default UITipIconText;
