/* eslint-disable react-hooks/exhaustive-deps */
import { ExportButton } from 'components/ActionButtons';
import Jumbotron from 'components/Jumbotron';
import {
  MonthlyReportOrganizationsTable,
  MonthlyReportOverallTable,
  MonthlyReportUsersTable,
  MonthlyReportProspectingTable,
  MonthlyReportROITable,
  MonthlyReportPricingTable,
} from 'pages/monthlyReport/components/tables';
import { useOperation } from 'hooks/common';
import useDownloadFile from 'hooks/common/useDownloadFile';
import { MonthlyReportData } from 'interfaces/monthlyReport';
import { MainWrapper } from 'layouts';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchMonthlyReportExcel } from 'store/ducks/monthlyReport/api';
import { fetchMonthlyReportDetail } from 'store/ducks/monthlyReport/operations';
import { getMonthlyReportDetail } from 'store/ducks/monthlyReport/selectors';
import { StateProps } from 'store/interfaces';
import styled from 'styled-components';
import MonthlyReportTop50CompaniesSearchedTable from '../components/tables/MonthlyReportTop50CompaniesSearchedTable';

const MonthlyReportDetail = () => {
  const params: { reportId: string } = useParams();

  const [onFetchMonthlyReportDetail, , isLoading] = useOperation(
    fetchMonthlyReportDetail,
  );

  const detail = useSelector<StateProps, MonthlyReportData | undefined>(
    getMonthlyReportDetail,
  );

  const exportExcel = useDownloadFile(fetchMonthlyReportExcel);

  useEffect(() => {
    onFetchMonthlyReportDetail(params.reportId);
  }, []);

  return (
    <MainWrapper
      isLoading={isLoading}
      header={
        <Jumbotron name={detail?.name} back>
          <ExportButton
            name="Export to Excel"
            uploadHandler={e => {
              e.stopPropagation();
              exportExcel(params.reportId);
            }}
          />
        </Jumbotron>
      }
    >
      <Body>
        {!!detail?.overAllData?.length && (
          <>
            <TableTitle>Overall</TableTitle>
            <MonthlyReportOverallTable rows={detail?.overAllData || []} />
          </>
        )}

        {!!detail?.organizationData?.length && (
          <>
            <TableTitle>Organizations</TableTitle>
            <MonthlyReportOrganizationsTable rows={detail?.organizationData || []} />
          </>
        )}

        {!!detail?.usersData?.length && (
          <>
            <TableTitle>Users</TableTitle>
            <MonthlyReportUsersTable rows={detail?.usersData || []} />
          </>
        )}

        {!!detail?.prospectingData?.length && (
          <>
            <TableTitle>Prospecting</TableTitle>
            <MonthlyReportProspectingTable rows={detail.prospectingData} />
          </>
        )}

        {!!detail?.companySearchLog?.length && (
          <>
            <TableTitle>Top 50 Companies Searched</TableTitle>
            <MonthlyReportTop50CompaniesSearchedTable
              rows={detail.companySearchLog}
              reportId={params.reportId}
            />
          </>
        )}

        {!!detail?.roiData?.length && (
          <>
            <TableTitle>ROI</TableTitle>
            <MonthlyReportROITable rows={detail.roiData} />
          </>
        )}

        {!!detail?.pricingData?.length && (
          <>
            <TableTitle>Pricing</TableTitle>
            <MonthlyReportPricingTable rows={detail.pricingData} />
          </>
        )}
      </Body>
    </MainWrapper>
  );
};

const Body = styled.div`
  margin-bottom: 40px;
`;

export const TableTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  margin: 40px 0 16px;
  display: flex;
  justify-content: space-between;

  &:first-child {
    margin: 0 0 16px;
  }
`;

export default MonthlyReportDetail;
