import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { UIButton, UICheckbox } from 'components/UI';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { Field } from 'components/FinalForm';
import { SubscriptionCompany } from 'interfaces/companies';

export interface FormProps {
  onSubmit: (values: SubscriptionCompany) => OnSubmitReturnType;
  handleRemoveSubscription: () => void;
  initialValues?: SubscriptionCompany;
  isLoading?: boolean;
}

const SubscriptionCompanyForm = ({
  onSubmit,
  initialValues,
  handleRemoveSubscription,
}: FormProps) => {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
        values,
        form,
      }: FormRenderProps<SubscriptionCompany>) => {
        return (
          <div>
            <Body>
              <FieldSection>
                <TitleSection>Subscription Plan</TitleSection>
                <Field name="isComplete">
                  {({ input }) => (
                    <Checkbox
                      label="Complete Corporate Bundle"
                      check={input.value}
                      handleClick={() => {
                        input.onChange(!input.value);
                        form.change('isIssue', !input.value);
                        form.change('isVetting', !input.value);
                        form.change('isRoi', !input.value);
                        form.change('isCanva', !input.value);
                        form.change('isFundraising', !input.value);
                        form.change('isGiving', !input.value);
                      }}
                    />
                  )}
                </Field>

                <Field name="isIssue">
                  {({ input }) => (
                    <Checkbox
                      label="Issue Mapping"
                      check={input.value}
                      handleClick={() => input.onChange(!input.value)}
                      disabled={values.isComplete}
                    />
                  )}
                </Field>

                <Field name="isRoi">
                  {({ input }) => (
                    <Checkbox
                      label="ROI"
                      check={input.value}
                      handleClick={() => input.onChange(!input.value)}
                      disabled={values.isComplete}
                    />
                  )}
                </Field>

                <Field name="isCanva">
                  {({ input }) => (
                    <IndentWrapper>
                      <Checkbox
                        label="Custom Report"
                        check={input.value}
                        handleClick={() => input.onChange(!input.value)}
                        disabled={values.isComplete || !values.isRoi}
                      />
                    </IndentWrapper>
                  )}
                </Field>
                <Field name="isFundraising">
                  {({ input }) => (
                    <Checkbox
                      label="Fundraising"
                      check={input.value}
                      handleClick={() => input.onChange(!input.value)}
                      disabled={values.isComplete}
                    />
                  )}
                </Field>
                <Field name="isVetting">
                  {({ input }) => (
                    <Checkbox
                      label="Vetting"
                      check={input.value}
                      handleClick={() => input.onChange(!input.value)}
                      disabled={values.isComplete}
                    />
                  )}
                </Field>
                <Field name="isGiving">
                  {({ input }) => (
                    <Checkbox
                      label="Giving"
                      check={input.value}
                      handleClick={() => input.onChange(!input.value)}
                      disabled={values.isComplete}
                    />
                  )}
                </Field>
              </FieldSection>

              <BtnGroup>
                <UIButton
                  handleClick={handleRemoveSubscription}
                  modifiers="danger"
                  title="Delete"
                />

                <UIButton
                  title="Save"
                  modifiers="primary"
                  handleClick={handleSubmit}
                  type="submit"
                  disabled={pristine || submitting || hasValidationErrors}
                  isLoading={submitting}
                />
              </BtnGroup>
            </Body>
          </div>
        );
      }}
    />
  );
};

const BtnGroup = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 32px;

  button:first-child {
    margin-right: 8px;
  }

  & button {
    width: 136px;
  }
`;

const Checkbox = styled(UICheckbox)`
  margin-bottom: 8px;
`;

const FieldSection = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '30px'};
`;

const Body = styled.div`
  background: #fff;
`;

const TitleSection = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  margin-bottom: 24px;
`;

const IndentWrapper = styled.div`
  margin-left: 24px;
`;

export default SubscriptionCompanyForm;
