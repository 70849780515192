/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { UISpinner } from 'components/UI';
import UserIcon from 'assets/images/icons/user.svg';
import { useFileUrl } from 'hooks/common';

interface Props {
  className?: string;
  imgKey?: string;
  loading?: boolean;
  title?: string;
}

const UILoadAvatar: FC<Props> = ({ className, imgKey, loading, title }: Props) => {
  const { fileUrl, load, setFileUrl } = useFileUrl({});

  useEffect(() => {
    if (!imgKey) return;
    if (/^http/.test(imgKey) || /^data:/.test(imgKey)) {
      setFileUrl(imgKey);
      return;
    }

    load(imgKey);
  }, [imgKey]);

  return (
    <Root title={title}>
      {loading ? (
        <Avatar className={className}>
          <UISpinner width={25} height={25} />
        </Avatar>
      ) : fileUrl ? (
        <AvatarImg
          className={className}
          style={{
            backgroundImage: `url(${fileUrl})`,
          }}
        />
      ) : (
        <Avatar className={className}>
          <EmptyAvatar src={UserIcon} />
        </Avatar>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AvatarImg = styled(Avatar)`
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const EmptyAvatar = styled(ReactSVG)`
  & svg {
    width: 48px;
    height: 48px;
    & path {
      fill: var(--darkGray);
    }
  }
`;

export default UILoadAvatar;
