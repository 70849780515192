import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { UIModal } from 'components/UI';
import { Title } from 'components/ProfileView/AffinityForm';
import { ReactSVG } from 'react-svg';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import Success from 'assets/images/icons/success-green.svg';
import FilePng from 'assets/images/file.png';
import { UIButton } from 'components/UI';
import { useOperation } from 'hooks/common';
import { fetchGetTaskInfo } from 'store/ducks/team/operations';
import useDownloadFile from 'hooks/common/useDownloadFile';
import { fetchExportByFail } from 'store/ducks/team/api';

interface Props extends InjectedProps {
  taskId: string;
  isCompany: boolean;
}

const ExportResults = ({ onClose, taskId, isCompany, ...modalProps }: Props) => {
  useLockBodyScroll();
  const [onSubscriptionTaskInfo] = useOperation(fetchGetTaskInfo);
  const [info, setInfo] = useState<any>();
  useEffect(() => {
    onSubscriptionTaskInfo(taskId).then(value => {
      if (value) {
        setInfo(value);
      }
    });
  }, []);

  const exportToExcel = useDownloadFile(fetchExportByFail);
  const downloadExcel = () => {
    return exportToExcel(taskId);
  };
  return (
    <UIModal
      onClose={onClose}
      hideCloseIcon={true}
      propsBodyCss={{ padding: '40px 60px' }}
      style={{
        width: '810px',
        maxWidth: '1129px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Title
        style={{
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '145%',
          fontFamily: 'Rubik',
          color: '#122434',
          fontStyle: 'normal',
          marginBottom: '30px',
        }}
      >
        Import or Update {isCompany ? 'Companies' : 'Organizations'}
      </Title>
      <Header>
        <Img style={{ marginLeft: 16, marginTop: '19px' }} src={FilePng} alt="heart" />
        <FileName>{info?.excelName}</FileName>
        <ReactSVG style={{ marginRight: 16, marginTop: '20px' }} src={Success} />
      </Header>
      <Header1>
        <Records>
          <NuOf>№ of Records in the Import File</NuOf>
          <Number>{info?.total}</Number>
        </Records>
        <Records>
          <NuOf>№ of Records Successfully Imported</NuOf>
          <Number>{info?.success}</Number>
        </Records>
        <Records>
          <NuOf>№ of Records that did Not Import</NuOf>
          <Number>{info?.fail}</Number>
        </Records>
      </Header1>
      <Header1 style={{ marginTop: '16px' }}>
        <div></div>
        {info?.fail > 0 && (
          <DownloadReport>
            Download Report{' '}
            <span style={{ color: '#2BAEE0', cursor: 'pointer' }} onClick={downloadExcel}>
              here
            </span>
          </DownloadReport>
        )}
      </Header1>
      <Header1>
        <Button
          title="OK"
          modifiers={'primary'}
          handleClick={onClose}
          style={{ marginBottom: '3px' }}
        />
      </Header1>
    </UIModal>
  );
};

const Img = styled.img`
  width: 22px;
  height: 29px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f9f9f9;
  height: 66px;
  font-size: 12px;
  border-radius: 6px;
  width: 760px;
  margin-left: -35px;
`;

const FileName = styled.div`
  margin-top: 27px;
  margin-left: -163px;
  color: #2baee0;
  font-size: 14px;
  width: 500px;
`;

const Header1 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 760px;
  margin-left: -35px;
  margin-top: 24px;
`;

const Records = styled.div`
  background: #f9f9f9;
  width: 241.28px;
  height: 71px;
`;
const NuOf = styled.div`
  font-size: 12px;
  text-align: center;
  color: #737373;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: 250%;
  margint-top: 10px;
`;

const Number = styled.div`
  text-align: center;
  color: #122434;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 155%;
`;

const DownloadReport = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #737373;
`;

const Button = styled(UIButton)`
  font-size: 12px;
  width: 780px;
`;
export default ExportResults;
