import React, { ReactNode } from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import SortIconSVG from 'assets/images/icons/sort-icon.svg';
import { ReactSVG } from 'react-svg';
import { SortStatus } from 'hooks/common/useSortStatus';

interface Props {
  style?: CSSProperties;
  children?: ReactNode;
  sortStatus: SortStatus;
  colName: string;
  onClick: (colName: string) => void;
}

const TableSortHeaderCell = ({
  style,
  children,
  sortStatus,
  onClick,
  colName,
  ...rest
}: Props) => {
  return (
    <Root style={style} onClick={() => onClick(colName)} {...rest}>
      <Text>
        {children}
        <SortGroup>
          <SortUp
            isHighlight={sortStatus.name === colName && sortStatus.status === 'ASC'}
          />
          <SortDown
            isHighlight={sortStatus.name === colName && sortStatus.status === 'DESC'}
          />
        </SortGroup>
      </Text>
    </Root>
  );
};

const Root = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  cursor: pointer;
  user-select: none;
  background: #f4f4f4;
  vertical-align: middle;
  position: sticky;
  top: 0;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-right: 24px;
  }
`;

const Text = styled.div`
  display: inline-block;
  white-space: nowrap;
`;

const SortGroup = styled.div`
  display: inline-block;
  margin-left: 6px;
`;

const Icon = styled(ReactSVG)`
  svg {
    width: 8px;
    height: 6px;
    margin: -1px 0;
  }

  svg * {
    ${({ isHighlight }) => isHighlight && `fill: var(--blue);`}
  }
`;

const SortUp = styled(Icon).attrs(() => ({
  src: SortIconSVG,
}))`
  transform: rotate(180deg);
`;
const SortDown = styled(Icon).attrs(() => ({ src: SortIconSVG }))`
  transform: rotate(0deg);
`;

export default TableSortHeaderCell;
