import React, { useEffect, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { composeValidators } from 'utils';
import { UIButton, UIModal } from 'components/UI';
import styled from 'styled-components';
import { FieldInput, FieldDatePicker, FieldTextArea } from 'components/FormFields';
import { required } from 'utils/validation';
import { News } from 'interfaces/companies';
import { fetch } from 'services/ApiService';

const EditTeamNews = ({
  onClose = () => {},
  onSubmit = a => {},
  model,
  ...modalProps
}) => {
  const [formModel, setFormModel] = useState<News>({ ...model });
  useEffect(() => {
    if (model.id) {
      fetch<News>('get', `news/${model.id}`).then(x => {
        setFormModel(x);
      });
    }
  }, [model.id]);
  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '28px 40px 40px' }}
      style={{
        width: '100%',
        maxWidth: '844px',
        top: '100px',
      }}
      overlayStyle={{
        overflow: 'auto',
      }}
      header={<ModalTitle>{formModel.id ? 'Edit' : 'Add'} News</ModalTitle>}
    >
      <Form
        initialValues={formModel}
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          submitting,
          hasValidationErrors,
        }: FormRenderProps<News>) => {
          return (
            <form>
              <div style={{ display: 'none' }}>
                <Field
                  name="id"
                  component={FieldInput}
                  placeholder="Enter id"
                  label="id"
                />
                <Field
                  name="teamId"
                  component={FieldInput}
                  placeholder="Enter TeamId"
                  validate={required}
                  label="teamId"
                />
              </div>
              <Field
                name="pageDate"
                component={FieldDatePicker}
                placeholder="Enter News Date"
                label="News Date"
              />
              <Field
                name="siteFrom"
                component={FieldInput}
                placeholder="Enter Site Name"
                label="Site Name"
              />
              <Field
                name="title"
                component={FieldInput}
                placeholder="Enter title"
                validate={required}
                label="Title"
              />
              <Field
                name="author"
                component={FieldInput}
                placeholder="Enter Author"
                label="Author"
              />
              <Field
                name="url"
                component={FieldInput}
                placeholder="Url"
                validate={composeValidators<string>(required)}
                label="Url"
              />

              <Field
                name="imageUrl"
                component={FieldInput}
                placeholder="Enter Image Url"
                label="Image Url"
              />
              <Field
                name="content"
                component={FieldTextArea}
                placeholder="Enter Content Summary"
                label="Content Summary"
              />

              <BtnGroup>
                <UIButton
                  modifiers="secondary"
                  title="Cancel"
                  handleClick={onClose}
                  type="button"
                />
                <UIButton
                  modifiers="primary"
                  title="Save"
                  handleClick={handleSubmit}
                  type="submit"
                  disabled={submitting || hasValidationErrors}
                  isLoading={submitting}
                />
              </BtnGroup>
            </form>
          );
        }}
      />
    </UIModal>
  );
};
const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  padding: 20px 0 16px;
`;
const BtnGroup = styled.div`
  display: flex;
  width: 517px;
  justify-content: space-between;

  & button:first-child {
    margin-right: 9px;
  }

  & button {
    flex: 1;
  }
`;

export default EditTeamNews;
