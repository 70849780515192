import styled, { CSSProp } from 'styled-components';

const FormLabel = styled.label<{ css?: CSSProp }>`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  margin-bottom: 4px;

  ${({ css }) => css}
`;

export default FormLabel;
