/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import AuthFormWrapper from 'components/AuthForms/AuthFormWrapper';
import LoginForm from 'components/AuthForms/LoginForm';
import { AuthData } from 'interfaces/auth';
import { useOperation } from 'hooks/common';
import { fetchSignIn } from 'store/ducks/auth/operations';
import { useRemember } from 'react-remember';

const Login = () => {
  const [onLogin] = useOperation(fetchSignIn);

  const [localStore] = useRemember();

  const login = useCallback((values: AuthData) => {
    return onLogin(values);
  }, []);

  return (
    <Wrapper>
      <LoginForm initialValues={localStore?.loginData} onSubmit={login} />
    </Wrapper>
  );
};

const Wrapper = styled(AuthFormWrapper)`
  margin-bottom: 40px;
`;

export default Login;
