import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import HeartIcon from 'assets/images/icons/heart-small.svg';
import UISpinner from './UISpinner';

interface Props {
  isActive: boolean;
  setLikeHandler: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  withBorder?: boolean;
}

const LikeButton = ({
  isActive,
  setLikeHandler,
  disabled,
  isLoading,
  withBorder = true,
}: Props) => {
  return (
    <Button onClick={setLikeHandler} disabled={disabled} withBorder={withBorder}>
      {isLoading ? (
        <SpinnerWrapper width={18} height={18} />
      ) : (
        <Icon $isActive={isActive} src={HeartIcon} />
      )}
    </Button>
  );
};

const SpinnerWrapper = styled(UISpinner)`
  svg {
    circle {
      stroke: rgba(212, 201, 236, 0.4);
    }
  }
`;

const Button = styled.button<{ withBorder: boolean }>`
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ withBorder }) =>
    withBorder &&
    `
    border: 1px solid #e8e8e8;
    &:hover {
      border-color: var(--red);
    }

    &:focus {
    border-color: var(--red);
    }

  `}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Icon = styled(ReactSVG)<{ $isActive: boolean }>`
  & path {
    fill: ${({ $isActive }) => ($isActive ? 'var(--red)' : 'none')};
  }
`;

export default LikeButton;
