export const SET_TEAM = 'team/SET_TEAM';
export const SET_TEAMS = 'team/SET_TEAMS';
export const SET_TEAMS_SUGGESTED = 'team/SET_TEAMS_SUGGESTED';
export const DELETE_TEAM = 'team/DELETE_TEAM';

export const SET_SUBSCRIPTION = 'team/SET_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION = 'team/DELETE_SUBSCRIPTION';

export const DELETE_TEAM_MEMBERS = 'team/DELETE_TEAM_MEMBERS';
export const GET_TEAM_MEMBERS = 'team/GET_TEAM_MEMBERS';
export const SET_TEAM_NEWS = 'team/SET_TEAM_NEWS';
export const SET_TEAM_MEMBER = 'team/SET_TEAM_MEMBER';
export const SET_TEAM_MEMBER_ISCANVA = 'team/SET_TEAM_MEMBER_ISCANVA';

export const DELETE_CONTACT = 'team/DELETE_CONTACT';
export const SET_CONTACT = 'team/SET_CONTACT';
export const SET_CONTACTS = 'team/SET_CONTACTS';
export const SET_TEAM_PAYPALGIVINGFUND_CANUPDATE =
  'team/SET_TEAM_PAYPALGIVINGFUND_CANUPDATE';
export const SET_TEAM_PAYPALGIVINGFUND_IFCONFIRM =
  'team/SET_TEAM_PAYPALGIVINGFUND_IFCONFIRM';
export const SET_IMPACT_GENOME = 'team/SET_IMPACT_GENOME';
