import { MonthlyReportStoreData } from 'interfaces/monthlyReport';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import * as types from './types';

type RootAction = ActionType<typeof actions>;
export default createReducer<MonthlyReportStoreData, RootAction>({
  monthlyReportList: {
    items: [],
    meta: {
      currentPage: '0',
      itemCount: 0,
      itemsPerPage: '0',
      totalItems: 0,
      totalPages: 0,
    },
  },
  monthlyReportDetail: undefined,
})
  .handleType([types.SET_MONTHLYREPORT_LIST], (state, action) => ({
    ...state,
    monthlyReportList: action.payload,
  }))
  .handleType([types.SET_MONTHLYREPORT_DETAIL], (state, action) => ({
    ...state,
    monthlyReportDetail: action.payload,
  }));
