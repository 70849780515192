import Pagination from 'components/Pagination';
import { UIButton, UILoadAvatar } from 'components/UI';
import { useModal } from 'hooks/common';
import { PricingData, PricingListReq, PricingListRes } from 'interfaces/pricing';
import { TeamData } from 'interfaces/team';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import NewValuationModal from './NewValuationModal';
import PricingTable from './PricingTable';

interface Props {
  team: TeamData;
  pricingList: PricingData[];
  pricingMeta: PricingListRes['meta'];
  handleFetchPricingList: (param: PricingListReq) => void;
  handleCreateValuation: (param: Partial<PricingData>) => Promise<unknown>;
  handleFetchDeleteValuation: (id: string) => Promise<unknown>;
}

const PricingView: FC<Props> = ({
  team,
  pricingList,
  pricingMeta,
  handleCreateValuation,
  handleFetchPricingList,
  handleFetchDeleteValuation,
}) => {
  const [isCreateLoading, setIsCreateLoading] = useState(false);

  const [showModal, hideModal] = useModal(
    () => (
      <NewValuationModal
        onClose={hideModal}
        isLoading={isCreateLoading}
        handleAccess={values => {
          setIsCreateLoading(true);
          handleCreateValuation({ ...values, teamId: team.id }).then(() => {
            setIsCreateLoading(false);
            hideModal();
          });
        }}
      />
    ),
    [isCreateLoading],
  );

  return (
    <>
      <Header>
        <Image imgKey={team.logoKey} />
        <OrganizationTitle>{team.organizationName}</OrganizationTitle>
      </Header>
      <Body>
        <BodyHeader>
          <BodyTitle>Pricing</BodyTitle>
          <Btn modifiers="third" title="New Pricing" handleClick={showModal} />
        </BodyHeader>
        <PaginationWrapper>
          <Pagination
            page={Number(pricingMeta.currentPage)}
            pageLimit={Number(pricingMeta.itemsPerPage)}
            total={pricingMeta.totalItems}
            submitHandler={page => handleFetchPricingList({ teamId: team.id, page })}
          />
        </PaginationWrapper>
        <PricingTable
          headCells={['Name', 'Data submitted', 'Report Completed', 'Status']}
          rows={pricingList}
          handleFetchDeleteValuation={handleFetchDeleteValuation}
        />
      </Body>
    </>
  );
};

const Header = styled.section`
  width: 100%;
  height: 180px;
  background-color: #f2f2f2;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 40px;
  margin-bottom: 18px;
`;

const Image = styled(UILoadAvatar)`
  border-radius: 8px;
  width: 100px;
  min-width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 26px;
  background-color: #fff;
`;

const OrganizationTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
`;

const Body = styled.section`
  background-color: #fff;
  width: 100%;
  height: 558px;
  padding: 24px 36px;
`;

const BodyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const BodyTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
`;
const Btn = styled(UIButton)`
  width: 112px;
  height: 36px;
  padding: 0;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 4px 0;
`;

export default PricingView;
