import { MonthlyReportData, MonthlyReportListRes } from 'interfaces/monthlyReport';
import * as types from './types';

export const setMonthlyReportList = (data: MonthlyReportListRes) =>
  ({
    type: types.SET_MONTHLYREPORT_LIST,
    payload: data,
  } as const);

export const setMonthlyReportDetail = (data: MonthlyReportData) =>
  ({
    type: types.SET_MONTHLYREPORT_DETAIL,
    payload: data,
  } as const);
