import {
  RecommendationData,
  RecommendationsListRes,
  RecommendationSuggestTeam,
} from 'interfaces/issueMapping';
import * as types from './types';

export const setRecommendationsList = (list: RecommendationsListRes) =>
  ({
    type: types.SET_RECOMMENDATIONS_LIST,
    payload: list,
  } as const);

export const setRecommendations = (data: RecommendationData) =>
  ({
    type: types.SET_RECOMMENDATION,
    payload: data,
  } as const);

export const setRecommendationSuggestTeams = (teams: RecommendationSuggestTeam[]) =>
  ({
    type: types.SET_RECOMMENDATION_SUGGEST_TEAMS,
    payload: teams,
  } as const);

export const clearRecommendationStore = () =>
  ({
    type: types.CLEAR_RECOMMENDATION_STORE,
  } as const);

export const setRecommendationPublished = (data: RecommendationData) =>
  ({
    type: types.SET_RECOMMENDATION_PUBLISHED,
    payload: data,
  } as const);
