import React from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import SocialImpactDataLogTable from './SocialImpactDataLogTable';
import { useSortStatus } from 'hooks/common';
import { ExportButton } from 'components/ActionButtons';
import { SocialImpactLogItem } from 'interfaces/companies';

interface Props extends UIModalProps {
  socialImpactLog: SocialImpactLogItem[];
  exportExcel: () => void;
}

const SocialImpactDataLogModal = ({
  onClose,
  socialImpactLog,
  exportExcel,
  ...modalProps
}: Props) => {
  useLockBodyScroll();

  const { data, sortStatus, handleSwitch } = useSortStatus(socialImpactLog);

  return (
    <UIModal
      hideCloseIcon
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{
        padding: '40px',
      }}
      style={{
        width: '100%',
        maxWidth: '1241px',
        height: '815px',
        overflow: 'hidden',
      }}
    >
      <Title>
        Social Impact Data Log
        <ExportButton uploadHandler={exportExcel} name="Download Impact Data Log" />
      </Title>
      <TableWrapper>
        <SocialImpactDataLogTable
          rows={data}
          onSort={handleSwitch}
          sortStatus={sortStatus}
        />
      </TableWrapper>
      <UIButton title="Close" modifiers={'secondary'} handleClick={onClose} />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 145%;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
`;

const TableWrapper = styled.div`
  margin-bottom: 40px;
`;

export default SocialImpactDataLogModal;
