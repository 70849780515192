import { Field } from 'components/FinalForm';
import { FieldTextInput, MaskedMoneyTextInput } from 'components/FormFields';
import { UIAsterisk, UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { RecommendationData } from 'interfaces/issueMapping';
import React from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { integerValidator, required } from 'utils/validation';

interface FormData {
  name: string;
  overallInvestment: string;
}

interface Props extends InjectedProps {
  isLoading: boolean;
  data?: RecommendationData;
  handleAccess: (values: FormData | RecommendationData) => void;
}

const RecommendationModal = ({
  data,
  onClose,
  handleAccess,
  isLoading,
  ...modalProps
}: Props) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '828px',
        overflow: 'hidden',
        padding: '40px 30px 30px',
      }}
    >
      <Title>Recommendation</Title>
      <Form<FormData>
        initialValues={data}
        onSubmit={handleAccess}
        render={({ handleSubmit }) => (
          <>
            <Field
              label={
                <>
                  Recommendation Name <UIAsterisk />
                </>
              }
              name="name"
              component={FieldTextInput}
              validate={required}
            />
            <Field
              label="Overall Investment"
              name="overallInvestment"
              component={MaskedMoneyTextInput}
              validate={integerValidator}
            />

            <BtnGroup>
              <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
              <Btn
                modifiers="primary"
                title="Save"
                isLoading={isLoading}
                handleClick={handleSubmit}
              />
            </BtnGroup>
          </>
        )}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 20px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

export default RecommendationModal;
