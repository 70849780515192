import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { UISuggestProps } from './interfaces';

type InputValue = string;

function UISuggest({
  list,
  handleGetSuggestions,
  handleSetValue,
  placeholder,
  initValue,
  handleBlur,
  error,
}: UISuggestProps) {
  const [value, onChangeInputValue] = useState<InputValue>(initValue || '');

  useEffect(() => {
    if (initValue) {
      onChangeInputValue(initValue);
    }
  }, [initValue]);

  const onChange = (event, { newValue }) => {
    onChangeInputValue(newValue);
  };

  const getSuggestionValue = (suggestion: any) => {
    return suggestion.name;
  };

  const renderSuggestion = (val: any) => (
    <div className="autosuggest__item">{val.name || val.organizationName}</div>
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    handleGetSuggestions(value.trim().toLowerCase());
  };

  const inputProps = {
    placeholder,
    value,
    onChange: onChange,
    onBlur: handleBlur,
    style: error ? { background: '#FFF2F2', border: '1px solid var(--red)' } : {},
  };

  return (
    <Autosuggest
      suggestions={list}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={() => {}}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={(e, { suggestion }) => {
        handleSetValue(suggestion);
        onChangeInputValue('');
      }}
    />
  );
}

export default UISuggest;
