import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

interface Props {
  defaultReportUrl: string;
}

const ROIReportSelect = ({ defaultReportUrl }: Props) => {
  return (
    <MainCard>
      <Title>Generate Partner Report</Title>
      <CardGroup>
        <Card to={defaultReportUrl}>Default Valuation Report</Card>
        {/* <Card to={`/roi`}>Customize Partner Report</Card> */}
      </CardGroup>
    </MainCard>
  );
};

const MainCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 505px;
  background: #fff;
`;

const Title = styled.div`
  display: inline-block;
  margin: 130px 0 44px;
  font-weight: 500;
  font-size: 16px;
`;

const CardGroup = styled.div`
  display: flex;
`;

const Card = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  width: 343px;
  height: 124px;
  border: 1px solid #2baee0;
  border-radius: 6px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 24px;
  }
`;

export default ROIReportSelect;
