import React from 'react';
import NumberFormat from 'react-number-format';

interface Props {
  value: number | string;
}

const UIDollarNumber = ({ value }: Props) => (
  <NumberFormat
    displayType="text"
    prefix="$"
    value={value}
    thousandSeparator=","
    thousandsGroupStyle="thousand"
  />
);

export default UIDollarNumber;
