import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { ROIMeltwaterData } from 'interfaces/roi';
import { format } from 'date-fns';
import TwitterSquareImg from 'assets/images/icons/twitter-brid.svg';
import { formatNumber } from 'utils/formatters';

interface Props {
  data?: ROIMeltwaterData['analyticsData']['top_shared']['results'];
}

const NewsCard = ({ data }: Props) => {
  return (
    <Root>
      {data?.map(item => (
        <NewsRow key={item.external_id}>
          <NewsInfo>
            <NewsInfoTitle onClick={() => window.open(item.url)}>
              <NewsInfoLogo src={TwitterSquareImg} />
              {item.author.display_name}
            </NewsInfoTitle>
            <NewsInfoDesc>{item?.document_title}</NewsInfoDesc>
            <NewsInfoFooter>
              <NewsInfoDate>
                {format(new Date(item.created_at), 'd MMM y, h:ss')}
              </NewsInfoDate>
              <NewsActionGroup>
                <NewsAction>
                  {formatNumber(item.author.twitter.followers)} Followers
                </NewsAction>
              </NewsActionGroup>
            </NewsInfoFooter>
          </NewsInfo>
        </NewsRow>
      ))}
    </Root>
  );
};

const Root = styled.div`
  height: 300px;
  overflow: auto;
`;

const NewsRow = styled.div`
  height: 80px;
  margin-bottom: 24px;
  display: flex;
`;
const NewsInfo = styled.div`
  width: 480px;
  margin-left: 32px;
`;
const NewsInfoTitle = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  cursor: pointer;
`;
const NewsInfoLogo = styled(ReactSVG)`
  margin-right: 10px;
`;

const NewsInfoDesc = styled.div`
  color: var(--darkGray);
  font-size: 12px;
  line-height: 150%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 14px;
  margin-left: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const NewsInfoFooter = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 150%;
  margin-top: 14px;
  padding-bottom: 4px;
  border-bottom: 1px solid #e8e8e8;
  margin-left: 30px;
  margin-left: 30px;
`;
const NewsInfoDate = styled.div`
  color: var(--darkGray);
`;
const NewsActionGroup = styled.div`
  color: var(--blue);
  display: flex;
  margin-left: auto;
`;
const NewsAction = styled.div`
  margin-left: 10px;
`;

export default NewsCard;
