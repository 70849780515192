import {
  MonthlyReportData,
  MonthlyReportListReq,
  MonthlyReportListRes,
} from 'interfaces/monthlyReport';
import { fetch } from 'services/ApiService';
import { paramsToQueryString } from 'utils';

export const fetchMonthlyReportList = (param: MonthlyReportListReq) =>
  fetch<MonthlyReportListRes>('get', `monthlyReport${paramsToQueryString(param)}`);

export const fetchMonthlyReportGenerateAll = () =>
  fetch<{ status: string }>('patch', 'monthlyReport/generateAllReports', {
    payload: { reportDate: '2021-01-01T00:00:00.001Z' },
  });

export const fetchMonthlyReportDetail = (id: string) =>
  fetch<MonthlyReportData>('get', `monthlyReport/${id}`);

export const fetchMonthlyReportExcel = (id: string) =>
  fetch<{ name: string; file: string }>('patch', `monthlyReport/${id}/generateexcel`);
