import {
  RecommendationCreateReq,
  RecommendationData,
  RecommendationsListReq,
} from 'interfaces/issueMapping';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import { fetchErrorHandler } from 'utils/errorHandlers';
import * as actions from './actions';
import * as api from './api';

export const fetchRecommendationsList = (param: RecommendationsListReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchRecommendationsList(param)
    .then(value => dispatch(actions.setRecommendationsList(value)))
    .catch(() => Toast.error('Error get recommendations list.'));
};

export const fetchRecommendation = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchRecommendation(id)
    .then(value => dispatch(actions.setRecommendations(value)))
    .catch(() => Toast.error('Error get recommendation.'));
};

export const fetchRecommendationCreate = (
  params: RecommendationCreateReq,
) => async () => {
  return api
    .fetchRecommendationCreate(params)
    .then(() => {
      Toast.success(`Recommendation create successfully.`);
    })
    .catch(() => {
      Toast.error(`Error create Recommendation.`);
    });
};

export const fetchRecommendationUpdate = (params: RecommendationData) => async () => {
  return api
    .fetchRecommendationUpdate(params)
    .then(() => {
      Toast.success(
        `Recommendation ${
          params.status !== 'Published' ? 'Saved' : 'Publish'
        } successfully.`,
      );
    })
    .catch(err => {
      Toast.error(
        `Error ${params.status !== 'Published' ? 'Save' : 'Publish'} Recommendation.`,
      );
      throw err;
    });
};

export const fetchRecommendationDelete = (deleteIds: string) => async () => {
  return api
    .fetchRecommendationDelete(deleteIds)
    .then(() => {
      Toast.success('Successfully delete.');
    })
    .catch(() => {
      Toast.error('Delete failed');
    });
};

export const fetchRecommendationPublished = (companyId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchRecommendationPublished(companyId)
    .then(data => {
      dispatch(actions.setRecommendationPublished(data));
    })
    .catch(fetchErrorHandler);
};

export const fetchRecommendationTeams = (params: { id: string; q: string }) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchRecommendationTeams(params)
    .then(data => {
      dispatch(actions.setRecommendationSuggestTeams(data));
    })
    .catch(() => {
      Toast.error('Error get teams.');
    });
};

export const clearRecommendationStore = () => async (dispatch: Dispatch) => {
  dispatch(actions.clearRecommendationStore());
};
