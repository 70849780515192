export const ROIListDropdownOptions = [
  'Enable Manage',
  'Disable Manage',
  'Enable Steward',
  'Disable Steward',
  'Enable Measure',
  'Disable Measure',
  'Delete',
] as const;

export enum ROIEditNavigationEnum {
  Manage = 'Manage',
  Steward = 'Steward',
  Measure = 'Measure',
}

export enum ROIEditMeasureNavigationEnum {
  Tracking = 'Tracking',
  Data_Intake = 'Data_Intake',
  Report = 'Report',
}

export const OperationsSelectOptions = [
  { label: 'All Operations', value: 'All Operations' },
  { label: 'Steward', value: 'Steward' },
  { label: 'Measure', value: 'Measure' },
  { label: 'Manage', value: 'Manage' },
] as const;

export const TimerRangeSelectOptions = [
  { label: 'All Time', value: 'All Time' },
  { label: 'Last Year', value: 'Last Year' },
  { label: 'Last 3 Months', value: 'Last 3 Months' },
  { label: 'Last Month', value: 'Last Month' },
  { label: 'Last Week', value: 'Last Week' },
  { label: 'Yesterday', value: 'Yesterday' },
  { label: 'Today', value: 'Today' },
  { label: 'This Week', value: 'This Week' },
  { label: 'This Month', value: 'This Month' },
  { label: 'In Three Months', value: 'In Three Months' },
] as const;

export enum PartnershipGoalsEnum {
  'Brand Awareness' = 1,
  'Mission Awareness' = 2,
  'Fundraise' = 3,
  'Brand Reputation' = 4,
  'Call-to-Action' = 5,
  'Employee Engagement' = 6,
  'Other Stakeholder Engagement' = 7,
  'Media Exposure' = 8,
}

export const PartnershipGoalsOptions = [
  { label: 'Brand Awareness', value: PartnershipGoalsEnum['Brand Awareness'] },
  { label: 'Mission Awareness', value: PartnershipGoalsEnum['Mission Awareness'] },
  { label: 'Fundraise', value: PartnershipGoalsEnum['Fundraise'] },
  { label: 'Brand Reputation', value: PartnershipGoalsEnum['Brand Reputation'] },
  { label: 'Call-to-Action', value: PartnershipGoalsEnum['Call-to-Action'] },
  { label: 'Employee Engagement', value: PartnershipGoalsEnum['Employee Engagement'] },
  {
    label: 'Other Stakeholder Engagement',
    value: PartnershipGoalsEnum['Other Stakeholder Engagement'],
  },
  { label: 'Media Exposure', value: PartnershipGoalsEnum['Media Exposure'] },
] as const;

export const StewardshipElements = [
  { label: 'Basic', value: 'Basic' },
  { label: 'Intermediate', value: 'Intermediate' },
  { label: 'Premium', value: 'Premium' },
  { label: 'Custom', value: 'Custom' },
] as const;

export const StewardshipItems = [
  { label: 'Annual Kick-Off', value: 'Annual Kick-Off' },
  {
    label: 'Monthly Partner Newsletter',
    value: 'Monthly Partner Newsletter',
  },
  {
    label: 'Partner Feature in Monthly Partner Newsletter',
    value: 'Partner Feature in Monthly Partner Newsletter',
  },
  { label: 'Monthly Partner Meeting', value: 'Monthly Partner Meeting' },
  {
    label: 'Quarterly Partner Meeting',
    value: 'Quarterly Partner Meeting',
  },
  { label: 'Partner Toolkit', value: 'Partner Toolkit' },
  { label: 'VIP Event', value: 'VIP Event' },
  {
    label: 'Speaking Engagement at VIP Event',
    value: 'Speaking Engagement at VIP Event',
  },
  {
    label: 'Partner Appreciation Event',
    value: 'Partner Appreciation Event',
  },
  {
    label: 'Annual Partner Satisfaction Survey',
    value: 'Annual Partner Satisfaction Survey',
  },
  { label: 'Annual Review Meeting', value: 'Annual Review Meeting' },
  {
    label: 'Additional Custom Stewardship Activation',
    value: 'Additional Custom Stewardship Activation',
  },
  {
    label: 'Growth Opportunities Request',
    value: 'Growth Opportunities Request',
  },
  {
    label: 'Basic Impact Reporting Push Notification',
    value: 'Basic Impact Reporting Push Notification',
  },
  {
    label: 'ROI Impact Reporting Push Notification',
    value: 'ROI Impact Reporting Push Notification',
  },
  {
    label: 'Personalized Executive Thank You',
    value: 'Personalized Executive Thank You',
  },
] as const;

export enum ORIinvolvedEnum {
  Noinvolvement = 'No involvement',
  Somewhatinvolved = 'Somewhat involved',
  Veryinvolved = 'Very involved',
}

export enum TrackingStatusEnum {
  PendingAdmin = 'PendingAdmin',
  Complete = 'Complete',
}

export enum TrackingDashboardDocumentStatusEnum {
  positive = 'Positive',
  neutral = 'Neutral',
  negative = 'Negative',
}
