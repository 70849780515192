/* eslint-disable react-hooks/exhaustive-deps */
import { ROIEditMeasureNavigationEnum, TrackingStatusEnum } from 'constants/roi';
import { useOperation } from 'hooks/common';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  fetchROIGenerateReport,
  fetchROIMeltwaterData,
  fetchROIMeltwaterRegenerate,
  fetchROIUpdate,
  fetchROIUpdateMeltwater,
} from 'store/ducks/roi/operations';
import { getROI, getROIMeltwaterData } from 'store/ducks/roi/selectors';
import styled from 'styled-components';
import queryString from 'query-string';
import { UITipIconText, UIActionText } from 'components/UI';
import InfoBlueSVG from 'assets/images/icons/info-blue.svg';
import { MeasureForm } from 'components/roi/forms';
import PartnerDataIntakeForm from 'components/roi/forms/PartnerDataIntakeForm';
import ROIReportSelect from 'components/roi/ROIReportSelect';

const Measure = () => {
  const [navigationType, setNavigationType] = useState<ROIEditMeasureNavigationEnum>(
    ROIEditMeasureNavigationEnum.Tracking,
  );

  const params = useParams<{ teamId: string; roiId: string }>();
  const { push } = useHistory();
  const search = queryString.parse(window.location.search);

  const [onFetchROIUpdate] = useOperation(fetchROIUpdate);
  const [onFetchROIUpdateMeltwater] = useOperation(fetchROIUpdateMeltwater);
  const [onFetchROIMeltwaterRegenerate] = useOperation(fetchROIMeltwaterRegenerate);
  const [onFetchROIMeltwaterData] = useOperation(fetchROIMeltwaterData);
  const [onFetchROIGenerateReport] = useOperation(fetchROIGenerateReport);

  const handleChangeNavigationType = (type: ROIEditMeasureNavigationEnum) => {
    setNavigationType(type);
    push(`${window.location.pathname}?secondaryType=${type}`);
  };

  const roi = useSelector(getROI);
  const meltwaterData = useSelector(getROIMeltwaterData);

  useEffect(() => {
    if (!search.secondaryType) return;

    setNavigationType(search.secondaryType as ROIEditMeasureNavigationEnum);
  }, [search]);

  useEffect(() => {
    onFetchROIMeltwaterData(params.roiId);
  }, []);

  if (!roi) return null;

  return (
    <Root>
      <NavigationBar>
        <NavigationItem
          active={navigationType === ROIEditMeasureNavigationEnum.Tracking}
          isRedDot={roi.trackingStatus === TrackingStatusEnum.PendingAdmin}
          onClick={() =>
            handleChangeNavigationType(ROIEditMeasureNavigationEnum.Tracking)
          }
        >
          Tracking
        </NavigationItem>
        <NavigationItem
          active={navigationType === ROIEditMeasureNavigationEnum.Data_Intake}
          onClick={() =>
            handleChangeNavigationType(ROIEditMeasureNavigationEnum.Data_Intake)
          }
        >
          Data intake
        </NavigationItem>
        <NavigationItem
          active={navigationType === ROIEditMeasureNavigationEnum.Report}
          onClick={() => handleChangeNavigationType(ROIEditMeasureNavigationEnum.Report)}
        >
          Report
        </NavigationItem>
      </NavigationBar>
      {roi && (
        <Panel>
          <TrackingStatus>
            {(search.secondaryType === ROIEditMeasureNavigationEnum.Tracking ||
              !search.secondaryType) &&
              roi.trackingStatus === TrackingStatusEnum.Complete &&
              (meltwaterData ? (
                <ActionText onClick={() => push(`/roi/tracking-dashboard/${roi.id}`)}>
                  View Tracking
                </ActionText>
              ) : (
                <TipText icon={InfoBlueSVG}>Tracking request is sent</TipText>
              ))}
          </TrackingStatus>

          {navigationType === ROIEditMeasureNavigationEnum.Tracking && (
            <MeasureForm
              roi={roi}
              meltwaterData={meltwaterData || undefined}
              onTrack={values =>
                onFetchROIUpdate(
                  {
                    ...values,
                    trackingStatus: TrackingStatusEnum.Complete,
                  },
                  true,
                ).then(
                  () =>
                    new Promise((res, rej) => {
                      onFetchROIUpdateMeltwater({
                        roiId: values.id,
                        generatedQuery: values.generatedQuery,
                        searchId: values.searchId,
                      })
                        .then(() => {
                          res(undefined);
                          if (!meltwaterData) {
                            return;
                          }
                          onFetchROIMeltwaterRegenerate(values.id);
                        })
                        .catch(() => rej());
                    }),
                )
              }
              onRevert={() =>
                onFetchROIUpdate(
                  { ...roi, trackingStatus: null },
                  false,
                  'Revert successful',
                )
              }
            />
          )}
          {navigationType === ROIEditMeasureNavigationEnum.Data_Intake && (
            <PartnerDataIntakeForm
              roi={roi}
              onSubmit={values => {
                onFetchROIUpdate(values).then(() => {
                  onFetchROIGenerateReport(params.roiId);
                });
              }}
            />
          )}
          {navigationType === ROIEditMeasureNavigationEnum.Report && (
            <ROIReportSelect defaultReportUrl={`/roi/slim-report/${params.roiId}`} />
          )}
        </Panel>
      )}
    </Root>
  );
};

const Root = styled.div`
  background-color: #fff;
  border-radius: 6px;
  margin-top: 24px;
`;

const NavigationBar = styled.div`
  width: 100%;
  height: 70px;
  padding: 0 24px 0;
  display: flex;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;

  & > div:not(:first-child) {
    margin-left: 24px;
  }
`;

const NavigationItem = styled.div<{ active?: boolean; isRedDot?: boolean }>`
  display: flex;
  align-items: center;
  border-bottom: 3px solid transparent;
  transition: border-color 0.5s ease, color 0.5s ease;
  user-select: none;
  cursor: pointer;
  position: relative;

  ${({ active }) =>
    active &&
    `
    color: var(--blue);
    border-color: var(--blue);
  `}

  ${({ isRedDot }) =>
    isRedDot &&
    `
   &::after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    right: -8px;
    top: 18px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: #e92d30;
  }
  `}

  &:hover {
    color: var(--blue);
    border-color: var(--blue);
  }
`;

const Panel = styled.div`
  padding: 0 24px 0;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 24px;
  position: relative;
`;

const ActionText = styled(UIActionText)`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const TipText = styled(UITipIconText)`
  margin-left: auto;
`;

const TrackingStatus = styled.div`
  position: absolute;
  top: 0;
  right: 24px;
`;

export default Measure;
