import React, { useState } from 'react';
import styled from 'styled-components';
import { Competitor } from 'interfaces/companies';

import { companiesTabsOptions } from 'constants/company';
import { UIActionText } from 'components/UI';

export interface CompaniesTabsProps {
  competitors?: Competitor[];
  parentName?: string | null;
}

const CompaniesTabs = ({ competitors, parentName }: CompaniesTabsProps) => {
  const [isHide, setIsHide] = useState(true);
  const [companiesSelectTab, setCompaniesTab] = useState<string>('similar');

  return (
    <Root>
      <CompaniesTabsWrapper>
        <CompaniesTabsHeader>
          {companiesTabsOptions.map((item, index) => {
            const active = item.value === companiesSelectTab;
            return (
              <CompaniesTabsHeaderItem
                key={index}
                onClick={() => setCompaniesTab(item.value)}
                active={active}
              >
                {item.label}
              </CompaniesTabsHeaderItem>
            );
          })}
        </CompaniesTabsHeader>

        <ListWrapper>
          {companiesSelectTab === 'similar' && (
            <List>
              {competitors
                ?.slice(0, isHide ? 20 : competitors.length)
                .map(({ id, name }: Competitor) => {
                  return <Item key={id}>{name}</Item>;
                })}
            </List>
          )}
          {companiesSelectTab === 'parent' && (
            <List>
              <Item>{parentName || 'No information'}</Item>
            </List>
          )}
          {companiesSelectTab === 'similar' && (
            <ActionText onClick={() => setIsHide(!isHide)}>
              {isHide ? 'View More' : 'View Less'}
            </ActionText>
          )}
        </ListWrapper>
      </CompaniesTabsWrapper>
    </Root>
  );
};

const Root = styled.div`
  margin-bottom: 32px;
`;

const ListWrapper = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 24px;
  margin-bottom: 32px;
`;

export const List = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  & > p {
    width: 50%;
  }
`;

export const Item = styled.p`
  font-size: 16px;
  line-height: 155%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  &:before {
    content: '';
    width: 6px;
    height: 6px;
    background: var(--blue);
    border-radius: 100%;
    margin-right: 8px;
    min-width: 6px;
  }
`;

const CompaniesTabsWrapper = styled.div``;

const CompaniesTabsHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const CompaniesTabsHeaderItem = styled.div<{ active?: boolean }>`
  font-size: 16px;
  line-height: 145%;
  cursor: pointer;
  transition: all 0.2s ease;
  ${({ active }) => (active ? `color: var(--blue);` : 'color: var(--darkGray);')}
  &:not(:last-child) {
    margin-right: 32px;
  }
  &:hover {
    color: var(--blue);
    transition: all 0.2s ease;
  }
`;

const ActionText = styled(UIActionText)`
  margin-top: 10px;
`;

export default CompaniesTabs;
