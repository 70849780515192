/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useOperation } from 'hooks/common';
import {
  fetchTeam,
  fetchTeamUpdate,
  fetchSubscriptionTeams,
  fetchSubscriptionUpdate,
  fetchSubscriptionDestroy,
  fetchFileUpload,
  fetchTeamNews,
  fetchTeamContacts,
} from 'store/ducks/team/operations';
import {
  getTeamById,
  getSubscription,
  getTeamNews,
  getTeamContacts,
} from 'store/ducks/team/selectors';
import { StateProps } from 'store/interfaces';
import { TeamData, SubscriptionProps, TeamContact } from 'interfaces/team';
import Jumbotron from 'components/Jumbotron';
import ProfileView from 'components/ProfileView';
import { TeamWrapper } from 'layouts';
import { News } from 'interfaces/companies';
import {
  fetchNewsCreate,
  fetchNewsDelete,
  fetchNewsUpdate,
} from 'store/ducks/news/operations';

const Team = () => {
  const [onfetchTeam, isLoading] = useOperation(fetchTeam);
  const [onUpdateTeam] = useOperation(fetchTeamUpdate);
  const [onFetchSubscriptionTeams] = useOperation(fetchSubscriptionTeams);
  const [onSubscriptionUpdate] = useOperation(fetchSubscriptionUpdate);
  const [onSubscriptionDestroy] = useOperation(fetchSubscriptionDestroy);
  const [onFetchTeamNews] = useOperation(fetchTeamNews);
  const [onFetchNewsCreate] = useOperation(fetchNewsCreate);
  const [onFetchNewsUpdate] = useOperation(fetchNewsUpdate);
  const [onFetchNewsDelete] = useOperation(fetchNewsDelete);
  const [onFetchTeamContacts] = useOperation(fetchTeamContacts);

  const [onFileUpload] = useOperation(fetchFileUpload);
  const params: { teamId: string } = useParams();

  const team = useSelector<StateProps, TeamData | undefined>(getTeamById(params.teamId));
  const subscription = useSelector(getSubscription);
  const news = useSelector<StateProps, News[]>(getTeamNews);
  const contacts = useSelector<StateProps, TeamContact[]>(getTeamContacts);

  useEffect(() => {
    onfetchTeam(params.teamId);
    onFetchSubscriptionTeams(params.teamId);
    onFetchTeamNews(params.teamId);
    onFetchTeamContacts(params.teamId);
  }, [params.teamId]);

  const handleUpdateTeam = useCallback((values: TeamData) => {
    return onUpdateTeam({
      id: params.teamId,
      data: values,
    });
  }, []);

  const handleUpdateSubscriptionTeam = useCallback((values: SubscriptionProps) => {
    return onSubscriptionUpdate({
      id: params.teamId,
      data: values,
    });
  }, []);

  const onUploadDocument = useCallback(async (file: File) => {
    return onFileUpload({ file, id: params.teamId });
  }, []);

  return (
    <TeamWrapper
      teamId={params.teamId}
      isLoading={isLoading}
      header={<Jumbotron name={team?.organizationName || ''} back />}
    >
      {team && (
        <ProfileView
          team={team}
          news={news}
          contacts={contacts}
          handleUpdateSubscription={handleUpdateSubscriptionTeam}
          handleRemoveSubscription={onSubscriptionDestroy}
          handleUpdateTeam={handleUpdateTeam}
          subscription={subscription}
          handleUploadDocument={onUploadDocument}
          onNewsCreate={async param => {
            await onFetchNewsCreate(param);
            onFetchTeamNews(params.teamId);
          }}
          onNewsUpdate={async param => {
            await onFetchNewsUpdate(param);
            onFetchTeamNews(params.teamId);
          }}
          onNewsDelete={async id => {
            await onFetchNewsDelete(id);
            onFetchTeamNews(params.teamId);
          }}
        />
      )}
    </TeamWrapper>
  );
};

export default Team;
