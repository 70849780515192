import { ROIMeltwaterData } from 'interfaces/roi';
import React from 'react';
import { BarChart, Bar, XAxis, Tooltip, Legend, YAxis } from 'recharts';

interface Props {
  data?: ROIMeltwaterData['analyticsData']['top_sources']['sources'];
}

const TopAuthorsChartC = ({ data }: Props) => {
  if (!data) return null;

  return (
    <BarChart
      width={568}
      height={282}
      data={data}
      layout="vertical"
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      barCategoryGap={10}
    >
      <XAxis type="number" hide />
      <YAxis type="category" dataKey="author.display_name" hide />
      <Tooltip />
      <Legend formatter={() => 'followers'} />
      <Bar
        isAnimationActive={false}
        dataKey="author.twitter.followers"
        fill="#28759C"
        radius={6}
        maxBarSize={32}
        label={({ y, index }) => {
          return (
            <text x={20} y={y} fill="#666" textAnchor="start" dy={-4}>
              {data[index].author.display_name}
            </text>
          );
        }}
      />
    </BarChart>
  );
};

export default TopAuthorsChartC;
