import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FormError, UICheckbox } from 'components/UI';
import styled from 'styled-components';
import { CheckBoxItem } from 'components/UI/interfaces';

interface FieldCheckboxGroupProps extends FieldRenderProps<[]> {
  options: CheckBoxItem[];
  hideNumber?: boolean;
}

const FieldCheckBoxGroup = ({
  input,
  meta,
  options,
  hideNumber,
  ...props
}: FieldCheckboxGroupProps) => {
  const { value, onChange, onBlur } = input;
  const { error, submitError, touched, dirtySinceLastSubmit } = meta;
  const isError = (error && touched) || (submitError && !dirtySinceLastSubmit);

  const onSetValue = useCallback(
    val => {
      const isValue = value && !!value.find((item: CheckBoxItem) => item.id === val.id);

      onBlur();
      if (isValue)
        return onChange(value.filter((item: CheckBoxItem) => item.id !== val.id));

      return onChange(value.length > 0 ? value.concat(val) : [val]);
    },
    [value, onBlur, onChange],
  );

  return (
    <Field>
      <CheckboxList>
        {options.map((val: CheckBoxItem, index) => {
          const isCheck = value && !!value.find((i: CheckBoxItem) => i.id === val.id);

          return (
            <CheckboxItem
              {...props}
              key={val.id}
              check={isCheck}
              label={hideNumber ? val.title : `${index + 1}. ${val.title}`}
              handleClick={() => onSetValue(val)}
            />
          );
        })}
      </CheckboxList>
      {isError && <FormError meta={meta} />}
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const CheckboxList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CheckboxItem = styled(UICheckbox)`
  margin-bottom: 16px;
  width: 50%;
`;

export default FieldCheckBoxGroup;
