import { SubscriptionPlan } from 'interfaces/team';
import { transformEnumForOptions } from 'utils';

export const subscriptionPlanOptions = transformEnumForOptions(SubscriptionPlan);

export const headTableUsers = [
  'Full Name',
  'Email',
  'Linkedin',
  'Role',
  'Last Login',
  'Canva License',
];

export enum GuidestarTransparencyRankingEnum {
  None = 'None',
  Bronze = 'Bronze',
  Silver = 'Silver',
  Gold = 'Gold',
  Platinum = 'Platinum',
}

export const GuidestarTransparencyRankingOptions = Object.keys(
  GuidestarTransparencyRankingEnum,
).map(item => ({
  label: `${item.charAt(0).toUpperCase()}${item.slice(1)}`,
  value: GuidestarTransparencyRankingEnum[item],
}));

export const headTableContacts = [
  'Full Name',
  'Departament',
  'Phone',
  'LinkedIn',
  'Email',
  'Accuracy Score',
];

export const paypalTypeOptions = [
  { label: 'PayPal Commerce', value: '1' },
  { label: 'PayPal Giving Fund', value: '0' },
];

export const constituentsOptions = [
  { label: '< 10,000', value: '< 10,000' },
  { label: '10,001 – 50,000', value: '10,001 – 50,000' },
  { label: '50,001 – 250,000', value: '50,001 – 250,000' },
  { label: '250,001 – 1,000,000', value: '250,001 – 1,000,000' },
  { label: '1,000,000+', value: '1,000,000+' },
];
