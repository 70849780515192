import { ROIMeltwaterData } from 'interfaces/roi';
import React from 'react';
import { Cell, Pie, PieChart, Tooltip, Legend } from 'recharts';

interface Props {
  data?: ROIMeltwaterData['analyticsData']['analytics']['sentiment'];
}

const COLORS = ['#4AD78B', '#E92D30', '#1e59da', '#CCCACA'];

const PieChartC = ({ data }: Props) => {
  const d = [
    { name: 'positive', value: data?.positive.percent },
    { name: 'negative', value: data?.negative.percent },
    { name: 'neutral', value: data?.neutral.percent },
    { name: 'unknow', value: data?.unknown.percent },
  ];
  return (
    <PieChart width={568} height={282}>
      <Pie dataKey="value" data={d} cx={284} cy={141} innerRadius={40} outerRadius={80}>
        {d.map((entry, index) => {
          return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />;
        })}
      </Pie>
      <Legend iconType="circle" />
      <Tooltip formatter={value => `${Math.floor(value * 100)}%`} />
    </PieChart>
  );
};

export default PieChartC;
