import React from 'react';
import NumberFormat from 'react-number-format';

interface Props {
  value: number | string;
}

const UIPercentageNumber = ({ value }: Props) => (
  <NumberFormat
    displayType="text"
    suffix="%"
    value={value}
    decimalScale={2}
    thousandSeparator=","
    thousandsGroupStyle="thousand"
  />
);

export default UIPercentageNumber;
