import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { WrapperProps } from 'interfaces/common';
import { UISpinner } from 'components/UI';

interface Props {
  isLoading?: boolean;
  header?: ReactNode;
  contentWithoutLoading?: ReactNode;
  withOutNav?: boolean;
}

function MainWrapper({
  isLoading,
  children,
  contentWithoutLoading,
  header,
  withOutNav = false,
}: Props & WrapperProps) {
  return (
    <Body>
      {header && <Header>{header}</Header>}
      <Inner>
        {/* {!withOutNav && (
          <NavList>
            <LinkElem to="/teams">Organizations</LinkElem>
            <LinkElem to="/users">Users</LinkElem>
            <LinkElem to="/companies">Companies</LinkElem>
          </NavList>
        )} */}

        <Content>
          {contentWithoutLoading}
          {isLoading ? <Spinner /> : children}
        </Content>
      </Inner>
    </Body>
  );
}

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 242px);
`;

const Body = styled.div`
  background: #f9f9f9;
  min-height: calc(100% - 80px);
`;

const Header = styled.div`
  width: 100%;
  background: #fff;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 1320px;
  padding: 0 20px;
  margin: 0 auto;
  padding-top: 32px;
  height: 100%;
  display: flex;
  align-items: flex-start;
`;

const Content = styled.div`
  width: 100%;
  max-width: 100%;
`;

export default MainWrapper;
