import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { UISuggestPlaces } from 'components/UI';
import styled from 'styled-components';
import { FormError } from 'components/UI';
import { FormLabel } from 'components/UI/form';

const FieldSuggestPlaces = ({ input, label, meta }: FieldRenderProps<string>) => {
  const { error, submitError, dirtySinceLastSubmit } = meta;
  const isError = error || (submitError && !dirtySinceLastSubmit);

  const handleChange = useCallback(
    value => {
      input.onChange(value);
    },
    [input],
  );

  return (
    <Field>
      {label && <FormLabel>{label}</FormLabel>}

      <UISuggestPlaces
        handleSelect={handleChange}
        value={input.value}
        error={!!isError}
      />

      {isError && <Error meta={meta} />}
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const Error = styled(FormError)`
  position: absolute;
`;

export default FieldSuggestPlaces;
