import { IssueMappingStoreData } from 'interfaces/issueMapping';
import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as types from './types';

type RootAction = ActionType<typeof actions>;

export default createReducer<IssueMappingStoreData, RootAction>({
  recommendationsList: {
    items: [],
    meta: {
      currentPage: '0',
      itemCount: 0,
      itemsPerPage: '0',
      totalItems: 0,
      totalPages: 0,
    },
  },
  recommendation: undefined,
  recommendationPublished: undefined,
  recommendationTeams: [],
})
  .handleType([types.SET_RECOMMENDATIONS_LIST], (state, action) => ({
    ...state,
    recommendationsList: action.payload,
  }))
  .handleType([types.SET_RECOMMENDATION], (state, action) => ({
    ...state,
    recommendation: action.payload,
  }))
  .handleType([types.SET_RECOMMENDATION_SUGGEST_TEAMS], (state, action) => ({
    ...state,
    recommendationTeams: action.payload,
  }))
  .handleType([types.CLEAR_RECOMMENDATION_STORE], (state, action) => ({
    ...state,
    recommendation: undefined,
    recommendationTeams: [],
  }))
  .handleType([types.SET_RECOMMENDATION_PUBLISHED], (state, action) => ({
    ...state,
    recommendationPublished: action.payload,
  }));
