/* eslint-disable react-hooks/exhaustive-deps */
import { ExportButton } from 'components/ActionButtons';
import Jumbotron from 'components/Jumbotron';
import Pagination from 'components/Pagination';
import { SingleSelect } from 'components/UI';
import { MonthlyReportFilterOptions } from 'constants/monthlyReport';
import { useOperation } from 'hooks/common';
import { MonthlyReportListRes } from 'interfaces/monthlyReport';
import { MainWrapper } from 'layouts';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchMonthlyReportList } from 'store/ducks/monthlyReport/operations';
import { getMonthlyReportList } from 'store/ducks/monthlyReport/selectors';
import { StateProps } from 'store/interfaces';
import styled from 'styled-components';
import TotalRow from 'components/TotalRow';
import { FormLabel } from 'components/UI/form';
import { useHistory } from 'react-router';
import useDownloadFile from 'hooks/common/useDownloadFile';
import { fetchMonthlyReportExcel } from 'store/ducks/monthlyReport/api';

const MonthlyReportList = () => {
  const [filterValue, setFilterValue] = useState<string | undefined>(
    MonthlyReportFilterOptions[0].value,
  );

  const { push } = useHistory();

  const [onFetchMonthlyReportList, , isLoading] = useOperation(fetchMonthlyReportList);

  const { meta, items } = useSelector<StateProps, MonthlyReportListRes>(
    getMonthlyReportList,
  );
  const { currentPage, totalItems, itemsPerPage } = meta;

  const exportExcel = useDownloadFile(fetchMonthlyReportExcel);

  useEffect(() => {
    onFetchMonthlyReportList({ year: filterValue || '', page: 1 });
  }, []);

  return (
    <MainWrapper isLoading={isLoading} header={<Jumbotron name="Monthly Report" />}>
      <Body>
        <TotalRow title={`${meta.totalItems} Item${meta.totalItems > 1 ? 's' : ''}`} />
        <Header>
          <div>
            <FilterLabel>Filter</FilterLabel>
            <FilterSelector
              options={MonthlyReportFilterOptions}
              placeholder="Select"
              handleSelect={v => {
                setFilterValue(v?.value);
                onFetchMonthlyReportList({ year: v?.value || '', page: 1 });
              }}
              value={filterValue}
              isClearable={false}
            />
          </div>
          {totalItems > 0 && (
            <Pagination
              total={totalItems}
              pageLimit={Number(itemsPerPage)}
              page={Number(currentPage)}
              submitHandler={(page: number) => {
                onFetchMonthlyReportList({ year: filterValue || '', page });
              }}
            />
          )}
        </Header>
        {items.map(item => (
          <ListItem key={item.id}>
            <ListItemTitle onClick={() => push(`/monthly-report/detail/${item.id}`)}>
              {item.name}
            </ListItemTitle>

            <Actions>
              <ExportButton
                name="Export to Excel"
                uploadHandler={e => {
                  e.stopPropagation();
                  exportExcel(item.id);
                }}
              />
            </Actions>
          </ListItem>
        ))}
      </Body>
    </MainWrapper>
  );
};

const Body = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const FilterSelector = styled(SingleSelect)`
  width: 180px;
`;

const ListItem = styled.div`
  background: #fff;
  border-radius: 6px;
  height: 87px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
`;

const ListItemTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  cursor: pointer;
  transition: color 0.15s ease-out;

  &:hover {
    color: var(--blue);
  }
`;

const Actions = styled.div`
  display: flex;
`;

const FilterLabel = styled(FormLabel)`
  margin-bottom: 12px;
`;

export default MonthlyReportList;
