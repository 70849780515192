import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { UIRadioBtn } from 'components/UI';
import styled from 'styled-components';

type FieldRadioButtonsProps = FieldRenderProps<boolean>;

const FieldRadioButtons = ({ input }: FieldRadioButtonsProps) => {
  const { value, onChange } = input;

  return (
    <Field>
      <CheckboxList>
        <UIRadioBtn isChecked={value} label="Yes" handleCheck={() => onChange(true)} />

        <UIRadioBtn isChecked={!value} label="No" handleCheck={() => onChange(false)} />
      </CheckboxList>
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const CheckboxList = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 174px;
`;

export default FieldRadioButtons;
