/* eslint-disable react-hooks/exhaustive-deps */
import { ExportButton } from 'components/ActionButtons';
import Jumbotron from 'components/Jumbotron';
import { useFileBase64Url, useModal, useOperation } from 'hooks/common';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { ReactSVG } from 'react-svg';
import {
  fetchROI,
  fetchROIMeltwaterData,
  fetchROIReportSavePDF,
} from 'store/ducks/roi/operations';
import { getROI } from 'store/ducks/roi/selectors';
import styled from 'styled-components';
import ValuationSVG from 'assets/images/valuationReport/valuation-slim.svg';
import DownloadSVG from 'assets/images/icons/download.svg';
import { moneyNumberFormat, numberFormat } from 'utils/formatters';
import { UIButton } from 'components/UI';
import html2pdf from 'html2pdf.js/dist/html2pdf';
import { fetchSignedUrl, uploadFile } from 'store/ducks/utility/api';
import Toast from 'services/Toast';
import Dropdown from 'components/Dropdown/Dropdown';
import { PieChart, Pie, Cell } from 'recharts';
import DollarSVG from 'assets/images/valuationReport/dollar.svg';
import ChartSVG from 'assets/images/valuationReport/chart.svg';
import CorporateIntangibleAssetTable from 'components/roi/tables/CorporateIntangibleAssetTable';
import { goalAlignmentOptions } from 'constants/goalAlignment';
import { ShareReportModal } from 'components/roi/modals';
import { UIPanel } from 'components/UI';

const ROIReport = () => {
  const params = useParams<{ roiId: string }>();
  const { go } = useHistory();

  const [logoKey, setLogoKey] = useState('');
  const [isExportLoading, setIsExportLoading] = useState(false);

  const { fileBase64Url: logoBase64Url } = useFileBase64Url({ imgKey: logoKey });

  const [onFetchROI, isROIExecuting] = useOperation(fetchROI);
  const [onFetchROIReportSavePDF] = useOperation(fetchROIReportSavePDF);
  const [onFetchROIMeltwaterData] = useOperation(fetchROIMeltwaterData);

  const roi = useSelector(getROI);

  // const exportExcel = useDownloadFile(fetchGenerateexcel);

  const pdfFileUrl = useMemo(() => {
    if (!roi?.file) {
      return;
    }
    return fetchSignedUrl(roi.file).then(value => value.result);
  }, [roi?.file]);

  const exportAndUploadPdf = useCallback(() => {
    if (pdfFileUrl) {
      pdfFileUrl.then(url => window.open(url));
      return;
    }
    setIsExportLoading(true);
    const element = document.getElementById('generate-pdf-content');
    const opt = {
      margin: [0, -8, 0, 0],
      filename: `${roi?.name} Report.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      pagebreak: {
        mode: ['avoid-all'],
      },
      html2canvas: {
        dpi: 192,
        scale: 2,
        onrendered: function() {},
        letterRendering: true,
        useCORS: true,
      },
      jsPDF: {
        unit: 'pt',
        format: [963, Number(element?.clientHeight) * 0.79],
        orientation: 'p',
      },
    };
    if (element) {
      const worker = html2pdf();
      const xx = worker
        .set(opt)
        .from(element)
        .output('blob');
      xx.then((x: Blob) => {
        const file = new File([x], `${roi?.name} Report.pdf`);
        uploadFile(file, 'roi-report').then(v => {
          if (v.status === 'success') {
            onFetchROIReportSavePDF(v.fileKey).then(() => {
              setIsExportLoading(false);
              worker
                .set(opt)
                .from(element)
                .save();
            });
            return;
          }
          setIsExportLoading(false);
          Toast.error('Error export PDF');
        });
      });
    }
  }, [params, roi]);

  const [showShareReportModal, hideShareReportModal] = useModal(
    () => (
      <ShareReportModal
        onClose={hideShareReportModal}
        generateUrl={() =>
          `${window.location.host}?redirectTo=/roi/slim-report/${params.roiId}`
        }
      />
    ),
    [],
  );

  useEffect(() => {
    onFetchROI(params.roiId);
    onFetchROIMeltwaterData(params.roiId);
  }, []);

  useEffect(() => {
    if (!roi) return;
    setLogoKey(roi.customizeReport?.logoKey || '');
  }, [roi]);

  return (
    <ContentBlockWrapper
      loading={isROIExecuting}
      header={
        <Jumbotron
          back
          name={
            <JumbotronTitleWrapper>
              <JumbotronBlock>
                <LogoImg src={logoBase64Url} />
              </JumbotronBlock>
              {roi?.name} Report
            </JumbotronTitleWrapper>
          }
        >
          <ExportWrapper>
            <ShareButton name="Share" uploadHandler={showShareReportModal} />
            <ReactSVG src={DownloadSVG} />
            <Dropdown title="Export to PDF" style={{ marginLeft: '8px' }}>
              <DropdownBody>
                <ExportButton
                  name="Export to PDF"
                  isloading={isExportLoading}
                  uploadHandler={exportAndUploadPdf}
                />
                {/* <ExportButton
                  name="Export to Excel"
                  uploadHandler={() => exportExcel(roi?.id || '')}
                /> */}
              </DropdownBody>
            </Dropdown>
          </ExportWrapper>
        </Jumbotron>
      }
    >
      <Body>
        <ExportPDFWrapper id="generate-pdf-content">
          <InnerJumbotron
            id="pdf-content-header"
            name={
              <JumbotronTitleWrapper>
                <JumbotronBlock>
                  <LogoImg src={logoBase64Url} />
                </JumbotronBlock>
                {roi?.name} Report
              </JumbotronTitleWrapper>
            }
          />
          <UIPanel
            style={{
              background:
                'linear-gradient(90deg, #2BAEE0 -0.8%, #1BA4D9 -0.79%, #107FA2 66.4%)',
            }}
          >
            <PanelLogoLayout>
              <InfoImage src={ValuationSVG} />
              <InfoDetail style={{ color: 'white' }}>
                <PanelPrimaryNumber>
                  {moneyNumberFormat(roi?.calculation?.totalGiving)}
                </PanelPrimaryNumber>
                <ShortBar />
                <PanelSencondTitle>Total Giving</PanelSencondTitle>
                <PanelDesc>
                  The value for Total Giving includes all of the social spend reported by
                  your partner. The categories of social spend incorporate consumer cash,
                  employee cash, overall cash and non-cash.
                </PanelDesc>
              </InfoDetail>
            </PanelLogoLayout>
          </UIPanel>

          <UIPanel
            style={{ background: 'white', border: '1px solid #E8E8E8' }}
            title="Good Beyond Giving"
          >
            <DoughnutRowWrapper>
              <DoughnutCard>
                <DoughnutChartWrapper>
                  <DoughnutChart value={roi?.givingParticipationRate || 0} />
                </DoughnutChartWrapper>
                <DoughnutText>Giving Participation Rate</DoughnutText>
              </DoughnutCard>
              <DoughnutCard $theme="dark">
                <DoughnutChartWrapper>
                  <DoughnutChart
                    value={roi?.volunteeringParticipationRate || 0}
                    theme="dark"
                  />
                </DoughnutChartWrapper>
                <DoughnutText>Volunteering Participation Rate</DoughnutText>
              </DoughnutCard>
            </DoughnutRowWrapper>
          </UIPanel>

          <UIPanel
            style={{
              background: 'linear-gradient(90deg, #F08D1B -0.79%, #BA6A31 100%)',
            }}
          >
            <InfoDetail style={{ color: 'white' }}>
              <PanelPrimaryNumber>
                {moneyNumberFormat(roi?.calculation?.bottomLineValue)}
              </PanelPrimaryNumber>
              <ShortBar />
              <PanelSencondTitle>Bottom Line Value</PanelSencondTitle>
              <PanelDesc>
                The bottom line value of the partnership considers tangible assets
                including media impressions, sales driven, mitigated risk, consumer value,
                and turnover cost savings.
              </PanelDesc>
              <BottomLineValueWrapper>
                <div>
                  <ReactSVG src={DollarSVG} />
                  <BottomLineValue>
                    {moneyNumberFormat(roi?.calculation?.totalMediaValue)}
                  </BottomLineValue>
                  <BottomLineDesc>Total Media Vaue</BottomLineDesc>
                </div>
                <div>
                  <ReactSVG src={ChartSVG} />
                  <BottomLineValue>
                    {numberFormat(roi?.calculation?.totalMediaImpression)}
                  </BottomLineValue>
                  <BottomLineDesc>Total Media Impressions</BottomLineDesc>
                </div>
                <div>
                  <BottomLineValue>{moneyNumberFormat(roi?.salesDriven)}</BottomLineValue>
                  <BottomLineDesc>Sales Driven by Partnership ($)</BottomLineDesc>
                </div>
                <div>
                  <BottomLineValue>
                    {moneyNumberFormat(roi?.savedInMitigatedRisks)}
                  </BottomLineValue>
                  <BottomLineDesc>Saved in Mitigated Risks ($)</BottomLineDesc>
                </div>

                <div>
                  <BottomLineValue>
                    {moneyNumberFormat(roi?.calculation?.potentialConsumerValue)}
                  </BottomLineValue>
                  <BottomLineDesc>Potential Consumer Value ($)</BottomLineDesc>
                </div>
                <div>
                  <BottomLineValue>
                    {moneyNumberFormat(roi?.calculation?.potentialTurnoverCostsSaved)}
                  </BottomLineValue>
                  <BottomLineDesc>Potential Turnover Cost Saved ($)</BottomLineDesc>
                </div>
              </BottomLineValueWrapper>
            </InfoDetail>
          </UIPanel>

          <UIPanel
            style={{ background: 'white', border: '1px solid #E8E8E8' }}
            title="Brand Reputation"
          >
            <CorporateIntangibleAssetsWrapper>
              <CorporateIntangibleAssetTable
                headCells={['Corporate Intangible Asset', 'Value']}
                rows={roi?.calculation?.corporateIntangibleAssetsItems || []}
              />
            </CorporateIntangibleAssetsWrapper>
          </UIPanel>
          <UIPanel
            style={{ background: 'white', border: '1px solid #E8E8E8' }}
            title="Mission Impact"
          >
            <MissionImpactValue>{roi?.missionImpact || 'N/A'}</MissionImpactValue>
            <DoughnutRowWrapper>
              <DoughnutCard $theme="dark">
                <DoughnutChartWrapper>
                  <DoughnutChart value={roi?.missionAdvancement || 0} theme="dark" />
                </DoughnutChartWrapper>
                <DoughnutText>Mission Advancement</DoughnutText>
              </DoughnutCard>
              <DoughnutCard>
                <DoughnutChartWrapper>
                  <DoughnutChart
                    value={(roi?.scopeOfImpact && roi?.scopeOfImpact.length * 20) || 0}
                  />
                </DoughnutChartWrapper>
                <DoughnutText>Scope of Impact </DoughnutText>
              </DoughnutCard>
            </DoughnutRowWrapper>
          </UIPanel>

          <UIPanel
            style={{ background: 'white', border: '1px solid #E8E8E8' }}
            title="Business Value"
          >
            <ImpactRow>
              <CorporateIntangibleAssetsStatusCard>
                {roi?.calculation?.constituentEngagement === 0 && (
                  <CorporateIntangibleAssetsStatusTitle style={{ color: '#F05658' }}>
                    Low
                  </CorporateIntangibleAssetsStatusTitle>
                )}
                {roi?.calculation?.constituentEngagement === 10 && (
                  <CorporateIntangibleAssetsStatusTitle style={{ color: '#E0A02A' }}>
                    Medium
                  </CorporateIntangibleAssetsStatusTitle>
                )}
                {roi?.calculation?.constituentEngagement === 20 && (
                  <CorporateIntangibleAssetsStatusTitle style={{ color: '#29AA26' }}>
                    High
                  </CorporateIntangibleAssetsStatusTitle>
                )}
                <CorporateIntangibleAssetsStatusDesc>
                  Constituent Engagement
                </CorporateIntangibleAssetsStatusDesc>
                <ColorBarGroup>
                  <ColorBarItem
                    $color="#F05658"
                    $active={roi?.calculation?.constituentEngagement === 0}
                  />
                  <ColorBarItem
                    $color="#E0A02A"
                    $active={roi?.calculation?.constituentEngagement === 10}
                  />
                  <ColorBarItem
                    $color="#29AA26"
                    $active={roi?.calculation?.constituentEngagement === 20}
                  />
                </ColorBarGroup>
                <ColorBarFieldTexts>
                  <div>Low</div>
                  <div>Medium</div>
                  <div>High</div>
                </ColorBarFieldTexts>
              </CorporateIntangibleAssetsStatusCard>
              <div style={{ width: '100%' }}>
                <ImpactSubTitle>Ambassadors</ImpactSubTitle>
                <ImpactSection>
                  {roi?.ambassadors?.didYouHaveAnyAmbassadors && (
                    <ImpactValue>ambassadors, executives, or influencers</ImpactValue>
                  )}
                  {roi?.partnerActivation?.accessToAgencies && (
                    <ImpactValue>agencies, vendors or other portfolio brands</ImpactValue>
                  )}
                  {!roi?.ambassadors?.didYouHaveAnyAmbassadors &&
                    !roi?.partnerActivation?.accessToAgencies && (
                      <ImpactValue>No Information</ImpactValue>
                    )}
                </ImpactSection>
                <ImpactSubTitle>Stewardship Activities</ImpactSubTitle>
                <ImpactSection>
                  {roi?.stewardship?.stewardshipitems &&
                  roi?.stewardship?.stewardshipitems.length > 0 ? (
                    roi?.stewardship?.stewardshipitems.map((item, index) => (
                      <ImpactValue key={index}>{item}</ImpactValue>
                    ))
                  ) : (
                    <ImpactValue>No Information</ImpactValue>
                  )}
                </ImpactSection>
              </div>
            </ImpactRow>
          </UIPanel>
          <BigCapsule>
            <BigCapsuleTitle>Intangible Asset Value</BigCapsuleTitle>
            <BigCapsuleItemValue>
              {moneyNumberFormat(
                roi?.isCompany
                  ? roi?.calculation?.corporateIntangibleAssetValue
                  : roi?.calculation?.nonProfitIntangibleAssetValue,
              )}
            </BigCapsuleItemValue>
          </BigCapsule>

          <UIPanel
            style={{ background: 'white', border: '1px solid #E8E8E8' }}
            title="SDG Goals"
          >
            <SDGValueCardWrapper>
              <SDGValueCard>
                <div>SDG Targets</div>
                <div>{roi?.sdgTargets || 'N/A'}</div>
              </SDGValueCard>
              <SDGValueCard>
                <div>SDG Indicators</div>
                <div>{roi?.sdgIndicators || 'N/A'}</div>
              </SDGValueCard>
            </SDGValueCardWrapper>
            {roi?.sdgGoals && roi.sdgGoals.length > 0 ? (
              <AlignmentList>
                {[...roi?.sdgGoals]
                  .map(item => goalAlignmentOptions[item])
                  .filter(item => item)
                  .sort((a, b) => a.id - b.id)
                  .map(item => (
                    <AlignmentItem key={item.id} src={item.img} alt={item.title} />
                  ))}
              </AlignmentList>
            ) : (
              <EmptyAlignment>No selected goal</EmptyAlignment>
            )}
          </UIPanel>

          <ChartCard>
            <ChartCardRow>
              <BigCapsule>
                <BigCapsuleTitle>Overall Partnership Value</BigCapsuleTitle>
                <BigCapsuleContent>
                  <BigCapsuleItem>
                    <BigCapsuleItemValue>
                      {moneyNumberFormat(
                        roi?.calculation?.intangiblePartnershipBenchmark
                          .overallPartnershipValuetoNonprofit,
                      )}
                    </BigCapsuleItemValue>
                    <BigCapsuleItemLabel>To Nonprofit </BigCapsuleItemLabel>
                  </BigCapsuleItem>
                  <BigCapsuleItem>
                    <BigCapsuleItemValue>
                      {moneyNumberFormat(
                        roi?.calculation?.intangiblePartnershipBenchmark
                          .overallPartnershipValuetoCompany,
                      )}
                    </BigCapsuleItemValue>
                    <BigCapsuleItemLabel>To Company </BigCapsuleItemLabel>
                  </BigCapsuleItem>
                </BigCapsuleContent>
                <BigCapsuleDesc>
                  {roi?.isCompany
                    ? 'Overall Partnership Value is an aggregate value that the nonprofit contributed to the partnership in the following ways: marketing and media support, and brand value.'
                    : 'Overall Partnership Value is an aggregate value that the company contributed to the partnership in the following ways: monetary or in-kind contributions, marketing and media support, and brand value.'}
                </BigCapsuleDesc>
              </BigCapsule>
            </ChartCardRow>
            <ChartCardRow>
              <BigCapsule type="dark">
                <BigCapsuleTitle>Return on Partnership</BigCapsuleTitle>
                <BigCapsuleContent>
                  <BigCapsuleItem>
                    <BigCapsuleItemValue>
                      {roi?.calculation?.intangiblePartnershipBenchmark
                        ? (
                            roi?.calculation?.intangiblePartnershipBenchmark
                              .returnOnPartnershiptoNonprofit * 100
                          )?.toFixed(0) + '%'
                        : 'N/A'}
                    </BigCapsuleItemValue>
                    <BigCapsuleItemLabel>To Nonprofit </BigCapsuleItemLabel>
                  </BigCapsuleItem>
                  <BigCapsuleItem>
                    <BigCapsuleItemValue>
                      {roi?.calculation?.intangiblePartnershipBenchmark
                        ? (
                            roi?.calculation?.intangiblePartnershipBenchmark
                              .returnOnPartnershiptoCompany * 100
                          )?.toFixed(0) + '%'
                        : 'N/A'}
                    </BigCapsuleItemValue>
                    <BigCapsuleItemLabel>To Company </BigCapsuleItemLabel>
                  </BigCapsuleItem>
                </BigCapsuleContent>
                <BigCapsuleDesc>
                  {roi?.isCompany
                    ? "Return on Partnership is the measure of overall partnership value less the company's contribution divided by the company's contribution, reported as a percentage. An ROI of 150% is the standard industry expectation for corporate partners."
                    : "Return on Partnership is measure of overall partnership value less the nonprofit's contribution divided by the nonprofit's contribution, reported as a percentage."}
                  {/* {roi?.calculation?.intangiblePartnershipBenchmark.revealThisPrescriptionCompany} */}
                </BigCapsuleDesc>
              </BigCapsule>
            </ChartCardRow>
          </ChartCard>
        </ExportPDFWrapper>
        <Button
          title="Back"
          modifiers="secondary"
          handleClick={() => {
            go(-1);
            window.scrollTo(0, 0);
          }}
        />
      </Body>
    </ContentBlockWrapper>
  );
};

const DoughnutChart = ({ value, theme }: { value: number; theme?: 'dark' }) => {
  const data = [{ value }, { value: 100 - value }];
  return (
    <PieChart width={60} height={60}>
      <text
        x={30}
        y={36}
        textAnchor="middle"
        fill={theme === 'dark' ? '#ffffff' : '#122434'}
        fontSize="16px"
      >
        {`${value}%`}
      </text>
      <Pie
        data={[{ value: 100 }]}
        dataKey="value"
        innerRadius={20}
        outerRadius={26}
        fill={theme === 'dark' ? '#47b2da' : '#e8e8e8'}
        stroke={theme === 'dark' ? '#47b2da' : '#e8e8e8'}
        isAnimationActive={false}
      />
      <Pie
        data={data}
        innerRadius={20}
        outerRadius={26}
        cornerRadius={8}
        fill={theme === 'dark' ? '#47b2da' : '#e8e8e8'}
        stroke={theme === 'dark' ? '#47b2da' : '#e8e8e8'}
        dataKey="value"
      >
        <Cell fill={theme === 'dark' ? '#ffffff' : '#2BAEE0'} />;
      </Pie>
    </PieChart>
  );
};

const ShortBar = styled.div`
  width: 111px;
  background: #f9f9f9;
  margin: 8px 0 16px;
  height: 3px;
`;

const PanelLogoLayout = styled.div`
  display: flex;
  width: 100%;
`;

const PanelSencondTitle = styled.div`
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  margin-bottom: 8px;
`;

const PanelDesc = styled.div`
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.02em;
`;

const PanelPrimaryNumber = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
`;

const DoughnutRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
`;
const DoughnutCard = styled.div<{ $theme?: 'dark' | 'light' }>`
  border: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  height: 100px;
  border-radius: 10px;
  padding: 0 44px;
  color: var(--black);

  ${({ $theme }) =>
    $theme === 'dark' &&
    `
    background: linear-gradient(90deg, #447E94 0%, #114D64 100%); 
    color: white;
  `};
`;
const DoughnutText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
`;
const DoughnutChartWrapper = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 30px;
`;

const BottomLineValueWrapper = styled.div`
  height: 236px;
  display: grid;
  grid-template-columns: 205px 250px 1fr 1fr;
  column-gap: 32px;
  row-gap: 24px;
  margin-top: 32px;
  color: var(--black);

  & > div {
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 16px;
    justify-content: space-between;
    align-items: start;
  }

  & > div:nth-child(1),
  & > div:nth-child(2) {
    grid-row: 1 / 3;
    align-items: center;
    justify-content: center;
  }
`;

const BottomLineValue = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;
const BottomLineDesc = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
`;

const CorporateIntangibleAssetsWrapper = styled.div`
  display: flex;
`;

const CorporateIntangibleAssetsStatusCard = styled.div`
  width: 402px;
  background: var(--lightGray);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  &:not(:first-child) {
    margin-top: 24px;
  }
`;

const CorporateIntangibleAssetsStatusTitle = styled.div`
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  color: #29aa26;
  margin-bottom: 16px;
`;
const CorporateIntangibleAssetsStatusDesc = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin-bottom: 28px;
`;

const ImpactRow = styled.div`
  display: flex;
  column-gap: 24px;
`;

const ImpactValue = styled.p`
  font-size: 16px;
  line-height: 155%;
  display: flex;
  align-items: center;
  &:not(:nth-last-child(n - 2)) {
    margin-bottom: 12px;
  }
  &:before {
    content: '';
    width: 6px;
    min-width: 6px;
    height: 6px;
    background: var(--blue);
    border-radius: 100%;
    margin-right: 8px;
  }
`;

const ImpactSection = styled.div`
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 24px;
  margin-bottom: 24px;
`;

const ImpactSubTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin-bottom: 16px;
`;

const AlignmentList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 112px);
  gap: 16px;
`;

const AlignmentItem = styled.img`
  width: 112px;
  height: 112px;
`;

export const EmptyAlignment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;
  width: 112px;
  height: 112px;
  background: #f2f2f2;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  text-align: center;
  margin-bottom: 20px;
`;

const Body = styled.div`
  & #pdf-content-header {
    display: none;
  }
`;

const ExportWrapper = styled.div`
  margin-left: auto;
  display: flex;
`;

const JumbotronTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const JumbotronBlock = styled.div`
  width: 83px;
  height: 83px;
  background-color: var(--secondaryBlue);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 27px;
`;

const LogoImg = styled.img`
  border-radius: 6px;
`;

const InfoCard = styled.section<{ type?: 'light' | 'dark' | 'white' | 'gold' }>`
  border-radius: 6px;
  padding: 48px 24px;
  display: flex;
  margin-bottom: 16px;
  width: 100%;

  background: ${({ type = 'light' }) => {
    switch (type) {
      case 'light':
        return 'linear-gradient(90deg, #2baee0 0%, #178fb5 100%)';
      case 'dark':
        return 'linear-gradient(90deg, #447E94 0%, #114D64 100%)';
      case 'gold':
        return 'linear-gradient(90deg, #F08D1B -0.79%, #BA6A31 100%);';
      case 'white':
        return '#fff';
    }
  }};
`;

const InfoImage = styled(ReactSVG)`
  width: 318px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoDetail = styled.div`
  flex: 1;
  color: #fff;
`;

const ChartCard = styled.section`
  background: #fff;
  margin-bottom: 16px;
  border-radius: 6px;

  & > div:first-child {
    border-bottom: 1px solid #e8e8e8;
  }
`;

const ChartCardRow = styled.div<{ isBottomBorder?: boolean }>`
  line-height: 150%;
  display: flex;
  padding: 15px 24px;
  white-space: pre-wrap;
  ${({ isBottomBorder }) => isBottomBorder && 'border-bottom: 1px solid #e8e8e8'}
`;

const BigCapsule = styled(InfoCard)`
  width: 100%;
  margin: 0 auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  padding: 48px 40px;
`;

const BigCapsuleTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 145%;
  margin-bottom: 45px;
`;
const BigCapsuleContent = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: 180px;
  }
`;
const BigCapsuleDesc = styled.div`
  font-size: 16px;
  line-height: 148%;
  margin-top: 38px;
`;
const BigCapsuleItem = styled.div`
  margin: 0 16px;
  display: flex;
  align-items: center;
`;
const BigCapsuleItemValue = styled.div`
  font-weight: bold;
  font-size: 32px;
`;
const BigCapsuleItemLabel = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  margin-left: 16px;
`;

const InnerJumbotron = styled(Jumbotron)`
  margin-bottom: 24px;
  border-radius: 6px;
`;

const ExportPDFWrapper = styled.div``;

const Button = styled(UIButton)`
  padding: 10px 24px;
`;

const DropdownBody = styled.div`
  width: 190px;
  padding: 12px;
`;

const ShareButton = styled(ExportButton)`
  margin-right: 18px;

  svg {
    transform: rotate(180deg);
  }
`;

const ColorBarGroup = styled.div`
  display: flex;

  & > div:first-child {
    border-radius: 99em 0 0 99em;
  }

  & > div:last-child {
    border-radius: 0 99em 99em 0;
  }
`;

const ColorBarItem = styled.div<{ $color: string; $active: boolean }>`
  width: 80px;
  height: 14px;
  position: relative;
  margin-bottom: 8px;

  ${({ $color }) => $color && `background: ${$color};`}

  &::after {
    content: '';
    visibility: ${({ $active }) => ($active ? 'visible' : 'hidden')};
    position: absolute;
    top: -12px;
    left: calc(50% - 3px);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    ${({ $color }) => $color && `background: ${$color};`}
  }
`;

const ColorBarFieldTexts = styled.div`
  width: 240px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  justify-content: space-between;
`;

const SDGValueCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 24px;
`;

const SDGValueCard = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  height: 106px;
  padding: 24px;

  & > div:nth-child(1) {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 16px;
  }
`;

const MissionImpactValue = styled.div`
  min-height: 100px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  margin-bottom: 24px;
  padding: 20px 33px;
  word-wrap: break-word;
`;

export default ROIReport;
