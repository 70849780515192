import * as types from './types';
import { User, Users } from 'interfaces/user';
import { AdditionalInfo, searchOrganizationsInfo } from 'interfaces/auth';

export const setUser = (user: User) =>
  ({
    type: types.SET_USER,
    payload: user,
  } as const);

export const setUsers = (users: Users) =>
  ({
    type: types.SET_USERS,
    payload: users,
  } as const);

export const setAdditionalInfo = (additionalInfo: AdditionalInfo) =>
  ({
    type: types.SET_ADDITIONAL_INFO,
    payload: additionalInfo,
  } as const);

export const setOrganizations = (organizations: searchOrganizationsInfo) =>
  ({
    type: types.SET_ADMIN_FILTERINFO,
    payload: organizations,
  } as const);

export const deleteUser = (userId: string) =>
  ({
    type: types.DELETE_USER,
    payload: userId,
  } as const);

export const setUserStatus = (user: User) =>
  ({
    type: types.SET_USER_STATUS,
    payload: user,
  } as const);
