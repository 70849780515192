/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { debounce } from 'lodash';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { MainWrapper } from 'layouts';
import { headTableCompanies } from 'constants/tableHeads';
import { useModal, useOperation } from 'hooks/common';
import { UIButton, UISuggest, UITextInput } from 'components/UI';
import Jumbotron from 'components/Jumbotron';
import Pagination from 'components/Pagination';
import { CompaniesTable } from 'components/Tables';
import { ExportButton } from 'components/ActionButtons';
import { fetchExportByFilters } from 'store/ducks/companies/api';
import useDownloadFile from 'hooks/common/useDownloadFile';
import { fetchMonthlyReportExcel } from 'store/ducks/monthlyReport/api';
import {
  fetchCompaniesSearch,
  fetchCompanyCreate,
  fetchCompanyDestroy,
  fetchcompaniesInfo,
  fetchImportCompanies,
} from 'store/ducks/companies/operations';
import { PAGE_WIDTH } from 'constants/common';
import {
  getCompanies,
  getCompaniesMeta,
  getCompaniesFomat,
} from 'store/ducks/companies/selectors';
import { getSearchJobTitles } from 'store/ducks/companies/selectors';
import { Company } from 'interfaces/companies';
import { UISuggestOption } from 'components/UI/interfaces';
import { StateProps } from 'store/interfaces';
import { PaginationMeta } from 'interfaces/common';
import InviteCompanyModal from 'components/Modals/InviteCompanyModal';
import CompaniesModal from 'components/Modals/CompaniesModal';
import ContactsModal from 'components/Modals/ContactsModal';
import { MultiSelectWithCheckBoxes } from 'components/UI/select';
import { Option } from 'react-select/src/filters';
import { fetchSearchJobTitle } from 'store/ducks/companies/operations';
import CompanyJobSection from 'components/Company/CompanyJobSection';
import Toast from 'services/Toast';

const CompaniesList = () => {
  const { location, push } = useHistory();
  const [onFetchCompanies, isLoading] = useOperation(fetchCompaniesSearch);
  const [initialQuery, setInitialValues] = useState<string>('');
  const [comLocation, setComLocation] = useState<Option[]>([]);
  const [comRevenue, setComRevenue] = useState<Option[]>([]);
  const [comJob, setComJob] = useState<Option[]>([]);
  const [onCompanyDestroy] = useOperation(fetchCompanyDestroy);
  const [createCompanies, isCreateCompaniesLoading] = useOperation(fetchCompanyCreate);
  const [onFetchCompaniesInfo] = useOperation(fetchcompaniesInfo);
  const [onSearchJobTitles] = useOperation(fetchSearchJobTitle);
  const [importCompanies, isImportCompanies] = useOperation(fetchImportCompanies);

  const companies = useSelector<StateProps, Company[]>(getCompanies);
  const meta = useSelector<StateProps, PaginationMeta>(getCompaniesMeta);
  const companiesInfo = useSelector(getCompaniesFomat());
  const searchJobTitles = useSelector(getSearchJobTitles);
  const [upLoading, setUpLoading] = useState(false);

  const { currentPage, totalItems, itemsPerPage } = meta;
  const getInitialState = (refresh: boolean) => {
    const filtersString = queryString.parse(location.search)?.q;
    const pageString = queryString.parse(location.search)?.page;
    const locationString = queryString.parse(location.search)?.comLocation;
    const revenueString = queryString.parse(location.search)?.comRevenue;
    const jobString = queryString.parse(location.search)?.comJob;
    const comrevenue = revenueString
      ? JSON.parse(window.atob(revenueString?.toString()))
      : null;
    const comlocation = locationString
      ? JSON.parse(window.atob(locationString?.toString()))
      : null;
    const comjob = jobString ? JSON.parse(window.atob(jobString?.toString())) : null;
    const param = filtersString && JSON.parse(window.atob(filtersString?.toString()));
    const comlocationText = comlocation && comlocation.map(item => item.value);
    const comrevenueText = comrevenue && comrevenue.map(item => item.value);
    const comJobText = comjob && comjob.map(item => item.id);

    if (queryString.parse(location.search)) {
      onFetchCompanies({
        page: Number(pageString) || 1,
        q: refresh ? '' : param,
        location: comlocationText,
        revenue: comrevenueText,
        jobTitle: comJobText,
      });

      setInitialValues(param);
      setComRevenue(comrevenue);
      setComLocation(comlocation);
      setComJob(comjob);

      return;
    }

    setInitialValues('');
    onFetchCompanies({ page: Number(pageString) || 1 });
  };

  const exportToExcel = useDownloadFile(fetchExportByFilters);
  const downloadExcel = () => {
    if (totalItems > 10000) {
      Toast.error('Only 10,000 records will be downloaded.');
      return;
    }
    const locationString = queryString.parse(location.search)?.comLocation;
    const revenueString = queryString.parse(location.search)?.comRevenue;
    const jobString = queryString.parse(location.search)?.comJob;
    const qString = queryString.parse(location.search)?.q;

    const locations = locationString
      ? JSON.parse(window.atob(locationString?.toString()))
      : null;
    const revenue = revenueString
      ? JSON.parse(window.atob(revenueString?.toString()))
      : null;
    const job = jobString ? JSON.parse(window.atob(jobString?.toString())) : null;
    const q = qString ? JSON.parse(window.atob(qString?.toString())) : null;

    const locationsText = locations && locations.map(item => item.value);
    const revenueText = revenue && revenue.map(item => item.value);
    const jobText = job && job.map(item => item.name);
    return exportToExcel({
      location: locationsText,
      revenue: revenueText,
      jobTitle: jobText,
      q,
    });
  };

  const [showModal, hideModal] = useModal(
    () => (
      <InviteCompanyModal
        isLoading={false}
        handleAccess={values => {
          createCompanies(values).then(() => {
            hideModal();
          });
        }}
        onClose={hideModal}
      />
    ),
    [isCreateCompaniesLoading],
  );

  const [showImportCompaniesModal, hideImportCompaniesModal] = useModal(
    () => (
      <CompaniesModal
        upLoading={upLoading}
        handleAccess={values => {
          if (values['files']) {
            importCompanies(values['files'][0])
              .then((e: any) => {
                hideImportCompaniesModal();
                getInitialState(false);
              })
              .finally(() => {
                hideImportCompaniesModal();
              });
          }
        }}
        onClose={hideImportCompaniesModal}
      />
    ),
    [location],
  );

  const [showImportContactsModal, hideImportContactsModal] = useModal(
    () => (
      <ContactsModal
        upLoading={upLoading}
        handleAccess={values => {
          importCompanies(values).then((e: any) => {
            hideImportContactsModal();
            getInitialState(false);
          });
        }}
        onClose={hideImportContactsModal}
      />
    ),
    [],
  );

  useEffect(() => {
    getInitialState(false);
    onFetchCompaniesInfo(undefined);
  }, [location]);

  const handleSearchCompanies = (
    val?: string,
    comLocation?: Option[],
    comRevenue?: Option[],
    comJob?: Option[],
  ) => {
    const base64 = window.btoa(JSON.stringify(val));
    const page = queryString.parse(location.search)?.page;
    const comLocationBase64 = window.btoa(JSON.stringify(comLocation));
    const comRevenueBase64 = window.btoa(JSON.stringify(comRevenue));
    const comJobBase64 = window.btoa(JSON.stringify(comJob));
    if (page) {
      if (val) {
        push(
          `${location.pathname}?${queryString.stringify({
            page,
            q: base64,
            comLocation: comLocationBase64,
            comRevenue: comRevenueBase64,
            comJob: comJobBase64,
          })}`,
        );
        //return onFetchCompanies({ page: 1, q: val });
      } else {
        push(`${location.pathname}`);
        //return onFetchCompanies({ page: 1, q: val });
      }
    } else {
      if (val) {
        push(
          `${location.pathname}?${queryString.stringify({
            q: base64,
            comLocation: comLocationBase64,
            comRevenue: comRevenueBase64,
            comJob: comJobBase64,
          })}`,
        );
        //return onFetchCompanies({ page: 1, q: val });
      } else {
        // push(`${location.pathname}`);
        push(
          `${location.pathname}?${queryString.stringify({
            comLocation: comLocationBase64,
            comRevenue: comRevenueBase64,
            comJob: comJobBase64,
          })}`,
        );
        /* return onFetchCompanies({
          page: 1,
          q: val,
        }); */
      }
    }
  };

  const submitHandlerPagination = (page: number) => {
    const qString = queryString.parse(location.search)?.q;

    /* if (qString) {
      const param = JSON.parse(window.atob(qString?.toString()));
      return onFetchCompanies({ page, q: param });
    } else {
      return onFetchCompanies({ page });
    } */
  };

  const handleSearchCompaniesDebounce = useCallback(
    debounce(handleSearchCompanies, 800),
    [],
  );

  const exportExcel = useDownloadFile(fetchMonthlyReportExcel);
  const handleSearchJobTitles = (val: string) => {
    return onSearchJobTitles(val);
  };
  const handleSearchJobTitleDebounce = useCallback(
    debounce(handleSearchJobTitles, 800),
    [],
  );
  const jobTitlesSearchList = useMemo(
    (): UISuggestOption[] =>
      searchJobTitles?.map(item => ({ id: item.jobTitle, name: item.jobTitle })),
    [searchJobTitles],
  );

  return (
    <MainWrapper
      // isLoading={isLoading}
      header={
        <Jumbotron name="Companies">
          <Btns>
            <Btn modifiers="primary" handleClick={showModal} title="Invite Company" />
            <Btn
              modifiers="primary"
              handleClick={() => push('company-new')}
              title="Create Company"
            />
          </Btns>
        </Jumbotron>
      }
      contentWithoutLoading={
        <SuggestWrapper>
          <UITextInput
            value={initialQuery}
            onChange={({ target: { value } }) => {
              setInitialValues(value);
              handleSearchCompaniesDebounce(value, comLocation, comRevenue, comJob);
            }}
            placeholder="Search companies"
            disableTrim
          />
        </SuggestWrapper>
      }
    >
      <>
        <SearchTitle>Filter</SearchTitle>
        <FilterWrapper>
          <MultiSelectWithCheckBoxes
            options={companiesInfo.location}
            onChange={(option: Option[]) => {
              setComLocation(option);
              handleSearchCompanies(initialQuery, option, comRevenue, comJob);
            }}
            onCloseMenu={() => {}}
            value={comLocation}
            labelledBy="Location"
          />
          <MultiSelectWithCheckBoxes
            options={companiesInfo.revenue}
            onChange={(option: Option[]) => {
              setComRevenue(option);
              handleSearchCompanies(initialQuery, comLocation, option, comJob);
            }}
            onCloseMenu={() => {}}
            value={comRevenue}
            labelledBy="Revenue Reported"
          />
          <div>
            <CompanyJobSection
              handleGetJobTitleSuggestions={handleSearchJobTitleDebounce}
              jobTitlesSearchList={jobTitlesSearchList}
              getValue={option => {
                setComJob(option);
                handleSearchCompanies(initialQuery, comLocation, comRevenue, option);
              }}
              initVal={comJob}
            />
          </div>
        </FilterWrapper>
        <HeaderList>
          <SuggestionActions>
            <>
              <ExportButton
                name="Export Companies"
                uploadHandler={downloadExcel}
              ></ExportButton>
              <div style={{ marginLeft: '-20px' }}></div>
              <ExportButton
                name="Import or Update Companies"
                iconCss={{ transform: 'rotate(180deg)' }}
                uploadHandler={showImportCompaniesModal}
              />
              <div style={{ marginLeft: '20px' }}></div>
              {/* <ExportButton
                name="Import or Update Organization Contacts                "
                iconCss={{ transform: 'rotate(180deg)' }}
                uploadHandler={showImportContactsModal}
              /> */}
            </>
          </SuggestionActions>
        </HeaderList>

        <PaginationWrapper>
          {totalItems > 0 && (
            <Pagination
              total={totalItems}
              pageLimit={Number(itemsPerPage)}
              page={Number(currentPage)}
              submitHandler={(page: number) => submitHandlerPagination(page)}
            />
          )}
        </PaginationWrapper>
        <CompaniesTable
          rows={companies}
          headCells={headTableCompanies}
          handleRemoveCompany={onCompanyDestroy}
        />
      </>
    </MainWrapper>
  );
};

const Btns = styled.div``;

const Btn = styled(UIButton)`
  &:not(:first-child) {
    margin-left: 12px;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const SuggestWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const FilterWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 34px;

  & > div {
    width: 288px;
  }
`;

const SearchTitle = styled.div`
  margin-bottom: 14px;
  color: #737373;
  font-size: 12px;
`;

const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px;
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;

const SuggestionActions = styled.div`
  display: flex;

  button:nth-child(-n + 2) {
    margin-right: 40px;
  }
`;
const Actions = styled.div`
  margin-left: auto;
  display: flex;
`;
export default CompaniesList;
