import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useToggler } from 'hooks/common';
import styled, { css } from 'styled-components';
import EyeOffIcon from 'assets/images/icons/eye-off.svg';
import EyeIcon from 'assets/images/icons/eye.svg';

import FieldInput from '../FieldInput';
import { UITextInput } from 'components/UI';
import { ReactSVG } from 'react-svg';

function FieldPasswordInput(props: FieldRenderProps<string>) {
  const [isVisible, toggle] = useToggler(false);
  const type = isVisible ? 'text' : 'password';
  const currentIcon = isVisible ? EyeIcon : EyeOffIcon;

  return (
    <FieldInput
      {...props}
      renderInput={inputProps => (
        <Wrapper>
          <UITextInput
            {...inputProps}
            type={type}
            inputCss={css`
              padding-right: 50px;
            `}
          />
          <IconWrapper>
            <ReactSVG src={currentIcon} onClick={toggle} />
          </IconWrapper>
        </Wrapper>
      )}
    />
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 11px;
  cursor: pointer;

  & svg {
    path {
      stroke: #b4b0bb;
    }
  }
`;

export default FieldPasswordInput;
