import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { teamReducer } from './team';
import { userReducer } from './user';
import { companiesReducer } from './companies';
import { StateProps } from 'store/interfaces';
import { pricingReducer } from './pricing';
import { roiReducer } from './roi';
import { audienceReducer } from './audience';
import { monthlyReportReducers } from './monthlyReport';
import { campaignReducer } from './campaign';
import { issueMappingReducer } from './issueMapping';

export const rootReducer = combineReducers<StateProps>({
  auth: authReducer,
  team: teamReducer,
  user: userReducer,
  companies: companiesReducer,
  pricing: pricingReducer,
  roi: roiReducer,
  audience: audienceReducer,
  monthlyReport: monthlyReportReducers,
  campaign: campaignReducer,
  issueMapping: issueMappingReducer,
});
