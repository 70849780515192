import { ViewImageModal } from 'components/Modals';
import { useModal } from 'hooks/common';
import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { imageFileValidator } from 'utils/validation';
import SuccessGreenSVG from 'assets/images/icons/success-green.svg';
import CloseCircleSVG from 'assets/images/icons/close-circle.svg';
import CloseCircleGraySVG from 'assets/images/icons/close-circle-gray.svg';
import { UILoadAvatar } from 'components/UI';
import { numberFormat } from 'utils/formatters';

interface BaseProps {
  filelist: { label: string | undefined; value: string; filesize?: number }[];
  disabled?: boolean;
  onDeleteFile: (fileKey: string) => void;
}

interface FileListByListProps extends BaseProps {
  itemWidth?: number;
}

const FileListByList = ({
  filelist,
  itemWidth,
  disabled,
  onDeleteFile,
}: FileListByListProps) => {
  const [viewImgKey, setViewImgKey] = useState('');

  const [showViewImageModal, hideViewImageModal] = useModal(
    () => <ViewImageModal onClose={hideViewImageModal} imgKey={viewImgKey} />,
    [viewImgKey],
  );

  if (!filelist) return null;
  return (
    <>
      {filelist.map(item => (
        <FileBar key={item.value} style={{ width: `${itemWidth || 100}%` }}>
          <FileBarLabel
            $canClick={imageFileValidator(item.value, true)}
            onClick={() => {
              if (!imageFileValidator(item.value, true)) return;
              setViewImgKey(item.value);
              showViewImageModal();
            }}
          >
            {item.label}
            <SuccessIcon src={SuccessGreenSVG} />
          </FileBarLabel>
          {!disabled && (
            <CloseListIcon
              src={CloseCircleSVG}
              onClick={() => onDeleteFile(item.value)}
            />
          )}
        </FileBar>
      ))}
    </>
  );
};

const FileListByThumbnail = ({ filelist, disabled, onDeleteFile }: BaseProps) => {
  if (!filelist) return null;
  return (
    <div style={{ marginTop: '26px', width: '96px' }}>
      {filelist.map(item => (
        <div key={item.value} style={{ position: 'relative' }}>
          {!disabled && (
            <CloseThumbnailIcon
              src={CloseCircleGraySVG}
              onClick={() => onDeleteFile(item.value)}
            />
          )}
          <LoadAvatar imgKey={item.value} />
          <FileDesc>{item.label}</FileDesc>
          {item.filesize && (
            <FileDesc style={{ color: '#898080' }}>
              {numberFormat(Math.round(item.filesize / 1024))} kb
            </FileDesc>
          )}
        </div>
      ))}
    </div>
  );
};

const FileBar = styled.div`
  height: 54px;
  background-color: #f9f9f9;
  margin-top: 8px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 21px;
`;

const SuccessIcon = styled(ReactSVG)`
  margin-left: 24px;
`;

const CloseListIcon = styled(ReactSVG)`
  cursor: pointer;
`;

const FileBarLabel = styled.span<{ $canClick: boolean }>`
  color: #2baee0;
  font-size: 14px;
  line-height: 145%;
  display: flex;
  align-items: center;

  ${({ $canClick }) =>
    $canClick &&
    `
    cursor: pointer;
    &:hover {
      text-decoration-line: underline;
    }
  `}
`;

const LoadAvatar = styled(UILoadAvatar)`
  width: 96px;
  height: 94px;
  background-size: cover;
  margin-bottom: 9px;
`;

const CloseThumbnailIcon = styled(ReactSVG)`
  cursor: pointer;
`;

const FileDesc = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
`;

export { FileListByList, FileListByThumbnail };
