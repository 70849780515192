import * as api from './api';
import { User, UserChangeCanvaReq, UserChangeStatusReq } from 'interfaces/user';
import * as actions from './actions';
import { Dispatch } from 'redux';
import { RequestProps, RequestPropsTS } from 'interfaces/common';
import Toast from 'services/Toast';
import { fetchErrorHandler } from 'utils/errorHandlers';

export const fetchUsersSearch = ({ page, q, limit }: RequestProps) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchUsersSearch(page, q, limit)
    .then(users => {
      dispatch(actions.setUsers(users));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchUsersSearchTS = ({ page, q, limit }: RequestPropsTS) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchUsersSearchTS(page, q, limit)
    .then(users => {
      dispatch(actions.setUsers(users));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchUser = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchUser(id)
    .then(user => {
      dispatch(actions.setUser(user));
    })
    .catch(error => {
      throw error;
    });
};

export const additionalInfoGet = () => async (dispatch: Dispatch) => {
  return api
    .getAdditionalInfo()
    .then(response => {
      dispatch(actions.setAdditionalInfo(response));
    })
    .catch(() => {});
};

export const organizationsInfoGet = () => async (dispatch: Dispatch) => {
  return api
    .getOrganizationInfo()
    .then(response => {
      dispatch(actions.setOrganizations(response));
    })
    .catch(() => {});
};

export const fetchUserUpdate = ({ id, data }, config?: { hideToast: boolean }) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchUserUpdate(id, data)
    .then(user => {
      !config?.hideToast && Toast.success('User successfully updated');
      dispatch(actions.setUser(user));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchUserCreate = (data: User) => async (dispatch: Dispatch) => {
  return api
    .fetchUserCreate(data)
    .then(user => {
      Toast.success('User successfully created');
      dispatch(actions.setUser(user));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchUploadUserAvatar = (file: File) => async () => {
  return api
    .fetchUploadUserAvatar(file)
    .then(() => {
      return Toast.success('Avatar successfully uploaded');
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchChangePassword = ({ id, data }) => async () => {
  return api
    .fetchChangePassword(id, data)
    .then(() => {
      Toast.success('Password created successfully');
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchUserDelete = (userId: string) => async (dispatch: Dispatch) => {
  return api
    .fetchUserDelete(userId)
    .then(() => {
      dispatch(actions.deleteUser(userId));
    })
    .catch(() => Toast.error('Error delete user'));
};

export const fetchUserSendEmail = (email: string) => async () => {
  return api
    .fetchUserSendEmail(email)
    .then(() => {})
    .catch(() => Toast.error('Error user send email'));
};

export const fetchUserSetAsOwner = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchUserSetAsOwner(id)
    .then(user => {
      Toast.success('User successfully set as an owner');
      dispatch(actions.setUser(user));
    })
    .catch(() => Toast.error('Error set user as owner'));
};

export const fetchUserChangeStatus = (params: UserChangeStatusReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchUserChangeStatus(params)
    .then(user => {
      Toast.success('User status changed successfully');
      dispatch(actions.setUserStatus(user));
    })
    .catch(() => Toast.error('Error set user status'));
};

export const fetchCreateOktaUser = (userId: string) => async () => {
  return api.fetchCreateOktaUser(userId).catch(err => {
    fetchErrorHandler(err);
  });
};

export const fetchUserChangeCanva = (params: UserChangeCanvaReq) => async () => {
  return api.fetchUserChangeCanva(params).catch(err => {
    fetchErrorHandler(err);
  });
};
