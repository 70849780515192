import React from 'react';
import styled from 'styled-components';

import { DefaultItemRendererProps } from '../interfaces';
import UICheckbox from '../UICheckbox';

const ItemSearchSelectPanel = ({
  onClick,
  checked,
  disabled,
  option,
}: DefaultItemRendererProps) => {
  return (
    <Root onClick={onClick} className={`item-renderer ${disabled && 'disabled'}`}>
      <Checkbox check={checked} disabled={disabled} />

      <Item>{option.label}</Item>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  padding: ${17 / 2}px 0;
`;

const Item = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
`;

const Checkbox = styled(UICheckbox)`
  margin-right: 10px;
`;

export default ItemSearchSelectPanel;
