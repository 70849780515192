import { CropText, UIButton } from 'components/UI';
import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import { TeamData } from 'interfaces/team';
import { useModal } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
interface Props {
  rows: TeamData[];
  headCells: string[];
  handleDeleteOrganization: (id: string) => void;
}

interface TableRowProps {
  row: TeamData;
  handleDeleteOrganization: (id: string) => void;
}

const userTableWidth = {
  column1: 250,
  column2: 220,
  column3: 220,
  column4: 100,
  column5: 150,
  column6: 300,
  column7: 100,
} as const;

const { column1, column2, column3, column4, column5, column6, column7 } = userTableWidth;

const TableRowWrapper = ({ row, handleDeleteOrganization }: TableRowProps) => {
  const {
    id,
    organizationName,
    aieldActivity,
    contactPhone,
    address,
    owner,
    contactWebsite,
  } = row;
  const [showModal, hideModal] = useModal(() => {
    const deleteHandler = () => {
      handleDeleteOrganization(id);
      hideModal();
    };

    return (
      <ConfirmModal
        name={'Delete Organization'}
        textAccessBtn="Delete"
        description={'Are you sure you want to delete the Organization?'}
        accessHandler={deleteHandler}
        cancelHandler={hideModal}
        onClose={hideModal}
        hideCloseIcon
      />
    );
  });

  return (
    <TableRow>
      <TableRowCell style={{ width: `${column1}px` }}>
        <TableLinkWrapper width={column1 - 30}>
          <TableLink to={`/team/view/${id}`}>{organizationName || 'Profile'}</TableLink>
        </TableLinkWrapper>
      </TableRowCell>

      <TableRowCell style={{ width: `${column2}px` }}>
        <CropText width={column2 - 20} text={contactWebsite || '-'} />
      </TableRowCell>

      <TableRowCell style={{ width: `${column3}px` }}>
        <CropText width={column3 - 20} text={owner?.email || '-'} />
      </TableRowCell>

      <TableRowCell style={{ width: `${column4}px` }}>
        <CropText width={column4 - 20} text={aieldActivity || '-'} />
      </TableRowCell>

      <TableRowCell style={{ width: `${column5}px` }}>
        <TableLinkWrapper width={column5 - 10}>
          {contactPhone ? (
            <TableLinkPhone href={`tel:${contactPhone}`}>{contactPhone}</TableLinkPhone>
          ) : (
            '-'
          )}
        </TableLinkWrapper>
      </TableRowCell>

      <TableRowCell style={{ width: `${column6}px` }}>
        <p>{address || '-'} </p>
      </TableRowCell>

      <TableRowCell style={{ width: `${column7}px` }}>
        <RemoveBtn modifiers="danger" handleClick={showModal} title="Delete" />
      </TableRowCell>
    </TableRow>
  );
};

const TeamsTable = ({ rows, headCells, handleDeleteOrganization }: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${column1}px` }} />
          <col span={1} style={{ width: `${column2}px` }} />
          <col span={1} style={{ width: `${column3}px` }} />
          <col span={1} style={{ width: `${column4}px` }} />
          <col span={1} style={{ width: `${column5}px` }} />
          <col span={1} style={{ width: `${column6}px` }} />
          <col span={1} style={{ width: `${column7}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            {headCells.map((item, index) => {
              const width = userTableWidth[`column${index + 1}`];

              return (
                <TableHeadCell key={index} style={{ width: `${width}px` }}>
                  {item}
                </TableHeadCell>
              );
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(item => (
            <TableRowWrapper
              key={item.id}
              row={item}
              handleDeleteOrganization={handleDeleteOrganization}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableLinkWrapper = styled.div<{ width?: string | number }>`
  display: block;
  ${({ width }) => width && `width: ${width}px;`}
`;

const TableLink = styled(Link)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

const TableLinkPhone = styled.a`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

const TableWrapper = styled.div`
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  display: block;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
  width: 100%;
  display: block;
`;

const TableHeadRow = styled.tr`
  height: 39px;
  display: block;
`;

const TableBody = styled.tbody`
  background: #ffffff;
  display: block;
  height: 100%;
  overflow: scroll;
  max-height: calc(100vh - 372px);
  width: 100%;
`;
const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-right: 24px;
  }
`;

const TableRow = styled.tr`
  height: 100%;
  width: 100%;
  display: block;
  border-bottom: 1px solid #f6f6f6;
`;

const TableRowCell = styled.td`
  padding: 20px 0;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

const RemoveBtn = styled(UIButton)`
  width: 56px;
  font-size: 12px;
  padding: 3px 0;
`;

export default TeamsTable;
