import * as types from './types';
import {
  TeamData,
  Teams,
  SubscriptionProps,
  TeamContact,
  ImpactGenome,
} from 'interfaces/team';
import { News } from 'interfaces/companies';
import { User, UserChangeCanvaReq } from 'interfaces/user';

export const setTeam = (team: TeamData) =>
  ({
    type: types.SET_TEAM,
    payload: team,
  } as const);

export const setTeams = (teams: Teams) =>
  ({
    type: types.SET_TEAMS,
    payload: teams,
  } as const);

export const setTeamsSuggested = (teams: TeamData[]) =>
  ({
    type: types.SET_TEAMS_SUGGESTED,
    payload: teams,
  } as const);

export const setTeamSubscription = (subscription: SubscriptionProps) =>
  ({
    type: types.SET_SUBSCRIPTION,
    payload: subscription,
  } as const);

export const removeTeamSubscription = (teamId: string) =>
  ({
    type: types.DELETE_SUBSCRIPTION,
    payload: teamId,
  } as const);

export const deleteTeam = (teamId: string) =>
  ({
    type: types.DELETE_TEAM,
    payload: teamId,
  } as const);

export const deleteTeamMembers = (id: string) =>
  ({
    type: types.DELETE_TEAM_MEMBERS,
    payload: id,
  } as const);

export const getTeamMembers = (teamMembers: User[]) =>
  ({
    type: types.GET_TEAM_MEMBERS,
    payload: teamMembers,
  } as const);

export const setTeamNews = (news: News[]) =>
  ({
    type: types.SET_TEAM_NEWS,
    payload: news,
  } as const);

export const deleteContact = (contactId: string) =>
  ({
    type: types.DELETE_CONTACT,
    payload: contactId,
  } as const);

export const setContacts = (contacts: TeamContact[]) =>
  ({
    type: types.SET_CONTACTS,
    payload: contacts,
  } as const);

export const setContact = (contact: TeamContact) =>
  ({
    type: types.SET_CONTACT,
    payload: contact,
  } as const);

export const setTeamMember = (user: User) =>
  ({
    type: types.SET_TEAM_MEMBER,
    payload: user,
  } as const);

export const setTeamMemberIsCanva = (params: UserChangeCanvaReq) =>
  ({
    type: types.SET_TEAM_MEMBER_ISCANVA,
    payload: params,
  } as const);

export const setTeamPayPalGivingFundCanUpdate = (canUpdate: boolean) =>
  ({
    type: types.SET_TEAM_PAYPALGIVINGFUND_CANUPDATE,
    payload: canUpdate,
  } as const);

export const setTeamPayPalGivingFundIfConfirm = (ifConfirm: boolean) =>
  ({
    type: types.SET_TEAM_PAYPALGIVINGFUND_IFCONFIRM,
    payload: ifConfirm,
  } as const);

export const setImpactGenome = (data: ImpactGenome[]) =>
  ({
    type: types.SET_IMPACT_GENOME,
    payload: data,
  } as const);

export const setCompany = (data: any) =>
  ({
    type: types.SET_IMPACT_GENOME,
    payload: data,
  } as const);
