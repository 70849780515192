export const SET_PRICING_LIST = 'pricing/SET_PRICING_LIST';
export const SET_CATEGORY = 'pricing/SET_CATEGORY';
export const SET_VALUATION_SAVE = 'pricing/SET_VALUATION_SAVE';
export const SET_PRICING = 'pricing/SET_PRICING';
export const DELETE_VALUATION = 'pricing/DELETE_VALUATION';
export const ADD_PRICING = 'pricing/ADD_PRICING';
export const SET_REPORT = 'pricing/SET_REPORT';
export const SET_REPORT_FILE = 'pricing/SET_REPORT_FILE';
export const SET_ADMINNOTE_LIST = 'pricing/SET_ADMINNOTE_LIST';
export const ADD_ADMINNOTE = 'pricing/ADD_ADMINNOTE';
