export const SET_ROI_LIST = 'roi/SET_ROI_LIST';
export const SET_ROI = 'roi/SET_ROI';
export const CREATE_ROI = 'roi/SET_ROI_CREATE';
export const UPDATE_ROI = 'roi/SET_ROI_UPDATE';
export const DELETE_ROI = 'roi/SET_ROI_DELETE';
export const SET_ROI_TEMPLATES = 'roi/SET_ROI_TEMPLATES';
export const SET_ROI_TEMPLATE_PREVIEW = 'roi/SET_ROI_TEMPLATE_PREVIEW';
export const SET_ROI_TEMPLATES_ITEM = 'roi/SET_ROI_TEMPLATE_SAVE';
export const TOGGLE_ROI_TEMPLATES_DISABLE = 'roi/TOGGLE_ROI_TEMPLATES_DISABLE';
export const SET_ROI_REPORT = 'roi/SET_ROI_REPORT';
export const SET_ROI_MELTWATER_DATA = 'roi/SET_ROI_MELTWATER_DATA';
