/* eslint-disable react-hooks/exhaustive-deps */
import Jumbotron from 'components/Jumbotron';
import { InviteUser, UserTable } from 'components/team';
import { UISpinner } from 'components/UI';
import { headTableUsers } from 'constants/team';
import { useOperation } from 'hooks/common';
import { Company } from 'interfaces/companies';
import { User } from 'interfaces/user';
import { CompanyWrapper } from 'layouts';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchCompany,
  fetchCompanyMemberChangeCanva,
  fetchCompanyMembersAdd,
  fetchCompanyMembersDelete,
  fetchCompanyMembersInvite,
  fetchCompanyMembersList,
  fetchCompanySubscription,
} from 'store/ducks/companies/operations';
import {
  getCompanyById,
  getCompanyMembers,
  getCompanySubscription,
} from 'store/ducks/companies/selectors';
import { fetchCreateOktaUser } from 'store/ducks/user/operations';
import { StateProps } from 'store/interfaces';
import styled from 'styled-components';

const CompanyMembers = () => {
  const [onFetchCompany, isLoading] = useOperation(fetchCompany);
  const [onFetchCompanyMembersList] = useOperation(fetchCompanyMembersList);
  const [onFetchCompanyMembersAdd] = useOperation(fetchCompanyMembersAdd);
  const [onFetchCompanySubscription] = useOperation(fetchCompanySubscription);
  const [onFetchCreateOktaUser, , isCreateOktaUserLoading] = useOperation(
    fetchCreateOktaUser,
  );
  const [onFetchCompanyMemberChangeCanva, , isMemberUpdateLoading] = useOperation(
    fetchCompanyMemberChangeCanva,
  );

  const params: { companyId: string } = useParams();

  const users = useSelector<StateProps, User[]>(getCompanyMembers);
  const company = useSelector<StateProps, Company | undefined>(
    getCompanyById(params.companyId),
  );
  const subscription = useSelector(getCompanySubscription);

  useEffect(() => {
    onFetchCompany(params.companyId);
    onFetchCompanyMembersList(params.companyId);
    onFetchCompanySubscription(params.companyId);
  }, [params.companyId]);

  return (
    <CompanyWrapper
      companyId={params.companyId}
      isLoading={isLoading}
      header={<Jumbotron name={company?.name || ''} back backPath="/companies" />}
    >
      <Root>
        {(isMemberUpdateLoading || isCreateOktaUserLoading) && <Spinner />}

        <Header>User Management</Header>
        <InviteUser
          onSubmit={email =>
            onFetchCompanyMembersAdd({ email, companyId: params.companyId })
          }
          fetchInviteLinkOperation={fetchCompanyMembersInvite}
          inviteId={params.companyId}
        />
        <UserTable
          rows={users}
          headCells={headTableUsers}
          showCanvaColumn={!!subscription?.isCanva}
          deleteMemberOperation={fetchCompanyMembersDelete}
          onChangeUser={user => {
            if (user.isCanva) {
              onFetchCreateOktaUser(user.id).then(() => {
                onFetchCompanyMemberChangeCanva({
                  userId: user.id,
                  isCanva: user.isCanva,
                });
              });
              return;
            }
            onFetchCompanyMemberChangeCanva({ userId: user.id, isCanva: user.isCanva });
          }}
        />
      </Root>
    </CompanyWrapper>
  );
};

const Root = styled.div`
  background: #fff;
  position: relative;
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  padding: 24px 0 0 24px;
`;

const Spinner = styled(UISpinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export default CompanyMembers;
