/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import BenchmarkLineSVG from 'assets/images/benchmark-line.svg';
import { ReactSVG } from 'react-svg';

interface Props {
  /** 1..100 */
  value: number;
  benchmark?: number;
  segmentsCount?: number;
  color?: string;
  text?: string;
  className?: string;
  barHeight?: number;
}

const ProgressBar: FC<Props> = ({
  className,
  color,
  value,
  barHeight = 20,
  benchmark = 0,
  segmentsCount = 10,
  text = '',
}) => {
  const colorType = useMemo(() => {
    if (value > (benchmark / segmentsCount) * 100) return 'beyond';
    if (value < (benchmark / segmentsCount) * 100) return 'under';
    return 'on';
  }, [benchmark]);

  return (
    <Root colorType={colorType} color={color} className={className}>
      <BarWrapper barHeight={barHeight}>
        {[...Array(segmentsCount - 1)].map((_, i) =>
          i === benchmark - 1 ? (
            <BenchmarkLine key={i} src={BenchmarkLineSVG} />
          ) : (
            <SplitLine key={i} />
          ),
        )}
        <Bar value={value}>
          <BarText>{text}</BarText>
        </Bar>
      </BarWrapper>
    </Root>
  );
};

const Root = styled.div<{ colorType: 'under' | 'on' | 'beyond'; color?: string }>`
  --under-color: #f36d25;
  --on-color: #2baee0;
  --beyond-color: #28759c;

  display: flex;
  align-items: center;
  width: 100%;

  ${({ colorType, color }) => {
    if (color) return `--color: ${color};`;

    if (colorType === 'under') return `--color: var(--under-color);`;

    if (colorType === 'on') return `--color: var(--on-color);`;

    return `--color: var(--beyond-color);`;
  }}
`;

const Bar = styled.div<{ value: number }>`
  width: ${({ value }) => `${value}%`};
  ${({ value }) => {
    if (value !== 100)
      return `
        border-radius: 99em;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
     `;

    return 'border-radius: 99em;';
  }}
  height: 100%;

  background-color: var(--color);
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 145%;
  color: var(--black);
`;

const BarText = styled.div`
  margin: 0 12px;
  width: 100%;
  text-align: right;
`;

const BarWrapper = styled.div<{ barHeight: Props['barHeight'] }>`
  background-color: #fff;

  height: ${({ barHeight }) => `${barHeight}px`};

  width: 100%;
  border-radius: 99em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
`;

const SplitLine = styled.div`
  width: 1px;
  height: 20px;
  background-color: #e8e8e8;
  z-index: 1;
`;

const BenchmarkLine = styled(ReactSVG)`
  width: 0px;
  position: relative;
  left: -5px;
  z-index: 1;
`;

export default ProgressBar;
