import {
  ROICreateReq,
  ROIData,
  ROIListByCompanyReq,
  ROIListReq,
  ROIListRes,
  ROIMeltwaterData,
  ROIReport,
  ROITemplate,
  ROIUpdateMeltwaterReq,
} from 'interfaces/roi';
import { clientApiUrl, fetch } from 'services/ApiService';
import { paramsToQueryString } from 'utils';
import StorageService from 'services/Storage';
import { CompanyCreateReq } from 'interfaces/companies';

export const fetchROIList = ({ limit = 12, ...rest }: ROIListReq) =>
  fetch<ROIListRes>('get', `ROI/byTeams${paramsToQueryString({ limit, ...rest })}`);

export const fetchROIListByCompany = ({ limit = 12, ...rest }: ROIListByCompanyReq) =>
  fetch<ROIListRes>('get', `ROI/byCompanies${paramsToQueryString({ limit, ...rest })}`);

export const fetchROICreate = (param: ROICreateReq) =>
  fetch<ROIData>('post', 'ROI', { payload: param });

export const fetchROI = (id: string) => fetch<ROIData>('get', `ROI/${id}`);

export const fetchROIUpdate = (param: Partial<ROIData>) =>
  fetch<ROIData>('patch', 'ROI', {
    payload: {
      ...param,
      longevityOfPartnership:
        param.longevityOfPartnership && Number(param.longevityOfPartnership),
    },
  });

export const fetchMultipleDelete = (ids: string) =>
  fetch<ROIData>(
    'delete',
    `ROI/MultipleDelete${paramsToQueryString({ deleteIds: ids })}`,
  );

export const fetchDownloadROITemplate = () =>
  fetch<{ name: string; file: string }>('get', 'ROI/downLoadTemplate');

export const fetchUploadROIXLSX = (file: File) => {
  const data = new FormData();
  data.append('file', file, file?.name);

  return fetch<{ status: string; fileKey: string }>(
    'post',
    `ROI/upload-excel`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchROIGenerateTemplates = (roiId: string) =>
  fetch<ROITemplate[]>('post', `ROI/generateTemplates/${roiId}`);

export const fetchROITemplates = (roiId: string) =>
  fetch<ROITemplate[]>('get', `ROI/template/list/${roiId}`);

export const fetchROITemplate = (templateId: string) =>
  fetch<ROITemplate>('get', `ROI/template/${templateId}`);

export const fetchROITemplatePreview = (templateId: string) =>
  fetch<string>('get', `ROI/template/preview?templateId=${templateId}`);

export const fetchROITemplateCompiled = (template: ROITemplate) =>
  fetch<string>('post', `ROI/template/compiled`, { payload: template });

export const fetchROITemplateSave = (template: ROITemplate) =>
  fetch<ROITemplate>('post', 'ROI/template/save', { payload: template });

export const fetchROITemplateListSave = (templateList: ROITemplate[]) =>
  fetch<ROITemplate[]>('post', 'ROI/template/saveList', {
    payload: templateList,
  });

export const fetchROITemplateReset = (templateId: string) =>
  fetch<ROITemplate>('post', `ROI/template/reset/${templateId}`);

export const fetchRoiCalReport = (roiId: string) =>
  fetch<ROIReport>('patch', `ROI/${roiId}/cal`);

export const fetchRoiGenerateReport = (roiId: string) =>
  fetch<ROIReport>('patch', `ROI/${roiId}/generate`);

export const fetchRoiAddCompany = (param: CompanyCreateReq) =>
  fetch<ROIReport>('post', `ROI/addCompany`, { payload: param });

export const fetchROIUpdateMeltwater = (param: ROIUpdateMeltwaterReq) =>
  fetch<ROIData>('post', `ROI/meltwater`, { payload: param, host: clientApiUrl });

export const fetchROIMeltwaterRegenerate = (roiId: string) =>
  fetch<ROIReport>('get', `ROI/meltwater/${roiId}/regenerate`, {
    host: clientApiUrl,
  });

export const fetchROIMeltwaterData = (roiId: string) =>
  fetch<ROIMeltwaterData>('get', `ROI/meltwater/${roiId}/LastAnalytics`, {
    host: clientApiUrl,
  });

export const fetchROIMeltwaterDataById = (analyticsId: string) =>
  fetch<ROIMeltwaterData>('get', `ROI/meltwater/${analyticsId}`, {
    host: clientApiUrl,
  });

export const fetchROIReportRevert = (roiId: string) =>
  fetch<ROIData>('patch', `ROI/${roiId}/revert`);

export const fetchGenerateexcel = (roiId: string) =>
  fetch<{ name: string; file: string }>('patch', `ROI/${roiId}/generateexcel`);
