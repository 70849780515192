import React from 'react';
import { CropText, UIButton } from 'components/UI';
import { User, UserChangeStatusReq } from 'interfaces/user';
import { Link } from 'react-router-dom';
import { getFullName, getRelativeTime } from 'utils/formatters';
import styled from 'styled-components';
import { useModal, useOperation } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
import Toast from 'services/Toast';
import { userSiteUrl } from 'services/ApiService';
import { fetchUserToken } from 'store/ducks/auth/operations';

interface Props {
  rows: User[];
  headCells: string[];
  handleDeleteUser: (userId: string) => void;
  handleChangeStatus: (params: UserChangeStatusReq) => void;
}

interface TableRowProps {
  row: User;
  handleDeleteUser: Props['handleDeleteUser'];
  handleChangeStatus: Props['handleChangeStatus'];
}

const userTableWidth = {
  nameWidth: 282,
  emailWidth: 238,
  teamWidth: 160,
  teamRoleWidth: 120,
  loggedInWidth: 120,
  createdWidth: 120,
  updatedWidth: 120,
  statusWidth: 100,
  actionWidth: 100,
} as const;

const {
  nameWidth,
  emailWidth,
  teamWidth,
  teamRoleWidth,
  loggedInWidth,
  createdWidth,
  updatedWidth,
  statusWidth,
  actionWidth,
} = userTableWidth;

const TableRowWrapper = ({
  row,
  handleDeleteUser,
  handleChangeStatus,
}: TableRowProps) => {
  const {
    id,
    email,
    firstName,
    lastName,
    team,
    role,
    createdAt,
    updatedAt,
    loggedInAt,
    status,
  } = row;
  const [showModal, hideModal] = useModal(() => {
    const deleteHandler = () => {
      handleDeleteUser(id);
      hideModal();
    };

    return (
      <ConfirmModal
        name={'Delete User'}
        textAccessBtn="Delete"
        description={'Are you sure you want to delete the User?'}
        accessHandler={deleteHandler}
        cancelHandler={hideModal}
        onClose={hideModal}
        hideCloseIcon
      />
    );
  });

  const handleRemove = () => {
    if (row.role !== 'owner') {
      return showModal();
    }

    Toast.warn(`Owner cannot be deleted. 
    Please change owner of the organization before you delete this owner.`);
  };

  const userName = getFullName(firstName, lastName);

  const createdAtTime = createdAt && getRelativeTime(createdAt);
  const updatedAtTime = updatedAt && getRelativeTime(updatedAt);
  const loggedInAtTime = loggedInAt && getRelativeTime(loggedInAt);
  const teamName = team?.organizationName;
  const teamRole = role;
  const [onFetchUserToken] = useOperation(fetchUserToken);
  const toUserSiteByToken = (id: string) => {
    onFetchUserToken(id).then(value => {
      if (!value) return;

      window.open(`${userSiteUrl}?accessToken=${value.accessToken}`);
    });
  };

  return (
    <TableRow>
      <TableRowCell>
        <TableLinkWrapper>
          <TableLink to={`/teamsnaps/${id}`}>{userName || 'Profile'}</TableLink>
        </TableLinkWrapper>
      </TableRowCell>

      <TableRowCell>
        <CropText width={emailWidth - 20} text={email || '-'} />
      </TableRowCell>

      <TableRowCell>
        <p>{teamName || '-'} </p>
      </TableRowCell>

      <TableRowCell>
        <p>{teamRole || '-'} </p>
      </TableRowCell>

      <TableRowCell>
        <p>{loggedInAtTime}</p>
      </TableRowCell>

      <TableRowCell>
        <p>{createdAtTime}</p>
      </TableRowCell>

      <TableRowCell>
        <p>{updatedAtTime}</p>
      </TableRowCell>

      <TableRowCell>
        <p>{status ? 'Active' : 'Inactive'}</p>
      </TableRowCell>

      <TableRowCell>
        <ActionGroup>
          <ActionButton
            handleClick={() => toUserSiteByToken(id)}
            modifiers="blue"
            title="Access"
          />
          <DangerButton
            handleClick={() => handleChangeStatus({ id, status: !status })}
            title={status ? 'Disable' : 'Enable'}
            isNotDanger={!status}
          />
          <DangerButton handleClick={handleRemove} title="Delete" />
        </ActionGroup>
      </TableRowCell>
    </TableRow>
  );
};

const UsersTeamsTable = ({
  rows,
  headCells,
  handleDeleteUser,
  handleChangeStatus,
}: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${nameWidth}px` }} />
          <col span={1} style={{ width: `${emailWidth}px` }} />
          <col span={1} style={{ width: `${teamWidth}px` }} />
          <col span={1} style={{ width: `${teamRoleWidth}px` }} />
          <col span={1} style={{ width: `${loggedInWidth}px` }} />
          <col span={1} style={{ width: `${createdWidth}px` }} />
          <col span={1} style={{ width: `${updatedWidth}px` }} />
          <col span={1} style={{ width: `${statusWidth}px` }} />
          <col span={1} style={{ width: `${actionWidth}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            {headCells.map((item, index) => {
              return <TableHeadCell key={index}>{item}</TableHeadCell>;
            })}
            <TableHeadCell></TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(item => (
            <TableRowWrapper
              key={item.id}
              row={item}
              handleDeleteUser={handleDeleteUser}
              handleChangeStatus={handleChangeStatus}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableLinkWrapper = styled.div<{ width?: string | number }>`
  ${({ width }) => width && `width: ${width}px;`}
`;

const TableLink = styled(Link)`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  &:hover {
    text-decoration: underline;
  }
`;

const TableWrapper = styled.div`
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
  width: 100%;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableBody = styled.tbody`
  background: #ffffff;
  height: 100%;
  overflow: scroll;
  max-height: calc(100vh - 372px);
  width: 100%;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-right: 24px;
  }
`;

const TableRow = styled.tr`
  height: 100%;
  width: 100%;
  border-bottom: 1px solid #f6f6f6;
`;

const TableRowCell = styled.td`
  padding: 20px 0;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;
  }

  &:last-child {
    padding-right: 24px;
  }
`;

const ActionButton = styled(UIButton)`
  width: 56px;
  font-size: 12px;
  padding: 3px 0;
`;

const DangerButton = styled(ActionButton).attrs({ modifiers: 'danger' })<{
  isNotDanger?: boolean;
}>`
  ${({ isNotDanger }) => isNotDanger && `color: var(--blue)`}
`;

const ActionGroup = styled.div`
  display: flex;

  & > :not(:first-child) {
    margin-left: 8px;
  }
`;

export default UsersTeamsTable;
