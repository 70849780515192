import { UIButton } from 'components/UI';
import React, { FC, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import CloseIcon from 'assets/images/icons/close.svg';
import { Form } from 'react-final-form';
import { PricingData, ValuationDetailData } from 'interfaces/pricing';
import { useHistory, useParams } from 'react-router';
import arrayMutators from 'final-form-arrays';
import { Field } from 'components/FinalForm';
import {
  FieldSingleSelect,
  FieldTextInput,
  FiledMultiSelectWithCheckBoxes,
  MaskedMoneyTextInput,
} from 'components/FormFields';
import { formatValueEnum } from 'components/UI/interfaces';
import { FieldArray } from 'react-final-form-arrays';
import PlusIcon from 'assets/images/icons/plus.svg';
import { CustomAssetsOptions } from 'constants/pricing';
import FieldRatioInput from 'components/FormFields/FieldRatioInput/FieldRatioInput';
import { integerValidator } from 'utils/validation';

interface Props {
  pricing: PricingData;
  handleSaveValuation: (data) => Promise<unknown>;
}

const ValuationForm: FC<Props> = ({ pricing, handleSaveValuation }) => {
  const { push } = useHistory();
  const params = useParams<{ teamId: string; valuationId: string }>();

  const initialValues = useMemo(
    () => ({
      ...pricing,
      fundraisingIncome:
        pricing.fundraisingIncome && pricing.fundraisingIncome.length
          ? [
              ...pricing.fundraisingIncome,
              ...Array(5 - pricing.fundraisingIncome?.length).fill(null),
            ]
          : [...Array(5).fill(null)],
    }),
    [pricing],
  );

  return (
    <Form
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={(v: ValuationDetailData) => {
        if (!pricing) {
          return;
        }

        const formValues: ValuationDetailData = {
          ...v,
          customAssets: v.customAssets?.filter(item => item),
        };

        handleSaveValuation({ ...pricing, ...formValues }).then(() =>
          push(`/pricing/view/${params.teamId}`),
        );
      }}
      render={({
        handleSubmit,
        form: {
          mutators: { push: formPush },
        },
        values,
      }) => {
        return (
          <Body>
            <FieldSection>
              <FieldTitle>Current Minimum Threshold</FieldTitle>
              <FieldDefinition>
                A minimum threshold is the minimum donation that your organization or
                property will accept to exchange its intellectual property.
              </FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="currentMinimumThreshold"
                  label="What is your current minimum threshold for partners or sponsors of your organization or specfiic property?"
                  component={MaskedMoneyTextInput}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Industry Ranking</FieldTitle>
              <FieldDefinition>
                Your GuideStar seal of transparency rating. (E.g., Platinum)
              </FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="industryRanking"
                  label="Include your Guidestar Ranking "
                  component={FieldSingleSelect}
                  placeholder="Select"
                  options={[
                    { value: 'None', label: 'None' },
                    { value: 'Bronze', label: 'Bronze' },
                    { value: 'Silver', label: 'Silver' },
                    { value: 'Gold', label: 'Gold' },
                    { value: 'Platinum', label: 'Platinum' },
                  ]}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Unaided Brand Awareness</FieldTitle>
              <FieldDefinition>
                This is a measure of the number of people who express knowledge of your
                organization withoutprompting. In other words, brand recall. -“What
                organization comes to mind when you think of cancer?”
              </FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="unaidedBrandAwareness"
                  label="Unaided Brand Awareness"
                  component={FieldRatioInput}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Aided Brand Awareness</FieldTitle>
              <FieldDefinition>
                This is a measure of the number of people who express knowledge of your
                organization when prompted. In other words, brand recognition. -“Have you
                heard of St. Jude’s?
              </FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="aidedBrandAwareness"
                  label="Aided Brand Awareness"
                  component={FieldRatioInput}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Constituent Data</FieldTitle>
              <FieldDefinition>
                If you have partnered with Accelerist for constituent analysis, then you
                should check all boxes.
              </FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="constituentData"
                  label="What kinds of constituent data do you have?"
                  component={FieldSingleSelect}
                  options={[
                    { value: 'Demographic Data', label: 'Demographic' },
                    { value: 'Psychographic Data', label: 'Psychographic' },
                    { value: 'Purchasing Behavior', label: 'Purchasing Behavior' },
                    { value: 'Affinity Data', label: 'Brand Affinities' },
                    {
                      value: "I don't have much",
                      label: "I don't have much but would like to",
                    },
                  ]}
                  placeholder="Select"
                  formatValue={formatValueEnum.BASE_ARRAY}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Community Impact</FieldTitle>
              <FieldDefinition>
                Every organization has many different strategies and indicators to measure
                their impact. This question asks for the organization’s averagepositive
                change or impact across all programs and mission areas.
              </FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="communityImpact"
                  label="Over the last 12 months, what positive change did your organization make?"
                  component={FieldRatioInput}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Sense of Urgency</FieldTitle>
              <FieldDefinition>
                A lot of causes are not a disease or condition that is curable or not.
                However, partners are interested in making tangible change. This question
                is, in essence, about urgency of your cause.
              </FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="senseOfUrgencyCurable"
                  label="Is your cause curable?"
                  component={FieldSingleSelect}
                  placeholder="Select"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                />
              </FieldSingleRow>
              <FieldDefinition>
                Not all causes are clearly preventable or not. This question gives
                potential partners a clear understanding of a path to impact.
              </FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="senseOfUrgencyPreventable"
                  label="Is your cause preventable?"
                  component={FieldSingleSelect}
                  placeholder="Select"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Audience Loyalty</FieldTitle>
              <FieldDefinition>
                Refers to the number of constituents –donors, participants, volunteers,
                general audience –who return year over year.
              </FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="audienceLoyalty"
                  label="How many of your overall audience has come back and given or engaged over the last three years?"
                  component={FieldRatioInput}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Audience Activation</FieldTitle>
              <FieldDefinition>
                Refers to the number of your constituents who engage more than one way
                with you. In other words, how many people are donating, engaging onsocial
                media, involved in peer-to-peer activity, etc. simultaneously?
              </FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="audienceActivation"
                  label="Can you engage an audience across multiple facets or is it a unique audience each time?"
                  component={FieldRatioInput}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Fundraising Income</FieldTitle>
              <FieldDefinition>
                Include the organization&apos;s or property&apos;s fundraising income over
                the past 5 years
              </FieldDefinition>
              <FieldArray name="fundraisingIncome">
                {({ fields }) =>
                  fields.map((name, index) => (
                    <FieldSingleRow key={index}>
                      <Field
                        name={name}
                        label={`${new Date().getFullYear() - index} fundraising income`}
                        component={MaskedMoneyTextInput}
                      />
                    </FieldSingleRow>
                  ))
                }
              </FieldArray>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Operational Expenses</FieldTitle>
              <FieldDefinition>
                Refers to how much an organization spends on operational expenses versus
                how much is afforded to the mission.
              </FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="operationalExpenses"
                  label="What percent of funds raised do you reserve for mission-related activities?"
                  component={FieldRatioInput}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Geographic Scope</FieldTitle>
              <FieldDefinition>
                An organization&apos;s geographic scope helps to determine its total
                potential impact and reach.
              </FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="geographicScope"
                  label="Where do you conduct your mission or fundraising? "
                  component={FiledMultiSelectWithCheckBoxes}
                  options={[
                    { value: 'Global', label: 'Global' },
                    { value: 'International', label: 'International' },
                    { value: 'National', label: 'National' },
                    { value: 'Regional', label: 'Regional' },
                    {
                      value: 'State/Province-wide',
                      label: 'State/Province-wide',
                    },
                  ]}
                  formatValue={formatValueEnum.BASE_ARRAY}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Corporate Partners</FieldTitle>
              <FieldDefinition>Refers to current, active partners.</FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="corporatePartners"
                  label="How many corporate sponsors do you have?"
                  component={FieldTextInput}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Corporate Partnership Strategy</FieldTitle>
              <FieldDefinition>
                Growth strategy is based on how defined and focused your organization’s
                plan is for future growth. A deliberate strategy allows you to align your
                trajectory with your mission and plot a clear path forward.
              </FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="corporatePartnershipStrategy"
                  label="Characterize your corporate sponsorship strategy"
                  component={FieldSingleSelect}
                  placeholder="Select"
                  options={[
                    { value: 'Non-existent', label: 'Non-existent' },
                    {
                      value: 'High-level vision but no tactics',
                      label: 'High-level vision but no tactics',
                    },
                    {
                      value: 'Strategic and tactical',
                      label: 'Strategic and tactical',
                    },
                    { value: "I don't know", label: "I don't know" },
                  ]}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Influencer Support</FieldTitle>
              <FieldDefinition>
                Influencers and celebrities refers to brand ambassadors, micro- or
                macro-influencers, local or national influencers, or other personalities
                who are champions for your organization and mission.
              </FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="influencerSupport"
                  label="Influencer Relationships"
                  component={FieldSingleSelect}
                  placeholder="Select"
                  options={[
                    { value: "We don't have any", label: "We don't have any" },
                    {
                      value: 'Name and Likeness',
                      label: 'Name and Likeness',
                    },
                    {
                      value: 'Personal Apperances',
                      label: 'Personal Apperances',
                    },
                    { value: 'Multiple touchpoints', label: 'Multiple touchpoints' },
                  ]}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Brand Exposure</FieldTitle>
              <FieldDefinition>
                The number of followers that all influencers have across social media
                platforms.
              </FieldDefinition>
              <FieldSingleRow>
                <Field
                  name="brandExposure"
                  label="Collective Influencer Reach"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Organization Size</FieldTitle>
              <FieldSingleRow>
                <Field
                  name="organizationSize"
                  label="Annual Total Contributions"
                  component={MaskedMoneyTextInput}
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Earned Media</FieldTitle>
              <FieldSingleRow>
                <Field
                  name="earnedMedia.totalEarnedMediaImpressionsInTheLast12Months"
                  label="Total Earned Media Impressions in the last 12 months."
                  component={MaskedMoneyTextInput}
                  hidePrefix
                />
              </FieldSingleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Paid Media</FieldTitle>
              <FieldDefinition>
                Paid media refers to external marketing efforts that involve a paid or
                donated placement. This includes paid search, print, radio, TV, digital
                advertising, and out-of-home advertising
              </FieldDefinition>
              <FieldDoubleRow>
                <Field
                  name="paidMedia.totalPrintPublicationImpressions"
                  label="Total Print Publication Impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
                <Field
                  name="paidMedia.totalSpentOnPrintMedia"
                  label="Total Spent on Print Media"
                  component={MaskedMoneyTextInput}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="paidMedia.totalDigitalImpressions"
                  label="Total Digital Impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
                <Field
                  name="paidMedia.totalSpentOnDigitalMedia"
                  label="Total Spent on Digital Media"
                  component={MaskedMoneyTextInput}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="paidMedia.totalTelevisionImpressions"
                  label="Total Television Impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
                <Field
                  name="paidMedia.totalSpentOnRadio"
                  label="Total Spent on Radio"
                  component={MaskedMoneyTextInput}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="paidMedia.totalRadioImpressions"
                  label="Total Radio Impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
                <Field
                  name="paidMedia.totalSpentOnTelevision"
                  label="Total Spent on Television"
                  component={MaskedMoneyTextInput}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="paidMedia.totalOutOfHomeImpressions"
                  label="Total Out-of-Home Impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
                <Field
                  name="paidMedia.totalSpentOnOutOfHome"
                  label="Total Spent on Out-of-Home"
                  component={MaskedMoneyTextInput}
                />
              </FieldDoubleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Owned Media</FieldTitle>
              <FieldDefinition>
                Owned media refers to communication channels within the organization’s
                control, such as websites, blogs, or e-communications.
              </FieldDefinition>
              <FieldDoubleRow>
                <Field
                  name="ownedMedia.howManyPeopleParticipateInYourEventOrVisitYourPropertyEachYear"
                  label="How many people participate in your event or visit your property each year?"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
                <Field
                  name="ownedMedia.totalWebsiteImpressionsInThePast12Months"
                  label="Total website impressions in the past 12 months"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="ownedMedia.areSponsorLogosPlacedAboveTheFoldOnYourWebsite"
                  label="Are sponsor logos placed above the fold on your website?"
                  component={FieldSingleSelect}
                  placeholder="Select"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                />
                <Field
                  name="ownedMedia.dosponsorLogosOnYourWebsiteLinkToThePartnerSSite"
                  label="Do sponsor logos on your website link to the partner's site?"
                  component={FieldSingleSelect}
                  placeholder="Select"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="ownedMedia.doesYourEventHaveVideoContentThatIsSponsorable"
                  label="Does your event have video content that is sponsorable?"
                  component={FieldSingleSelect}
                  placeholder="Select"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                />
                <Field
                  name="ownedMedia.whatIsYourOverallEmailOpenRate"
                  label="What is your overall email open rate? "
                  component={FieldRatioInput}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="ownedMedia.totalECommunicationsImpressionsOfECommsSentOfEmailAddressesOnDistributionList"
                  label="Total e-communications impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
                <Field
                  name="ownedMedia.totalDigitalPublicationImpressions"
                  label="Total digital publication impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
              </FieldDoubleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Total Pieces of Event Collateral</FieldTitle>
              <FieldDoubleRow>
                <Field
                  name="totalPiecesOfEventCollateral.eventBrochureFlyer"
                  label="Event Brochure/Flyer"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
                <Field
                  name="totalPiecesOfEventCollateral.participantBibs"
                  label="Participant Bibs"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="totalPiecesOfEventCollateral.eventInfoPackets"
                  label="Event Info Packets"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
                <Field
                  name="totalPiecesOfEventCollateral.eventSignage"
                  label="Event Signage"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
              </FieldDoubleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Live Partner Activation</FieldTitle>
              <FieldDoubleRow>
                <Field
                  name="livePartnerActivation.swagBagSampling"
                  label="Swag Bag Sampling"
                  component={FieldSingleSelect}
                  placeholder="Select"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                />
                <Field
                  name="livePartnerActivation.booth10x10"
                  label="10x10 Booth"
                  component={FieldSingleSelect}
                  placeholder="Select"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="livePartnerActivation.signatureExperience"
                  label="Signature Experience"
                  component={FieldSingleSelect}
                  placeholder="Select"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                />
                <Field
                  name="livePartnerActivation.liveAnnoucements"
                  label="Live Annoucements"
                  component={FieldSingleSelect}
                  placeholder="Select"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                />
              </FieldDoubleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>Shared</FieldTitle>
              <FieldDoubleRow>
                <Field
                  name="shared.howManyFacebookFollowersPageLikesDoYouHave"
                  label="How many Facebook followers / page likes do you have?"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
                <Field
                  name="shared.howManyTwitterFollowersDoYouHave"
                  label="How many Twitter followers do you have?"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="shared.howManyYouTubeSubscribersDoYouHave"
                  label="How many YouTube subscribers do you have?"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
                <Field
                  name="shared.howManyInstagramFollowersDoYouHave"
                  label="How many Instagram followers do you have?"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="shared.howManySnapchatFollowersDoYouHave"
                  label="How many Snapchat followers do you have?"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
                <Field
                  name="shared.howManyPinterestFollowersDoYouHave"
                  label="How many Pinterest followers do you have?"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
              </FieldDoubleRow>
            </FieldSection>
            <FieldSection>
              <FieldTitle>
                Custom Assets
                <AddBtn
                  onClick={() => formPush('customAssets', undefined)}
                  disabled={values?.customAssets?.length === CustomAssetsOptions.length}
                >
                  <Icon src={PlusIcon} />
                  Add
                </AddBtn>
              </FieldTitle>

              <FieldArray name="customAssets">
                {({ fields }) =>
                  fields.map((name, index) => (
                    <FieldDoubleRow key={index}>
                      <Field
                        name={`${name}.name`}
                        label="Name"
                        component={FieldSingleSelect}
                        placeholder="Select"
                        options={CustomAssetsOptions}
                      />
                      <Field
                        name={`${name}.totalNumberOfImpressions`}
                        label="Total number of impressions"
                        component={FieldTextInput}
                        validate={integerValidator}
                      />
                      <DeleteIcon src={CloseIcon} onClick={() => fields.remove(index)} />
                    </FieldDoubleRow>
                  ))
                }
              </FieldArray>
              {!values?.customAssets?.length && <Whitespace />}
            </FieldSection>
            <BtnWrapper>
              <div>
                <Btn
                  modifiers="secondary"
                  title="Cancel"
                  handleClick={() => push(`/pricing/view/${params.teamId}`)}
                />
              </div>
              <Btn modifiers="primary" title="Save" handleClick={handleSubmit} />
            </BtnWrapper>
          </Body>
        );
      }}
    />
  );
};

const Body = styled.div`
  max-width: 1096px;
  background-color: #fff;
  padding: 16px 40px 40px;
  border-radius: 6px;
`;

const FieldSection = styled.section`
  margin-top: 24px;
`;

const FieldTitle = styled.h3`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 145%;
  font-weight: 500;
`;
const FieldDefinition = styled.p`
  max-width: 580px;
  margin-top: 12px;
  font-size: 12px;
  line-height: 150%;
`;

const FieldSingleRow = styled.div`
  margin-top: 12px;
  width: 496px;
`;

const FieldDoubleRow = styled.div`
  display: flex;
  margin-top: 12px;

  & > div {
    width: 378px;

    :first-child {
      margin-right: 60px;
    }
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  width: 146px;
  margin-right: 8px;
  padding: 10px 0;
  font-size: 16px;
`;

const AddBtn = styled.button`
  border-radius: 6px;
  border: 1px solid #2baee0;
  transition: all 0.2s ease;
  padding: 8px 0;
  width: 82px;
  color: var(--black);
  font-size: 12px;
  line-height: 150%;
  margin-left: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #ebf9ff;
    color: #2baee0;
  }

  &:active {
    background: #caf0ff;
    color: #2baee0;
  }

  &:disabled {
    background: #ceedf9;
    border-color: rgba(43, 174, 224, 0.3);
    opacity: 0.5;
    color: #2baee0;
    cursor: not-allowed;
  }
`;

const Icon = styled(ReactSVG)`
  margin-right: 12px;
`;

const Whitespace = styled.div`
  height: 18px;
`;

const DeleteIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-top: 30px;
  margin-left: 12px;
  cursor: pointer;

  &:hover {
    border: 1px solid var(--blue);
    border-radius: 4px;
  }
`;

export default ValuationForm;
