import {
  ROIData,
  ROIListRes,
  ROIMeltwaterData,
  ROIReport,
  ROITemplate,
} from 'interfaces/roi';
import * as types from './types';

export const setROIList = (roiList: ROIListRes) =>
  ({
    type: types.SET_ROI_LIST,
    payload: roiList,
  } as const);

export const createROI = (roi: ROIData) =>
  ({
    type: types.CREATE_ROI,
    payload: roi,
  } as const);

export const deleteROI = (ids: string[]) =>
  ({ type: types.DELETE_ROI, payload: ids } as const);

export const setROI = (roi: ROIData) =>
  ({
    type: types.SET_ROI,
    payload: roi,
  } as const);

export const setROITemplates = (templates: ROITemplate[]) =>
  ({
    type: types.SET_ROI_TEMPLATES,
    payload: templates,
  } as const);

export const setROITemplatePreview = (preview: string) =>
  ({
    type: types.SET_ROI_TEMPLATE_PREVIEW,
    payload: preview,
  } as const);

export const setROITemplatesItem = (template: ROITemplate) =>
  ({
    type: types.SET_ROI_TEMPLATES_ITEM,
    payload: template,
  } as const);

export const toggleROITemplatesDisable = (id: string) =>
  ({
    type: types.TOGGLE_ROI_TEMPLATES_DISABLE,
    payload: id,
  } as const);

export const setRoiReport = (report: ROIReport) =>
  ({
    type: types.SET_ROI_REPORT,
    payload: report,
  } as const);

export const setROIMeltwaterData = (data: ROIMeltwaterData) =>
  ({
    type: types.SET_ROI_MELTWATER_DATA,
    payload: data,
  } as const);
