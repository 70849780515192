import React from 'react';
import { UIRadioBtnProps } from './interfaces';
import styled from 'styled-components';

function UIRadioBtn({
  label,
  handleCheck,
  isChecked = false,
  disabled,
  className,
}: UIRadioBtnProps) {
  return (
    <Wrapper className={className} onClick={() => !disabled && handleCheck()}>
      <Btn isChecked={isChecked} disabled={disabled} />
      <Label disabled={disabled}>{label}</Label>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const Btn = styled.div<{ isChecked?: boolean; disabled?: boolean }>`
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid var(--gray);
  box-sizing: border-box;
  border-radius: 100%;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;

  &:after {
    content: '';
    display: flex;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 100%;
  }

  

  ${({ isChecked }) =>
    isChecked &&
    `
    border-color: var(--blue);
    &:after {
      background-color: var(--blue);
    }
  `}

  ${({ disabled }) =>
    disabled
      ? `cursor: not-allowed;`
      : `
      &:hover {
        border-color: var(--blue);
        &:after {
          background-color: var(--blue);
        }
      }
  `}

  ${({ isChecked, disabled }) =>
    disabled &&
    isChecked &&
    `
    border-color: #E8E8E8;
    &:after {
      background-color: #E8E8E8;
    }
    `}
`;

const Label = styled.p<{ disabled?: boolean }>`
  font-size: 16px;
  line-height: 155%;
  cursor: pointer;

  ${({ disabled }) => disabled && `color: var(--gray); cursor: not-allowed;`}
`;

export default UIRadioBtn;
