import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

interface Props {
  src: string;
  color?: string;
  width?: number;
  height?: number;
  className?: string;
}

const UIIconSvg = (props: Props) => <Svg className={props.className} {...props} />;

const Svg = styled(ReactSVG)<Props>`
  svg {
    width: ${({ width }) => `${width || 24}px`};
    height: ${({ height }) => `${height || 24}px`};
  }
  path {
    stroke: ${({ color }) => color};
  }
`;

export default UIIconSvg;
