import { CompaniesData } from 'interfaces/companies';
import { ActionType, createReducer } from 'typesafe-actions';

import * as types from './types';
import * as actions from './actions';

type RootAction = ActionType<typeof actions>;

export default createReducer<CompaniesData, RootAction>({
  companies: {
    items: [],
    links: {},
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
  contacts: [],
  companyNews: [],
  suggestCompanies: {
    items: [],
    links: {},
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
  companyMembers: [],
  subscription: undefined,
  socialImpactLog: [],
  searchCompaniesInfo: {
    location: [],
    revenue: [],
  },
  searchJobTitles: [],
})
  .handleType([types.SET_COMPANIES], (state, action) => ({
    ...state,
    companies: action.payload,
  }))
  .handleType([types.SET_COMPANY_SELECT], (state, action) => ({
    ...state,
    searchCompaniesInfo: action.payload,
  }))
  .handleType([types.SET_SUGGEST_COMPANIES], (state, action) => ({
    ...state,
    suggestCompanies: action.payload,
  }))
  .handleType([types.SET_COMPANY_NEWS], (state, action) => ({
    ...state,
    companyNews: action.payload,
  }))
  .handleType([types.SET_CONTACTS], (state, action) => ({
    ...state,
    contacts: action.payload,
  }))
  .handleType([types.SET_JOBTITLE], (state, action) => ({
    ...state,
    searchJobTitles: action.payload,
  }))

  .handleType([types.REMOVE_COMPANY], (state, action) => {
    const companyId = action.payload;
    if (state.companies.items.find(({ id }) => id === companyId))
      return {
        ...state,
        companies: {
          ...state.companies,
          items: state.companies.items.filter(({ id }) => id !== companyId),
        },
      };

    return {
      ...state,
      companies: {
        ...state.companies,
      },
    };
  })
  .handleType([types.SET_COMPANY], (state, action) => {
    const company = action.payload;
    if (state.companies.items.find(({ id }) => id === company.id))
      return {
        ...state,
        companies: {
          ...state.companies,
          items: state.companies.items.map(item => {
            if (item.id !== company.id) return item;

            return {
              ...item,
              ...company,
            };
          }),
        },
      };

    return {
      ...state,
      companies: {
        ...state.companies,
        items: [company, ...state.companies.items],
      },
    };
  })
  .handleType([types.SET_CONTACT], (state, action) => {
    const contact = action.payload;
    if (state.contacts.find(({ id }) => id === contact.id))
      return {
        ...state,
        contacts: state.contacts.map(item => {
          if (item.id !== contact.id) return item;

          return {
            ...item,
            ...contact,
          };
        }),
      };

    return {
      ...state,
      contacts: [contact, ...state.contacts],
    };
  })
  .handleType([types.DELETE_CONTACT], (state, action) => ({
    ...state,
    contacts: state.contacts.filter(item => item.id !== action.payload),
  }))
  .handleType(types.GET_COMPANY_MEMBERS, (state, action) => {
    return {
      ...state,
      companyMembers: action.payload,
    };
  })
  .handleType([types.DELETE_COMPANY_MEMBERS], (state, action) => {
    return {
      ...state,
      companyMembers: state.companyMembers.filter(item => item.id !== action.payload),
    };
  })
  .handleType([types.SET_COMPANY_SUBSCRIPTION], (state, action) => ({
    ...state,
    subscription: action.payload,
  }))
  .handleType([types.SET_COMPANY_MEMBER], (state, action) => ({
    ...state,
    companyMembers: state.companyMembers.map(item =>
      item.id === action.payload.id ? action.payload : item,
    ),
  }))
  .handleType([types.SET_COMPANY_MEMBER_ISCANVA], (state, action) => ({
    ...state,
    companyMembers: state.companyMembers.map(item => {
      if (item.id === action.payload.userId)
        return { ...item, isCanva: action.payload.isCanva };
      return item;
    }),
  }))
  .handleType([types.SET_COMPANY_SOCIAL_IMPACT_LOG], (state, action) => ({
    ...state,
    socialImpactLog: action.payload,
  }));
