import { AudienceStoreData } from 'interfaces/audience';
import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as types from './types';

type RootAction = ActionType<typeof actions>;

export default createReducer<AudienceStoreData, RootAction>({
  audienceList: {
    items: [],
    meta: {
      currentPage: '0',
      itemCount: 0,
      itemsPerPage: '0',
      totalItems: 0,
      totalPages: 0,
    },
  },
}).handleType([types.SET_AUDIENCE_LIST], (state, action) => ({
  ...state,
  audienceList: action.payload,
}));
