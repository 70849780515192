import React from 'react';
import styled from 'styled-components';

import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { Field, Form } from 'react-final-form';
import { FieldInviteInput } from 'components/FormFields';
import Toast from 'services/Toast';

interface Props extends InjectedProps {
  generateUrl: () => string;
}

const ShareReportModal = ({ onClose, generateUrl, ...modalProps }: Props) => {
  useLockBodyScroll();

  return (
    <UIModal
      hideCloseIcon
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{
        padding: '40px 46px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      style={{
        width: '100%',
        maxWidth: '1067px',
        height: '456px',
        overflow: 'hidden',
      }}
    >
      <Title>Share Valuation Report</Title>
      <Body>
        <Form
          initialValues={{
            link: generateUrl(),
          }}
          onSubmit={() => {}}
          render={({ values }) => (
            <>
              <ShareWrapper>
                <Field
                  disabled
                  name="link"
                  component={FieldInviteInput}
                  fetchInviteLinkOperation={() => async () => generateUrl()}
                />
                <Btn
                  modifiers="primary"
                  title="Copy"
                  handleClick={() =>
                    navigator.clipboard
                      .writeText(values.link)
                      .then(() => Toast.success('Copy successful'))
                  }
                />
              </ShareWrapper>
              <CloseBtn title="Close" modifiers="secondary" handleClick={onClose} />
            </>
          )}
        />
      </Body>
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 28px;
  text-align: center;
`;

const Body = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

const Btn = styled(UIButton)`
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 0 6px 6px 0;
  white-space: nowrap;
  min-width: 114px;
`;

const ShareWrapper = styled.div`
  display: flex;
  position: relative;
  top: -40px;
`;

const CloseBtn = styled(UIButton)`
  position: absolute;
  right: 0;
  bottom: 0;
  padding-left: 24px;
  padding-right: 24px;
`;

export default ShareReportModal;
