/* eslint-disable react-hooks/exhaustive-deps */
import { InviteData } from 'components/Forms/interfaces';
import InviteForm from 'components/Forms/InviteForm';
import React, { useCallback } from 'react';
import styled from 'styled-components';

interface Props {
  onSubmit: (email: string) => void;
  fetchInviteLinkOperation: (
    teamId: string,
  ) => () => Promise<string | object | boolean | undefined>;
  inviteId: string;
}

const InviteUser = ({ onSubmit, fetchInviteLinkOperation, inviteId }: Props) => {
  const submit = useCallback((values: InviteData) => {
    return onSubmit(values.email);
  }, []);

  return (
    <Root>
      <InviteForm
        onSubmit={submit}
        fetchInviteLinkOperation={fetchInviteLinkOperation}
        inviteId={inviteId}
      />
    </Root>
  );
};

const Root = styled.div`
  background: #ffffff;
  border-radius: 4px;
  padding: 40px 24px;
  margin-bottom: 24px;
`;

export default InviteUser;
