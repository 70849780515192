import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { composeValidators } from 'utils';
import { required, webSiteLink, email, integerValidator } from 'utils/validation';
import History from 'services/History';
import { FieldTextInput, MaskedTextInput } from 'components/FormFields';
import { UIButton } from 'components/UI';
import { phoneNumberMaskedProps } from 'utils/formatters';

import { OnSubmitReturnType } from 'interfaces/finalForm';
import { Field } from 'components/FinalForm';
import { TeamContact } from 'interfaces/team';

export interface FormProps {
  onSubmit: (values: TeamContact) => OnSubmitReturnType;
  initialValues?: TeamContact | {};
  isLoading?: boolean;
}

const TeamContactForm = ({ onSubmit, initialValues }: FormProps) => {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }: FormRenderProps<TeamContact>) => {
        return (
          <form>
            <Body>
              <FieldSection marginBottom="8px">
                <TitleSection>Info</TitleSection>
                <Row>
                  <Field
                    name="firstName"
                    label="First Name"
                    component={FieldTextInput}
                    placeholder="Enter first Name"
                    validate={required}
                  />
                  <Field
                    name="lastName"
                    label="Last Name"
                    component={FieldTextInput}
                    placeholder="Enter last Name"
                    validate={required}
                  />
                </Row>

                <Row>
                  <Field
                    name="middleName"
                    label="Middle Name"
                    component={FieldTextInput}
                    placeholder="Enter middle Name"
                  />
                  <Field
                    name="jobTitle"
                    label="Job Title"
                    component={FieldTextInput}
                    placeholder="Enter job Title"
                    validate={required}
                  />
                </Row>

                <Row>
                  <Field
                    name="phone"
                    label="Phone"
                    mask={phoneNumberMaskedProps.mask}
                    component={MaskedTextInput}
                    placeholder="Enter phone"
                  />

                  <Field
                    name="email"
                    label="Email"
                    component={FieldTextInput}
                    placeholder="Enter email"
                    validate={composeValidators<string>(required, email)}
                  />
                </Row>
              </FieldSection>

              <Row>
                <Field
                  name="linkedinUrl"
                  label="Linked In"
                  component={FieldTextInput}
                  placeholder="Enter linked in url"
                  allowNull={true}
                  validate={webSiteLink}
                />
                <Field
                  name="contactAccuracyScore"
                  label="Score"
                  component={FieldTextInput}
                  placeholder="Enter score"
                  validate={integerValidator}
                />
              </Row>

              <BtnGroup>
                <UIButton
                  handleClick={() => History.back()}
                  modifiers="secondary"
                  title="Cancel"
                />
                <UIButton
                  title="Save"
                  modifiers="primary"
                  handleClick={handleSubmit}
                  type="submit"
                  disabled={submitting}
                  isLoading={submitting}
                />
              </BtnGroup>
            </Body>
          </form>
        );
      }}
    />
  );
};

const BtnGroup = styled.div`
  display: flex;
  margin-top: 40px;

  button:first-child {
    margin-right: 8px;
  }

  & button {
    width: 136px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: calc(50% - 8px);
  }
`;

const FieldSection = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '32px'};
`;

const TitleSection = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  margin-bottom: 24px;
`;

const Body = styled.div`
  background: #fff;
  padding: 40px;
  margin-bottom: 40px;
`;

export default TeamContactForm;
