export const typesOfInvestmentOptions = [
  {
    id: 'Grant',
    title: 'Grant',
  },
  {
    id: 'Employee Engagement',
    title: 'Employee Engagement',
  },
  {
    id: 'Cause Marketing (commercial co-venture)',
    title: 'Cause Marketing (commercial co-venture)',
  },
  {
    id: 'Sponsorship',
    title: 'Sponsorship',
  },
  {
    id: 'Social Enterprise',
    title: 'Social Enterprise',
  },
];
