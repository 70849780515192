import React from 'react';
import ShowMoreText from 'react-show-more-text';
import { moneyFormatter } from 'utils/formatters';
import { useHistory } from 'react-router';
import { parseLink } from 'utils';
import EmptyCompany from 'assets/images/icons/empty-company.svg';
import AddIcon from 'assets/images/icons/add-icon.svg';
import EyeSVG from 'assets/images/icons/eye.svg';
import styled from 'styled-components';

import {
  Root,
  Header,
  HeaderInfo,
  CompanyTitle,
  CompanyTitleWrapper,
  CompanyType,
  Body,
  Content,
  SectionTitle,
  SectionSubTitle,
  Description,
  ContactsWrapper,
  ContactsItem,
  ContactsLink,
  ContactsIcon,
  TickerWrapper,
  TickerItem,
  TickerTitle,
  ReportedWrapper,
  ReportedItem,
  ReportedRow,
  ReportedTitle,
  ReportedValue,
  DemographicsDesc,
  ImpactSection,
  // PartnershipLink,
  CharitablePartners,
  AlignmentList,
  AlignmentItem,
  ImpactRow,
  ImpactRowInner,
  ImpactValue,
  ImageEmpty,
  EditBtn,
  ContactsInner,
  DescriptionWrapper,
  EmptyAlignment,
  // EmployeeValue,
  ContactMoreLink,
  LoadImage,
} from './styled';
import {
  Company,
  TypesOfInvestment,
  News,
  SubscriptionCompany,
  SocialImpactLogItem,
} from 'interfaces/companies';
import CompaniesTabs from './CompaniesTabs';
import PrimaryInformation from './PrimaryInformation';
import SecondaryInformation from './SecondaryInformation';
import SocialMediaLinks from './SocialMediaLinks';
import CompanyContactsTable from './CompanyContactsTable';

import PhoneIcon from 'assets/images/icons/phone.svg';
import MapPinIcon from 'assets/images/icons/map-pin.svg';
import WebSiteIcon from 'assets/images/icons/website.svg';
import { goalAlignmentOptions } from 'constants/goalAlignment';
import { CRSFocus } from 'store/interfaces';
import { ReactSVG } from 'react-svg';
import CompanySideBar from './CompanySideBar';
import SubscriptionCompanyForm from 'components/SubscriptionCompanyForm';
import { NewsData } from 'interfaces/news';
import { format } from 'date-fns';
import { useModal } from 'hooks/common';
import SocialImpactDataLogModal from './SocialImpactDataLogModal';

export interface CompanyViewProps {
  company: Company;
  news: News[];
  subscription?: SubscriptionCompany;
  socialImpactLog: SocialImpactLogItem[];
  exportSocialImpactLogExcel: () => void;
  handleUpdateSubscription: (data: SubscriptionCompany) => void;
  handleDeleteSubscription: () => void;
  onNewsCreate: (param: Partial<NewsData>) => void;
  onNewsUpdate: (param: Partial<NewsData>) => void;
  onNewsDelete: (id: string) => void;
}

const CompanyView = ({
  company,
  news,
  subscription,
  socialImpactLog,
  exportSocialImpactLogExcel,
  handleUpdateSubscription,
  handleDeleteSubscription,
  onNewsCreate,
  onNewsUpdate,
  onNewsDelete,
}: CompanyViewProps) => {
  const { push } = useHistory();

  const {
    id,
    logo,
    name,
    descriptionList,
    productsBrandDescription,
    cashContributions,
    employeeContributions,
    annualContributions,
    inKindContributions,
    website,
    phone,
    street,
    city,
    state,
    country,
    zipCode,
    revenue,
    industries,
    ticker,
    sdgGoals,
    genders,
    income,
    age,
    ethnicity,
    affiliation,
    nonprofit,
    purchase,
    brands,
    interests,
    competitors,
    employeeCount,
    parentName,
    crsFocus,
    typesOfInvestment,
    // partnershipLink,
    // employeeEngagementOpportunities,
    charitablePartners,
    socialMediaUrls,
    contacts,
    seekingOpportunities,
    impactGoals,
    corporateFoundation,
    foundationName,
    foundationDescription,
    enrichUpdateDate,
  } = company;

  const linkWebSite = parseLink(website);
  // const linkPartnership = partnershipLink && parseLink(partnershipLink);

  const [showModal, hideModal] = useModal(() => {
    return (
      <SocialImpactDataLogModal
        socialImpactLog={socialImpactLog}
        exportExcel={exportSocialImpactLogExcel}
        onClose={hideModal}
      />
    );
  }, [socialImpactLog, exportSocialImpactLogExcel]);

  return (
    <Root>
      <Header>
        <HeaderInfo>
          {logo ? (
            <LoadImage imgKey={logo} />
          ) : (
            <ImageEmpty>
              <ReactSVG src={EmptyCompany} />
            </ImageEmpty>
          )}
          <div>
            <CompanyTitleWrapper>
              <CompanyTitle>{name}</CompanyTitle>
            </CompanyTitleWrapper>
            <CompanyType>{industries ? industries : 'No information'}</CompanyType>
            {socialMediaUrls && socialMediaUrls.length > 0 && (
              <SocialMediaLinks urls={socialMediaUrls} />
            )}
          </div>
        </HeaderInfo>
        <EditBtn
          handleClick={() => push(`/edit-company/${id}`)}
          modifiers="secondary"
          title="Edit"
        />
      </Header>

      <Body>
        <Content>
          <SectionTitle>Business Description Products</SectionTitle>

          {descriptionList && descriptionList.length > 0 && (
            <>
              <SectionSubTitle>Description</SectionSubTitle>

              <DescriptionWrapper>
                <ShowMoreText
                  lines={3}
                  more="see more"
                  less="see less"
                  className="descriptionSeeMore"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                >
                  {typeof descriptionList === 'string' ? (
                    <Description>{descriptionList}</Description>
                  ) : (
                    descriptionList.map((item, index) => (
                      <Description key={index}>{item.description}</Description>
                    ))
                  )}
                </ShowMoreText>
              </DescriptionWrapper>
            </>
          )}

          {productsBrandDescription && (
            <>
              <SectionSubTitle>Products & Brand Descriptions</SectionSubTitle>
              <DescriptionWrapper>
                <ShowMoreText
                  lines={3}
                  more="see more"
                  less="see less"
                  className="descriptionSeeMore"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                >
                  <Description>{productsBrandDescription}</Description>
                </ShowMoreText>
              </DescriptionWrapper>
            </>
          )}
          {((competitors && competitors.length > 0) || parentName) && (
            <CompaniesTabs competitors={competitors} parentName={parentName} />
          )}

          <SubscriptionCompanyForm
            initialValues={subscription}
            onSubmit={handleUpdateSubscription}
            handleRemoveSubscription={handleDeleteSubscription}
          />
          <SectionSubTitle>Reported</SectionSubTitle>
          <ReportedWrapper>
            <ReportedRow>
              <ReportedItem>
                <ReportedTitle>Revenue Reported</ReportedTitle>
                <ReportedValue>
                  {revenue
                    ? Number(revenue) === 1000000000
                      ? moneyFormatter.from(Number(1000000000), { symbol: '$ ' }) + '+'
                      : moneyFormatter.from(Number(revenue), { symbol: '$ ' })
                    : '-'}
                </ReportedValue>
              </ReportedItem>

              <ReportedItem>
                <ReportedTitle>Employees Reported</ReportedTitle>
                <ReportedValue>
                  {employeeCount
                    ? Number(employeeCount) === 10000
                      ? moneyFormatter.from(Number(10000), { symbol: '' }) + '+'
                      : moneyFormatter.from(Number(employeeCount), { symbol: '' })
                    : '-'}
                </ReportedValue>
              </ReportedItem>
            </ReportedRow>
          </ReportedWrapper>
          <SectionSubTitle>Company Ticker</SectionSubTitle>
          <TickerWrapper>
            <TickerItem>
              <TickerTitle>{ticker || 'No information'}</TickerTitle>
            </TickerItem>
          </TickerWrapper>

          <>
            <ContactsHeader>
              <SectionSubTitle>Contacts</SectionSubTitle>
              <LinkGroup>
                <IconWrapper>
                  <ReactSVG
                    src={AddIcon}
                    onClick={() => push(`/edit-contact-company/${id}`)}
                  />
                </IconWrapper>
                <ContactMoreLink to={`/company-contacts/${id}`}>see more</ContactMoreLink>
              </LinkGroup>
            </ContactsHeader>

            <CompanyContactsTable
              rows={contacts ? [...contacts].slice(0, 3) : []}
              companyId={id}
            />
          </>

          <SectionSubTitle>Company Contacts</SectionSubTitle>

          <ContactsWrapper>
            <ContactsInner>
              <ContactsItem>
                <ContactsIcon src={WebSiteIcon} />
                {website ? (
                  <ContactsLink href={linkWebSite} target="_blank">
                    {website}
                  </ContactsLink>
                ) : (
                  <p>No website</p>
                )}
              </ContactsItem>

              <ContactsItem>
                <ContactsIcon src={PhoneIcon} />

                {phone ? (
                  <ContactsLink href={`tel:${phone}`}>{phone}</ContactsLink>
                ) : (
                  <p>No phone number</p>
                )}
              </ContactsItem>
            </ContactsInner>
            <ContactsItem>
              <ContactsIcon src={MapPinIcon} />
              <p>
                {street && `${street} `}
                {city && `${city} `}
                {state && `${state} `}
                {country && `${country} `}
                {zipCode && `${zipCode}`}
                {!street && !city && !state && !country && !zipCode && 'No Address'}
              </p>
            </ContactsItem>
          </ContactsWrapper>
          <SectionTitle>Corporate Foundation</SectionTitle>
          <SectionSubTitle>Corporate Foundation</SectionSubTitle>
          <DescriptionWrapper>
            {corporateFoundation ? foundationName || 'N/A' : 'N/A'}
          </DescriptionWrapper>
          {corporateFoundation && (
            <>
              <SectionSubTitle>Description</SectionSubTitle>
              <DescriptionWrapper>
                <Description>{foundationDescription}</Description>
              </DescriptionWrapper>
            </>
          )}

          <SectionTitle>
            Social Impact
            {enrichUpdateDate && (
              <EnrichUpdateDate>
                <ShowSocialImpactIcon src={EyeSVG} onClick={showModal} />
                Updated on {format(new Date(enrichUpdateDate), 'MMM dd, yyyy')}
              </EnrichUpdateDate>
            )}
          </SectionTitle>

          <ImpactRow>
            <ImpactRowInner>
              <SectionSubTitle>Type of Investment</SectionSubTitle>

              <ImpactSection>
                {typesOfInvestment && typesOfInvestment.length > 0 ? (
                  typesOfInvestment.map(({ id, title }: TypesOfInvestment) => (
                    <ImpactValue key={id}>{title}</ImpactValue>
                  ))
                ) : (
                  <ImpactValue>No Information</ImpactValue>
                )}
              </ImpactSection>
            </ImpactRowInner>

            <ImpactRowInner>
              <SectionSubTitle>CSR Focus</SectionSubTitle>

              <ImpactSection>
                {crsFocus && crsFocus.length > 0 ? (
                  crsFocus.map(({ id, name }: CRSFocus) => (
                    <ImpactValue key={id}>{name}</ImpactValue>
                  ))
                ) : (
                  <ImpactValue>No Information</ImpactValue>
                )}
              </ImpactSection>
            </ImpactRowInner>
          </ImpactRow>

          {seekingOpportunities && seekingOpportunities.length > 0 && (
            <>
              <SectionSubTitle>Seeking Opportunities</SectionSubTitle>

              <DescriptionWrapper>
                <ShowMoreText
                  lines={3}
                  more="see more"
                  less="see less"
                  className="descriptionSeeMore"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                >
                  <Description>{seekingOpportunities[0].text}</Description>
                </ShowMoreText>
              </DescriptionWrapper>
            </>
          )}

          {impactGoals && impactGoals.length > 0 && (
            <>
              <SectionSubTitle>Impact Goals</SectionSubTitle>

              <DescriptionWrapper>
                <ShowMoreText
                  lines={3}
                  more="see more"
                  less="see less"
                  className="descriptionSeeMore"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                >
                  <Description>{impactGoals[0].text}</Description>
                </ShowMoreText>
              </DescriptionWrapper>
            </>
          )}

          <SectionTitle>SDG Goal Alignment</SectionTitle>

          {sdgGoals && sdgGoals.length > 0 ? (
            <AlignmentList>
              {[...sdgGoals]
                .map(item => goalAlignmentOptions[item])
                .filter(item => item)
                .sort((a, b) => a.id - b.id)
                .map(item => (
                  <AlignmentItem key={item.id} src={item.img} alt={item.title} />
                ))}
            </AlignmentList>
          ) : (
            <EmptyAlignment>No selected goal</EmptyAlignment>
          )}

          <SectionSubTitle>Contributions</SectionSubTitle>
          <ReportedWrapper>
            <ReportedRow>
              <ReportedItem>
                <ReportedTitle>Cash Contributions</ReportedTitle>
                <ReportedValue>
                  {cashContributions
                    ? moneyFormatter.from(Number(cashContributions), { symbol: '$ ' })
                    : '-'}
                </ReportedValue>
              </ReportedItem>

              <ReportedItem>
                <ReportedTitle>Employee Contributions</ReportedTitle>
                <ReportedValue>
                  {employeeContributions
                    ? moneyFormatter.from(Number(employeeContributions), { symbol: '$ ' })
                    : '-'}
                </ReportedValue>
              </ReportedItem>
            </ReportedRow>
            <ReportedRow>
              <ReportedItem>
                <ReportedTitle>Total Social Contributions</ReportedTitle>
                <ReportedValue>
                  {annualContributions
                    ? moneyFormatter.from(Number(annualContributions), { symbol: '$ ' })
                    : '-'}
                </ReportedValue>
              </ReportedItem>

              <ReportedItem>
                <ReportedTitle>In-Kind Contributions</ReportedTitle>
                <ReportedValue>
                  {inKindContributions
                    ? moneyFormatter.from(Number(inKindContributions), { symbol: '$ ' })
                    : '-'}
                </ReportedValue>
              </ReportedItem>
            </ReportedRow>
          </ReportedWrapper>

          {charitablePartners && charitablePartners.length > 0 && (
            <>
              <SectionSubTitle>Charitable partners</SectionSubTitle>

              <CharitablePartners>
                {charitablePartners.map((partner, index) => (
                  <ImpactValue key={index}>{partner}</ImpactValue>
                ))}
              </CharitablePartners>
            </>
          )}

          {/* <SectionSubTitle>Charitable Focus & Programs</SectionSubTitle>
          {linkPartnership ? (
            <PartnershipLink
              href={linkPartnership}
              target="_blank"
            >{`Go to the company's website`}</PartnershipLink>
          ) : (
            <EmployeeValue>No information</EmployeeValue>
          )} */}

          {/* <SectionTitle>GRI Compliant</SectionTitle>
          <EmployeeValue>{employeeEngagementOpportunities ? 'Yes' : 'No'}</EmployeeValue> */}

          <SectionTitle>Customer Demographics</SectionTitle>

          {(genders ||
            income ||
            age ||
            ethnicity ||
            affiliation ||
            nonprofit ||
            purchase ||
            brands ||
            interests) && (
            <>
              <DemographicsDesc>
                Consumer data is representative of this brand’s consumers that follow them
                on social media. The rankings and lists represent the highest indexing (or
                most above average) attributes and preferences of this dataset.
              </DemographicsDesc>

              {(genders || income || age || ethnicity) && (
                <>
                  <SectionSubTitle>Primary Information</SectionSubTitle>
                  <PrimaryInformation
                    genders={genders}
                    income={income}
                    age={age}
                    ethnicity={ethnicity}
                  />
                </>
              )}

              {(affiliation || nonprofit || purchase || brands || interests) && (
                <>
                  <SectionSubTitle>Secondary Information</SectionSubTitle>
                  <SecondaryInformation
                    affiliation={affiliation}
                    nonprofit={nonprofit}
                    purchase={purchase}
                    brands={brands}
                    interests={interests}
                  />
                </>
              )}
            </>
          )}
        </Content>
        <CompanySideBar
          companyId={company.id}
          news={news}
          onNewsCreate={onNewsCreate}
          onNewsUpdate={onNewsUpdate}
          onNewsDelete={onNewsDelete}
        />
      </Body>
    </Root>
  );
};

const ContactsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconWrapper = styled.div`
  cursor: pointer;

  & svg {
    path {
      stroke: var(--blue);
    }
  }
`;

const LinkGroup = styled.div`
  display: flex;
  align-items: center;

  & > div:nth-child(1) {
    margin-right: 12px;
  }
`;

export const EnrichUpdateDate = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: #737373;
  display: flex;
  align-items: center;
`;

export const ShowSocialImpactIcon = styled(ReactSVG)`
  margin-right: 8px;
  cursor: pointer;

  & path {
    stroke: var(--blue);
  }
`;

export default CompanyView;
