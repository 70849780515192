import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { CircleChartDataProps } from 'interfaces/common';

import styled from 'styled-components';

const COLORS = ['#112764', '#0E47B1', '#1D7CF7', '#A4D7FE'];

interface CircleChartProps {
  className?: string;
  data: CircleChartDataProps[];
}

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CircleChart = ({ className, data }: CircleChartProps) => {
  return (
    <Root className={className}>
      <PieChart width={408} height={408}>
        <Pie
          data={data}
          cx={204}
          cy={204}
          innerRadius={80}
          outerRadius={150}
          label={renderCustomizedLabel}
          fill="#54A4FA"
          dataKey="value"
        >
          {data.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />;
          })}
        </Pie>
      </PieChart>

      <Footer>
        {data.map((item, index) => (
          <FooterItem key={index} style={{ background: COLORS[index % COLORS.length] }}>
            <p>{item.name}</p>
            <FooterValue>{`${item.value.toFixed(0)}%`}</FooterValue>
          </FooterItem>
        ))}
      </Footer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Footer = styled.div`
  background: #f7fcff;
  border-top: 1px solid #e8e8e8;
  padding: 20px 40px;
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const FooterItem = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 150%;
  border-radius: 19px;
  color: #fff;
  padding: 6px 20px;
  margin: 0 6px 8px 0;
`;

const FooterValue = styled.p`
  font-weight: 500;
  margin-left: 5px;
`;

export default CircleChart;
