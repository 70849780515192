import React, { useCallback, useEffect, useState } from 'react';
import { MainWrapper } from 'layouts';
import { debounce } from 'lodash';
import ProfileForm from '../../../components/Forms/ProfileForm';
import { UISpinner } from 'components/UI';
import { useSelector } from 'react-redux';
import { useOperation } from 'hooks/common';
import { useHistory, useParams } from 'react-router';
import { TeamData, TeamDataForm } from 'interfaces/team';
import { Company } from 'interfaces/companies';
import {
  getImpactGenome,
  getPayPalGivingFundCanUpdate,
  getPayPalGivingFundIfConfirm,
  getProgramFocusOptions,
  getTeamById,
} from 'store/ducks/team/selectors';
import {
  fetchTeam,
  fetchTeamUpdate,
  fetchTeamLogoUpload,
  fetchTeamCreate,
  fetchPayPalGivingFundCanUpdate,
  fetchPayPalGivingFundIfConfirm,
  fetchImpactGenome,
} from 'store/ducks/team/operations';
import { fetchCompaniesSearch } from 'store/ducks/companies/operations';
import { getCompanies } from 'store/ducks/companies/selectors';
import { selectAdditionalInfo } from 'store/ducks/user/selectors';
import styled from 'styled-components';
import { StateProps } from 'store/interfaces';
import { AdditionalInfo } from 'interfaces/auth';
import { SelectOption } from 'components/UI/interfaces';
import Jumbotron from 'components/Jumbotron';
import History from 'services/History';
import { getEinSuggest } from 'store/ducks/campaign/selectors';
import { fetchChangePpgf, fetchEinList } from 'store/ducks/campaign/operations';
import Toast from 'services/Toast';

const TeamEdit = () => {
  const [onfetchTeam] = useOperation(fetchTeam);
  const [isLoading, setIsLoading] = useState(false);
  const [onUpdateTeam] = useOperation(fetchTeamUpdate);
  const [onCreateTeam] = useOperation(fetchTeamCreate);
  const [onTeamLogoUpload] = useOperation(fetchTeamLogoUpload);
  const [onSearchCompanies] = useOperation(fetchCompaniesSearch);
  const [onSearchEin] = useOperation(fetchEinList);
  const [onFetchPayPalGivingFundCanUpdate] = useOperation(fetchPayPalGivingFundCanUpdate);
  const [onFetchPayPalGivingFundIfConfirm] = useOperation(fetchPayPalGivingFundIfConfirm);
  const [onFetchChangePpgf] = useOperation(fetchChangePpgf);
  const [onFetchImpactGenome] = useOperation(fetchImpactGenome);

  const params: { teamId: string } = useParams();
  const { push } = useHistory();
  const team = useSelector<StateProps, TeamData | undefined>(getTeamById(params.teamId));
  const payPalGivingFundCanUpdate = useSelector(getPayPalGivingFundCanUpdate);
  const payPalGivingFundIfConfirm = useSelector(getPayPalGivingFundIfConfirm);
  const einSearchList = useSelector(getEinSuggest);
  const programFocusOptions = useSelector(getProgramFocusOptions);
  const impactGenome = useSelector(getImpactGenome);

  useEffect(() => {
    if (params.teamId) {
      onfetchTeam(params.teamId);
      onFetchPayPalGivingFundCanUpdate(params.teamId);
      onFetchPayPalGivingFundIfConfirm(params.teamId);
      onFetchImpactGenome(undefined);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.teamId]);

  const additionalInfo = useSelector<StateProps, AdditionalInfo>(selectAdditionalInfo);

  const companiesSearchList = useSelector<StateProps, Company[]>(getCompanies);
  const handleUpdateTeam = useCallback(
    (values: TeamDataForm) => {
      if (!values?.paypalGivingFundId && values?.paypalGivingFundName) {
        // values.paypalGivingFundName = '';
        Toast.error(
          'Please select a PayPal Giving Fund account from the drop-down list.',
        );
        return;
      }
      const data = {
        ...values,
        charitablePartners: (values.charitablePartners || []).map(partner => partner.id),
        teamPartnerCompanies: values.teamPartnerCompanies.map(({ id, name }) => ({
          companyId: id,
          companyName: name,
        })),
      };
      if (params.teamId) {
        return onUpdateTeam({ id: params.teamId, data: data }).then(() => {
          if (payPalGivingFundIfConfirm && values.paypalGivingFundId)
            onFetchChangePpgf({
              paypalGivingFundId: values.paypalGivingFundId,
              teamId: params.teamId,
            });
          push(`/team/view/${params.teamId}`);
        });
      } else {
        return onCreateTeam(data).then(x => {
          History.back();
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [payPalGivingFundIfConfirm, team, params.teamId],
  );
  const onUploadDocument = useCallback(async (file: File) => {
    return onTeamLogoUpload({ file, teamId: params.teamId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchCompanies = (val: string) => {
    return onSearchCompanies({ q: val, page: 1, limit: 12 });
  };

  const handleSearchCompaniesDebounce = useCallback(
    debounce(handleSearchCompanies, 800),
    [],
  );

  const handleSearchList = (val: string) => {
    return onSearchEin(val);
  };

  const handleSearchListDebounce = useCallback(debounce(handleSearchList, 800), []);

  return (
    <MainWrapper withOutNav header={<Jumbotron name="Organization Profile" back />}>
      {isLoading ? (
        <Spinner />
      ) : (
        <ProfileForm
          initialValues={team}
          onSubmit={handleUpdateTeam}
          handleGetCompanies={handleSearchCompaniesDebounce}
          companiesSearchList={companiesSearchList}
          missions={additionalInfo.missions.map(
            (item: any) =>
              ({
                value: item.Id,
                label: item.Mission,
              } as SelectOption),
          )}
          handleLogoUpload={onUploadDocument}
          payPalGivingFundCanUpdate={payPalGivingFundCanUpdate}
          handleSearchFund={handleSearchListDebounce}
          einSearchList={einSearchList}
          programFocusOptions={programFocusOptions}
          impactGenome={impactGenome}
        />
      )}
    </MainWrapper>
  );
};

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 242px);
`;

export default TeamEdit;
