import { Field } from 'components/FinalForm';
import { FieldSingleSelect, FieldSuggest } from 'components/FormFields';
import FieldSection from 'components/FormFields/FieldSection';
import { FormContactsSectionProps } from 'components/Forms/interfaces';
import { Body } from 'components/ProfileView/styled';
import { UISuggestOption } from 'components/UI/interfaces';
import { filterSectionTexts } from 'constants/search';
import React, { useCallback } from 'react';
import { Form, FormProps, FormRenderProps } from 'react-final-form';
import styled from 'styled-components';

const CompanyJobSection = ({
  handleGetJobTitleSuggestions,
  jobTitlesSearchList,
  getValue,
  initVal,
}: FormContactsSectionProps) => {
  const onChangeValue = value => {
    getValue(value);
  };
  return (
    <Form
      onSubmit={values => {
        console.log('form value:', values);
      }}
      render={({ handleSubmit, submitting }: FormRenderProps<any>) => {
        return (
          <div>
            <Row>
              <Field
                name="jobTitle"
                component={FieldSuggest}
                handleGetSuggestions={handleGetJobTitleSuggestions}
                placeholder="Job Title"
                list={jobTitlesSearchList}
                onChangeValue={onChangeValue}
                initVal={initVal}
              />
            </Row>
          </div>
        );
      }}
    />
  );
};

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    width: calc(100%);
  }
`;

export default CompanyJobSection;
