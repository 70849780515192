import { UIIconSVG } from 'components/UI';
import { formatISO, isValid, parse, parseISO } from 'date-fns';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import '../FieldDatePicker/style.css';
import CalendarSVG from 'assets/images/icons/calendar.svg';
import { FormError, FormLabel } from 'components/UI/form';

interface Props extends FieldRenderProps<string> {
  disabled?: boolean;
}

const FieldDatePicker = ({ input, label, meta, ...restProps }: Props) => {
  const { error, submitError, touched, dirtySinceLastSubmit } = meta;
  const isError = (error && touched) || (submitError && !dirtySinceLastSubmit);

  return (
    <Field>
      {label && <FormLabel>{label}</FormLabel>}
      <div style={{ width: '100%' }}>
        <UIDatePicker {...restProps} error={isError} input={input} meta={meta} />
      </div>
      {isError && <Error meta={meta} />}
    </Field>
  );
};

const UIDatePicker = ({ input, disabled = false, error, ...restProps }: Props) => {
  let date = parseISO(input.value);
  if (isValid(date) === false) {
    date = parse(input.value, 'dd MMM yyyy', new Date());
  }

  return (
    <DatePickerWrapper disabled={disabled} error={error}>
      <Icon src={CalendarSVG} color="var(--darkGray)" />
      <DatePicker
        selected={isValid(date) ? date : null} // needs to be checked if it is valid date
        disabledKeyboardNavigation
        dateFormat="dd MMM yyyy"
        className="datePickerInput"
        onChange={date => {
          // On Change, you should use final-form Field Input prop to change the value
          if (isValid(date)) {
            input.onChange(formatISO(new Date(date)));
            //input.onChange(format(new Date(date), "dd-MM-yyyy"));
          } else {
            input.onChange(null);
          }
        }}
        onBlur={input.onBlur}
        disabled={disabled}
        {...restProps}
      />
    </DatePickerWrapper>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const Error = styled(FormError)`
  position: absolute;
`;

const DatePickerWrapper = styled.div<{ disabled: Props['disabled']; error: boolean }>`
  position: relative;
  .react-datepicker-wrapper {
    width: 100%;

    input {
      padding-left: 36px;

      ${({ error }) =>
        error &&
        `
        border: 1px solid var(--red);
        background: #FFF2F2;
      `}

      ${({ disabled }) =>
        disabled &&
        `
        cursor: not-allowed;
        background: #f9f9f9;
        border: #f9f9f9;
      `}
    }
  }

  .react-datepicker-popper {
    z-index: 2;
  }
`;

const Icon = styled(UIIconSVG)`
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
`;

export default FieldDatePicker;
