import { TableSortHeaderCell } from 'components/UI/table';
import useSortStatus from 'hooks/common/useSortStatus';
import { MonthlyReportData } from 'interfaces/monthlyReport';
import React from 'react';
import styled from 'styled-components';

type Row = NonNullable<MonthlyReportData['overAllData']>;
interface Props {
  rows: Row;
}

interface TableRowProps {
  row: Row[0];
}

const userTableWidth = {
  column1: 100,
  column2: 100,
  column3: 100,
  column4: 100,
  column5: 100,
  column6: 100,
  column7: 100,
  column8: 100,
  column9: 0,
} as const;

const {
  column1,
  column2,
  column3,
  column4,
  column5,
  column6,
  column7,
  column8,
  column9,
} = userTableWidth;

const TableRowWrapper = ({ row }: TableRowProps) => {
  const {
    associatedOrganizations,
    numberOfPitchesSent,
    numberOfProspectingSessions,
    numberOfGamePlansDeployed,
    numberOfPricing,
    numberOfRoi,
    nonEmptyColumn,
    numberOfAudience,
  } = row;

  return (
    <TableRow>
      <TableRowCell bold>{associatedOrganizations}</TableRowCell>

      <TableRowCell>{Math.floor((nonEmptyColumn / 27) * 100)}%</TableRowCell>
      <TableRowCell>{numberOfPitchesSent}</TableRowCell>
      <TableRowCell>{numberOfProspectingSessions}</TableRowCell>
      <TableRowCell>{numberOfGamePlansDeployed}</TableRowCell>
      <TableRowCell>{numberOfPricing}</TableRowCell>
      <TableRowCell>{numberOfRoi}</TableRowCell>
      <TableRowCell>{numberOfAudience}</TableRowCell>
      <TableRowCell />
    </TableRow>
  );
};

const MonthlyReportOverallTable = ({ rows }: Props) => {
  const { data, sortStatus, handleSwitch } = useSortStatus(rows);

  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${column1}px` }} />
          <col span={1} style={{ width: `${column2}px` }} />
          <col span={1} style={{ width: `${column3}px` }} />
          <col span={1} style={{ width: `${column4}px` }} />
          <col span={1} style={{ width: `${column5}px` }} />
          <col span={1} style={{ width: `${column6}px` }} />
          <col span={1} style={{ width: `${column7}px` }} />
          <col span={1} style={{ width: `${column8}px` }} />
          <col span={1} style={{ width: `${column9}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            <TableSortHeaderCell
              colName="associatedOrganizations"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Organization
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="nonEmptyColumn"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Profile Completion %
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="numberOfPitchesSent"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Pitches Sent
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="numberOfProspectingSessions"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Saved Prospecting Sessions
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="numberOfGamePlansDeployed"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Deployed Game Plans
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="numberOfPricing"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Completed Pricing
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="numberOfRoi"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              ROI Impact Reports
            </TableSortHeaderCell>
            <TableSortHeaderCell
              // TODO: col name
              colName="numberOfAudience"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Completed Audience Analysis
            </TableSortHeaderCell>
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRowWrapper key={index} row={item} />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  width: 100%;
  position: relative;
  max-height: 600px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  background: #f4f4f4;
  position: sticky;
  top: 0;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableRow = styled.tr`
  height: 100%;
  background-color: #fff;
`;

const TableRowCell = styled.td<{ bold?: boolean }>`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  ${({ bold }) => bold && 'font-weight: 500;'}

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

export default MonthlyReportOverallTable;
