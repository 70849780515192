import { Field, FieldProps } from 'react-final-form';
import React from 'react';

const identity = value => (value == null || value === '' ? null : value);

/* This wraps react-final-form's <Field/> component.
 * The identity function ensures form values never get set to null,
 * but rather, empty strings.
 *
 * See https://github.com/final-form/react-final-form/issues/130
 */
const FormField = (props: FieldProps<any, any, any>) => (
  <Field parse={identity} {...props} />
);

export default FormField;
