import React from 'react';
import styled from 'styled-components';

import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';

const CompanyExist = ({ onClose, ...modalProps }: InjectedProps) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 24px 24px' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '333px',
      }}
    >
      <Title>Company already exist.</Title>
      <Btn modifiers="primary" title="Ok" handleClick={onClose} />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 20px;
  text-align: center;
`;

const Btn = styled(UIButton)`
  margin: 0 auto;
  display: block;
  width: 120px;
  font-size: 14px;
  padding: 7px 0;
`;

export default CompanyExist;
