import { format } from 'date-fns';
import { AudienceData } from 'interfaces/audience';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { TableSortHeaderCell } from 'components/UI/table';
import EyeIcon from 'assets/images/icons/eye.svg';
import AttachmentSVG from 'assets/images/icons/attachment.svg';
import { ReactSVG } from 'react-svg';
import { SortStatus } from 'hooks/common/useSortStatus';
import UploadButton from 'components/ActionButtons/UploadButton';
import { uploadFile } from 'store/ducks/utility/api';
import Toast from 'services/Toast';
import { useModal } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
import { UIButton, UIModal } from 'components/UI';
import useDownloadFile from 'hooks/common/useDownloadFile';
import { fetchAudienceDownloadExcel } from 'store/ducks/audience/api';
import { fileExtensionValidator, lessThanMB } from 'utils/validation';
import UploadFileArea from 'components/UploadFileArea';

interface Props {
  rows: AudienceData[];
  headCells: string[];
  sortStatus: SortStatus;
  onSort: (colName: string) => void;
  handleAudienceUpdate: (params: AudienceData) => Promise<unknown>;
  handleShowPDFPreview: (fileKey: string) => void;
}

interface TableRowProps {
  row: AudienceData;
  handleAudienceUpdate: Props['handleAudienceUpdate'];
  handleShowPDFPreview: Props['handleShowPDFPreview'];
}

const userTableWidth = {
  column1: 158,
  column2: 130,
  column3: 120,
  column4: 100,
  column5: 120,
  column6: 50,
  column7: 120,
  column8: 120,
} as const;

const { column2, column3, column4, column5, column6, column7 } = userTableWidth;

const TableRowWrapper = ({
  row,
  handleAudienceUpdate,
  handleShowPDFPreview,
}: TableRowProps) => {
  const {
    id,
    name,
    createdAt,
    status,
    adminUploadAt,
    adminAttachment,
    rawAttachment,
  } = row;
  const uploadRawButtonRef = useRef<HTMLLabelElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const downloadFile = useDownloadFile(fetchAudienceDownloadExcel);

  const upload = (file: File) =>
    new Promise<void>((resolve, reject) => {
      setLoading(true);
      uploadFile(file, 'audience-report')
        .then(v => {
          if (v.status === 'success') {
            handleAudienceUpdate({
              ...row,
              adminAttachment: v.fileKey,
              adminUploadAt: new Date().toISOString(),
              status: row.rawAttachment ? 'Completed' : row.status,
            }).finally(() => {
              setLoading(false);
              resolve();
            });
            return;
          }
          setLoading(false);
          reject();
          Toast.error('Error upload file');
        })
        .catch(() => {
          setLoading(false);
          reject();
          Toast.error('Error upload file');
        });
    });

  const uploadRaw = (file: File) => {
    uploadFile(file, 'audience-report')
      .then(v => {
        if (v.status === 'success') {
          handleAudienceUpdate({
            ...row,
            rawAttachment: v.fileKey,
            adminUploadAt: new Date().toISOString(),
            status: row.adminAttachment ? 'Completed' : row.status,
          });
          return;
        }
        Toast.error('Error upload file');
      })
      .catch(() => {
        Toast.error('Error upload file');
      });
  };

  const handleClickUploadRawBtn = () => {
    uploadRawButtonRef.current?.click();
  };

  const [showUploadModal, hideUploadModal] = useModal(() => {
    return (
      <UIModal onClose={hideUploadModal} style={{ width: '828px' }} hideCloseIcon>
        <UploadModalTitle>Upload Report</UploadModalTitle>
        <UploadFileArea
          name="Upload PDF Report"
          desc="Please upload a single PDF file. File size limit is 150 Mb."
          accept=".pdf"
          acceptValidator={fileExtensionValidator(['pdf'], 'Must be pdf files')}
          inputId={`upload-report-${id}`}
          fileSizeValidator={lessThanMB(150)}
          onDeleteFile={() => setFile(null)}
          filelist={file ? [{ label: file?.name, value: file?.name }] : []}
          onUpload={files => setFile(files[0])}
        />
        <UploadModalButtonGroup>
          <UIButton modifiers="secondary" title="Cancel" handleClick={hideUploadModal} />
          <UIButton
            modifiers="primary"
            title="Submit"
            handleClick={() => {
              if (!file) return;
              upload(file).then(() => {
                setFile(null);
                hideUploadModal();
              });
            }}
            isLoading={loading}
          />
        </UploadModalButtonGroup>
      </UIModal>
    );
  }, [file, loading]);

  const [showUploadConfirmModal, hideUploadConfirmModal] = useModal(() => {
    return (
      <ConfirmModal
        name={'Uploading a New Report '}
        textAccessBtn="Cancel"
        textCancelBtn="Yes"
        description={
          'By uploading a new report file, the previous file will be overwritten. Are you sure you want to continue? '
        }
        accessHandler={hideUploadConfirmModal}
        cancelHandler={() => {
          hideUploadConfirmModal();
          showUploadModal();
        }}
        onClose={hideUploadConfirmModal}
        hideCloseIcon
      />
    );
  });

  const [showUploadRawModal, hideUploadRawModal] = useModal(() => {
    return (
      <ConfirmModal
        name={'Uploading a New Report '}
        textAccessBtn="Cancel"
        textCancelBtn="Yes"
        description={
          'By uploading a new report file, the previous file will be overwritten. Are you sure you want to continue? '
        }
        accessHandler={hideUploadRawModal}
        cancelHandler={() => {
          hideUploadRawModal();
          handleClickUploadRawBtn();
        }}
        onClose={hideUploadRawModal}
        hideCloseIcon
      />
    );
  });

  return (
    <TableRow>
      <TableRowCell>{name}</TableRowCell>

      <TableRowCell>
        {createdAt ? format(new Date(createdAt), 'MMM dd, yyyy') : 'N/A'}
      </TableRowCell>

      <TableRowCell>
        <Tag $color={status === 'Completed' ? '#279B48' : '#868686'}>{status}</Tag>
      </TableRowCell>
      <TableRowCell>
        <ActionIcon src={AttachmentSVG} onClick={() => downloadFile(id)} />
      </TableRowCell>
      <TableRowCell>
        <FakeUploadButton
          title="Upload"
          modifiers="primary"
          handleClick={() =>
            adminAttachment ? showUploadConfirmModal() : showUploadModal()
          }
        />
      </TableRowCell>
      <TableRowCell>
        <ViewIconWrapper>
          {adminAttachment && (
            <ActionIcon
              src={EyeIcon}
              onClick={() => handleShowPDFPreview(adminAttachment)}
            />
          )}
        </ViewIconWrapper>
      </TableRowCell>
      <TableRowCell>
        <div style={{ display: 'none' }}>
          <UploadButton
            ref={uploadRawButtonRef}
            name="Upload"
            accept=".csv,.xls,.xlsx"
            acceptValidator={fileExtensionValidator(
              ['csv', 'xls', 'xlsx'],
              'Must be Excel files',
            )}
            inputId={`upload-raw-report-${id}`}
            uploadHandler={files => {
              uploadRaw(files[0]);
            }}
            fileSizeValidator={lessThanMB(100)}
          />
        </div>
        <FakeUploadButton
          title="Upload"
          modifiers="primary"
          handleClick={() =>
            rawAttachment ? showUploadRawModal() : handleClickUploadRawBtn()
          }
        />
      </TableRowCell>
      <TableRowCell>
        {adminUploadAt ? format(new Date(adminUploadAt), 'MMM dd, yyyy') : ''}
      </TableRowCell>
    </TableRow>
  );
};

const AudienceTable = ({
  rows,
  headCells,
  sortStatus,
  onSort,
  handleAudienceUpdate,
  handleShowPDFPreview,
}: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} />
          <col span={1} style={{ width: `${column2}px` }} />
          <col span={1} style={{ width: `${column3}px` }} />
          <col span={1} style={{ width: `${column4}px` }} />
          <col span={1} style={{ width: `${column5}px` }} />
          <col span={1} style={{ width: `${column6}px` }} />
          <col span={1} style={{ width: `${column7}px` }} />
          <col span={1} style={{ width: `${column7}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            <TableSortHeaderCell colName="name" sortStatus={sortStatus} onClick={onSort}>
              List Name
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="createdAt"
              sortStatus={sortStatus}
              onClick={onSort}
            >
              Request Date
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="status"
              sortStatus={sortStatus}
              onClick={onSort}
            >
              Status
            </TableSortHeaderCell>
            {headCells.map((item, index) => (
              <TableHeadCell key={index}>{item}</TableHeadCell>
            ))}
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(item => (
            <TableRowWrapper
              key={item.id}
              row={item}
              handleAudienceUpdate={handleAudienceUpdate}
              handleShowPDFPreview={handleShowPDFPreview}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  height: 440px;
  margin-bottom: 30px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  background: #f4f4f4;
  position: sticky;
  top: 0;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

const ViewIconWrapper = styled.div`
  & svg {
    path {
      stroke: #2baee0;
    }
  }
`;

const Tag = styled.div<{ $color: string }>`
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 12px;
  width: fit-content;
  border: 1px solid ${({ $color }) => $color};
  color: ${({ $color }) => $color};
`;

const FakeUploadButton = styled(UIButton)`
  color: white;
  font-size: 12px;
  font-weight: normal;
`;

const ActionIcon = styled(ReactSVG)`
  cursor: pointer;
`;

const UploadModalTitle = styled.div`
  font-weight: 500;
  line-height: 145%;
  margin: 12px 0 48px;
  text-align: center;
`;

const UploadModalButtonGroup = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: space-between;

  button {
    font-size: 12px;
    font-weight: 400;
  }
`;

export default AudienceTable;
