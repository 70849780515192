import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { WrapperProps } from 'interfaces/common';
import { UISpinner } from 'components/UI';

interface Props {
  teamId: string;
  isLoading?: boolean;
  header?: ReactNode;
  contentWithoutLoading?: ReactNode;
  withOutNav?: boolean;
}

function TeamWrapper({
  teamId,
  isLoading,
  children,
  contentWithoutLoading,
  header,
  withOutNav = false,
}: Props & WrapperProps) {
  return (
    <Body>
      {header && <Header>{header}</Header>}
      <Inner>
        {!withOutNav && (
          <NavList>
            <LinkElem to={`/team/view/${teamId}`}>Profile</LinkElem>
            <LinkElem to={`/pricing/view/${teamId}`}>Pricing</LinkElem>
            <LinkElem to={`/roi/view/${teamId}`}>ROI</LinkElem>
            <LinkElem to={`/audience/view/${teamId}`}>Audience</LinkElem>
            <LinkElem to={`/team-members/${teamId}`}>User Management</LinkElem>
          </NavList>
        )}

        <Content>
          {contentWithoutLoading}
          {isLoading ? <Spinner /> : children}
        </Content>
      </Inner>
    </Body>
  );
}

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 242px);
`;

const Body = styled.div`
  background: #f9f9f9;
  min-height: calc(100% - 80px);
`;

const Header = styled.div`
  width: 100%;
  background: #fff;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 1320px;
  padding: 0 20px;
  margin: 0 auto;
  padding-top: 32px;
  height: 100%;
  display: flex;
  align-items: flex-start;
`;

const NavList = styled.div`
  background: #ffffff;
  border-radius: 6px;
  padding: 22px 0;
  width: 230px;
  min-width: 230px;
  margin-right: 24px;
`;

const LinkElem = styled(NavLink)`
  display: block;
  font-size: 16px;
  line-height: 155%;
  color: var(--darkGray);
  padding: 8px 40px;

  &:hover {
    background: #caf0ff;
    color: var(--black);
  }

  &.active {
    background: #caf0ff;
    color: var(--black);
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 100%;
`;

export default TeamWrapper;
