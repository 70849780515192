import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { PaginationProps } from 'interfaces/common';

type ReturnType = {
  handleNext: () => void;
  handlePrev: () => void;
  firstNumToPage: number;
  secondNumToPage: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  page: number;
};

const usePagination = ({
  total,
  pageLimit,
  submitHandler,
}: PaginationProps): ReturnType => {
  const { location, push } = useHistory();
  const parsedQuery = queryString.parse(location.search, {
    parseNumbers: true,
  });
  const page = typeof parsedQuery?.page === 'number' ? parsedQuery?.page : 1;
  const totalPages = useMemo(() => Math.ceil(total / pageLimit), [pageLimit, total]);

  const getParams = useCallback(
    (value: number) => queryString.stringify({ ...parsedQuery, page: value }),
    [parsedQuery],
  );

  const handleNext = () => {
    if (page < 1) return push(`${location.pathname}?${getParams(2)}`);

    submitHandler(page + 1);
    push(`${location.pathname}?${getParams(page + 1)}`);
  };

  const handlePrev = () => {
    if (page > totalPages)
      return push(`${location.pathname}?${getParams(totalPages - 1)}`);

    submitHandler(page - 1);
    push(`${location.pathname}?${getParams(page - 1)}`);
  };

  const isFirstPage = page <= 1;
  const isLastPage = page >= totalPages;

  const firstNumToPage = useMemo(() => {
    if (page < 1) return 1;
    if (page > totalPages) return pageLimit;

    return pageLimit * (page - 1) + 1;
  }, [page, pageLimit, totalPages]);
  const secondNumToPage = useMemo(() => {
    if (page < 1) return pageLimit;
    if (page > totalPages) return total;

    return pageLimit * page > total ? total : pageLimit * page;
  }, [page, pageLimit, total, totalPages]);

  return {
    handleNext,
    handlePrev,
    firstNumToPage,
    secondNumToPage,
    isFirstPage,
    isLastPage,
    page,
  };
};

export default usePagination;
