import { CampaignStoreData } from 'interfaces/campaign';
import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as types from './types';

type RootAction = ActionType<typeof actions>;

export default createReducer<CampaignStoreData, RootAction>({
  einSuggest: {
    data: [],
    msg: '',
  },
}).handleType([types.SET_EIN_SUGGEST], (state, action) => ({
  ...state,
  einSuggest: action.payload,
}));
