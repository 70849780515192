/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from 'react';
import { debounce } from 'lodash';
import styled from 'styled-components';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { MainWrapper } from 'layouts';
import { headTableTeams } from 'constants/tableHeads';
import { TeamsTable } from 'components/Tables';
import { UIButton, UITextInput } from 'components/UI';
import Pagination from 'components/Pagination';
import { useOperation } from 'hooks/common';
import { MultiSelectWithCheckBoxes } from 'components/UI/select';
import { fetchExportByFilters } from 'store/ducks/team/api';
import {
  fetchSubscriptionUpdate,
  fetchTeamCreate,
  fetchTeamDelete,
  fetchTeamsSearch,
  fetchImportCompanies,
} from 'store/ducks/team/operations';
import { getTeams, getTeamsMeta } from 'store/ducks/team/selectors';
import { getOrganizations } from 'store/ducks/companies/selectors';
import { StateProps } from 'store/interfaces';
import { TeamData } from 'interfaces/team';
import Jumbotron from 'components/Jumbotron';
import { useModal } from 'react-modal-hook';
import InviteOrganizationModal from 'components/Modals/InviteOrganizationModal';
import OrganizationsModal from 'components/Modals/OrganizationsModal';
import { PaginationMeta } from 'interfaces/common';
import { organizationsInfoGet as fetchOrganizationsInfoGet } from 'store/ducks/user/operations';
import { PAGE_WIDTH } from 'constants/common';
import { ExportButton } from 'components/ActionButtons';
import useDownloadFile from 'hooks/common/useDownloadFile';
import { Option } from 'react-select/src/filters';
import Toast from 'services/Toast';

const TeamList = () => {
  const [onfetchTeams, isLoading] = useOperation(fetchTeamsSearch);
  const [onFetchDeleteTeam] = useOperation(fetchTeamDelete);
  const [onCreateTeam, , isCreateTeamLoading] = useOperation(fetchTeamCreate);
  const [onSubscriptionUpdate] = useOperation(fetchSubscriptionUpdate);
  const [onFetchOrganizationsInfoGet] = useOperation(fetchOrganizationsInfoGet);

  const [initialQuery, setInitialValues] = useState<string>('');
  const [filterLocations, setFilterLocations] = useState<Option[]>([]);
  const [filterMissionFocus, setFilterMissionFocus] = useState<Option[]>([]);
  const [filterSdgGoals, setfilterSdgGoals] = useState<Option[]>([]);

  const { location, push } = useHistory();
  const teams = useSelector<StateProps, TeamData[]>(getTeams);
  const meta = useSelector<StateProps, PaginationMeta>(getTeamsMeta);
  const organizationsInfo = useSelector(getOrganizations());

  const { currentPage, totalItems, itemsPerPage } = meta;
  const [upLoading, setUpLoading] = useState(false);
  const [importCompanies, isImportCompanies] = useOperation(fetchImportCompanies);

  const [showModal, hideModal] = useModal(
    () => (
      <InviteOrganizationModal
        isLoading={isCreateTeamLoading}
        handleAccess={({ subscription, ...rest }) => {
          onCreateTeam(rest).then(team => {
            onSubscriptionUpdate({ id: team.id, data: subscription }, true);
            hideModal();
          });
        }}
        onClose={hideModal}
      />
    ),
    [isCreateTeamLoading],
  );

  const getInitialState = () => {
    const filtersString = queryString.parse(location.search)?.q;
    const pageString = queryString.parse(location.search)?.page;
    const locationString = queryString.parse(location.search)?.filterLocations;
    const sdgGoalsString = queryString.parse(location.search)?.sdgGoals;
    const missionString = queryString.parse(location.search)?.missionFocus;

    if (queryString.parse(location.search)) {
      const q = filtersString && JSON.parse(window.atob(filtersString?.toString()));
      const page = Number(pageString) || 1;
      const locations =
        locationString && JSON.parse(window.atob(locationString?.toString()));
      const missionFocus =
        missionString &&
        JSON.parse(decodeURIComponent(window.atob(missionString?.toString())));
      const sdgGoals =
        sdgGoalsString && JSON.parse(window.atob(sdgGoalsString?.toString()));

      const locationText = locations && locations.map(item => item.value);
      const sdgGoalsText = sdgGoals && sdgGoals.map(item => item.value);
      const missionFocusTest = missionFocus && missionFocus.map(item => item.value);

      onfetchTeams({
        page,
        q,
        missionFocus: missionFocusTest,
        sdgGoals: sdgGoalsText,
        location: locationText,
      });

      setInitialValues(q);
      setFilterLocations(locations);
      setfilterSdgGoals(sdgGoals);
      setFilterMissionFocus(missionFocus);

      return;
    }

    setInitialValues('');
    onfetchTeams({ page: Number(pageString) || 1 });
  };

  const [showImportOrganizationsModal, hideImportOrganizationsModal] = useModal(
    () => (
      <OrganizationsModal
        upLoading={upLoading}
        handleAccess={values => {
          if (values['files']) {
            importCompanies(values['files'][0]).then((e: any) => {
              hideImportOrganizationsModal();
              getInitialState();
            });
          }
        }}
        onClose={hideImportOrganizationsModal}
      />
    ),
    [location],
  );

  const exportToExcel = useDownloadFile(fetchExportByFilters);
  const downloadExcel = () => {
    if (totalItems > 10000) {
      Toast.error('Only 10,000 records will be downloaded.');
      return;
    }
    const locationString = queryString.parse(location.search)?.filterLocations;
    const missionFocusString = queryString.parse(location.search)?.missionFocus;
    const sdgGoalsString = queryString.parse(location.search)?.sdgGoals;
    const qString = queryString.parse(location.search)?.q;

    const locations =
      locationString && JSON.parse(window.atob(locationString?.toString()));
    const missionFocus =
      missionFocusString &&
      JSON.parse(decodeURIComponent(window.atob(missionFocusString?.toString())));
    const sdgGoals =
      sdgGoalsString && JSON.parse(window.atob(sdgGoalsString?.toString()));
    const qText = qString && JSON.parse(window.atob(qString?.toString()));

    const locationText = locations && locations.map(item => item.value);
    const missionFocusText = missionFocus && missionFocus.map(item => item.value);
    const sdgGoalsText = sdgGoals && sdgGoals.map(item => item.value);

    return exportToExcel({
      location: locationText,
      sdgGoals: sdgGoalsText,
      missionFocus: missionFocusText,
      q: qText,
    });
    // return showCannotExportModal();
  };

  useEffect(() => {
    getInitialState();
    onFetchOrganizationsInfoGet(undefined);
  }, [location]);

  const handleSearchTeams = (
    val?: string,
    filterLocations?: Option[],
    filtermissionFocus?: Option[],
    filterSdgGoals?: Option[],
  ) => {
    const base64 = window.btoa(JSON.stringify(val));
    const locationsBase64 =
      filterLocations && window.btoa(JSON.stringify(filterLocations));
    const missionFocusBase64 =
      filtermissionFocus &&
      window.btoa(encodeURIComponent(JSON.stringify(filtermissionFocus)));
    const sdgGoalsBase64 = filterSdgGoals && window.btoa(JSON.stringify(filterSdgGoals));

    const page = queryString.parse(location.search)?.page;
    if (page) {
      if (val) {
        push(
          `${location.pathname}?${queryString.stringify({
            page,
            q: base64,
            filterLocations: locationsBase64,
            missionFocus: missionFocusBase64,
            sdgGoals: sdgGoalsBase64,
          })}`,
        );
      } else {
        push(`${location.pathname}`);
      }
    } else {
      if (val) {
        push(
          `${location.pathname}?${queryString.stringify({
            q: base64,
            filterLocations: locationsBase64,
            missionFocus: missionFocusBase64,
            sdgGoals: sdgGoalsBase64,
          })}`,
        );
      } else {
        push(
          `${location.pathname}?${queryString.stringify({
            filterLocations: locationsBase64,
            sdgGoals: sdgGoalsBase64,
            missionFocus: missionFocusBase64,
          })}`,
        );
      }
    }
  };

  const submitHandlerPagination = (page: number) => {
    const qString = queryString.parse(location.search)?.q;

    if (qString) {
      const param = JSON.parse(window.atob(qString?.toString()));

      //return onfetchTeams({ page, q: param});
    } else {
      //return onfetchTeams({ page });
    }
  };

  const handleSearchTeamsDebounce = useCallback(debounce(handleSearchTeams, 800), []);

  return (
    <MainWrapper
      // isLoading={isLoading}
      header={
        <Jumbotron name="Organizations">
          <UIButton
            modifiers="primary"
            handleClick={() => showModal()}
            title="Invite an Organization"
          />
        </Jumbotron>
      }
      contentWithoutLoading={
        <SuggestWrapper>
          <UITextInput
            value={initialQuery}
            onChange={({ target: { value } }) => {
              setInitialValues(value);
              handleSearchTeamsDebounce(
                value,
                filterLocations,
                filterMissionFocus,
                filterSdgGoals,
              );
            }}
            placeholder="Search organizations"
            disableTrim
          />
        </SuggestWrapper>
      }
    >
      <>
        <SearchTitle>Filter</SearchTitle>
        <FilterWrapper>
          <MultiSelectWithCheckBoxes
            options={organizationsInfo.location}
            changeHandler={(option: Option[]) => {
              setFilterLocations(option);
              handleSearchTeams(initialQuery, option, filterMissionFocus, filterSdgGoals);
            }}
            onCloseMenu={() => {}}
            /* onCloseMenu={() => {
              handleSearchTeams(
                initialQuery,
                filterLocations,
                filterMissionFocus,
                filterSdgGoals,
              );
            }} */
            labelledBy="Location"
            value={filterLocations}
          />
          <MultiSelectWithCheckBoxes
            options={organizationsInfo.missionFocus}
            changeHandler={option => {
              setFilterMissionFocus(option);
              handleSearchTeams(initialQuery, filterLocations, option, filterSdgGoals);
            }}
            onCloseMenu={() => {}}
            /* onCloseMenu={() => {
              handleSearchTeams(
                initialQuery,
                filterLocations,
                filterMissionFocus,
                filterSdgGoals,
              );
            }} */
            value={filterMissionFocus}
            labelledBy="Mission Focus"
          />
          <MultiSelectWithCheckBoxes
            options={organizationsInfo.sdgGoals}
            changeHandler={option => {
              setfilterSdgGoals(option);
              handleSearchTeams(
                initialQuery,
                filterLocations,
                filterMissionFocus,
                option,
              );
            }}
            onCloseMenu={() => {}}
            /* onCloseMenu={() => {
              handleSearchTeams(
                initialQuery,
                filterLocations,
                filterMissionFocus,
                filterSdgGoals,
              );
            }} */
            value={filterSdgGoals}
            labelledBy="SDG Goals"
          />
        </FilterWrapper>

        <HeaderList>
          <SuggestionActions>
            <>
              <ExportButton
                name="Export Organizations"
                uploadHandler={downloadExcel}
              ></ExportButton>
              <div style={{ marginLeft: '-20px' }}></div>
              <ExportButton
                name="Import or Update Organizations"
                iconCss={{ transform: 'rotate(180deg)' }}
                uploadHandler={showImportOrganizationsModal}
              />
            </>
          </SuggestionActions>
        </HeaderList>
        <PaginationWrapper>
          {totalItems > 0 && (
            <Pagination
              total={totalItems}
              pageLimit={Number(itemsPerPage)}
              page={Number(currentPage)}
              submitHandler={(page: number) => submitHandlerPagination(page)}
            />
          )}
        </PaginationWrapper>
        <TeamsTable
          rows={teams}
          headCells={headTableTeams}
          handleDeleteOrganization={id => onFetchDeleteTeam(id)}
        />
      </>
    </MainWrapper>
  );
};

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const SuggestWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const FilterWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 34px;

  & > div {
    width: 288px;
  }
`;

const SearchTitle = styled.div`
  margin-bottom: 14px;
  color: #737373;
  font-size: 12px;
`;

const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px;
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;

const SuggestionActions = styled.div`
  display: flex;

  button:nth-child(-n + 2) {
    margin-right: 40px;
  }
`;

export default TeamList;
