/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { ReactSVG } from 'react-svg';

import { SocialWrapper, SocialLink } from './styled';
import { SocialMedia } from 'interfaces/companies';
import { SocialMediaTypeEnum } from 'constants/socialMediaTypes';

import LinkedInIcon from 'assets/images/icons/linkedIn.svg';
import TwitterIcon from 'assets/images/icons/twitter.svg';
import FacebookIcon from 'assets/images/icons/facebook.svg';
import YoutubeIcon from 'assets/images/icons/youtube.svg';

export interface SocialMediaLinksProps {
  urls: SocialMedia[];
}

const SocialMediaLinks = ({ urls }: SocialMediaLinksProps) => {
  const getIcon = (type: string) => {
    switch (type) {
      case SocialMediaTypeEnum.linkedIn:
        return LinkedInIcon;
      case SocialMediaTypeEnum.facebook:
        return FacebookIcon;
      case SocialMediaTypeEnum.twitter:
        return TwitterIcon;
      case SocialMediaTypeEnum.youtube:
        return YoutubeIcon;
      default:
    }
  };

  const filterUrls = useMemo(
    () =>
      urls.filter(
        ({ type }) =>
          type === SocialMediaTypeEnum.linkedIn ||
          type === SocialMediaTypeEnum.facebook ||
          type === SocialMediaTypeEnum.twitter ||
          type === SocialMediaTypeEnum.youtube,
      ),
    [],
  );

  return (
    <SocialWrapper>
      {filterUrls.map(({ type, url }: SocialMedia, index: number) => {
        const icon = getIcon(type);
        return (
          <SocialLink key={index} href={url} target="_blank">
            <ReactSVG src={icon} />
          </SocialLink>
        );
      })}
    </SocialWrapper>
  );
};

export default SocialMediaLinks;
