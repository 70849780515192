import React from 'react';
import styled from 'styled-components';
import { FieldRenderProps } from 'react-final-form';

export interface FormErrorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: FieldRenderProps<any>['meta'];
  className?: string;
}

function FormError({ meta: { error, submitError }, className }: FormErrorProps) {
  const inputErrors = error || submitError;

  return (
    <>
      {Array.isArray(inputErrors) ? (
        inputErrors.map((item, index) => (
          <Error className={className} key={index.toString()}>
            {item}
          </Error>
        ))
      ) : (
        <Error className={className}>{inputErrors}</Error>
      )}
    </>
  );
}

const Error = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--red);
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export default FormError;
