import { ROIEditNavigationEnum } from 'constants/roi';
import { CompanyCreateReq } from 'interfaces/companies';
import {
  ROIData,
  ROIDisableReq,
  ROIListByCompanyReq,
  ROIListReq,
  ROITemplate,
  ROIUpdateMeltwaterReq,
} from 'interfaces/roi';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import { StateProps } from 'store/interfaces';
import * as actions from './actions';
import * as api from './api';

export const fetchROIList = (param: ROIListReq) => async (dispatch: Dispatch) => {
  return api
    .fetchROIList(param)
    .then(value => dispatch(actions.setROIList(value)))
    .catch(() => Toast.error('Error get Partnership list'));
};

export const fetchROIListByCompany = (param: ROIListByCompanyReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchROIListByCompany(param)
    .then(value => dispatch(actions.setROIList(value)))
    .catch(() => Toast.error('Error get Partnership list'));
};

export const fetchROICreate = (name: string) => async (dispatch: Dispatch) => {
  return api
    .fetchROICreate({ name })
    .then(roi => dispatch(actions.createROI(roi)))
    .catch(err => {
      return err.response.data.statusCode === 400
        ? Toast.error(err.response.data.message)
        : Toast.error('Error create Partnership');
    });
};

export const fetchROIDelete = (ids: string[]) => async (dispatch: Dispatch) => {
  return api
    .fetchMultipleDelete(ids.join())
    .then(() => dispatch(actions.deleteROI(ids)))
    .catch(() => Toast.error('Error delete Partnership'));
};

export const fetchROI = (id: string) => async (dispatch: Dispatch) => {
  return api.fetchROI(id).then(roi => dispatch(actions.setROI(roi)));
};

export const fetchROIUpdate = (
  roi: ROIData,
  hideToast?: boolean,
  toastSuccessText?: string,
) => async (dispatch: Dispatch) => {
  return api
    .fetchROIUpdate(roi)
    .then(roi => {
      dispatch(actions.setROI(roi));
      !hideToast && Toast.success(toastSuccessText || 'Partnership saved successfully');
    })
    .catch(() => Toast.error('Error save Partnership'));
};

export const fetchROIChangeStatus = ({ roiId, type, bool }: ROIDisableReq) => async (
  dispatch: Dispatch,
) => {
  switch (type) {
    case ROIEditNavigationEnum.Manage:
      return api.fetchROIUpdate({ id: roiId, disabled: !bool }).then(roi => {
        dispatch(actions.setROI(roi));
      });
    case ROIEditNavigationEnum.Steward:
      return api.fetchROIUpdate({ id: roiId, activeStewardship: bool }).then(roi => {
        dispatch(actions.setROI(roi));
      });
    case ROIEditNavigationEnum.Measure:
      return api.fetchROIUpdate({ id: roiId, activeMeasure: bool }).then(roi => {
        dispatch(actions.setROI(roi));
      });
    default:
      return;
  }
};

export const fetchROIGenerateTemplates = (roiId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchROIGenerateTemplates(roiId)
    .then(templates => {
      dispatch(actions.setROITemplates(templates));
      Toast.success('Stewardship plan generated successfully');
    })
    .catch(err => {
      err.response.data.statusCode === 400
        ? Toast.error(err.response.data.message)
        : Toast.error('Error generate plan');
      return false;
    });
};

export const fetchROITemplates = (roiId: string) => async (dispatch: Dispatch) => {
  return api
    .fetchROITemplates(roiId)
    .then(templates => dispatch(actions.setROITemplates(templates)));
};

export const fetchROITemplatePreview = (templateId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchROITemplatePreview(templateId)
    .then(previewStr => dispatch(actions.setROITemplatePreview(previewStr)));
};

export const fetchROITemplateCompiled = (template: ROITemplate) => async (
  dispatch: Dispatch,
) => {
  return api.fetchROITemplateCompiled(template).then(previewStr => {
    dispatch(actions.setROITemplatePreview(previewStr));
    dispatch(actions.setROITemplatesItem(template));
  });
};

export const fetchROITemplate = (templateId: string) => async (dispath: Dispatch) => {
  return api.fetchROITemplate(templateId).then(template => {
    dispath(actions.setROITemplatesItem(template));
    return template;
  });
};

export const fetchROITemplateSave = (template: ROITemplate) => async (
  dispath: Dispatch,
) => {
  return api
    .fetchROITemplateSave({ ...template, interval: Number(template.interval) })
    .then(template => {
      dispath(actions.setROITemplatesItem(template));
      Toast.success('Template Saved successfully');
    })
    .catch(() => Toast.error('Error save Template'));
};

export const fetchROITemplateListSave = (templateList: ROITemplate[]) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchROITemplateListSave(templateList)
    .then(templates => {
      dispatch(actions.setROITemplates(templates));
      Toast.success('Stewardship plan saved successfully');
    })
    .catch(() => Toast.error('Error save Stewardship plan'));
};

export const toggleROITemplatesDisable = (templateId: string) => async (
  dispatch: Dispatch,
) => {
  dispatch(actions.toggleROITemplatesDisable(templateId));
};

export const fetchROITemplateReset = (templateId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchROITemplateReset(templateId)
    .then(template => dispatch(actions.setROITemplatesItem(template)));
};

export const fetchROICalReport = (roiId: string) => async (dispatch: Dispatch) => {
  return api
    .fetchRoiCalReport(roiId)
    .then(report => dispatch(actions.setRoiReport(report)));
};

export const fetchROIGenerateReport = (roiId: string) => async (dispatch: Dispatch) => {
  return api.fetchRoiGenerateReport(roiId).then(report => {
    dispatch(actions.setRoiReport(report));
  });
};

export const fetchROIReportSavePDF = (fileKey: string) => async (
  dispatch: Dispatch,
  getState: () => StateProps,
) => {
  const state = getState();
  const roi = state.roi.roi;
  if (!roi) return;

  return api
    .fetchROIUpdate({ ...roi, file: fileKey })
    .then(roi => dispatch(actions.setROI(roi)));
};

export const fetchROIAddCompany = (param: CompanyCreateReq) => async () => {
  return api
    .fetchRoiAddCompany(param)
    .then(() => Toast.success('Company successfully created'))
    .catch(() => Toast.error('Error create company'));
};

export const fetchROIUpdateMeltwater = (param: ROIUpdateMeltwaterReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchROIUpdateMeltwater(param)
    .then(roi => {
      dispatch(actions.setROI(roi));
      Toast.success('Generated successfully');
    })
    .catch(() => Toast.error('Error generate tracking'));
};

export const fetchROIMeltwaterRegenerate = (roiId: string) => async () => {
  return api.fetchROIMeltwaterRegenerate(roiId);
};

export const fetchROIMeltwaterData = (roiId: string) => async (dispatch: Dispatch) => {
  return api
    .fetchROIMeltwaterData(roiId)
    .then(meltwaterData => dispatch(actions.setROIMeltwaterData(meltwaterData)));
};

export const fetchROIMeltwaterDataById = (analyticsId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchROIMeltwaterDataById(analyticsId)
    .then(meltwaterData => dispatch(actions.setROIMeltwaterData(meltwaterData)));
};

export const fetchROIReportRevert = (roiId: string) => async (dispatch: Dispatch) => {
  return api
    .fetchROIReportRevert(roiId)
    .then(roi => {
      Toast.success('Revert report successfully');
      dispatch(actions.setROI(roi));
    })
    .catch(() => Toast.error('Error revert report'));
};
