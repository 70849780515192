import { SdgGoals } from 'constants/sdgGoals';
import { GuidestarTransparencyRankingEnum } from 'constants/team';
import { Company, News, TypesOfInvestment } from 'interfaces/companies';
import { PaginationList } from './common';
import { User } from './user';

export interface TeamData {
  id: string;
  address?: string;
  organizationName: string;
  numberConstituents: string;
  logoKey?: string;
  twitterLink: string;
  aieldActivity: string;
  linkedinLink: string;
  annualTotalContributions: number;
  contactName: string;
  contactPhone: number;
  contactWebsite: string;
  email: string;
  partners: Company[];
  goalAlignment?: SdgGoals[];
  employeeEngagementOpportunities?: boolean;
  opportunities?: [{ text: string }];
  impactStatements?: [{ text: string }];
  impactStory?: string;
  zip: string;
  primaryMissionFocus?: {
    value: string;
    label: string;
  } | null;
  secondaryMissionFocus?: [{ value: string; label: string }];

  typesOfInvestment?: TypesOfInvestment[];
  affinity?: string;
  affinities?: string[];
  twitterHandle?: string;
  genders?: Record<string, number>;
  income?: Record<string, number>;
  age?: Record<string, number>;
  ethnicity?: Record<string, number>;
  affiliation?: Record<string, number>;
  nonprofit?: Record<string, number>;
  purchase?: Record<string, number>;
  brands?: Record<string, number>;
  interests?: Record<string, number>;
  owner?: User;
  charitablePartners: {
    id: string;
    name: string;
    logo: string;
  }[];
  searchCount: number;
  pitchCount: number;
  additionalMaterials: string[];
  minimumPartnerThresholdAmount: number;
  minimumCommitment: number;
  numberOfSocialFollowers: number;
  teamPartnerCompanies: {
    id: string;
    company: Company;
    companyId: Company['id'];
    companyName: string;
    team: TeamData;
    teamId: TeamData['id'];
  }[];
  programExpenseRatio: string;
  programs: { text: string }[];
  guidestarTransparencyRanking: GuidestarTransparencyRankingEnum;
  guidestarLink: string;
  impact: { text: string }[];
  einStatus: string;
  missionDescription: string;
  paypalGivingFundId: string;
  paypalGivingFundName: string;
  paypalType?: '0' | '1';
  programFocus?: string;
  costPerOutcome?: string;
  paypalEin?: string;
  beneficiaries: string[];
}

export type TeamDataForm = Omit<TeamData, 'teamPartnerCompanies'> & {
  teamPartnerCompanies: { id: string; name: string }[];
  programFocusAndCostPerOutcome: {
    programFocus?: string;
    costPerOutcome?: string;
  }[];
};

export interface Teams extends PaginationList<TeamData> {
  links: {};
}

export interface TeamStoreData {
  teams: Teams;
  teamsSuggested: TeamData[];
  subscription: SubscriptionProps | undefined;
  teamMembers: User[];
  news: News[];
  contacts: TeamContact[];
  payPalGivingFundCanUpdate: boolean;
  payPalGivingFundIfConfirm: boolean;
  impactGenome: ImpactGenome[];
}

export interface SubscriptionProps {
  name: string;
  isProspecting?: boolean;
  isAudience?: boolean;
  isRoi?: boolean;
  isPrices?: boolean;
  roiLevel?: ROILevelEnum;
  isCanva?: boolean;
  isFundraising?: boolean;
}

export enum SubscriptionPlan {
  BASIC = 'Basic',
  PRO = 'Pro',
  ENTERPRISE = 'Enterprise',
  A_LA_CARTE = 'A la carte',
}

export enum ROILevelEnum {
  Level1 = 'Level 1',
  Level2 = 'Level 2',
  Level3 = 'Level 3',
  Level4 = 'Level 4',
}

export interface InviteOrganizationReq {
  organizationName: string;
  email: string;
}

export interface AddTeamMembersReq {
  email: string;
  teamId: string;
}

export interface TeamContact {
  id: string;
  zoomInfoId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  linkedinUrl: string;
  jobTitle: string;
  contactAccuracyScore: string;
  createdAt: string;
  companyId: string;
  teamId: string;
  team: {
    id: string;
    organizationName: string;
  };
}

export interface ImpactGenome {
  impactArea: string;
  outcome: string;
  genome: string;
  programFocus: string;
  costPerOutcome: string;
}

export type UploadTeamXLSXRes = {
  name: string;
  url: string;
  notes: string;
  ein: string;
  donationAmt: string;
  recurring: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  contractNumber: string;
  id: string;
}[];
