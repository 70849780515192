import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { GuestWrapper } from 'layouts';
import { Login } from 'pages/auth';
import Route from './Route';

const UnauthorizedRoutes = () => {
  return (
    <Switch>
      <Route path="/login" layout={GuestWrapper} component={Login} />
      <Route path="/" component={() => <Redirect to="/login" />} />
    </Switch>
  );
};

export default UnauthorizedRoutes;
