import { FieldSingleSelect } from 'components/FormFields';
import { Title } from 'components/ProfileView/AffinityForm';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import React, { useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import styled from 'styled-components';
import DownloadTemplate from 'components/DownloadTemplate';
import UploadFileArea from 'components/UploadFileArea';
import {
  downloadImportTemplate,
  fetchUploadCompaniesXLSX,
} from 'store/ducks/companies/api';
import Toast from 'services/Toast';
import { fetchErrorHandler } from 'utils/errorHandlers';
import { fileExtensionValidator, required } from 'utils/validation';

interface Props extends InjectedProps {
  handleAccess: (values) => void;
  upLoading: boolean;
}

const ContactsModal = ({ onClose, upLoading, handleAccess, ...modalProps }: Props) => {
  useLockBodyScroll();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isloadings, setIsloadings] = useState(false);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 60px' }}
      style={{
        width: 'max-content',
        maxWidth: '1129px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Title
        style={{
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '145%',
          fontFamily: 'Rubik',
          color: '#122434',
          fontStyle: 'normal',
          marginBottom: '30px',
        }}
      >
        Import or Update Organization Contacts
      </Title>
      <Form
        onSubmit={values => {
          upLoading = true;
          handleAccess(values);
          upLoading = false;
        }}
        initialValues={{ variantForm: 'Advanced' }}
        render={({ handleSubmit, values, form }: FormRenderProps<any>) => {
          return (
            <>
              <Field
                name="action"
                label={
                  <div
                    style={{
                      fontFamily: 'Rubik',
                      fontStyle: 'normal',
                      fontSize: '12px',
                      lineHeight: '145%',
                      color: '#122434',
                    }}
                  >
                    Import or Update Contacts
                  </div>
                }
                options={[
                  { value: 'create', label: 'Import Companies' },
                  { value: 'update', label: 'Update Companies' },
                ]}
                component={FieldSingleSelect}
                validate={required}
              />
              <LimitWidthAndCenter>
                <UploadFileArea
                  name="Upload Excel List"
                  isloading={isloadings}
                  acceptValidator={fileExtensionValidator(
                    ['xlsx'],
                    'Only Excel files are allowed.',
                  )}
                  areaHeight={203}
                  desc="You may upload 1 Excel file per time. File size limit is 50 Mb. Only Excel files are allowed.                  "
                  filelist={
                    values.files?.map(item => ({
                      label: item.name,
                      value: item.name,
                    })) || []
                  }
                  onUpload={file => {
                    // console.log(file, 'file');
                    if (!file.length) return;
                    setIsloadings(true);
                    fetchUploadCompaniesXLSX(file[0])
                      .then(value => {
                        form.change('files', file);
                        form.change('rows', value);
                        Toast.success('Uploaded successfully');
                        setIsDisabled(false);
                      })
                      .catch(fetchErrorHandler)
                      .finally(() => {
                        setIsloadings(false);
                      });
                  }}
                  onDeleteFile={() => {
                    form.change('files', []);
                    setIsDisabled(true);
                    upLoading = false;
                  }}
                />
                <DownloadTemplate fetchFile={downloadImportTemplate} />
                <div style={{ height: '35px' }}></div>
                <Buttons>
                  <Button title="Cancel" handleClick={onClose} modifiers="secondary" />
                  <Button
                    title="Submit"
                    handleClick={handleSubmit}
                    modifiers="primary"
                    disabled={isDisabled}
                    isLoading={upLoading}
                  />
                </Buttons>
              </LimitWidthAndCenter>
            </>
          );
        }}
      />
    </UIModal>
  );
};
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Button = styled(UIButton)`
  width: 128px;
`;

const LimitWidthAndCenter = styled.div<{ isDisabled?: boolean }>`
  width: 720px;
  margin: 0 auto;

  ${({ isDisabled }) => isDisabled && 'width: 100%;'}
`;
export default ContactsModal;
