import Pagination from 'components/Pagination';
import { UILoadAvatar } from 'components/UI';
import { AudienceData, AudienceListReq, AudienceListRes } from 'interfaces/audience';
import { TeamData } from 'interfaces/team';
import React, { FC } from 'react';
import styled from 'styled-components';
import AudienceListTable from '../../../components/audience/AudienceTable';
import { SortStatus } from 'hooks/common/useSortStatus';
import TotalRow from 'components/TotalRow';
import { useFileUrl, useModal } from 'hooks/common';
import { PDFPreviewModal } from 'components/Modals';

interface Props {
  team: TeamData;
  list: AudienceData[];
  meta: AudienceListRes['meta'];
  handleFetchList: (param: AudienceListReq) => void;
  handleAudienceUpdate: (params: AudienceData) => Promise<unknown>;
  sortStatus: SortStatus;
  onSort: (colName: string) => void;
}

const AudienceView: FC<Props> = ({
  team,
  list,
  meta,
  handleFetchList,
  handleAudienceUpdate,
  onSort,
  sortStatus,
}) => {
  const { fileUrl, load } = useFileUrl({ imgKey: undefined });

  const [showPDFPreviewModal, hidePDFPreviewModal] = useModal(
    () => <PDFPreviewModal onClose={hidePDFPreviewModal} fileUrl={fileUrl} />,
    [fileUrl],
  );

  const handleShowPDF = (fileKey: string) => {
    load(fileKey);
    showPDFPreviewModal();
  };

  return (
    <>
      <Header>
        <Image imgKey={team.logoKey} />
        <OrganizationTitle>{team.organizationName}</OrganizationTitle>
      </Header>
      <Body>
        <TotalRow
          maxWidth="1138px"
          title={`${meta.totalItems} Report${meta.totalItems > 1 ? 's' : ''}`}
        >
          <Pagination
            page={Number(meta.currentPage)}
            pageLimit={Number(meta.itemsPerPage)}
            total={meta.totalItems}
            submitHandler={page => handleFetchList({ id: team.id, page, type: 'team' })}
          />
        </TotalRow>
        <AudienceListTable
          headCells={['File', 'Upload Report', '', 'Upload Raw Data', 'Upload Date']}
          handleAudienceUpdate={handleAudienceUpdate}
          rows={list}
          onSort={onSort}
          sortStatus={sortStatus}
          handleShowPDFPreview={handleShowPDF}
        />
      </Body>
    </>
  );
};

const Header = styled.section`
  width: 100%;
  height: 180px;
  background-color: #f2f2f2;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 40px;
  margin-bottom: 18px;
`;

const Image = styled(UILoadAvatar)`
  border-radius: 8px;
  width: 100px;
  min-width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 26px;
  background-color: #fff;
`;

const OrganizationTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
`;

const Body = styled.section`
  background-color: #fff;
  width: 100%;
  height: 558px;
  padding: 24px 36px;
`;

export default AudienceView;
