import Pagination from 'components/Pagination';
import ROITable from 'components/roi/ROITable';
import { UILoadAvatar } from 'components/UI';
import { Company } from 'interfaces/companies';
import { ROIListByCompanyReq, ROIListRes } from 'interfaces/roi';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

interface Props {
  company: Company;
  roiList: ROIListRes['items'];
  roiMeta: ROIListRes['meta'];
  handleFetchROIList: (param: ROIListByCompanyReq) => void;
}

const ROIView: FC<Props> = ({ company, roiList, roiMeta, handleFetchROIList }) => {
  const { push } = useHistory();

  return (
    <>
      <Header>
        <Image imgKey={company.logo} />
        <OrganizationTitle>{company.name}</OrganizationTitle>
      </Header>
      <Body>
        <BodyHeader>
          <BodyTitle>ROI</BodyTitle>
        </BodyHeader>
        <PaginationWrapper>
          <Pagination
            page={Number(roiMeta.currentPage)}
            pageLimit={Number(roiMeta.itemsPerPage)}
            total={roiMeta.totalItems}
            submitHandler={page => handleFetchROIList({ companyId: company.id, page })}
          />
        </PaginationWrapper>
        <ROITable
          headCells={['Name', 'Renewal Date']}
          rows={roiList}
          onRowClick={id => push(`/company-roi/edit/${company.id}/${id}`)}
        />
      </Body>
    </>
  );
};

const Header = styled.section`
  width: 100%;
  height: 180px;
  background-color: #f2f2f2;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 40px;
  margin-bottom: 18px;
`;

const Image = styled(UILoadAvatar)`
  border-radius: 8px;
  width: 100px;
  min-width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 26px;
  background-color: #fff;
`;

const OrganizationTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
`;

const Body = styled.section`
  background-color: #fff;
  width: 100%;
  height: 558px;
  padding: 24px 36px;
`;

const BodyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const BodyTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 4px 0;
`;

export default ROIView;
