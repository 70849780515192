/* eslint-disable @typescript-eslint/class-name-casing */
import { User } from './user';
import {
  MissionProps,
  IndustriesProps,
  CRSFocus,
  StatesProps,
  IndustriesTreeProps,
  CountriesProps,
  CitesProps,
  NaicsCodeTreeProps,
  SdgGoalsProps,
} from 'store/interfaces';
export interface AuthData {
  email?: string;
  token?: string | string[] | undefined;
}

export interface AuthResponseData {
  accessToken: string;
  user: User;
}

export enum AuthStatuses {
  AUTHOREZED = 'authorized',
  UNATHORIZED = 'unauthorized',
}

export interface AdditionalInfo {
  missions: MissionProps[];
  industries: IndustriesProps[];
  industriesTree: IndustriesTreeProps[];
  crsFocus: CRSFocus[];
  states: StatesProps[];
  countries: CountriesProps[];
  cites: CitesProps[];
  naicsCodeTree: NaicsCodeTreeProps[];
  teamRange: {
    annualtotalcontributionsmax: string;
    annualtotalcontributionsmin: string;
    numberofsocialfollowersmax: string;
    numberofsocialfollowersmin: string;
    minimumcommitmentmax: string;
    minimumcommitmentmin: string;
  };
}

export interface searchOrganizationsInfo {
  location: [];
  missionFocus: [];
  sdgGoals: SdgGoalsProps[];
}

export interface AdminData {
  admin: User | {};
}

export interface UserTokenData {
  accessToken: string;
  user: User;
}

export interface searchCompaniesInfo {
  location: [];
  revenue: [];
}
