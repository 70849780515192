import React from 'react';
import styled from 'styled-components';
import { AuthWrapperProps } from 'interfaces/common';
import Header from 'components/Header';

function AuthenticatedWrapper({ children, location }: AuthWrapperProps) {
  return (
    <Root>
      <Header location={location} />
      {children}
    </Root>
  );
}

const Root = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1200px;
`;

export default AuthenticatedWrapper;
