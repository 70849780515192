import * as types from './types';
import {
  Companies,
  Company,
  CompanyContact,
  News,
  SocialImpactLogItem,
  SubscriptionCompany,
} from 'interfaces/companies';
import { User, UserChangeCanvaReq } from 'interfaces/user';
import { searchCompaniesInfo } from 'interfaces/auth';
import { SearchJobTitle } from 'interfaces/companies';

export const setCompany = (company: Company) =>
  ({
    type: types.SET_COMPANY,
    payload: company,
  } as const);

export const setCompanies = (companies: Companies) =>
  ({
    type: types.SET_COMPANIES,
    payload: companies,
  } as const);

export const removeCompany = (companyId: string) =>
  ({
    type: types.REMOVE_COMPANY,
    payload: companyId,
  } as const);

export const setContacts = (contacts: CompanyContact[]) =>
  ({
    type: types.SET_CONTACTS,
    payload: contacts,
  } as const);

export const setContact = (contact: CompanyContact) =>
  ({
    type: types.SET_CONTACT,
    payload: contact,
  } as const);

export const setSuggestCompanies = (companies: Companies) =>
  ({
    type: types.SET_SUGGEST_COMPANIES,
    payload: companies,
  } as const);

export const setCompanyNews = (news: News[]) =>
  ({
    type: types.SET_COMPANY_NEWS,
    payload: news,
  } as const);

export const deleteContact = (contactId: string) =>
  ({
    type: types.DELETE_CONTACT,
    payload: contactId,
  } as const);

export const deleteTeamMembers = (id: string) =>
  ({
    type: types.DELETE_COMPANY_MEMBERS,
    payload: id,
  } as const);

export const getTeamMembers = (teamMembers: User[]) =>
  ({
    type: types.GET_COMPANY_MEMBERS,
    payload: teamMembers,
  } as const);

export const setSubscription = (subscription?: SubscriptionCompany) =>
  ({
    type: types.SET_COMPANY_SUBSCRIPTION,
    payload: subscription,
  } as const);

export const setCompanyMember = (user: User) =>
  ({
    type: types.SET_COMPANY_MEMBER,
    payload: user,
  } as const);

export const setCompanyMemberIsCanva = (params: UserChangeCanvaReq) =>
  ({
    type: types.SET_COMPANY_MEMBER_ISCANVA,
    payload: params,
  } as const);

export const setCompanySocialImpactLog = (socialImpactLog: SocialImpactLogItem[]) =>
  ({
    type: types.SET_COMPANY_SOCIAL_IMPACT_LOG,
    payload: socialImpactLog,
  } as const);

export const setSearchCompanies = (companies: searchCompaniesInfo) =>
  ({
    type: types.SET_COMPANY_SELECT,
    payload: companies,
  } as const);

export const setSearchJobTitles = (jobTitles: SearchJobTitle[]) =>
  ({
    type: types.SET_JOBTITLE,
    payload: jobTitles,
  } as const);
