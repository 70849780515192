/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MainWrapper } from 'layouts';
import { useOperation } from 'hooks/common';
import { useParams } from 'react-router-dom';
import {
  fetchCompanyContactsUpdate,
  fetchCompanyContacts,
  fetchCompanyContactCreate,
} from 'store/ducks/companies/operations';
import { StateProps } from 'store/interfaces';
import { getContactById } from 'store/ducks/companies/selectors';
import { CompanyContact } from 'interfaces/companies';
import CompanyContactForm from 'components/CompanyContactForm';
import Jumbotron from 'components/Jumbotron';
import History from 'services/History';

const EditCompanyContact = () => {
  const [onfetchCompanyContacts] = useOperation(fetchCompanyContacts);

  const [onUpdateCompanyContact] = useOperation(fetchCompanyContactsUpdate);
  const [onCreateCompanyContact] = useOperation(fetchCompanyContactCreate);
  const params: { companyId: string; contactId?: string } = useParams();

  const contact = useSelector<StateProps, CompanyContact | undefined>(
    getContactById(params.contactId),
  );

  useEffect(() => {
    onfetchCompanyContacts(params.companyId);
  }, [params.companyId]);

  const onSubmit = useCallback(
    async (values: CompanyContact) => {
      if (params.contactId) {
        await onUpdateCompanyContact({
          contactId: params.contactId,
          companyId: params.companyId,
          data: values,
        });
      } else {
        await onCreateCompanyContact({
          companyId: params.companyId,
          data: values,
        });
      }

      History.back();
    },
    [params],
  );

  return (
    <MainWrapper withOutNav header={<Jumbotron name="Company Contact" back />}>
      <CompanyContactForm initialValues={contact || {}} onSubmit={onSubmit} />
    </MainWrapper>
  );
};

export default EditCompanyContact;
