/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { useRemember } from 'react-remember';
import { handleLogout } from 'store/ducks/auth/operations';

import useOperation from './useOperation';

const useLogout = () => {
  const [onLogout] = useOperation(handleLogout);
  const [, remember] = useRemember();

  const logout = useCallback(() => {
    remember({ loginData: {} });
    return onLogout(undefined);
  }, []);

  return {
    logout,
  };
};

export default useLogout;
