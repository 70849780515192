import { NewsData } from 'interfaces/news';
import Toast from 'services/Toast';
import * as api from './api';

export const fetchNewsCreate = (params: Partial<NewsData>) => async () => {
  return api.fetchNewsCreate(params).catch(() => Toast.error('Error create news'));
};

export const fetchNewsUpdate = (params: Partial<NewsData>) => async () => {
  return api.fetchNewsUpdate(params).catch(() => Toast.error('Error update news'));
};

export const fetchNewsDelete = (id: string) => async () => {
  return api.fetchNewsDelete(id).catch(() => Toast.error('Error delete news '));
};
