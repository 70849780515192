/* eslint-disable react-hooks/exhaustive-deps */
import Jumbotron from 'components/Jumbotron';
import { useOperation, useSortStatus } from 'hooks/common';
import { AudienceListRes } from 'interfaces/audience';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { TeamData } from 'interfaces/team';
import { TeamWrapper } from 'layouts';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchAudienceList, fetchAudienceUpdate } from 'store/ducks/audience/operations';
import { getAudienceList } from 'store/ducks/audience/selectors';
import { fetchTeam } from 'store/ducks/team/operations';
import { getTeamById } from 'store/ducks/team/selectors';
import { StateProps } from 'store/interfaces';
import AudienceView from '../components/AudienceView';

const Audience = () => {
  const { location } = useHistory();

  const [onfetchTeam, isLoading] = useOperation(fetchTeam);
  const [onFetchAudienceList] = useOperation(fetchAudienceList);
  const [onFetchAudienceUpdate] = useOperation(fetchAudienceUpdate);

  const { sortStatus, handleSwitch } = useSortStatus([], {
    isSort: false,
  });
  const params: { teamId: string } = useParams();

  const team = useSelector<StateProps, TeamData | undefined>(getTeamById(params.teamId));
  const { items, meta } = useSelector<StateProps, AudienceListRes>(getAudienceList);

  useEffect(() => {
    if (!sortStatus.name) return;
    const pageString = queryString.parse(location.search)?.page;
    const page = (pageString && Number(pageString)) || 1;
    onFetchAudienceList({
      page: page,
      id: params.teamId,
      orderType: sortStatus.status,
      order: sortStatus.name,
      type: 'team',
    });
  }, [sortStatus]);

  useEffect(() => {
    onfetchTeam(params.teamId);
    onFetchAudienceList({ page: 1, id: params.teamId, type: 'team' });
  }, []);

  return (
    <TeamWrapper
      teamId={params.teamId}
      isLoading={isLoading}
      header={<Jumbotron name={team?.organizationName || ''} back backPath="/teams" />}
    >
      {team && (
        <AudienceView
          team={team}
          list={items}
          meta={meta}
          handleFetchList={onFetchAudienceList}
          handleAudienceUpdate={params =>
            onFetchAudienceUpdate(params).then(() =>
              onFetchAudienceList({
                id: team.id,
                page: Number(meta.currentPage),
                type: 'team',
              }),
            )
          }
          sortStatus={sortStatus}
          onSort={handleSwitch}
        />
      )}
    </TeamWrapper>
  );
};

export default Audience;
