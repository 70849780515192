import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { AuthenticatedWrapper } from 'layouts';

import { TeamList, Team, TeamEdit } from 'pages/team';
import { UserList, EditUser, NewUser } from 'pages/user';
import { UserTeamsList, EditTeamsUser } from 'pages/teamsnap';
import {
  CompaniesList,
  NewCompany,
  EditCompany,
  CompanyPreview,
  EditCompanyContact,
} from 'pages/companies';
import { ROI, ROIEdit, ROISlimReport, ROITrackingDashboard } from 'pages/roi';
import { Audience } from 'pages/audience';
import Route from './Route';
import { Contacts } from 'pages/companies/Contacts';
import Pricing from 'pages/pricing/Pricing';
import PricingEdit from 'pages/pricing/PricingEdit';
import ValuationReport from 'pages/pricing/ValuationReport';
import ROIReport from 'pages/roi/ROIReport';
import TeamMembers from 'pages/team/TeamMembers';
import MonthlyReportList from 'pages/monthlyReport/MonthlyReportList';
import MonthlyReportDetail from 'pages/monthlyReport/MonthlyReportDetail';
import CompanyMembers from 'pages/companies/CompanyMembers';
import { TeamContacts } from 'pages/team/TeamContacts';
import EditTeamContact from 'pages/team/EditTeamContact';
import CompanyROI from 'pages/companies/CompanyROI/ROI';
import CompanyROIEdit from 'pages/companies/CompanyROI/ROIEdit';
import IssueMapping from 'pages/companies/IssueMapping';
import CreateRecommendation from 'pages/companies/IssueMapping/CreateRecommendation';

const AuthorizedRoutes = () => {
  return (
    <Switch>
      <Route path="/" layout={AuthenticatedWrapper}>
        {/* Team */}
        <Route path="/teams" component={TeamList} />
        <Route path="/team/view/:teamId" component={Team} />
        <Route path="/team/edit/:teamId" component={TeamEdit} />
        <Route path="/team/new" component={TeamEdit} />
        <Route path="/pricing/view/:teamId" component={Pricing} />
        <Route path="/pricing/edit/:teamId/:valuationId" component={PricingEdit} />
        <Route path="/pricing/report/:teamId/:valuationId" component={ValuationReport} />
        <Route path="/roi/view/:teamId" component={ROI} />
        <Route path="/roi/edit/:teamId/:roiId" component={ROIEdit} />
        <Route path="/roi/tracking-dashboard/:roiId" component={ROITrackingDashboard} />
        <Route path="/roi/report/:teamId/:roiId" component={ROIReport} />
        <Route path="/roi/slim-report/:roiId" component={ROISlimReport} />
        <Route path="/audience/view/:teamId" component={Audience} />
        <Route path="/team-members/:teamId" component={TeamMembers} />
        <Route path="/team-contacts/:teamId" component={TeamContacts} />
        <Route
          path="/edit-contact-team/:teamId/:contactId?"
          component={EditTeamContact}
        />

        {/* User */}
        <Route path="/users" component={UserList} />
        <Route path="/user/:userId" component={EditUser} />
        <Route path="/user-new" component={NewUser} />

        {/* Teamsnap User */}
        <Route path="/teamsnap" component={UserTeamsList} />
        <Route path="/teamsnaps/:userId" component={EditTeamsUser} />

        {/* Company */}
        <Route path="/companies" component={CompaniesList} />
        <Route path="/company-new" component={NewCompany} />
        <Route path="/company-contacts/:companyId" component={Contacts} />
        <Route path="/edit-company/:companyId" component={EditCompany} />
        <Route
          path="/edit-contact-company/:companyId"
          exact
          component={EditCompanyContact}
        />
        <Route
          path="/edit-contact-company/:companyId/:contactId"
          component={EditCompanyContact}
        />
        <Route path="/company/:companyId" component={CompanyPreview} />
        <Route path="/issue-mapping/view/:companyId" component={IssueMapping} />
        <Route
          path="/issue-mapping/create-recommendation/:recommendationId"
          component={CreateRecommendation}
        />

        <Route path="/company-members/:companyId" component={CompanyMembers} />
        <Route path="/company-roi/view/:companyId" component={CompanyROI} />
        <Route
          path="/company-roi/tracking-dashboard/:roiId"
          component={ROITrackingDashboard}
        />
        <Route path="/company-roi/edit/:companyId/:roiId" component={CompanyROIEdit} />
        <Route path="/company-roi/slim-report/:roiId" component={ROISlimReport} />

        {/* Monthly */}
        <Route path="/monthly-report" exact component={MonthlyReportList} />
        <Route path="/monthly-report/detail/:reportId" component={MonthlyReportDetail} />

        {/* Home */}
        <Route path="/" component={() => <Redirect to="/teams" />} />
      </Route>
    </Switch>
  );
};

export default AuthorizedRoutes;
