import { createSelector } from 'reselect';
import { StateProps } from 'store/interfaces';
import { User } from 'interfaces/user';

export const getUsers = (state: StateProps) => state.user.users.items;

export const getUsersMeta = (state: StateProps) => state.user.users.meta;

export const getUserById = (userId: string) =>
  createSelector([getUsers], users => users.find(({ id }: User) => userId === id));

export const selectAdditionalInfo = (state: StateProps) => state.user.additionalInfo;

export const getCrsFocus = createSelector(selectAdditionalInfo, info => info.crsFocus);
