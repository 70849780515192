import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  title: ReactNode;
  className?: string;
}

const UITag: FC<Props> = ({ title, className }) => {
  return <Root className={className}>{title}</Root>;
};

const Root = styled.div`
  border-radius: 99em;
  background-color: #1d7cf7;
  height: 30px;
  color: #fff;
  font-size: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 6px 10px 0;
  padding: 0 20px;

  &:nth-child(2n) {
    background-color: #54a4fa;
  }
`;

export default UITag;
