import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { css } from 'styled-components';

import { RadioButtonsLineProps } from 'components/UI/interfaces';
import FieldSection from '../FieldSection';
import { RadioButtonsLine } from 'components/UI';

type Props = RadioButtonsLineProps & FieldRenderProps<string>;

const labelCss = css`
  margin-bottom: 6px;
  display: block;
`;

const FieldRadioButtonsLine = ({ input, label, meta, ...selectProps }: Props) => {
  return (
    <FieldSection labelCss={labelCss} input={input} meta={meta} label={label}>
      <RadioButtonsLine {...selectProps} value={input.value} onChange={input.onChange} />
    </FieldSection>
  );
};

export default FieldRadioButtonsLine;
