import Pagination from 'components/Pagination';
import TotalRow from 'components/TotalRow';
import { useModal, useOperation, useSortStatus } from 'hooks/common';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  fetchRecommendationCreate,
  fetchRecommendationDelete,
  fetchRecommendationsList,
  fetchRecommendationUpdate,
} from 'store/ducks/issueMapping/operations';
import { getRecommendationsList } from 'store/ducks/issueMapping/selectors';
import styled from 'styled-components';
import RecommendationsTable from './components/RecommendationsTable';
import queryString from 'query-string';
import { UIButton } from 'components/UI';
import RecommendationModal from './components/RecommendationModal';
import { ConfirmModal } from 'components/Modals';
import { RecommendationData } from 'interfaces/issueMapping';

const Recommendations = () => {
  const params: { companyId: string } = useParams();

  const [activeRow, setActiveRow] = useState<RecommendationData | null>(null);
  const { sortStatus, handleSwitch } = useSortStatus([], {
    isSort: false,
  });

  const [onFetchRecommendationsList] = useOperation(fetchRecommendationsList);
  const [onFetchRecommendationCreate, , createLoading] = useOperation(
    fetchRecommendationCreate,
  );
  const [onFetchRecommendationUpdate] = useOperation(fetchRecommendationUpdate);
  const [onFetchRecommendationDelete] = useOperation(fetchRecommendationDelete);

  const { items, meta } = useSelector(getRecommendationsList);

  const [showModal, hideModal] = useModal(() => {
    const handleClose = () => {
      hideModal();
      setActiveRow(null);
    };
    return (
      <RecommendationModal
        onClose={handleClose}
        data={activeRow || undefined}
        handleAccess={values => {
          if ('id' in values) {
            onFetchRecommendationUpdate(values).then(() => {
              onFetchRecommendationsList({ companyId: params.companyId, page: 1 });
              handleClose();
            });
            return;
          }
          onFetchRecommendationCreate({ companyId: params.companyId, ...values }).then(
            () => {
              onFetchRecommendationsList({ companyId: params.companyId, page: 1 });
              handleClose();
            },
          );
        }}
        isLoading={createLoading}
      />
    );
  }, [params.companyId, activeRow, createLoading]);

  const [showDeleteModal, hideDeleteModal] = useModal(() => {
    const handleClose = () => {
      hideDeleteModal();
      setActiveRow(null);
    };
    return (
      <ConfirmModal
        accessHandler={() => {
          if (!activeRow) return;
          onFetchRecommendationDelete(activeRow.id).then(() => {
            onFetchRecommendationsList({ companyId: params.companyId, page: 1 });
          });
          handleClose();
        }}
        cancelHandler={handleClose}
        textAccessBtn="Yes"
        textCancelBtn="No"
        name="Delete Recommendation"
        description={
          activeRow?.status === 'Published'
            ? 'This recommendation has been published and is currently available to the corporate member. If you delete this recommendation, it will not be recoverable. Are you sure you want to delete this recommendation?'
            : 'Are you sure you want to delete this recommendation?'
        }
        onClose={handleClose}
      />
    );
  }, [activeRow]);

  useEffect(() => {
    if (!sortStatus.name) return;
    const pageString = queryString.parse(window.location.search)?.page;
    const page = (pageString && Number(pageString)) || 1;
    onFetchRecommendationsList({
      page: page,
      companyId: params.companyId,
      orderType: sortStatus.status,
      order: sortStatus.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  useEffect(() => {
    onFetchRecommendationsList({ companyId: params.companyId, page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Body>
      <TotalRow maxWidth="1138px" title={''}>
        <Actions>
          <Pagination
            page={Number(meta.currentPage)}
            pageLimit={Number(meta.itemsPerPage)}
            total={meta.totalItems}
            submitHandler={page =>
              onFetchRecommendationsList({ companyId: params.companyId, page })
            }
          />
          <CreateButton
            title="Create Recommendation"
            modifiers="primary"
            handleClick={showModal}
          />
        </Actions>
      </TotalRow>
      <RecommendationsTable
        onSort={handleSwitch}
        sortStatus={sortStatus}
        rows={items}
        onDelete={row => {
          setActiveRow(row);
          showDeleteModal();
        }}
        onEdit={row => {
          setActiveRow(row);
          showModal();
        }}
      />
    </Body>
  );
};

const Body = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 24px 36px;
  margin-bottom: 30px;
`;

const Actions = styled.div`
  display: flex;
  gap: 16px;
`;

const CreateButton = styled(UIButton)`
  font-size: 400;
`;

export default Recommendations;
