import { ROIReport } from 'interfaces/roi';
import React from 'react';
import styled from 'styled-components';

type Row = ROIReport['corporateIntangibleAssetsItems'];

interface Props {
  rows: Row;
  headCells: string[];
}

interface TableRowProps {
  row: Row[0];
}

const userTableWidth = {
  column1: 580,
  column2: 400,
} as const;

const { column1, column2 } = userTableWidth;

const TableRowWrapper = ({ row }: TableRowProps) => {
  const { label, value } = row;

  return (
    <TableRow>
      <TableRowCell>{label}</TableRowCell>

      <TableRowCell>{value || value === 0 ? `${value}%` : 'N/A'}</TableRowCell>

      <TableRowCell />
    </TableRow>
  );
};

const CorporateIntangibleAssetTable = ({ rows, headCells }: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${column1}px` }} />
          <col span={1} style={{ width: `${column2}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            {headCells.map((item, index) => (
              <TableHeadCell key={index}>{item}</TableHeadCell>
            ))}
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map((item, index) => (
            <TableRowWrapper key={index} row={item} />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  background: #e8e8e8;
  position: sticky;
  top: 0;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableBody = styled.tbody`
  background: #f9f9f9;
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td<{ bold?: boolean }>`
  border-bottom: 1px solid #e8e8e8;
  line-height: 70px;
  color: var(--black);
  vertical-align: middle;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: -0.02em;

  ${({ bold }) => bold && 'font-weight: 500;'}

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

export default CorporateIntangibleAssetTable;
