import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import Modal from 'react-modal';
import { ModalProvider } from 'react-modal-hook';
import AppRouter from 'routes';
import configureStore from 'store';
import Rememberer from 'react-remember';

import CustomToast from 'components/CustomToast';
import { normalize } from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';
import {
  globalStyles,
  toastStyles,
  suggestStyles,
  pdfPreviewStyles,
} from 'assets/styles';

const { store } = configureStore();

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${globalStyles}
  ${toastStyles}
  ${suggestStyles}
  ${pdfPreviewStyles}
`;

const App = (): JSX.Element => {
  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  return (
    <Rememberer>
      <Provider store={store}>
        <CustomToast />
        <ModalProvider>
          <AppRouter />
        </ModalProvider>
        <GlobalStyle />
      </Provider>
    </Rememberer>
  );
};

export default App;
