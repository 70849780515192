import React, { FC, CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import useDropdown from 'use-dropdown';
import ArrowDownSVG from 'assets/images/icons/arrow-down.svg';
import { ReactSVG } from 'react-svg';

interface Props {
  title: ReactNode;
  disableArrow?: boolean;
  className?: string;
  style?: CSSProperties;
}

const Dropdown: FC<Props> = ({ title, children, disableArrow, ...rests }) => {
  const [containerRef, isOpen, open, close] = useDropdown();

  return (
    <Root ref={containerRef} {...rests}>
      <Trigger onClick={open}>
        {title}
        {!disableArrow && (isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />)}
      </Trigger>

      {isOpen && (
        <Panel className="dropdown-panel" onClick={close}>
          {children}
        </Panel>
      )}
    </Root>
  );
};

const Root = styled.span`
  display: flex;
  align-items: center;
  position: relative;
`;

const Trigger = styled.span`
  font-size: 12px;
  color: var(--black);
  cursor: pointer;
  user-select: none;
  display: flex;
`;

const Panel = styled.div`
  position: absolute;
  top: 30px;
  right: -10px;
  height: max-content;
  background-color: #fff;
  border-radius: 6px;
  z-index: 10;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
`;

const ArrowDownIcon = styled(ReactSVG).attrs(() => ({ src: ArrowDownSVG }))`
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
`;

const ArrowUpIcon = styled(ArrowDownIcon)`
  transform: rotate(180deg);
`;

export default Dropdown;
