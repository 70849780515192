/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { UIButton, UITextInput } from 'components/UI';
import { useSelector } from 'react-redux';
import { MainWrapper } from 'layouts';
import { headTableUsers } from 'constants/tableHeads';
import { UsersTable } from 'components/Tables';
import Pagination from 'components/Pagination';
import Jumbotron from 'components/Jumbotron';
import { useOperation } from 'hooks/common';
import {
  fetchUserChangeStatus,
  fetchUserDelete,
  fetchUsersSearch,
} from 'store/ducks/user/operations';
import { getUsers, getUsersMeta } from 'store/ducks/user/selectors';
import { StateProps } from 'store/interfaces';
import { User } from 'interfaces/user';
import { PaginationMeta } from 'interfaces/common';

const UserList = () => {
  const [onfetchUsers, isLoading] = useOperation(fetchUsersSearch);
  const [onFetchDeleteUser] = useOperation(fetchUserDelete);
  const [onFetchUserChangeStatus] = useOperation(fetchUserChangeStatus);
  const [initialQuery, setInitialValues] = useState<string>('');
  const { location, push } = useHistory();
  const users = useSelector<StateProps, User[]>(getUsers);

  const meta = useSelector<StateProps, PaginationMeta>(getUsersMeta);
  const { currentPage, totalItems, itemsPerPage } = meta;

  const getInitialState = () => {
    const filtersString = queryString.parse(location.search)?.q;
    const pageString = queryString.parse(location.search)?.page;

    if (filtersString) {
      const param = JSON.parse(window.atob(filtersString?.toString()));
      onfetchUsers({ page: Number(pageString) || 1, q: param });
      return setInitialValues(param);
    } else {
      setInitialValues('');
      return onfetchUsers({ page: Number(pageString) || 1 });
    }
  };

  useEffect(() => {
    getInitialState();
  }, [location]);

  const handleSearchUsers = (val: string) => {
    const base64 = window.btoa(JSON.stringify(val));
    const page = queryString.parse(location.search)?.page;

    if (page) {
      if (val) {
        push(`${location.pathname}?${queryString.stringify({ page, q: base64 })}`);
        return onfetchUsers({ page: 1, q: val });
      } else {
        push(`${location.pathname}`);
        return onfetchUsers({ page: 1, q: val });
      }
    } else {
      if (val) {
        push(`${location.pathname}?${queryString.stringify({ q: base64 })}`);
        return onfetchUsers({ page: 1, q: val });
      } else {
        push(`${location.pathname}`);
        return onfetchUsers({ page: 1, q: val });
      }
    }
  };

  const submitHandlerPagination = (page: number) => {
    const qString = queryString.parse(location.search)?.q;

    if (qString) {
      const param = JSON.parse(window.atob(qString?.toString()));
      return onfetchUsers({ page, q: param });
    } else {
      return onfetchUsers({ page });
    }
  };

  const handleSearchUsersDebounce = useCallback(debounce(handleSearchUsers, 800), []);

  return (
    <MainWrapper
      isLoading={isLoading}
      header={
        <Jumbotron name="Users">
          <Btn
            modifiers="primary"
            handleClick={() => push('user-new')}
            title="Create User"
          />
        </Jumbotron>
      }
      contentWithoutLoading={
        <SuggestWrapper>
          <UITextInput
            value={initialQuery}
            onChange={({ target: { value } }) => {
              setInitialValues(value);
              handleSearchUsersDebounce(value);
            }}
            placeholder="Search users by email"
            disableTrim
          />
        </SuggestWrapper>
      }
    >
      <>
        <PaginationWrapper>
          {totalItems > 0 && (
            <Pagination
              total={totalItems}
              pageLimit={Number(itemsPerPage)}
              page={Number(currentPage)}
              submitHandler={(page: number) => submitHandlerPagination(page)}
            />
          )}
        </PaginationWrapper>
        <UsersTable
          rows={users}
          headCells={headTableUsers}
          handleDeleteUser={onFetchDeleteUser}
          handleChangeStatus={onFetchUserChangeStatus}
        />
      </>
    </MainWrapper>
  );
};

const Btn = styled(UIButton)`
  width: 160px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const SuggestWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export default UserList;
