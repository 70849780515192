import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { FieldTextInput, FieldPasswordInput } from 'components/FormFields';
import { UIButton } from 'components/UI';
import { required, email } from 'utils/validation';
import { AuthData } from 'interfaces/auth';
import { composeValidators } from 'utils';
import styled from 'styled-components';
import { AuthFormProps } from 'interfaces/common';
import { Field } from 'components/FinalForm';

const LoginForm = ({ onSubmit, initialValues }: AuthFormProps) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    render={({
      handleSubmit,
      submitting,
      hasValidationErrors,
    }: FormRenderProps<AuthData>) => {
      return (
        <form>
          <Title>Accelerist Admin</Title>

          <Field
            name="email"
            label="Email"
            component={FieldTextInput}
            placeholder="Enter email"
            validate={composeValidators<string>(required, email)}
          />

          <Field
            name="password"
            label="Password"
            type="password"
            component={FieldPasswordInput}
            validate={required}
            placeholder="Enter password"
          />

          <Btn
            modifiers="primary"
            title="Login"
            handleClick={handleSubmit}
            type="submit"
            disabled={submitting || hasValidationErrors}
            isLoading={submitting}
          />
        </form>
      );
    }}
  />
);

const Title = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  margin-bottom: 20px;
  text-align: center;
`;

const Btn = styled(UIButton)`
  width: 100%;
  margin-bottom: 25px;
`;

export default LoginForm;
