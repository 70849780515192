import React, { useCallback, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

import { UITextInput } from 'components/UI';
import AddIcon from 'assets/images/icons/add-icon.svg';
import CloseIcon from 'assets/images/icons/close.svg';
import { FormLabel } from 'components/UI/form';

const FieldInputArray = ({ input, label }: FieldRenderProps<[]>) => {
  const { value, onChange } = input;
  const [inputValue, onChangeInputValue] = useState<string>('');

  const onSetValue = useCallback(
    val => {
      onChangeInputValue('');
      return onChange(value.length > 0 ? value.concat(val) : [val]);
    },
    [value, onChange],
  );

  const onRemoveValue = useCallback(
    i => {
      return onChange(value.filter((item: string) => item !== i));
    },
    [value, onChange],
  );

  return (
    <Field>
      {label && <FormLabel>{label}</FormLabel>}
      <Wrapper>
        <UITextInput
          value={inputValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeInputValue(e.target.value)
          }
        />
        {inputValue && (
          <IconWrapper>
            <ReactSVG src={AddIcon} onClick={() => onSetValue(inputValue)} />
          </IconWrapper>
        )}
      </Wrapper>

      {value.length > 0 && (
        <SuggestedList>
          {value.map((item: string) => (
            <SuggestedItem key={item}>
              {item}
              <Remove onClick={() => onRemoveValue(item)} src={CloseIcon} />
            </SuggestedItem>
          ))}
        </SuggestedList>
      )}
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const SuggestedList = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
`;

const SuggestedItem = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #caf0ff;
  font-size: 12px;
  line-height: 150%;
  border-radius: 6px;
  padding: 5px 10px;
  margin-bottom: 8px;
  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const Remove = styled(ReactSVG)`
  margin-left: 7px;
  cursor: pointer;
  & svg {
    width: 11px;
    height: 11px;
    & path {
      stroke: var(--black);
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 532px;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 11px;
  cursor: pointer;

  & svg {
    path {
      stroke: var(--blue);
    }
  }
`;

export default FieldInputArray;
