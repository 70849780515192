import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { TableSortHeaderCell } from 'components/UI/table';

import { SortStatus } from 'hooks/common/useSortStatus';

import { RecommendationData } from 'interfaces/issueMapping';
import UILink from 'components/UI/UILink';
import { useHistory } from 'react-router';
import { ReactSVG } from 'react-svg';
import EllipseSVG from 'assets/images/icons/ellipse.svg';
import { DropdownList } from 'components/DropdownList';

interface Props {
  rows: RecommendationData[];
  sortStatus: SortStatus;
  onSort: (colName: string) => void;
  onDelete: (row: RecommendationData) => void;
  onEdit: (row: RecommendationData) => void;
}

interface TableRowProps {
  row: RecommendationData;
  onDelete: Props['onDelete'];
  onEdit: Props['onEdit'];
}

const userTableWidth = {
  column1: 300,
  column2: 210,
  column3: 180,
  column4: 180,
  column5: 60,
} as const;

const { column1, column2, column3, column4, column5 } = userTableWidth;

const TableRowWrapper = ({ row, onDelete, onEdit }: TableRowProps) => {
  const { push } = useHistory();
  const { name, createdAt, status, publishedAt, id } = row;

  return (
    <TableRow>
      <TableRowCell>
        <UILink onClick={() => push(`/issue-mapping/create-recommendation/${id}`)}>
          {name}
        </UILink>
      </TableRowCell>
      <TableRowCell>
        {createdAt ? format(new Date(createdAt), 'MMM dd, yyyy') : ''}
      </TableRowCell>
      <TableRowCell>
        <StatusValue $status={status}>{status}</StatusValue>
      </TableRowCell>
      <TableRowCell>
        {publishedAt ? format(new Date(publishedAt), 'MMM dd, yyyy') : ''}
      </TableRowCell>
      <TableRowCell>
        <DropdownList
          title={<EllipseIcon src={EllipseSVG} />}
          options={
            row.status === 'Draft'
              ? [
                  { label: 'Edit', value: 'Edit' },
                  { label: 'Delete', value: 'Delete' },
                ]
              : [{ label: 'Delete', value: 'Delete' }]
          }
          disableArrow
          onClick={option => {
            if (option === 'Edit') return onEdit(row);
            if (option === 'Delete') return onDelete(row);
          }}
        />
      </TableRowCell>
    </TableRow>
  );
};

const RecommendationsTable = ({ rows, sortStatus, onSort, onDelete, onEdit }: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${column1}px` }} />
          <col span={1} style={{ width: `${column2}px` }} />
          <col span={1} style={{ width: `${column3}px` }} />
          <col span={1} style={{ width: `${column4}px` }} />
          <col span={1} style={{ width: `${column5}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            <TableSortHeaderCell colName="name" sortStatus={sortStatus} onClick={onSort}>
              Recommendation Name
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="createdAt"
              sortStatus={sortStatus}
              onClick={onSort}
            >
              Create date
            </TableSortHeaderCell>
            <TableHeadCell>Status</TableHeadCell>
            <TableHeadCell>Publish Date</TableHeadCell>
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(item => (
            <TableRowWrapper
              key={item.id}
              row={item}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div``;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  background: #f4f4f4;
  position: sticky;
  top: 0;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

const StatusValue = styled.div<{ $status: RecommendationData['status'] }>`
  padding: 6px 8px;
  border-radius: 6px;
  width: fit-content;
  min-width: 112px;
  text-align: center;

  ${({ $status }) => {
    if ($status === 'Published')
      return `
      background-color: var(--blue);
      color: #FFFFFF;
    `;

    if ($status === 'Draft')
      return `
      border: 1px solid #868686;
      color: #868686;
    `;

    if ($status === 'Unpublished')
      return `
      border: 1px solid #BFBFBF;
      background: #F9F9F9;
      color: #737373;
    `;
  }}
`;

const EllipseIcon = styled(ReactSVG)`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
`;

export default RecommendationsTable;
