import AttachmentSVG from 'assets/images/icons/attachment.svg';
import CloseSVG from 'assets/images/icons/close.svg';
import { UISpinner } from 'components/UI';
import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { ReactSVG } from 'react-svg';
import Toast from 'services/Toast';
import { uploadFile } from 'store/ducks/utility/api';
import styled from 'styled-components';
import { lessThan5MB } from 'utils/validation';
import { getFilenameByPath } from 'utils/formatters';
import { FormLabel } from 'components/UI/form';

interface Props extends FieldRenderProps<string[] | string> {
  disabled: boolean;
  category: string;
  htmlId?: string;
  accept?: string;
  hideIcon?: boolean;
  title?: string;
  label?: string;
  isSingleFile?: boolean;
}

const FieldUploadFile: FC<Props> = ({
  input,
  disabled,
  category,
  htmlId,
  accept,
  hideIcon,
  title = 'Attach',
  label,
  isSingleFile,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { value, onChange } = input;

  const onRemove = (fileKey: string) => {
    if (!Array.isArray(value)) return;
    onChange(value.filter(item => item !== fileKey));
  };

  const onRemoveSingleFile = () => {
    onChange(undefined);
  };

  const onUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) {
      return;
    }
    setIsLoading(true);
    const file = e.target.files[0];
    const sizeError = lessThan5MB(file.size);
    if (sizeError) {
      setIsLoading(false);
      return Toast.error(sizeError);
    }

    uploadFile(file, category).then(v => {
      setIsLoading(false);
      if (v.status === 'success') {
        if (isSingleFile) {
          onChange(v.fileKey);
        } else {
          onChange(value ? [...value, v.fileKey] : [v.fileKey]);
        }
        return Toast.success('file uploaded successfully');
      }
      Toast.error('Error upload file');
    });
  };

  const inputId = useMemo(() => htmlId || 'upload-input', [htmlId]);

  return (
    <Root>
      {label && <FormLabel>{label}</FormLabel>}
      {disabled || (
        <Input
          id={inputId}
          type="file"
          accept={accept ?? 'image/*,application/pdf,.xlsx,.xls,.doc,.docs'}
          onChange={onUpload}
        />
      )}
      <Label htmlFor={inputId} style={{ display: 'block' }}>
        <AttachBtn disabled={disabled}>
          {isLoading ? (
            <Spinner width={18} height={18} />
          ) : (
            <>
              {!hideIcon && <AttachIcon src={AttachmentSVG} />}
              {title}
            </>
          )}
        </AttachBtn>
      </Label>
      <AttachList>
        {Array.isArray(value) &&
          value.length > 0 &&
          value.map((item, i) => (
            <AttachListItem key={i}>
              {getFilenameByPath(item)}
              {disabled || <RemoveIcon src={CloseSVG} onClick={() => onRemove(item)} />}
            </AttachListItem>
          ))}
        {value && typeof value === 'string' && (
          <AttachListItem>
            {getFilenameByPath(value)}
            {disabled || (
              <RemoveIcon src={CloseSVG} onClick={() => onRemoveSingleFile()} />
            )}
          </AttachListItem>
        )}
      </AttachList>
    </Root>
  );
};

const Root = styled.div`
  margin-bottom: 24px;
`;

const AttachBtn = styled.div<{ disabled: boolean }>`
  width: 96px;
  height: 36px;
  color: var(--black);
  background: #caf0ff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  user-select: none;

  &:hover {
    cursor: pointer;
    background: #daf5ff;
  }

  &:active {
    background: #b6ebff;
  }

  &[disabled] {
    background: #dcf5ff;
    color: #a8bed2;
    cursor: not-allowed;
  }
`;

const AttachIcon = styled(ReactSVG)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  display: inline-block;
`;

const AttachList = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
`;

const AttachListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border: 1px solid #caf0ff;
  border-radius: 6px;
  margin-right: 8px;
  margin-top: 8px;
  word-break: break-all;
`;

const RemoveIcon = styled(ReactSVG)`
  margin-left: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    height: 12px;
    width: 12px;
  }
`;

const Input = styled.input`
  display: none;
`;

const Spinner = styled(UISpinner)`
  padding: 3px 0;
`;

const Label = styled.label`
  display: block;
  width: max-content;
`;

export default FieldUploadFile;
