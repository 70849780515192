/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CompanyWrapper } from 'layouts';
import { useParams } from 'react-router-dom';
import { useOperation } from 'hooks/common';
import {
  fetchCompany,
  fetchCompanyNews,
  fetchCompanySubscription,
  fetchCompanySubscriptionDelete,
  fetchCompanySubscriptionUpdate,
  fetchSocialImpactLog,
} from 'store/ducks/companies/operations';
import {
  getCompanyById,
  getCompanyNews,
  getCompanySocialImpactLog,
  getCompanySubscription,
} from 'store/ducks/companies/selectors';
import Jumbotron from 'components/Jumbotron';
import CompanyView from './CompanyView';
import { StateProps } from 'store/interfaces';
import { Company, News, SubscriptionCompany } from 'interfaces/companies';
import {
  fetchNewsCreate,
  fetchNewsDelete,
  fetchNewsUpdate,
} from 'store/ducks/news/operations';
import { fetchExportSocialImpactLog } from 'store/ducks/companies/api';
import useDownloadFile from 'hooks/common/useDownloadFile';

const CompanyPreview = () => {
  const [onFetchCompany] = useOperation(fetchCompany);
  const [onfetchCompanyNews] = useOperation(fetchCompanyNews);
  const [onFetchCompanySubscription] = useOperation(fetchCompanySubscription);
  const [onFetchCompanySubscriptionUpdate] = useOperation(fetchCompanySubscriptionUpdate);
  const [onFetchCompanySubscriptionDelete] = useOperation(fetchCompanySubscriptionDelete);
  const [onFetchNewsCreate] = useOperation(fetchNewsCreate);
  const [onFetchNewsUpdate] = useOperation(fetchNewsUpdate);
  const [onFetchNewsDelete] = useOperation(fetchNewsDelete);
  const [onFetchSocialImpactLog] = useOperation(fetchSocialImpactLog);

  const params: { companyId: string } = useParams();
  const company = useSelector<StateProps, Company | undefined>(
    getCompanyById(params.companyId),
  );
  const news = useSelector<StateProps, News[]>(getCompanyNews);
  const subscription = useSelector(getCompanySubscription);
  const socialImpactLog = useSelector(getCompanySocialImpactLog);

  const exportSocialImpactLogExcel = useDownloadFile(fetchExportSocialImpactLog);

  useEffect(() => {
    onFetchCompany(params.companyId);
    onfetchCompanyNews(params.companyId);
    onFetchCompanySubscription(params.companyId);
    onFetchSocialImpactLog(params.companyId);
  }, [params]);

  const handleUpdateSubscription = (data: SubscriptionCompany) => {
    onFetchCompanySubscriptionUpdate(params.companyId, data);
  };

  return (
    <CompanyWrapper
      companyId={params.companyId}
      header={<Jumbotron name="Company" back />}
    >
      {company && (
        <CompanyView
          company={company}
          news={news}
          subscription={subscription}
          socialImpactLog={socialImpactLog}
          exportSocialImpactLogExcel={() => exportSocialImpactLogExcel(params.companyId)}
          handleUpdateSubscription={handleUpdateSubscription}
          handleDeleteSubscription={() =>
            onFetchCompanySubscriptionDelete(params.companyId)
          }
          onNewsCreate={async param => {
            await onFetchNewsCreate(param);
            onfetchCompanyNews(params.companyId);
          }}
          onNewsUpdate={async param => {
            await onFetchNewsUpdate(param);
            onfetchCompanyNews(params.companyId);
          }}
          onNewsDelete={async id => {
            await onFetchNewsDelete(id);
            onfetchCompanyNews(params.companyId);
          }}
        />
      )}
    </CompanyWrapper>
  );
};

export default CompanyPreview;
