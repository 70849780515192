import React, { useState } from 'react';
import styled from 'styled-components';
import { difference } from 'lodash';
import { Form, FormRenderProps } from 'react-final-form';
import Toast from 'services/Toast';
import { FiledMultiSelectWithCheckBoxes } from 'components/FormFields';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { UIButton } from 'components/UI';
import { TeamData } from 'interfaces/team';
import { affinitiesOptions } from 'constants/company';
import { formatValueEnum, SelectOption } from 'components/UI/interfaces';
import { ISelectProps } from 'react-multi-select-component/dist/lib/interfaces';
import { Field } from 'components/FinalForm';

export interface FormProps {
  handleUpdateTeam: (values: TeamData) => OnSubmitReturnType;
  team: TeamData;
}

const AffinityForm = ({ handleUpdateTeam, team }: FormProps) => {
  const [isEdit, setEdit] = useState<boolean>(team.affinities?.length === 0);

  const onSubmit = async (values: TeamData) => {
    if (values.affinities && difference(team.affinities, values.affinities)) {
      try {
        const result = await handleUpdateTeam(values);
        setEdit(false);
        return result;
      } catch (error) {
        Toast.error('Team error updated');
      }
    }
    return setEdit(false);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={team}
      render={({ handleSubmit, submitting, values }: FormRenderProps<TeamData>) => {
        return (
          <div>
            <Header>
              <Title>Constituent demo and Nonprofit affinities</Title>

              {isEdit ? (
                <Btn
                  modifiers="primary"
                  title="Save"
                  handleClick={handleSubmit}
                  type="submit"
                  disabled={submitting}
                  isLoading={submitting}
                />
              ) : (
                <Btn
                  modifiers="secondary"
                  title="Edit"
                  handleClick={() => setEdit(true)}
                  type="button"
                />
              )}
            </Header>

            {isEdit ? (
              <Field
                name="affinities"
                label="Select affinities"
                component={FiledMultiSelectWithCheckBoxes}
                options={affinitiesOptions}
                placeholder={'Select'}
                formatValue={formatValueEnum.BASE_ARRAY}
              />
            ) : (
              <InfoValue>
                {values.affinities && values.affinities.length > 0
                  ? values.affinities.join(', ')
                  : 'No information'}
              </InfoValue>
            )}
          </div>
        );
      }}
    />
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const Btn = styled(UIButton)`
  width: 80px;
  font-size: 12px;
  padding: 5px 0;
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
`;

export const InfoValue = styled.p`
  font-size: 16px;
  line-height: 155%;
  margin-bottom: 24px;
  min-height: 46px;
  display: flex;
  align-items: center;
`;

export default AffinityForm;
