import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { ROIMeltwaterData } from 'interfaces/roi';
import { TrackingDashboardDocumentStatusEnum } from 'constants/roi';
import { format } from 'date-fns';
import { formatNumber } from 'utils/formatters';
import { UINoImage } from 'components/UI';
import NewSVG from 'assets/images/icons/news.svg';
import ForumsSVG from 'assets/images/icons/forums.svg';
import BlogsSVG from 'assets/images/icons/blogs.svg';
import FacebookSquareSVG from 'assets/images/icons/facebook-square.svg';
import RedditSVG from 'assets/images/icons/reddit.svg';
import TwitterBridSVG from 'assets/images/icons/twitter-brid.svg';
import InstagramSVG from 'assets/images/icons/instagram.svg';

interface Props {
  data?: ROIMeltwaterData['analyticsData']['exports']['data'];
}

const getIcon = (docItem: ROIMeltwaterData['analyticsData']['exports']['data'][0]) => {
  if (docItem.document_media_type === 'news' || docItem.source_name === 'news')
    return <NewsInfoLogo src={NewSVG} />;

  if (docItem.document_media_type === 'forums' || docItem.source_name === 'forums')
    return <NewsInfoLogo src={ForumsSVG} />;

  if (docItem.document_media_type === 'blogs' || docItem.source_name === 'blogs')
    return <NewsInfoLogo src={BlogsSVG} />;

  if (docItem.document_media_type === 'facebook' || docItem.source_name === 'facebook')
    return <NewsInfoLogo src={FacebookSquareSVG} />;

  if (docItem.document_media_type === 'reddit' || docItem.source_name === 'reddit')
    return <NewsInfoLogo src={RedditSVG} />;

  if (docItem.document_media_type === 'twitter' || docItem.source_name === 'twitter')
    return <NewsInfoLogo src={TwitterBridSVG} />;

  if (docItem.document_media_type === 'instagram' || docItem.source_name === 'instagram')
    return <NewsInfoLogo src={InstagramSVG} />;
};

const DocumentCard = ({ data }: Props) => {
  return (
    <Root>
      {data?.map(item => (
        <NewsRow key={item.document_id} onClick={() => window.open(item.document_url)}>
          {item.document_image_link ? (
            <NewsImage src={item.document_image_link} />
          ) : (
            <NoImage />
          )}
          <NewsInfo>
            <NewsInfoTitle>
              {getIcon(item) || <EmptyInfoLogo />}
              <NewsInfoName>{item.document_authors?.[0]?.name}</NewsInfoName>
              <NewsInfoStatus
                type={TrackingDashboardDocumentStatusEnum[item.document_sentiment]}
              >
                {TrackingDashboardDocumentStatusEnum[item.document_sentiment]}
              </NewsInfoStatus>
            </NewsInfoTitle>
            <NewsInfoDesc>{item.document_title}</NewsInfoDesc>
            <NewsInfoFooter>
              <NewsInfoDate>
                {format(new Date(item.document_publish_date), 'd MMM y, h:ss')}
              </NewsInfoDate>
              <NewsActionGroup>
                <NewsAction>{formatNumber(item.source_reach)} Reach</NewsAction>
                <NewsAction>Social Echo</NewsAction>
              </NewsActionGroup>
            </NewsInfoFooter>
          </NewsInfo>
        </NewsRow>
      ))}
    </Root>
  );
};

const Root = styled.div`
  height: 300px;
  overflow: auto;
`;

const NewsRow = styled.div`
  height: 80px;
  display: flex;
  margin-bottom: 24px;
  cursor: pointer;
`;
const NewsImage = styled.img`
  width: 123px;
  height: 79px;
  border-radius: 6px;
`;
const NoImage = styled(UINoImage)`
  width: 123px;
  height: 79px;
`;
const NewsInfo = styled.div`
  width: 410px;
  margin-left: 32px;
`;
const NewsInfoTitle = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
`;
const NewsInfoName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
`;
const NewsInfoLogo = styled(ReactSVG)`
  margin-right: 10px;
`;
const EmptyInfoLogo = styled.div`
  width: 21px;
  height: 21px;
  margin-right: 10px;
`;
const NewsInfoStatus = styled.div<{ type: TrackingDashboardDocumentStatusEnum }>`
  margin-left: auto;

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid transparent;
    display: inline-block;
    margin-left: 8px;

    ${({ type }) => {
      if (type === TrackingDashboardDocumentStatusEnum.positive) {
        return `border-color: #4AD78B`;
      }
      if (type === TrackingDashboardDocumentStatusEnum.negative) {
        return `border-color: #E92D30;`;
      }
      if (type === TrackingDashboardDocumentStatusEnum.neutral) {
        return `border-color: #c4c4c4;`;
      }
    }}
  }
`;
const NewsInfoDesc = styled.div`
  color: var(--darkGray);
  font-size: 12px;
  line-height: 150%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 14px;
  margin-left: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const NewsInfoFooter = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 150%;
  margin-top: 14px;
  padding-bottom: 4px;
  border-bottom: 1px solid #e8e8e8;
  margin-left: 30px;
`;
const NewsInfoDate = styled.div`
  color: var(--darkGray);
`;
const NewsActionGroup = styled.div`
  color: var(--blue);
  display: flex;
  margin-left: auto;
`;
const NewsAction = styled.div`
  margin-left: 10px;
`;

export default DocumentCard;
