import React, { useEffect } from 'react';
import useDropdown from 'use-dropdown';
import { useSelector } from 'react-redux';
import { getCurrentAdmin } from 'store/ducks/auth/selectors';
import { User } from 'interfaces/user';
import { StateProps } from 'store/interfaces';
import { Location } from 'history';

import styled from 'styled-components';

export interface HeaderDropDownProps {
  handleLogout: () => void;
  location: Location;
}

const HeaderDropDown = ({ handleLogout, location }: HeaderDropDownProps) => {
  const [containerRef, isOpen, open, close] = useDropdown();
  const user = useSelector<StateProps, User>(getCurrentAdmin);

  const { pathname } = location;
  useEffect(close, [close, pathname]);

  return (
    <Root ref={containerRef}>
      <Inner onClick={open}>
        <Title>{user?.email}</Title>
      </Inner>

      {isOpen && (
        <List>
          <Logout onClick={handleLogout}>Log out</Logout>
        </List>
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  max-width: 177px;
  cursor: pointer;
  position: relative;
`;

const Inner = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Title = styled.p`
  font-size: 12px;
  line-height: 150%;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 48px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(40, 31, 61, 0.04);
  border-radius: 6px;
  padding: 24px;
  z-index: 1;
`;

const Logout = styled.div`
  font-size: 12px;
  color: var(--red);
  &:hover {
    color: #eb1c2d;
  }
`;

export default HeaderDropDown;
