import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { ReactSwitchProps } from 'react-switch';

import styled from 'styled-components';
import { FormError, UISwitch } from 'components/UI';

type Props = Partial<ReactSwitchProps> & FieldRenderProps<boolean>;

const FieldSwitcher = ({ input, label, meta, ...rest }: Props) => {
  const { error, submitError, touched, dirtySinceLastSubmit } = meta;
  const isError = (error && touched) || (submitError && !dirtySinceLastSubmit);

  return (
    <Field>
      <Label>
        <span>{label}</span>
        <UISwitch checked={input.value || false} onChange={input.onChange} />
      </Label>

      {isError && <Error meta={meta} />}
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 155%;
  font-style: normal;
  font-weight: normal;
  color: var(--black);
  margin-bottom: 4px;
  display: flex;
  align-items: center;

  & span {
    margin-right: 36px;
  }
`;

const Error = styled(FormError)`
  position: absolute;
`;

export default FieldSwitcher;
