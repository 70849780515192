import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { UIButton, SingleSelect, UICheckbox } from 'components/UI';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { ROILevelEnum, SubscriptionPlan, SubscriptionProps } from 'interfaces/team';
import { subscriptionPlanOptions } from 'constants/team';
import { Field } from 'components/FinalForm';
import { SelectOption } from 'components/UI/interfaces';
import RadioList from 'components/RadioList';

export interface FormProps {
  onSubmit: (values: SubscriptionProps) => OnSubmitReturnType;
  handleRemoveSubscription: () => void;
  initialValues?: SubscriptionProps | {};
  isLoading?: boolean;
}

const SubscriptionForm = ({
  onSubmit,
  initialValues,
  handleRemoveSubscription,
}: FormProps) => {
  const initValues: SubscriptionProps | {} | undefined = initialValues
    ? {
        ...initialValues,
        roiLevel: initialValues['roiLevel'] || ROILevelEnum.Level1,
      }
    : {};

  return (
    <Form
      initialValues={initValues}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
        values,
        form,
      }: FormRenderProps<SubscriptionProps>) => {
        return (
          <div>
            <Body>
              <FieldSection>
                <TitleSection>Subscription Plan</TitleSection>
                <Field name="name">
                  {({ input }) => (
                    <SingleSelect
                      placeholder="Select Subscription Plan"
                      options={subscriptionPlanOptions}
                      value={input.value}
                      handleSelect={val => {
                        const value = (val as SelectOption)?.value;
                        input.onChange(value);
                        if (value !== 'A la carte') {
                          form.change('isProspecting', true);
                          form.change('isAudience', true);
                          form.change('isRoi', true);
                          form.change('isPrices', true);
                          form.change('isFundraising', true);
                        } else {
                          form.change('isProspecting', false);
                          form.change('isAudience', false);
                          form.change('isRoi', false);
                          form.change('isPrices', false);
                          form.change('isFundraising', false);
                        }
                      }}
                    />
                  )}
                </Field>
              </FieldSection>
              <FieldSection>
                <Field name="isProspecting">
                  {({ input }) => (
                    <Checkbox
                      label="Prospecting"
                      check={input.value}
                      handleClick={() => input.onChange(!input.value)}
                      disabled={values.name !== 'A la carte'}
                    />
                  )}
                </Field>

                <Field name="isAudience">
                  {({ input }) => (
                    <Checkbox
                      label="Audience"
                      check={input.value}
                      handleClick={() => input.onChange(!input.value)}
                      disabled={values.name !== 'A la carte'}
                    />
                  )}
                </Field>

                <Field name="isRoi">
                  {({ input }) => (
                    <Checkbox
                      label="ROI"
                      check={input.value}
                      handleClick={() => input.onChange(!input.value)}
                      disabled={values.name !== 'A la carte'}
                    />
                  )}
                </Field>

                <Field name="isCanva">
                  {({ input }) => (
                    <IndentWrapper>
                      <Checkbox
                        label="Custom Report"
                        check={input.value}
                        handleClick={() => input.onChange(!input.value)}
                        disabled={
                          values.name !== SubscriptionPlan.A_LA_CARTE || !values.isRoi
                        }
                      />
                    </IndentWrapper>
                  )}
                </Field>

                <Field name="roiLevel">
                  {({ input }) => (
                    <IndentWrapper>
                      <div style={{ marginBottom: '8px' }}>Levels</div>
                      <RadioList
                        onChange={v => input.onChange(v)}
                        value={input.value}
                        disabled={
                          values.name !== SubscriptionPlan.A_LA_CARTE || !values.isRoi
                        }
                        options={[
                          { label: 'Level 1 (20 partners)', value: ROILevelEnum.Level1 },
                          { label: 'Level 2 (50 partners)', value: ROILevelEnum.Level2 },
                          { label: 'Level 3 (100 partners)', value: ROILevelEnum.Level3 },
                          { label: 'Level 4 (unlimited)', value: ROILevelEnum.Level4 },
                        ]}
                      />
                    </IndentWrapper>
                  )}
                </Field>

                <Field name="isFundraising">
                  {({ input }) => (
                    <Checkbox
                      label="Fundraising"
                      check={input.value}
                      handleClick={() => input.onChange(!input.value)}
                      disabled={values.name !== 'A la carte'}
                    />
                  )}
                </Field>

                <Field name="isPrices">
                  {({ input }) => (
                    <Checkbox
                      label="Prices"
                      check={input.value}
                      handleClick={() => input.onChange(!input.value)}
                      disabled={values.name !== 'A la carte'}
                    />
                  )}
                </Field>
              </FieldSection>

              <BtnGroup>
                <UIButton
                  handleClick={handleRemoveSubscription}
                  modifiers="danger"
                  title="Delete"
                />

                <UIButton
                  title="Save"
                  modifiers="primary"
                  handleClick={handleSubmit}
                  type="submit"
                  disabled={pristine || submitting || hasValidationErrors}
                  isLoading={submitting}
                />
              </BtnGroup>
            </Body>
          </div>
        );
      }}
    />
  );
};

const BtnGroup = styled.div`
  display: flex;
  margin-top: 40px;

  button:first-child {
    margin-right: 8px;
  }

  & button {
    width: 136px;
  }
`;

const Checkbox = styled(UICheckbox)`
  margin-bottom: 8px;
`;

const FieldSection = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '30px'};
`;

const TitleSection = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  margin-bottom: 24px;
`;

const Body = styled.div`
  background: #fff;
  padding: 30px 40px 10px;
`;

const IndentWrapper = styled.div`
  margin-left: 24px;
`;

export default SubscriptionForm;
