export enum SocialMediaTypeEnum {
  twitter = 'TWITTER',
  linkedIn = 'LINKED_IN',
  facebook = 'FACEBOOK',
  youtube = 'YOUTUBE',
}

export const SocialMediaUrlsTypeOptions = Object.keys(SocialMediaTypeEnum).map(item => ({
  label: `${item.charAt(0).toUpperCase()}${item.slice(1)}`,
  value: SocialMediaTypeEnum[item],
}));
