import React, { FC, useEffect, useState } from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  isText?: boolean;
  className?: string;
  openText?: string;
  closeText?: string;
}

const UISwitch: FC<Props> = ({
  checked,
  onChange,
  disabled,
  isText,
  className,
  openText,
  closeText,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = (checked: boolean) => {
    setIsChecked(checked);
    onChange(checked);
  };

  return (
    <Root className={className}>
      <Switch
        checked={isChecked}
        onChange={handleChange}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor="#2BAEE0"
        offColor="#BFBFBF"
        activeBoxShadow=""
        handleDiameter={24}
        width={52}
        disabled={disabled}
      />
      {isText && (
        <SwtichText checked={isChecked}>
          {isChecked ? openText || 'Open' : closeText || 'Close'}
        </SwtichText>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const SwtichText = styled.span<{ checked: boolean }>`
  margin: 0 8px;
  width: 36px;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: ${({ checked }) => (checked ? 'var(--blue)' : 'var(--red)')};
`;

export default UISwitch;
