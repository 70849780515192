import styled from 'styled-components';

interface Props {
  modifier?: 'blue' | 'black';
}

const UILink = styled.a<Props>`
  font-size: 12px;
  line-height: 150%;
  font-weight: 500;
  color: ${({ modifier = 'black' }) => {
    switch (modifier) {
      case 'blue':
        return 'var(--blue)';
      default:
        return 'var(--black)';
    }
  }};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

export default UILink;
