import * as types from './types';
import { User } from 'interfaces/user';

export const setAdmin = (admin: User) =>
  ({
    type: types.SET_ADMIN,
    payload: admin,
  } as const);

export const logout = () => ({ type: types.LOG_OUT } as const);
