import { FormatMoney } from 'format-money-js';
import { DATE_FORMAT } from 'constants/date';
import { format } from 'date-fns';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const moneyMask = createNumberMask({
  inputMode: 'numeric',
  maskOptions: {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
  },
});

export const cropText = (text: string | undefined, num: number, endText = '...') => {
  if (!text) return text;

  if (text.length > num) return `${text.slice(0, num)}${endText}`;

  return text;
};

export const zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

export const getFullName = (
  firstName: string | undefined | null,
  lastName: string | undefined | null,
) => (firstName && lastName ? `${firstName} ${lastName}` : 'No name');

export const cropHttps = (text: string | null | undefined) => {
  return text && text.replace('https://', '');
};

export const getRelativeTime = (date: string) =>
  format(new Date(date), DATE_FORMAT.LAST_LOGIN_FORMAT);

export const phoneNumberMaskedProps = {
  mask: [
    '+',
    /\d/,
    ' ',
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    '-',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  parse: value => {
    return value && value.replace(/[ ()-]+/g, '');
  },
};

export const moneyFormatter = new FormatMoney({
  decimals: 0,
});

export const formatDataForChart = (data: Record<string, number>) => {
  const objToArray = Object.entries(data);
  return objToArray.map(item => {
    return {
      name: item[0],
      value: item[1],
    };
  });
};

export const getFilenameByPath = (path: string) => path.match(/[^./]+(\.\w+)+$/)?.[0];

export const formatNumber = (number: number) =>
  Intl.NumberFormat('en', {
    // @ts-ignore
    notation: 'compact',
  }).format(number);

export const capitalizeText = (text: string | null | undefined) => {
  return text ? text[0].toUpperCase() + text.slice(1).toLowerCase() : text;
};

export const moneyNumberFormat = (num: unknown) =>
  Number.isNaN(Number(num))
    ? 'N/A'
    : moneyFormatter.from(Number(num), {
        symbol: '$',
      });

export const numberFormat = (num: unknown) =>
  Number.isNaN(Number(num)) ? 'N/A' : moneyFormatter.from(Number(num), {});
