import { UserStoreData } from 'interfaces/user';
import { ActionType, createReducer } from 'typesafe-actions';

import * as types from './types';
import * as actions from './actions';

type RootAction = ActionType<typeof actions>;

export default createReducer<UserStoreData, RootAction>({
  users: {
    items: [],
    links: {},
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
  additionalInfo: {
    missions: [],
    industries: [],
    industriesTree: [],
    crsFocus: [],
    states: [],
    countries: [],
    cites: [],
    naicsCodeTree: [],
    teamRange: {
      annualtotalcontributionsmax: '0',
      annualtotalcontributionsmin: '0',
      numberofsocialfollowersmax: '0',
      numberofsocialfollowersmin: '0',
      minimumcommitmentmax: '0',
      minimumcommitmentmin: '0',
    },
  },
  searchOrganizationsInfo: {
    location: [],
    missionFocus: [],
    sdgGoals: [],
  },
})
  .handleType([types.SET_USERS], (state, action) => ({
    ...state,
    users: action.payload,
  }))
  .handleType([types.SET_USER], (state, action) => {
    const user = action.payload;
    if (state.users.items.find(({ id }) => id === user.id))
      return {
        ...state,
        users: {
          ...state.users,
          items: state.users.items.map(item => {
            if (item.id !== user.id) return item;

            return {
              ...item,
              ...user,
            };
          }),
        },
      };

    return {
      ...state,
      users: {
        ...state.users,
        items: [user, ...state.users.items],
      },
    };
  })
  .handleType([types.SET_ADDITIONAL_INFO], (state, action) => ({
    ...state,
    additionalInfo: action.payload,
  }))
  .handleType([types.SET_ADMIN_FILTERINFO], (state, action) => ({
    ...state,
    searchOrganizationsInfo: action.payload,
  }))

  .handleType([types.DELETE_USER], (state, action) => ({
    ...state,
    users: {
      ...state.users,
      items: state.users.items.filter(item => item.id !== action.payload),
    },
  }))
  .handleType([types.SET_USER_STATUS], (state, action) => ({
    ...state,
    users: {
      ...state.users,
      items: state.users.items.map(item =>
        item.id === action.payload.id ? action.payload : item,
      ),
    },
  }));
