import { clientApiUrl, fetch } from 'services/ApiService';
import {
  TeamData,
  Teams,
  SubscriptionProps,
  AddTeamMembersReq,
  TeamContact,
  ImpactGenome,
  UploadTeamXLSXRes,
} from 'interfaces/team';
import { paramsToQueryString } from 'utils';
import StorageService from 'services/Storage';
import { News } from 'interfaces/companies';
import { User } from 'interfaces/user';

export const fetchTeams = () => fetch<TeamData[]>('get', `teams`);

export const fetchTeamsSearch = (
  page: number,
  q?: string,
  limit = 12,
  sdgGoals?: string[],
  missionFocus?: string[],
  location?: string[],
) =>
  fetch<Teams>(
    'get',
    `teams/suggested${paramsToQueryString({
      page,
      limit,
      q,
      sdgGoals,
      missionFocus,
      location,
    })}`,
  );

export const fetchTeam = (id: string) => fetch<TeamData>('get', `teams/${id}`);

export const fetchTeamUpdate = (id: string, data: TeamData) =>
  fetch<TeamData>('patch', `teams/${id}`, {
    payload: data,
  });

export const fetchTeamCreate = (data: TeamData) =>
  fetch<TeamData>('post', `teams`, {
    payload: data,
  });

export const fetchTeamsSuggested = (q: string) =>
  fetch<{
    items: TeamData[];
  }>(
    'get',
    `teams/suggested${paramsToQueryString({
      q,
      page: 1,
      limit: 12,
    })}`,
  );

export const fetchSubscriptionTeams = (id: string) =>
  fetch<SubscriptionProps>('get', `teams/${id}/subscription`);

export const fetchSubscriptionUpdate = (id: string, data: SubscriptionProps) =>
  fetch<SubscriptionProps>('patch', `teams/${id}/subscription`, {
    payload: data,
  });

export const fetchSubscriptionDestroy = (teamId: string) =>
  fetch<string>('delete', `teams/${teamId}/subscription`);

export const fetchFileUpload = ({ file, id }: { file: File; id: string }) => {
  const data = new FormData() as any;
  data.append('file', file, file?.name);

  return fetch(
    'post',
    `teams/${id}/upload-excel`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
  );
};
export const fetchTeamLogoUpload = ({ file, teamId }: { file: File; teamId: string }) => {
  const data = new FormData() as any;
  data.append('file', file, file?.name);

  return fetch(
    'post',
    `teams/${teamId}/logo_upload`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
  );
};

export const fetchTeamDelete = (teamId: string) =>
  fetch<{ status: string }>('delete', `teams/${teamId}`);

export const fetchAddTeamMembers = (param: AddTeamMembersReq) =>
  fetch('post', `teams/members`, {
    payload: param,
  });

export const fetchInviteLinkTeam = (teamId: string) =>
  fetch('post', `teams/invite_link/${teamId}`);

export const fetchDeleteTeamMembers = (id: string) =>
  fetch('delete', `teams/members/${id}`);

export const fetchTeamMembers = (teamId: string) =>
  fetch<User[]>('get', `teams/members/${teamId}`);

export const fetchTeamNews = (id: string) =>
  fetch<News[]>('get', `team/${id}/news`, { host: clientApiUrl });

export const fetchTeamContacts = (id: string) =>
  fetch<TeamContact[]>('get', `teams/${id}/contacts`);

export const fetchTeamContactCreate = (teamId: string, data: TeamContact) =>
  fetch<TeamContact>('post', `teams/${teamId}/contacts`, { payload: data });

export const fetchTeamContactsUpdate = (
  teamId: string,
  contactId: string,
  data: TeamContact,
) =>
  fetch<TeamContact>('patch', `teams/${teamId}/contacts/${contactId}`, {
    payload: data,
  });

export const fetchTeamContactsDelete = (teamId: string, contactId: string) =>
  fetch<TeamContact>('delete', `teams/${teamId}/contacts/${contactId}`);

export const fetchPayPalGivingFundCanUpdate = (teamId: string) =>
  fetch<boolean>('get', `teams/${teamId}/canUpdatePayPalGivingFund`);

export const fetchPayPalGivingFundIfConfirm = (teamId: string) =>
  fetch<boolean>(
    'get',
    `campaign/judgeEin/ifConfirm/${paramsToQueryString({ teamId })}`,
    { host: clientApiUrl },
  );

export const fetchExportByFilters = (location: any) =>
  fetch<{ name: string; file: string }>(
    'get',
    `teams/exportByFilters${paramsToQueryString(location)}`,
  );

export const fetchUploadTeamXLSX = (file: File) => {
  const data = new FormData() as any;
  data.append('file', file, file?.name);

  return fetch(
    'post',
    `teams/uploadSearchTeam`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
  );
};

export const fetchUploadImportExcel = (file: File) => {
  const data = new FormData();
  data.append('file', file, file?.name);
  // const queryParams = new URLSearchParams({ name });

  return fetch(
    'post',
    `teams/uploadImportExcel`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchDownloadTeamTemplate = () =>
  fetch<{ name: string; file: string }>('get', 'team/downLoadTemplate');

export const downloadImportTemplate = () =>
  fetch<{ name: string; file: string }>('get', 'teams/downloadImportTemplate');

export const fetchImpactGenome = () => fetch<ImpactGenome[]>('get', 'teams/impactGenome');

// export const fetchImportCompanies = (data: TeamData) =>
//   fetch<TeamData>('post', `teams/saveImportExcel`, {
//     payload: data,
//   });

export const fetchImportCompanies = (file: File) => {
  const data = new FormData();
  data.append('file', file, file?.name);
  // const queryParams = new URLSearchParams({ name });

  return fetch(
    'post',
    `teams/saveImportExcel`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchGetImportTask = () =>
  fetch<{ code: string; msg: string }>('get', 'teams/getImportTask');

export const fetchGetTaskInfo = (Id: string) =>
  fetch<any>('get', `teams/getTaskInfo/${Id}`);

export const fetchExportByFail = (Id: string) =>
  fetch<any>('get', `teams/downloadTaskInfo/${Id}`);
