import { TrackingStatusEnum } from 'constants/roi';
import { format } from 'date-fns';
import { ROIData } from 'interfaces/roi';
import React from 'react';
import styled from 'styled-components';
import { getUTCAsLocalDate } from 'utils';

interface Props {
  rows: ROIData[];
  headCells: string[];
  onRowClick: (id: string) => void;
}

interface TableRowProps {
  row: ROIData;
  onRowClick: Props['onRowClick'];
}

const userTableWidth = {
  column1: 360,
  column2: 500,
  column3: 100,
} as const;

const { column1, column2, column3 } = userTableWidth;

const TableRowWrapper = ({ row, onRowClick }: TableRowProps) => {
  const { id, name, renewalDate, trackingStatus } = row;
  return (
    <TableRow>
      <TableRowCell>{name}</TableRowCell>

      <TableRowCell>
        {renewalDate ? format(getUTCAsLocalDate(renewalDate), 'MMM dd, yyyy') : 'N/A'}
      </TableRowCell>

      <TableRowCell>
        <Tag
          active={true}
          isRedDot={trackingStatus === TrackingStatusEnum.PendingAdmin}
          onClick={() => onRowClick(id)}
        >
          Measure
        </Tag>
      </TableRowCell>
    </TableRow>
  );
};

const ROITable = ({ rows, headCells, onRowClick }: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${column1}px` }} />
          <col span={1} style={{ width: `${column2}px` }} />
          <col span={1} style={{ width: `${column3}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            {headCells.map((item, index) => (
              <TableHeadCell key={index}>{item}</TableHeadCell>
            ))}
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(item => (
            <TableRowWrapper key={item.id} row={item} onRowClick={onRowClick} />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  height: 440px;
  margin-bottom: 30px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  background: #f4f4f4;
  position: sticky;
  top: 0;
  z-index: 1;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

const Tag = styled.div<{ active?: boolean; isRedDot: boolean }>`
  width: 67px;
  height: 30px;
  border-radius: 6px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${({ active }) => {
    if (active) {
      return `
        background: var(--blue);
        color:#fff;
        cursor: pointer;
      `;
    }
    return `
       border: 1px solid var(--secondaryBlue);
       color: rgba(43, 173, 224, 0.3);
       background: #CEEDF9;
    `;
  }}
  ${({ isRedDot }) =>
    isRedDot &&
    `
   &::after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    right: -2px;
    top: -2px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: #e92d30;
  }
  `}
`;

export default ROITable;
