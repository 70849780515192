import { Dispatch } from 'redux';
import * as api from './api';
import * as actions from './actions';
import { MonthlyReportListReq } from 'interfaces/monthlyReport';
import Toast from 'services/Toast';

export const fetchMonthlyReportList = ({
  limit = 6,
  ...rest
}: MonthlyReportListReq) => async (dispatch: Dispatch) => {
  return api.fetchMonthlyReportList({ ...rest, limit }).then(data => {
    dispatch(actions.setMonthlyReportList(data));
  });
};

export const fetchMonthlyReportGenerateAll = () => async () => {
  return api
    .fetchMonthlyReportGenerateAll()
    .catch(() => Toast.error('Error Generate MonthlyReport'));
};

export const fetchMonthlyReportDetail = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchMonthlyReportDetail(id)
    .then(data => dispatch(actions.setMonthlyReportDetail(data)))
    .catch(() => Toast.error('Error get MonthlyReport detail'));
};
