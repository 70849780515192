import React, { FC, ChangeEvent, useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import Toast from 'services/Toast';
import { UISpinner } from 'components/UI';
import { lessThan5MB } from 'utils/validation';
import SuccessIcon from 'assets/images/toast/success.svg';

interface Props {
  handleUpload: (file: File) => Promise<string | void | number>;
  isUploadDocument: boolean;
}

const UploadDocument: FC<Props> = ({
  handleUpload,
  isUploadDocument,
  ...rest
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [applied, setApplied] = useState<boolean>(isUploadDocument);

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      const file = event.target.files[0];
      const sizeError = lessThan5MB(file.size);
      setLoading(true);

      if (sizeError) {
        setLoading(false);
        return Toast.error(sizeError);
      }

      handleUpload(file)
        .then(() => {
          setLoading(false);
          setApplied(true);
        })
        .catch(() => {
          setLoading(false);
          Toast.error('Error');
        });
    }
  };

  return (
    <>
      <Root>
        <div>
          <Input
            id="upload-document-file"
            onChange={onUpload}
            type="file"
            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            {...rest}
          />
          {loading ? (
            <Spinner />
          ) : (
            <label htmlFor="upload-document-file">
              <Btn>Upload File</Btn>
            </label>
          )}
        </div>
        {applied && (
          <AppliedWrapper>
            <p>Information successfully applied</p>
            <ReactSVG src={SuccessIcon} />
          </AppliedWrapper>
        )}
      </Root>
    </>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const AppliedWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  & > p {
    font-size: 16px;
    margin-right: 10px;
  }
`;

const Spinner = styled(UISpinner)`
  width: 150px;
  padding: 8px 0;
  color: var(--black);
  background: #caf0ff;
  border-radius: 6px;
  text-align: center;
  & svg {
    width: 30px;
    height: 30px;
  }
`;

const Btn = styled.div`
  font-size: 16px;
  line-height: 150%;
  width: 150px;
  padding: 11px 0;
  color: var(--black);
  background: #caf0ff;
  border-radius: 6px;
  text-align: center;
  &:hover {
    cursor: pointer;
    background: #daf5ff;
  }

  &:active {
    background: #b6ebff;
  }
`;

const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  display: inline-block;
`;

export default UploadDocument;
