import { ROIMeltwaterData } from 'interfaces/roi';
import React from 'react';
import styled from 'styled-components';
import {
  DocumentCard,
  LineChart,
  PieChart,
  RandomTextCard,
  MapChart,
  BarChart,
  NewsCard,
  TotalPotentialReachChart,
} from '.';

interface Props {
  meltwaterData: ROIMeltwaterData;
}

const DashBoard = ({ meltwaterData }: Props) => {
  return (
    <>
      <CardGroupRow>
        <Card>
          <CardTitle>Content Stream (Top 50)</CardTitle>
          <CardBody>
            <DocumentCard data={meltwaterData.analyticsData.exports?.data} />
          </CardBody>
        </Card>
        <Card>
          <CardTitle>Media Exposure (over time)</CardTitle>
          <CardBody>
            <LineChart data={meltwaterData.analyticsData.analytics?.time_series} />
          </CardBody>
        </Card>
      </CardGroupRow>
      <CardGroupRow>
        <Card>
          <CardTitle>Sentiment</CardTitle>
          <CardBody>
            <PieChart data={meltwaterData.analyticsData.analytics?.sentiment} />
          </CardBody>
        </Card>
        <Card>
          <CardTitle>Trending Themes</CardTitle>
          <CardBody>
            <RandomTextCard
              data={meltwaterData.analyticsData.top_keyphrases?.keyphrases}
            />
          </CardBody>
        </Card>
      </CardGroupRow>
      <CardGroupRow>
        <Card>
          <CardTitle>Heat Map</CardTitle>
          <CardBody className="heat-map-chart">
            <MapChart data={meltwaterData.analyticsData.analytics?.top_countries} />
          </CardBody>
        </Card>
        <Card>
          <CardTitle>Top Authors</CardTitle>
          <CardBody>
            <BarChart
              data={meltwaterData.analyticsData.top_sources.sources?.slice(0, 4)}
            />
          </CardBody>
        </Card>
      </CardGroupRow>
      <CardGroupRow>
        <Card>
          <CardTitle>Top Retweeted</CardTitle>
          <CardBody>
            <NewsCard data={meltwaterData.analyticsData.top_shared?.results} />
          </CardBody>
        </Card>
        <Card>
          <CardTitle>Total Potential Reach</CardTitle>
          <CardBody>
            <TotalPotentialReachChart
              data={meltwaterData.analyticsData.exports?.documentTypes}
            />
          </CardBody>
        </Card>
      </CardGroupRow>
    </>
  );
};

const CardGroupRow = styled.div`
  display: flex;
`;

const Card = styled.div`
  width: 616px;
  height: 402px;
  border-radius: 6px;
  background: #fff;
  margin: 24px 24px 0 0;
  flex-shrink: 0;
`;

const CardTitle = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 145%;
  color: var(--black);
  height: 72px;
  border-bottom: 1px solid #e8e8e8;
  padding: 24px;
`;

const CardBody = styled.div`
  padding: 24px 12px 24px;
`;

export default DashBoard;
