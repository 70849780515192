import { fetch } from 'services/ApiService';
import { paramsToQueryString } from 'utils';
import {
  RecommendationsListRes,
  RecommendationsListReq,
  RecommendationData,
  RecommendationSuggestTeam,
  RecommendationCreateReq,
} from 'interfaces/issueMapping';

export const fetchRecommendationsList = ({
  limit = 7,
  ...params
}: RecommendationsListReq) =>
  fetch<RecommendationsListRes>(
    'get',
    `recommendation${paramsToQueryString({ limit, ...params })}`,
  );

export const fetchRecommendation = (id: string) =>
  fetch<RecommendationData>('get', `recommendation/${id}`);

export const fetchRecommendationCreate = (params: RecommendationCreateReq) =>
  fetch('post', 'recommendation', { payload: params });

export const fetchRecommendationUpdate = (params: RecommendationData) =>
  fetch('patch', 'recommendation', { payload: params });

export const fetchRecommendationDelete = (deleteIds: string) =>
  fetch('delete', `recommendation/MultipleDelete${paramsToQueryString({ deleteIds })}`);

export const fetchRecommendationTeams = (params: { id: string; q: string }) =>
  fetch<RecommendationSuggestTeam[]>(
    'get',
    `recommendation/teams${paramsToQueryString(params)}`,
  );

export const fetchRecommendationPublished = (companyId: string) =>
  fetch<RecommendationData>(
    'get',
    `recommendation/published${paramsToQueryString({ companyId })}`,
  );
