import { Field } from 'components/FinalForm';
import Jumbotron from 'components/Jumbotron';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import React, { useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useHistory, useParams } from 'react-router';
import { FormRow, FormTitle } from 'components/UI/form';
import {
  FieldCheckBoxGroup,
  FieldTextInput,
  MaskedMoneyTextInput,
} from 'components/FormFields';
import { AdditionalInfoProps, StateProps } from 'store/interfaces';
import { useSelector } from 'react-redux';
import { getAdditionalInfoSelect } from 'store/ducks/companies/selectors';
import styled from 'styled-components';
import { goalAlignmentOptions } from 'constants/goalAlignment';
import { UIAsterisk, UIButton } from 'components/UI';
import { useOperation } from 'hooks/common';
import {
  clearRecommendationStore,
  fetchRecommendation,
  fetchRecommendationPublished,
  fetchRecommendationTeams,
  fetchRecommendationUpdate,
} from 'store/ducks/issueMapping/operations';
import {
  getRecommendation,
  getRecommendationPublished,
  getRecommendationTeams,
} from 'store/ducks/issueMapping/selectors';
import { RecommendationData, RecommendationSuggestTeam } from 'interfaces/issueMapping';
import NavigationBar from 'components/NavigationBar';
import {
  CreateRecommendationNavigationEnum,
  IssueMappingNavigationEnum,
} from 'constants/issueMapping';
import queryString from 'query-string';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { ReactSVG } from 'react-svg';
import AddSVG from 'assets/images/icons/blue-add-icon.svg';
import { debounce } from 'lodash';
import { OnChange } from 'react-final-form-listeners';
import FieldSuggestSingle from 'components/FormFields/FieldSuggestSingle';
import { maxLength150, required } from 'utils/validation';
import { composeValidators } from 'utils';
import { RouteLeavingPrompt } from 'components/RouteLeavingPrompt';

interface FormData extends Omit<RecommendationData, 'mission' | 'charitables'> {
  mission?: { id: number; title: string }[];
  charitables?: (
    | {
        team?: { id: string; name: string };
        costPerOutcome: string;
        totalInvestment: string;
        potentialOutcome: string;
      }
    | undefined
  )[];
}

const CreateRecommendation = () => {
  const { recommendationId } = useParams<{ recommendationId: string }>();
  const search = queryString.parse(window.location.search) as {
    type: CreateRecommendationNavigationEnum;
  };
  const { push } = useHistory();

  const [onFetchRecommendation] = useOperation(fetchRecommendation);
  const [onFetchRecommendationUpdate, , isloading] = useOperation(
    fetchRecommendationUpdate,
  );
  const [onFetchRecommendationTeams] = useOperation(fetchRecommendationTeams);
  const [onClearRecommendationStore] = useOperation(clearRecommendationStore);
  const [onFetchRecommendationPublished] = useOperation(fetchRecommendationPublished);

  const additionalInfo = useSelector<StateProps, AdditionalInfoProps>(
    getAdditionalInfoSelect(),
  );
  const recommendation = useSelector(getRecommendation);
  const suggestTeams = useSelector(getRecommendationTeams);
  const recommendationPublished = useSelector(getRecommendationPublished);

  const handleSearchTeams = debounce(
    v => onFetchRecommendationTeams({ q: v, id: recommendationId }),
    800,
  );

  const notAllowSave = useMemo(
    () => recommendation?.status && recommendation.status !== 'Draft',
    [recommendation],
  );

  const fieldDisabled = useMemo(
    () => !recommendation?.sdgGoals?.length || !recommendation?.mission?.length,
    [recommendation],
  );

  useEffect(() => {
    if (!recommendation) return;
    onFetchRecommendationPublished(recommendation.companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendation]);

  useEffect(() => {
    onFetchRecommendation(recommendationId);
    return () => {
      onClearRecommendationStore(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.type]);

  const initialValues: FormData | undefined = useMemo(() => {
    if (!recommendation) return undefined;
    return {
      ...recommendation,
      mission: recommendation?.mission?.map(item => ({
        id: item.value,
        title: item.label,
      })),
      charitables: recommendation.charitables?.length
        ? recommendation.charitables.map(item => ({
            ...item,
            team: item.team && {
              ...item.team,
              id: item.team.id,
              name: item.team.organizationName,
            },
          }))
        : [undefined],
    };
  }, [recommendation]);

  return (
    <ContentBlockWrapper
      header={
        <Jumbotron
          name="Create Recommendation"
          back
          backPath={`/issue-mapping/view/${recommendation?.companyId}?type=${IssueMappingNavigationEnum.Recommendations}`}
        />
      }
    >
      <NavigationBarWrapper>
        <NavigationBar<CreateRecommendationNavigationEnum>
          defaultTab={CreateRecommendationNavigationEnum.Material_Issues}
          list={[
            {
              label: 'Material Issues',
              value: CreateRecommendationNavigationEnum.Material_Issues,
            },
            {
              label: 'Charitable Organization',
              value: CreateRecommendationNavigationEnum.Charitable_Organization,
            },
          ]}
          queryKey="type"
        />
      </NavigationBarWrapper>

      <Body>
        <Form<FormData>
          initialValues={initialValues}
          mutators={{
            ...arrayMutators,
          }}
          onSubmit={values => {
            const mission = values.mission?.map(item => ({
              label: item.title,
              value: item.id,
            }));
            const charitables =
              (values.charitables
                ?.filter(item => item)
                .map(item => ({
                  ...item,
                  teamId: item?.team?.id,
                })) as RecommendationData['charitables']) || [];

            const submitData: RecommendationData = {
              ...values,
              mission,
              charitables,
            };
            onFetchRecommendationUpdate(submitData).then(() => {
              if (values.status === 'Draft')
                return onFetchRecommendation(recommendationId);

              push(
                `/issue-mapping/view/${recommendation?.companyId}?type=${IssueMappingNavigationEnum.Recommendations}`,
              );
            });
          }}
          render={({ handleSubmit, form, values, dirty }) => {
            return (
              <>
                <RouteLeavingPrompt
                  title="Material Issues"
                  content="The selection will be lost if you leave this page without saving. Are you sure you want to leave?"
                  shouldBlockNavigation={({ search }) => {
                    if (search.includes('Charitable_Organization')) return true;
                    return false;
                  }}
                  when={dirty}
                />
                {(!search.type ||
                  search.type === CreateRecommendationNavigationEnum.Material_Issues) && (
                  <>
                    <FormTitle>Core issues</FormTitle>
                    <Field
                      name="mission"
                      component={FieldCheckBoxGroup}
                      validate={(values: unknown[]) =>
                        values?.length >= 1
                          ? undefined
                          : 'At least one selection required'
                      }
                      hideNumber
                      options={additionalInfo.missions.map(item => ({
                        id: item.value,
                        title: item.label,
                      }))}
                    />

                    <FormTitle>SDG Goal Alignment</FormTitle>
                    <Field
                      name="sdgGoals"
                      component={FieldCheckBoxGroup}
                      validate={(values: unknown[]) =>
                        values?.length >= 1
                          ? undefined
                          : 'At least one selection required'
                      }
                      options={Object.values(goalAlignmentOptions)}
                      parse={value => value.map(({ value: sdg }) => sdg)}
                      format={value => value?.map(sdg => goalAlignmentOptions[sdg]) || []}
                    />
                  </>
                )}

                {search.type ===
                  CreateRecommendationNavigationEnum.Charitable_Organization && (
                  <>
                    <FieldArray name="charitables">
                      {({ fields }) => {
                        return fields.map((name, index) => (
                          <div key={index}>
                            <FormRow>
                              <Field
                                name={`${name}.team`}
                                label={
                                  <>
                                    Charitable Organizations <UIAsterisk />
                                  </>
                                }
                                component={FieldSuggestSingle}
                                validate={required}
                                handleGetSuggestions={handleSearchTeams}
                                placeholder="Search"
                                list={suggestTeams}
                                disabled={fieldDisabled}
                              />
                              <OnChange name={`${name}.team`}>
                                {(value: RecommendationSuggestTeam, previous) => {
                                  if (!value) return;
                                  if (value === previous) return;
                                  const costPerOutcome = value.costPerOutcome;
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  form.change<any>(
                                    `${name}.costPerOutcome`,
                                    costPerOutcome?.includes(',')
                                      ? (
                                          costPerOutcome
                                            .split(',')
                                            .map(item => Number(item))
                                            .reduce((prev, curr) => prev + curr, 0) /
                                          costPerOutcome.split(',').length
                                        ).toFixed(0)
                                      : costPerOutcome,
                                  );
                                }}
                              </OnChange>
                              <Field
                                name={`${name}.costPerOutcome`}
                                label="Cost per Outcome"
                                component={FieldTextInput}
                                disabled
                              />
                            </FormRow>
                            <FormRow>
                              <Field
                                name={`${name}.totalInvestment`}
                                label={
                                  <>
                                    Total Investment <UIAsterisk />
                                  </>
                                }
                                component={MaskedMoneyTextInput}
                                validate={required}
                              />
                              <Field
                                name={`${name}.potentialOutcome`}
                                label={
                                  <>
                                    Potential Outcome <UIAsterisk />
                                  </>
                                }
                                component={FieldTextInput}
                                validate={composeValidators(required, maxLength150)}
                              />
                            </FormRow>
                            <ActionContentButtons>
                              {fields.value.length > 1 && (
                                <FormArrrayButton
                                  modifiers="danger"
                                  title="Remove Organization"
                                  handleClick={() => fields.remove(index)}
                                />
                              )}
                              {fields.value.length === index + 1 && (
                                <FormArrrayButton
                                  modifiers="secondary"
                                  disabled={fieldDisabled}
                                  title={
                                    <ArrayButtonWrapper>
                                      <ReactSVG src={AddSVG} style={{ marginRight: 8 }} />
                                      Add Another Organization
                                    </ArrayButtonWrapper>
                                  }
                                  handleClick={() => fields.push(undefined)}
                                />
                              )}
                            </ActionContentButtons>
                          </div>
                        ));
                      }}
                    </FieldArray>
                  </>
                )}

                <ActionsWrapper>
                  <Button
                    title="Cancel"
                    modifiers={'secondary'}
                    handleClick={() =>
                      push(
                        `/issue-mapping/view/${recommendation?.companyId}?type=${IssueMappingNavigationEnum.Recommendations}`,
                      )
                    }
                  />
                  <div>
                    <Button
                      title="Save"
                      modifiers={'third'}
                      isLoading={isloading}
                      disabled={notAllowSave}
                      handleClick={() => {
                        if (!values.status) form.change('status', 'Draft');
                        handleSubmit();
                      }}
                    />
                    {search.type ===
                      CreateRecommendationNavigationEnum.Charitable_Organization && (
                      <Button
                        title="Publish"
                        modifiers={'primary'}
                        isLoading={isloading}
                        disabled={notAllowSave || !!recommendationPublished}
                        handleClick={() => {
                          form.change('status', 'Published');
                          handleSubmit();
                        }}
                      />
                    )}
                  </div>
                </ActionsWrapper>
              </>
            );
          }}
        />
      </Body>
    </ContentBlockWrapper>
  );
};

const Body = styled.div`
  width: 1096px;
  background-color: white;
  padding: 48px 42px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    gap: 16px;
  }
`;

const Button = styled(UIButton)`
  font-size: 16px;
`;

const NavigationBarWrapper = styled.div`
  margin-bottom: 26px;
`;

const ActionContentButtons = styled.div`
  display: flex;
  margin-bottom: 16px;
  gap: 16px;
`;

const ArrayButtonWrapper = styled.div`
  display: flex;
`;

const FormArrrayButton = styled(UIButton)`
  font-size: 12px;
  padding: 8px 10px;
`;

export default CreateRecommendation;
