/* eslint-disable react-hooks/exhaustive-deps */
import Jumbotron from 'components/Jumbotron';
import { useOperation } from 'hooks/common';
import queryString from 'query-string';
import { CompanyWrapper } from 'layouts';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { StateProps } from 'store/interfaces';
import PersonaAnalysis from './PersonaAnalysis';
import { getCompanyById } from 'store/ducks/companies/selectors';
import { Company } from 'interfaces/companies';
import { fetchCompany } from 'store/ducks/companies/operations';
import NavigationBar from 'components/NavigationBar';
import { IssueMappingNavigationEnum } from 'constants/issueMapping';
import styled from 'styled-components';
import { UILoadAvatar } from 'components/UI';
import Recommendations from './Recommendations';

const IssueMapping = () => {
  const params: { companyId: string } = useParams();

  const search = queryString.parse(window.location.search) as {
    type: IssueMappingNavigationEnum;
  };

  const [onFetchCompany, isLoading] = useOperation(fetchCompany);

  const company = useSelector<StateProps, Company | undefined>(
    getCompanyById(params.companyId),
  );

  useEffect(() => {
    onFetchCompany(params.companyId);
  }, []);

  return (
    <CompanyWrapper
      companyId={params.companyId}
      isLoading={isLoading}
      header={<Jumbotron name={company?.name || ''} back backPath="/teams" />}
    >
      <Header>
        <Image imgKey={company?.logo} />
        <OrganizationTitle>{company?.name}</OrganizationTitle>
      </Header>
      <NavigationBarWrapper>
        <NavigationBar<IssueMappingNavigationEnum>
          defaultTab={IssueMappingNavigationEnum.Persona_Analysis}
          list={[
            {
              label: 'Persona Analysis',
              value: IssueMappingNavigationEnum.Persona_Analysis,
            },
            {
              label: 'Recommendations',
              value: IssueMappingNavigationEnum.Recommendations,
            },
          ]}
          queryKey="type"
        />
      </NavigationBarWrapper>

      {company &&
        (!search.type || search.type === IssueMappingNavigationEnum.Persona_Analysis) && (
          <PersonaAnalysis company={company} />
        )}
      {search.type === IssueMappingNavigationEnum.Recommendations && <Recommendations />}
    </CompanyWrapper>
  );
};

const Header = styled.section`
  width: 100%;
  height: 180px;
  background-color: #f2f2f2;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 40px;
  margin-bottom: 18px;
`;

const Image = styled(UILoadAvatar)`
  border-radius: 8px;
  width: 100px;
  min-width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 26px;
  background-color: #fff;
`;

const OrganizationTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
`;

const NavigationBarWrapper = styled.div`
  margin-bottom: 26px;
`;

export default IssueMapping;
