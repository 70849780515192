import { ObjNoteData } from 'interfaces/objNote';
import { PricingData, PricingListRes, ReportData } from 'interfaces/pricing';
import * as types from './types';

export const setPricingList = (list: PricingListRes) =>
  ({
    type: types.SET_PRICING_LIST,
    payload: list,
  } as const);

export const setPricing = (pricing: PricingData) =>
  ({
    type: types.SET_PRICING,
    payload: pricing,
  } as const);

export const setCategory = (param: {
  id: string;
  categorize: PricingData['categorize'];
}) =>
  ({
    type: types.SET_CATEGORY,
    payload: param,
  } as const);

export const setValuationSave = (param: PricingData) =>
  ({
    type: types.SET_VALUATION_SAVE,
    payload: param,
  } as const);

export const deleteValuation = (id: string) =>
  ({
    type: types.DELETE_VALUATION,
    payload: id,
  } as const);

export const addPricing = (pricing: PricingData) =>
  ({
    type: types.ADD_PRICING,
    payload: pricing,
  } as const);

export const setReport = (report: ReportData) =>
  ({
    type: types.SET_REPORT,
    payload: report,
  } as const);

export const setReportFile = (param: { id: string; fileKey: string }) =>
  ({
    type: types.SET_REPORT_FILE,
    payload: param,
  } as const);

export const setAdminNoteList = (data: ObjNoteData[]) =>
  ({
    type: types.SET_ADMINNOTE_LIST,
    payload: data,
  } as const);

export const addAdminNote = (data: ObjNoteData) =>
  ({
    type: types.ADD_ADMINNOTE,
    payload: data,
  } as const);
