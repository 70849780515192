import { ROIMeltwaterData } from 'interfaces/roi';
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
interface Props {
  data?: ROIMeltwaterData['analyticsData']['analytics']['time_series'];
}

const LineChartC = ({ data }: Props) => {
  return (
    <LineChart
      width={568}
      height={282}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend formatter={(value: string) => value.replace('_', ' ')} />
      <Line
        type="monotone"
        dataKey="document_count"
        stroke="#2BAEE0"
        activeDot={{ r: 8 }}
      />
    </LineChart>
  );
};

export default LineChartC;
