import { UICheckbox } from 'components/UI';
import { ROIData } from 'interfaces/roi';
import React from 'react';
import styled from 'styled-components';

type Row = NonNullable<ROIData['calculation']>['stewardshipItems'];
interface Props {
  rows: Row;
  headCells: string[];
}

interface TableRowProps {
  row: Row[0];
}

const userTableWidth = {
  column1: 900,
  column2: 100,
  column3: 0,
} as const;

const { column1, column2, column3 } = userTableWidth;

const TableRowWrapper = ({ row }: TableRowProps) => {
  const { label, status } = row;

  return (
    <TableRow>
      <TableRowCell bold>{label}</TableRowCell>

      <TableRowCell>
        <UICheckbox check={status} />
      </TableRowCell>

      <TableRowCell />
    </TableRow>
  );
};

const StewardshipTable = ({ rows, headCells }: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${column1}px` }} />
          <col span={1} style={{ width: `${column2}px` }} />
          <col span={1} style={{ width: `${column3}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            {headCells.map((item, index) => (
              <TableHeadCell key={index}>{item}</TableHeadCell>
            ))}
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map((item, index) => (
            <TableRowWrapper key={index} row={item} />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: #fff;
  background: rgba(0, 90, 158, 0.7);
  position: sticky;
  top: 0;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableRow = styled.tr`
  height: 100%;
  background-color: #f4f7f9;
`;

const TableRowCell = styled.td<{ bold?: boolean }>`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  ${({ bold }) => bold && 'font-weight: 500;'}

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

export default StewardshipTable;
