import { UIActionButton } from 'components/UI';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import ArrowIcon from 'assets/images/icons/arrow-left.svg';
import History from 'services/History';
import { useHistory } from 'react-router';
import queryString from 'query-string';

interface Props {
  name: ReactNode;
  children?: ReactNode;
  isWithName?: boolean;
  back?: boolean;
  backPath?: string;
  id?: string;
}

const Jumbotron = ({
  name,
  children,
  back,
  backPath,
  isWithName = true,
  id,
  ...rest
}: Props) => {
  const { push } = useHistory();

  const search = queryString.parse(window.location.search) as { fromPage?: string };
  if (search.fromPage) backPath = search.fromPage;

  return (
    <Root id={id} {...rest}>
      {back && (
        <Back onClick={() => (backPath ? push(backPath) : History.back())}>
          <UIActionButton handleClick={() => {}} icon={ArrowIcon} />
          {isWithName && <Name>{name}</Name>}
        </Back>
      )}

      {isWithName && !back && <Name>{name}</Name>}
      {children}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1320px;
  padding: 0 20px;
  margin: 0 auto;
  background: #ffffff;
  height: 96px;
`;

const Back = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Name = styled.h1`
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  color: var(--black);
`;

export default Jumbotron;
