import { transformArrayForOptions } from 'utils';

export const companiesTabsOptions = [
  {
    label: 'Similar Companies',
    value: 'similar',
  },
  {
    label: 'Parent Company',
    value: 'parent',
  },
];

export const affinities = ['Democrat', 'Independent', 'Republican'];

export const headTableContacts = [
  'Full Name',
  'Title',
  'Job Function',
  'Management Level',
  'Phone',
  'Email',
  'LinkedIn',
  'Accuracy Score',
];

export const affinitiesOptions = transformArrayForOptions(affinities);
