import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

interface Props {
  value: string;
  onChange: (v: string) => void;
}

const RatioInput = ({ value, onChange, ...rest }: Props) => {
  return (
    <Field>
      <NumberFormat
        suffix="%"
        value={value}
        onValueChange={v => onChange(v.value)}
        {...rest}
      />
    </Field>
  );
};

const Field = styled.div`
  & input {
    width: 100%;
    color: var(--black);
    font-size: 16px;
    line-height: 155%;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 16px;
    outline: none;

    &:focus {
      border: 1px solid #2baee0;
    }

    &:-webkit-input-placeholder {
      color: var(--darkGray);
    }

    &:-moz-placeholder {
      color: var(--darkGray);
    }

    &:-ms-input-placeholder {
      color: var(--darkGray);
    }

    &:-moz-placeholder {
      color: var(--darkGray);
    }
  }
`;

export default RatioInput;
