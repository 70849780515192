/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { fetchImage2Base64 } from 'store/ducks/utility/api';

interface Props {
  setLoading?: Dispatch<React.SetStateAction<boolean>>;
  imgKey?: string;
}

const useFileBase64Url = ({ setLoading, imgKey }: Props) => {
  const [fileBase64Url, setFileBase64Url] = useState<string | undefined>();

  useEffect(() => {
    const load = async () => {
      try {
        if (imgKey) {
          setLoading?.(true);
          const result = await fetchImage2Base64(imgKey);
          setFileBase64Url(result);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading?.(false);
      }
    };

    load();
  }, [imgKey]);

  return {
    fileBase64Url,
    setFileBase64Url,
  };
};

export default useFileBase64Url;
