/* eslint-disable @typescript-eslint/no-unused-vars */
import Axios, { Method, AxiosRequestConfig, AxiosPromise } from 'axios';
import StorageService from 'services/Storage';
import Toast from 'services/Toast';
let apiDispatch = () => {};

export const provideDispatchToApiService = dispatch => {
  apiDispatch = dispatch;
};

export const baseUrl = process.env.REACT_APP_API_URL;
export const userSiteUrl = process.env.REACT_APP_USERSITE_URL;

export const apiUrl = `${baseUrl}/api/v1/admin`;
export const clientApiUrl = `${baseUrl}/api/v1`;

export const axios = Axios.create();

const unauthorizedPaths = ['/login'];

const isCurrentAuthorizedPath = () => {
  const { pathname } = window.location;
  return !(pathname === '/' || unauthorizedPaths.some(item => pathname.startsWith(item)));
};

interface FetchOptionsData {
  host?: string;
  unauthorized?: boolean;
  payload?: any;
}

type Header = {} | { Authorization: string };

export const fetch = <O extends object | string | boolean>(
  method: Method,
  uri: string,
  options = {} as FetchOptionsData,
  headers?: any,
): Promise<O> => {
  const instance = axios;
  const url = `${options.host || apiUrl}/${uri}`;

  const response: AxiosPromise<O> = instance({
    method,
    url,
    data: options.payload,
    headers: headers || { Authorization: `Bearer ${StorageService.getAccessToken()}` },
  } as AxiosRequestConfig);

  return response
    .then(res => res.data)
    .catch(err => {
      const status = err?.response?.status;
      if (status === 401) {
        if (isCurrentAuthorizedPath()) Toast.error('Unauthorized');

        setTimeout(() => {
          if (isCurrentAuthorizedPath()) window.location.href = '/login';
        }, 1500);
      }
      throw err;
    });
};
