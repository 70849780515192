import { ROIMeltwaterData } from 'interfaces/roi';
import React from 'react';
import { BarChart, Bar, XAxis, Tooltip, Legend, YAxis } from 'recharts';

interface Props {
  data?: ROIMeltwaterData['analyticsData']['exports']['documentTypes'];
}

const TotalPotentialReachChartC = ({ data }: Props) => {
  if (!data) return null;

  return (
    <BarChart
      width={568}
      height={282}
      data={data}
      layout="vertical"
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      barCategoryGap={10}
    >
      <XAxis type="number" hide />
      <YAxis type="category" dataKey="document_media_type" hide />
      <Tooltip />
      <Legend formatter={(value: string) => value.replace('_', ' ')} />
      <Bar
        dataKey="source_reach"
        fill="#28759C"
        radius={6}
        maxBarSize={32}
        label={({ y, index }) => {
          return (
            <text x={20} y={y} fill="#666" textAnchor="start" dy={-4}>
              {data?.[index].document_media_type || ''}
            </text>
          );
        }}
      />
    </BarChart>
  );
};

export default TotalPotentialReachChartC;
