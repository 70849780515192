import styled from 'styled-components';
import React, { CSSProperties, ReactNode } from 'react';
import UIDivider from './UIDivider';

interface Props {
  title?: string;
  children?: ReactNode;
  style?: CSSProperties;
}

const UIPanel = ({ title, children, style }: Props) => {
  return (
    <Panel style={{ background: 'white', border: '1px solid #E8E8E8', ...style }}>
      {title && (
        <>
          <PanelTitle>{title}</PanelTitle>
          <UIDivider type="horizontal" />
        </>
      )}

      {children}
    </Panel>
  );
};

const Panel = styled.div`
  border-radius: 8px;
  padding: 32px;
  margin-bottom: 24px;
`;

const PanelTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 145%;
  color: var(--black);
`;

export default UIPanel;
