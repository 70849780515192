import React from 'react';
import { UIModalProps } from './interfaces';
import styled, { CSSProp } from 'styled-components';
import CloseIcon from 'assets/images/icons/close.svg';
import { ReactSVG } from 'react-svg';
import ReactModal from 'react-modal';

function UIModal({
  onClose,
  children,
  className,
  hideCloseIcon = false,
  header,
  style,
  propsBodyCss,
  overlayStyle,
}: UIModalProps) {
  return (
    <ReactModal
      style={{
        content: {
          background: '#fff',
          borderRadius: '6px',
          padding: 0,
          border: 'none',
          left: 0,
          right: 0,
          margin: '0 auto',
          top: 'auto',
          bottom: 'auto',
          ...style,
        },
        overlay: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          ...overlayStyle,
        },
      }}
      onRequestClose={onClose}
      className={className}
      isOpen
    >
      <Header>
        {header}
        {!hideCloseIcon && <Close src={CloseIcon} onClick={onClose} />}
      </Header>

      <Body css={propsBodyCss}>{children}</Body>
    </ReactModal>
  );
}

const Header = styled.header`
  position: relative;
  padding: 0 40px;
  background: #f2f2f2;
`;

const Body = styled.div<{ css?: CSSProp }>`
  padding: 24px;
  ${({ css }) => css}
`;

const Close = styled(ReactSVG)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

export default UIModal;
