import styled from 'styled-components';

const FormTitle = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  margin-bottom: 24px;
  color: var(--black);
  display: flex;
`;

export default FormTitle;
