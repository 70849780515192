import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
}

const UINoImage = ({ className }: Props) => {
  return <Root className={className}>No Image</Root>;
};

const Root = styled.div`
  background-color: #e8e8e8;
  color: #999;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default UINoImage;
