export const SET_COMPANY = 'companies/SET_COMPANY';
export const SET_COMPANIES = 'companies/SET_COMPANIES';
export const SET_SUGGEST_COMPANIES = 'companies/SET_SUGGEST_COMPANIES';
export const SET_CONTACTS = 'companies/SET_CONTACTS';
export const SET_CONTACT = 'companies/SET_CONTACT';
export const SET_COMPANY_NEWS = 'companies/SET_COMPANY_NEWS';
export const DELETE_CONTACT = 'companies/DELETE_CONTACT';

export const REMOVE_COMPANY = 'companies/REMOVE_COMPANY';

export const DELETE_COMPANY_MEMBERS = 'companies/DELETE_COMPANY_MEMBERS';
export const GET_COMPANY_MEMBERS = 'companies/GET_COMPANY_MEMBERS';
export const SET_COMPANY_SUBSCRIPTION = 'companies/GET_COMPANY_SUBSCRIPTION';
export const SET_COMPANY_MEMBER = 'companies/SET_COMPANY_MEMBER';
export const SET_COMPANY_MEMBER_ISCANVA = 'companies/SET_COMPANY_MEMBER_ISCANVA';
export const SET_COMPANY_SOCIAL_IMPACT_LOG = 'companies/SET_COMPANY_SOCIAL_IMPACT_LOG';
export const SET_COMPANY_SELECT = 'companies/FILTERINFO';
export const SET_JOBTITLE = 'companies/GETJOBTITLES';
