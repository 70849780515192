import { createSelector } from 'reselect';
import { StateProps } from 'store/interfaces';
import { TeamContact, TeamData } from 'interfaces/team';
import { values } from 'lodash';

export const getTeams = (state: StateProps) => state.team.teams.items;

export const getTeamsMeta = (state: StateProps) => state.team.teams.meta;
export const getSubscription = (state: StateProps) => state.team.subscription;
export const getTeamNews = (state: StateProps) => state.team.news;
export const getTeamContacts = (state: StateProps) => state.team.contacts;
export const getImpactGenome = (state: StateProps) => state.team.impactGenome;
export const getProgramFocusOptions = (state: StateProps) =>
  state.team.impactGenome.map(item => ({
    label: item.programFocus,
    value: item.programFocus,
  }));

export const getTeamById = (teamId: string) =>
  createSelector([getTeams], teams => teams.find(({ id }: TeamData) => teamId === id));

export const getTeamsSuggested = (state: StateProps) => {
  const teamsSuggested = state.team.teamsSuggested;

  return teamsSuggested.filter(({ organizationName }) => organizationName);
};

export const getTeamMembers = state =>
  values(state.team.teamMembers).map(member => {
    return {
      ...member,
      linkedIn: null,
    };
  });

export const getContactById = (contactId?: string) =>
  createSelector([getTeamContacts], contacts =>
    contacts.find(({ id }: TeamContact) => contactId === id),
  );

export const getPayPalGivingFundCanUpdate = (state: StateProps) =>
  state.team.payPalGivingFundCanUpdate;

export const getPayPalGivingFundIfConfirm = (state: StateProps) =>
  state.team.payPalGivingFundIfConfirm;
