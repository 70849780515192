import { AxiosError } from 'axios';
import Toast from 'services/Toast';

// eslint-disable-next-line no-useless-escape
const websiteRegExp = /^(http(s?):\/\/)?[a-zA-Z0-9\.\-\_]+(\.[a-zA-Z]{2,10})+(\/[a-zA-Z0-9\_\-\s\.\/\?\%\#\&\=]*)?$/;
// eslint-disable-next-line no-control-regex
const emailRegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const digitsAndCharsRegExp = /^[A-Za-z0-9]+$/i;
const atleastDigitRegexp = /^(?=.*?[0-9]).+$/i;
const nameRegExp = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
const integerRegExp = /^\d*$/;
const einRegExp = /^\d{9}$|(^\d\d-\d{7}$)/;
const notMark = (str: string) => new RegExp(`^[^${str}]+$`);

const IMAGE_FILE_EXTENSIONS = [
  'tiff',
  'pjp',
  'jfif',
  'bmp',
  'gif',
  'svg',
  'png',
  'xbm',
  'dib',
  'jxl',
  'jpeg',
  'svgz',
  'jpg',
  'webp',
  'ico',
  'tif',
  'pjpeg',
  'avif',
];

const MAX_FILE_SIZE_MB = 5;

// Begin form validators

export const lessThanMB = (sizeByMB: number) => (bytes: number) => {
  const sizeInMB = bytes / 1024 / 1024;

  return sizeInMB > sizeByMB ? `File size must be less than ${sizeByMB} mb` : undefined;
};

export const lessThan5MB = lessThanMB(MAX_FILE_SIZE_MB);

export const required = <FieldValue>(value?: FieldValue) =>
  value || (typeof value === 'number' && !Number.isNaN(value)) ? undefined : 'Required';

const maxLength = (length: number) => <FieldValue extends string>(value?: FieldValue) =>
  value && value.length > length
    ? `Maximum length of this field is ${length} characters.`
    : undefined;

export const maxLength100 = maxLength(100);
export const maxLength150 = maxLength(150);
export const maxLength500 = maxLength(500);

export const notOnlySpaces = <FieldValue extends string>(value?: FieldValue) =>
  value && value.trim().length === 0
    ? 'The field must not contain only spaces'
    : undefined;

export const atleastDigit = <FieldValue extends string>(value?: FieldValue) =>
  value && !atleastDigitRegexp.test(value)
    ? 'Password must have atleast one digit'
    : undefined;

export const passwordLength = <FieldValue extends string>(value?: FieldValue) =>
  value && value.length < 6 ? 'Minimum password length is 6 symbols' : undefined;

export const onlyDigitsAndChars = <FieldValue extends string>(value?: FieldValue) =>
  value && !digitsAndCharsRegExp.test(value)
    ? 'Only digits and characters allowed'
    : undefined;

export const password = <FieldValue extends string>(value?: FieldValue) => {
  const validationErrors = [
    atleastDigit(value),
    passwordLength(value),
    onlyDigitsAndChars(value),
  ].filter(result => result);

  return validationErrors.length ? validationErrors.join('\n') : undefined;
};

export const confirmPassword = <FieldValue extends string>(
  value: FieldValue,
  formValues,
) =>
  formValues.password !== value
    ? 'Password and confirmation password do not match'
    : undefined;

export const email = <FieldValue extends string>(value?: FieldValue) =>
  value && !emailRegExp.test(value) ? 'Incorrect email format' : undefined;

export const webSiteLink = <FieldValue extends string>(value?: FieldValue) =>
  value && !websiteRegExp.test(value) ? 'Invalid website' : undefined;

export const notQuote = <FieldValue extends string>(value?: FieldValue) =>
  value && !notMark('"').test(value)
    ? 'Invalid format: include Quotation mark'
    : undefined;

export const notAtSign = <FieldValue extends string>(value?: FieldValue) =>
  value && !notMark('@').test(value) ? 'Invalid format: include At sign' : undefined;

export const notNumberSign = <FieldValue extends string>(value?: FieldValue) =>
  value && !notMark('#').test(value) ? 'Invalid format: include Number sign' : undefined;

export const name = <FieldValue extends string>(value?: FieldValue) =>
  value && !nameRegExp.test(value) ? 'Incorrect name' : undefined;

export const phoneNumberLength = <FieldValue extends string>(value?: FieldValue) =>
  value && value.replace(/\+/, '').length < 11
    ? 'Phone number must be 11 digits length'
    : undefined;

export const integerValidator = (value: string) => {
  if (!value) {
    return undefined;
  }
  return integerRegExp.test(value) ? undefined : 'Must be an integer';
};

export const validateBackendErrors = (error: AxiosError) => {
  if (!error.response?.data.errors) return;

  return error.response?.data.errors.reduce((acc, current) => {
    acc[current.property] = Object.values(current.constraints);
    return acc;
  }, {});
};

export const percentageOneHundredValidator = (value: string) => {
  if (!value) return undefined;
  if (Number.isNaN(Number(value))) return undefined;
  return Number(value) > 100 ? 'The maximum value cannot exceed 100' : undefined;
};

export const anotherNotNullRequired = (fieldName: string) => (
  value: unknown,
  values?: object,
) => (!values?.[fieldName] ? undefined : required(value));
// End Form validators

export const imageFileValidator = (filename: string, hideMessage?: boolean): boolean => {
  const validationResult = IMAGE_FILE_EXTENSIONS.includes(
    filename
      .split('.')
      .pop()
      ?.toLowerCase() || '',
  );

  if (!validationResult && !hideMessage) Toast.warn('Must be image files');

  return validationResult;
};

export const fileExtensionValidator = (extList: string[], warnMessage: string) => (
  filename: string,
): boolean => {
  const validationResult = extList.includes(
    filename
      .split('.')
      .pop()
      ?.toLowerCase() || '',
  );

  if (!validationResult) Toast.warn(warnMessage);

  return validationResult;
};

export const einValidator = (value: string) => {
  if (!value) return undefined;
  return einRegExp.test(value) ? undefined : 'EIN must be nine numbers';
};
