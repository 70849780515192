/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useOperation } from 'hooks/common';
import { headTableContacts } from 'constants/team';
import Jumbotron from 'components/Jumbotron';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import ContactsTable from './TeamContactsTable';
import { StateProps } from 'store/interfaces';
import {
  fetchTeam,
  fetchTeamContacts,
  fetchTeamContactsDelete,
} from 'store/ducks/team/operations';
import { getTeamById, getTeamContacts } from 'store/ducks/team/selectors';
import { TeamContact, TeamData } from 'interfaces/team';

const TeamContacts = () => {
  const [onFetchContacts, isLoading] = useOperation(fetchTeamContacts);
  const [onFetchTeam] = useOperation(fetchTeam);
  const [onFetchDeleteTeamContact] = useOperation(fetchTeamContactsDelete);
  const params: { teamId: string } = useParams();
  const contacts = useSelector<StateProps, TeamContact[]>(getTeamContacts);
  const team = useSelector<StateProps, TeamData | undefined>(getTeamById(params.teamId));

  useEffect(() => {
    onFetchContacts(params.teamId);
    onFetchTeam(params.teamId);
  }, [params]);

  return (
    <ContentBlockWrapper
      loading={isLoading}
      header={<Jumbotron name="Organization Contacts" back />}
    >
      {contacts && (
        <>
          <ContactsCount>{contacts.length} contacts</ContactsCount>
          {team && (
            <ContactsTable
              rows={[...contacts]}
              headCells={headTableContacts}
              team={team}
              handleDeleteContact={(teamId: string, contactId: string) =>
                onFetchDeleteTeamContact({ teamId, contactId })
              }
            />
          )}
        </>
      )}
    </ContentBlockWrapper>
  );
};

const ContactsCount = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 16px;
`;

export default TeamContacts;
