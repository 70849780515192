import React from 'react';
import styled from 'styled-components';

import { SingleSelect, UIButton, UICheckbox, UIModal } from 'components/UI';
import { SelectOption, UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { Form } from 'react-final-form';
import { Field } from 'components/FinalForm';
import { FieldTextInput } from 'components/FormFields';
import { email, required } from 'utils/validation';
import { composeValidators } from 'utils';
import { ROILevelEnum, SubscriptionPlan } from 'interfaces/team';
import { subscriptionPlanOptions } from 'constants/team';
import RadioList from 'components/RadioList';
import { FormLabel } from 'components/UI/form';

interface Props extends InjectedProps {
  isLoading: boolean;
  handleAccess: (values) => void;
}

const InviteOrganizationModal = ({
  onClose,
  handleAccess,
  isLoading,
  ...modalProps
}: Props) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 93px' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '559px',
        height: 'max-content',
        overflow: 'hidden',
      }}
    >
      <Title>Invite an Organization</Title>
      <Form
        onSubmit={handleAccess}
        render={({ handleSubmit, values, form }) => (
          <>
            <Field
              label="Organization Name"
              name="organizationName"
              component={FieldTextInput}
              validate={required}
            />
            <Field
              label="Email"
              name="email"
              component={FieldTextInput}
              validate={composeValidators<string>(required, email)}
            />
            <FieldMarginBottomWrapper>
              <FormLabel>Subscription</FormLabel>
              <Field name="subscription.name">
                {({ input }) => (
                  <SingleSelect
                    placeholder="Select Subscription Plan"
                    options={subscriptionPlanOptions}
                    value={input.value}
                    handleSelect={val => {
                      const value = (val as SelectOption)?.value;
                      input.onChange(value);
                      if (value !== 'A la carte') {
                        form.change('subscription.isProspecting', true);
                        form.change('subscription.isAudience', true);
                        form.change('subscription.isRoi', true);
                        form.change('subscription.isPrices', true);
                      } else {
                        form.change('subscription.isProspecting', false);
                        form.change('subscription.isAudience', false);
                        form.change('subscription.isRoi', false);
                        form.change('subscription.isPrices', false);
                      }
                    }}
                  />
                )}
              </Field>
            </FieldMarginBottomWrapper>
            <FieldMarginBottomWrapper>
              <Field name="subscription.isProspecting">
                {({ input }) => (
                  <Checkbox
                    label="Prospecting"
                    check={
                      values?.subscription?.name !== 'A la carte' ? true : input.value
                    }
                    handleClick={() => input.onChange(!input.value)}
                    disabled={values?.subscription?.name !== 'A la carte'}
                  />
                )}
              </Field>

              <Field name="subscription.isAudience">
                {({ input }) => (
                  <Checkbox
                    label="Audience"
                    check={
                      values?.subscription?.name !== 'A la carte' ? true : input.value
                    }
                    handleClick={() => input.onChange(!input.value)}
                    disabled={values?.subscription?.name !== 'A la carte'}
                  />
                )}
              </Field>

              <Field name="subscription.isRoi">
                {({ input }) => (
                  <Checkbox
                    label="ROI"
                    check={
                      values?.subscription?.name !== 'A la carte' ? true : input.value
                    }
                    handleClick={() => input.onChange(!input.value)}
                    disabled={values?.subscription?.name !== 'A la carte'}
                  />
                )}
              </Field>

              <Field name="subscription.roiLevel" initialValue={ROILevelEnum.Level1}>
                {({ input }) => (
                  <RadioListWrapper>
                    <RadioList
                      onChange={v => input.onChange(v)}
                      value={input.value}
                      disabled={
                        values?.subscription?.name !== SubscriptionPlan.A_LA_CARTE ||
                        !values?.subscription?.isRoi
                      }
                      options={[
                        {
                          label: 'Level 1 (20 partners)',
                          value: ROILevelEnum.Level1,
                        },
                        {
                          label: 'Level 2 (50 partners)',
                          value: ROILevelEnum.Level2,
                        },
                        {
                          label: 'Level 3 (100 partners)',
                          value: ROILevelEnum.Level3,
                        },
                        { label: 'Level 4 (unlimited)', value: ROILevelEnum.Level4 },
                      ]}
                    />
                  </RadioListWrapper>
                )}
              </Field>

              <Field name="subscription.isPrices">
                {({ input }) => (
                  <Checkbox
                    label="Prices"
                    check={
                      values?.subscription?.name !== 'A la carte' ? true : input.value
                    }
                    handleClick={() => input.onChange(!input.value)}
                    disabled={values?.subscription?.name !== 'A la carte'}
                  />
                )}
              </Field>
            </FieldMarginBottomWrapper>

            <BtnGroup>
              <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
              <Btn
                modifiers="primary"
                title="Send"
                isLoading={isLoading}
                handleClick={handleSubmit}
              />
            </BtnGroup>
          </>
        )}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 20px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const Checkbox = styled(UICheckbox)`
  margin-bottom: 4px;
`;

const RadioListWrapper = styled.div`
  margin-left: 24px;
`;

const FieldMarginBottomWrapper = styled.div`
  margin-bottom: 24px;
`;

export default InviteOrganizationModal;
