/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MainWrapper } from 'layouts';
import { useOperation } from 'hooks/common';
import { useParams } from 'react-router-dom';
import { StateProps } from 'store/interfaces';
import Jumbotron from 'components/Jumbotron';
import History from 'services/History';
import {
  fetchTeamContactCreate,
  fetchTeamContacts,
  fetchTeamContactsUpdate,
} from 'store/ducks/team/operations';
import { TeamContact } from 'interfaces/team';
import { getContactById } from 'store/ducks/team/selectors';
import TeamContactForm from 'components/team/TeamContactForm';

const EditTeamContact = () => {
  const [onfetchTeamContacts] = useOperation(fetchTeamContacts);

  const [onUpdateTeamContact] = useOperation(fetchTeamContactsUpdate);
  const [onCreateTeamContact] = useOperation(fetchTeamContactCreate);
  const params: { teamId: string; contactId?: string } = useParams();

  const contact = useSelector<StateProps, TeamContact | undefined>(
    getContactById(params.contactId),
  );

  useEffect(() => {
    onfetchTeamContacts(params.teamId);
  }, [params.teamId]);

  const onSubmit = useCallback(
    async (values: TeamContact) => {
      if (params.contactId) {
        await onUpdateTeamContact({
          contactId: params.contactId,
          teamId: params.teamId,
          data: values,
        });
      } else {
        await onCreateTeamContact({
          teamId: params.teamId,
          data: values,
        });
      }

      History.back();
    },
    [params],
  );

  return (
    <MainWrapper withOutNav header={<Jumbotron name="Organization Contact" back />}>
      <TeamContactForm initialValues={contact || {}} onSubmit={onSubmit} />
    </MainWrapper>
  );
};

export default EditTeamContact;
