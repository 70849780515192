import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
// import { PitchButton } from 'components/ActionButtons';
import { Link } from 'react-router-dom';
import { CropText } from 'components/UI';
import { getFullName } from 'utils/formatters';
import LinkedInIcon from 'assets/images/icons/linkedIn.svg';
import { parseLink } from 'utils';
import { TeamContact } from 'interfaces/team';

interface Props {
  rows: TeamContact[];
  teamId: string;
}

interface TableRowProps {
  row: TeamContact;
  teamId: string;
}

const TableRowWrapper = ({ row, teamId }: TableRowProps) => {
  const {
    id,
    firstName,
    lastName,
    jobTitle,
    email,
    phone,
    linkedinUrl,
    contactAccuracyScore,
  } = row;
  const userName = getFullName(firstName, lastName);

  const linkedInParse = linkedinUrl && parseLink(linkedinUrl);

  return (
    <TableRow>
      <TableRowCell style={{ maxWidth: '160px', minWidth: '160px' }}>
        <NameWrapper>
          <Name>{userName}</Name>
          <Department>{jobTitle}</Department>
        </NameWrapper>
      </TableRowCell>
      <TableRowCell style={{ maxWidth: '120px', minWidth: '120px' }}>
        <Center>
          {phone ? (
            <ContactLink href={`tel:${phone}`}>{phone}</ContactLink>
          ) : (
            <CropText width={120} text="No phone" />
          )}
        </Center>
      </TableRowCell>
      <TableRowCell style={{ maxWidth: '50px', minWidth: '50px' }}>
        <Center>
          {linkedInParse ? (
            <SocialLink href={linkedInParse} target="_blank">
              <ReactSVG src={LinkedInIcon} />
            </SocialLink>
          ) : (
            <p>-</p>
          )}
        </Center>
      </TableRowCell>
      <TableRowCell style={{ maxWidth: '136px', minWidth: '136px' }}>
        <Center>
          {email ? (
            <ContactLink href={`mailto:${email}`}>{email}</ContactLink>
          ) : (
            <CropText width={180} text="No email" />
          )}
        </Center>
      </TableRowCell>
      <TableRowCell style={{ maxWidth: '80px', minWidth: '80px;' }}>
        <Center>
          {contactAccuracyScore ? (
            <Score>{contactAccuracyScore}</Score>
          ) : (
            <CropText width={180} text="No score" />
          )}
        </Center>
      </TableRowCell>
      <TableRowCell style={{ width: '96px' }}>
        <Center>
          <EditLink to={`/edit-contact-team/${teamId}/${id}`}>Edit</EditLink>
        </Center>
      </TableRowCell>
    </TableRow>
  );
};

const TeamContactsTable = ({ rows, teamId }: Props) => {
  return (
    <Table>
      <TableBody>
        {rows.map(item => (
          <TableRowWrapper key={item.id} row={item} teamId={teamId} />
        ))}
      </TableBody>
    </Table>
  );
};

const NameWrapper = styled.div`
  font-size: 12px;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const Name = styled.p`
  font-weight: 500;
  margin-bottom: 4px;
`;

const Department = styled.p`
  color: var(--darkGray);
`;

const Table = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const TableBody = styled.div`
  background: #ffffff;
`;

const TableRow = styled.div`
  height: 100%;
  display: flex;
`;

const TableRowCell = styled.div<{ isNoAddBorder?: boolean }>`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 12px;
  line-height: 150%;
  width: 100%;
`;

export const ContactLink = styled.a`
  max-width: 95%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

const SocialLink = styled.a`
  display: block;
  width: 20px;
  & > div {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const EditLink = styled(Link)`
  color: var(--blue);
`;

const Score = styled.p`
  text-align: center;
  width: 100%;
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export default TeamContactsTable;
