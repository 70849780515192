import { Field } from 'components/FinalForm';
import { FieldTextArea } from 'components/FormFields';
import { ConfirmModal } from 'components/Modals';
import { UIButton, UIDivider, UILoadAvatar, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import { format } from 'date-fns';
import { useModal } from 'hooks/common';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { ObjNoteData } from 'interfaces/objNote';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';

interface Props extends InjectedProps {
  onAddNote: (value: string) => void;
  onDeleteNote: (id: string) => void;
  notes: ObjNoteData[];
}

const AdminNoteModal = ({
  onClose,
  onAddNote,
  onDeleteNote,
  notes,
  ...modalProps
}: Props) => {
  useLockBodyScroll();
  const [noteId, setNoteId] = useState('');

  const [openConfirmModal, closeConfirmModal] = useModal(
    () => (
      <ConfirmModal
        onClose={closeConfirmModal}
        name="Delete note"
        accessHandler={() => {
          onDeleteNote(noteId);
          closeConfirmModal();
        }}
        cancelHandler={closeConfirmModal}
        textAccessBtn="Delete"
      />
    ),
    [noteId],
  );

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '26px 40px' }}
      hideCloseIcon
      style={{
        width: '844px',
        height: 'max-content',
        overflow: 'hidden',
      }}
      header={<ModalTitle>Admin Note</ModalTitle>}
    >
      <Form
        onSubmit={(values, form) => {
          onAddNote(values.note);
          setTimeout(form.reset);
        }}
        render={({ handleSubmit }) => (
          <>
            <Field name="note" component={FieldTextArea} />
            <AddBtn modifiers="primary" title="Add Note" handleClick={handleSubmit} />
            <Divider type="horizontal" />
            <NoteList>
              {notes.map(item => (
                <NoteListItem key={item.id}>
                  <NoteListItemHead>
                    <NoteListItemAvatar imgKey={item.createUser.avatarKey} />
                    <div>{`${item.createUser.firstName} ${item.createUser.lastName}`}</div>
                    <NoteListItemDate>
                      {format(new Date(item.createdAt), 'MMM d, yyyy h:mm aaa')}
                    </NoteListItemDate>
                    <DelButton
                      title="Delete"
                      modifiers="danger"
                      handleClick={() => {
                        setNoteId(item.id);
                        openConfirmModal();
                      }}
                    />
                  </NoteListItemHead>
                  <NoteListItemContent>{item.message}</NoteListItemContent>
                </NoteListItem>
              ))}
            </NoteList>

            <BtnGroup>
              <Btn modifiers="secondary" title="Close" handleClick={onClose} />
            </BtnGroup>
          </>
        )}
      />
    </UIModal>
  );
};

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const DelButton = styled(UIButton)`
  padding: 0 8px 0 8px;
  margin-left: 8px;
`;

const AddBtn = styled(Btn)`
  margin-left: auto;
`;

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  padding: 20px 0 16px;
`;

const Divider = styled(UIDivider)`
  margin-top: 28px;
  margin-left: -40px;
  margin-right: -40px;
  width: calc(100% + 80px);
`;

const NoteList = styled.div`
  height: 320px;
  overflow: auto;
`;

const NoteListItem = styled.div`
  margin-bottom: 24px;
  margin-right: 4px;
`;
const NoteListItemHead = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 150%;
`;
const NoteListItemAvatar = styled(UILoadAvatar)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
`;
const NoteListItemDate = styled.div`
  margin-left: auto;
  color: #868686;
`;

const NoteListItemContent = styled.pre`
  font-size: 16px;
  line-height: 155%;
  margin-left: 40px;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

export default AdminNoteModal;
