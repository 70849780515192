import React, { useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { required, email } from 'utils/validation';
import { composeValidators } from 'utils';
import History from 'services/History';
import { FieldTextInput, FieldSwitcher, FieldSuggest } from 'components/FormFields';
import { UIButton } from 'components/UI';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { User } from 'interfaces/user';

import { identityField } from 'utils';
import { Field } from 'components/FinalForm';
import { TeamData } from 'interfaces/team';

export interface FormProps {
  onSubmit: (values: User) => OnSubmitReturnType;
  initialValues?: User;
  isLoading?: boolean;
  teamsSearchList: TeamData[];
  handleGetTeams: (val: string) => void;
  handleSetAsOwner?: (id: string) => void;
}

const UserProfileForm = ({
  onSubmit,
  initialValues,
  teamsSearchList,
  handleGetTeams,
  handleSetAsOwner,
}: FormProps) => {
  const initial = useMemo(() => {
    return {
      ...initialValues,
      organizetionField: initialValues?.team
        ? [{ organizationName: initialValues?.team?.organizationName, id: null }]
        : undefined,
    };
  }, [initialValues]);

  return (
    <Form
      initialValues={initial}
      onSubmit={v =>
        onSubmit({
          ...v,
          teamId: v.organizetionField?.[0].id || initialValues?.teamId || null,
        })
      }
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
        form,
      }: FormRenderProps<
        User & { organizetionField?: { organizationName: string; id: null | string }[] }
      >) => {
        return (
          <form>
            <Body>
              <FieldSection marginBottom="8px">
                <TitleSection>Personal Info</TitleSection>
                <Row>
                  <Field
                    name="firstName"
                    label="First Name"
                    component={FieldTextInput}
                    placeholder="Enter First Name"
                    validate={required}
                  />
                  <Field
                    name="lastName"
                    label="Last Name"
                    component={FieldTextInput}
                    placeholder="Enter Last Name"
                    validate={required}
                  />
                  <Field
                    name="email"
                    label="Email"
                    component={FieldTextInput}
                    placeholder="Enter Email"
                    validate={composeValidators<string>(required, email)}
                  />
                  <Field
                    name="linkedinLink"
                    parse={identityField}
                    label="Linkedin"
                    component={FieldTextInput}
                    placeholder="Enter LinkedIn link"
                  />
                  <Field
                    name="jobTitle"
                    label="Job Title"
                    component={FieldTextInput}
                    placeholder="Job Title"
                  />
                  {(initialValues?.role === 'owner' ||
                    initialValues?.role === 'user') && (
                    <Field
                      name="organizetionField"
                      label="Organizaion Name"
                      component={FieldSuggest}
                      handleGetSuggestions={handleGetTeams}
                      placeholder="Search"
                      validate={v =>
                        v && v[0]?.organizationName ? undefined : 'Required'
                      }
                      isMulti={false}
                      list={teamsSearchList}
                      parse={v =>
                        Array.isArray(v) && v.length ? [v[v.length - 1]] : undefined
                      }
                      format={(v: TeamData[]) =>
                        v && v.length
                          ? [{ ...v[0], name: v[0].organizationName }]
                          : undefined
                      }
                    />
                  )}
                </Row>
              </FieldSection>

              <FieldSection marginBottom="8px">
                <TitleSection>Notification</TitleSection>
                <Row>
                  <Field
                    name="isReceivingNotifications"
                    label="Turn OFF/ON"
                    component={FieldSwitcher}
                  />
                </Row>
              </FieldSection>

              <BtnGroup>
                <div>
                  <UIButton
                    handleClick={() => History.back()}
                    modifiers="secondary"
                    title="Cancel"
                  />
                  <UIButton
                    title="Save"
                    modifiers="primary"
                    handleClick={handleSubmit}
                    type="submit"
                    disabled={pristine || submitting || hasValidationErrors}
                    isLoading={submitting}
                  />
                </div>

                {initialValues?.id && (
                  <UIButton
                    title="Set as Owner"
                    modifiers="secondary"
                    handleClick={() => {
                      handleSetAsOwner?.(initialValues.id);
                    }}
                    disabled={
                      initialValues?.role === 'owner' ||
                      initialValues?.role === 'companyOwner' ||
                      initialValues?.role === 'admin' ||
                      initialValues?.role === 'teamsnapManager'
                    }
                  />
                )}
              </BtnGroup>
            </Body>
          </form>
        );
      }}
    />
  );
};

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  button:first-child {
    margin-right: 8px;
  }

  & button {
    width: 136px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: calc(50% - 8px);
  }
`;

const FieldSection = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '32px'};
`;

const TitleSection = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  margin-bottom: 24px;
`;

const Body = styled.div`
  background: #fff;
  padding: 40px;
  margin-bottom: 40px;
`;

export default UserProfileForm;
