import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { getFullName } from 'utils/formatters';
import { CropText } from 'components/UI';
import { CompanyContact, Company } from 'interfaces/companies';
import LinkedInIcon from 'assets/images/icons/linkedIn.svg';
import { parseLink } from 'utils';
import { Link } from 'react-router-dom';
import { useModal } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';

interface Props {
  rows: CompanyContact[];
  headCells: string[];
  company: Company;
  handleDeleteContact: (companyId: string, contactId: string) => void;
}

interface TableRowProps {
  row: CompanyContact;
  company: Company;
  handleDeleteContact: (companyId: string, contactId: string) => void;
}

const userTableWidth = {
  nameWidth: 263,
  jobTitleWidth: 250,
  jobFunctionWidth: 250,
  managementLevelWidth: 350,
  phoneWidth: 120,
  linkedinWidth: 100,
  emailWidth: 213,
  scoreWidth: 200,
  actionWidth: 120,
} as const;

const {
  nameWidth,
  jobTitleWidth,
  phoneWidth,
  linkedinWidth,
  emailWidth,
  actionWidth,
  scoreWidth,
  managementLevelWidth,
  jobFunctionWidth,
} = userTableWidth;

const TableRowWrapper = ({ row, handleDeleteContact }: TableRowProps) => {
  const {
    id,
    companyId,
    firstName,
    lastName,
    jobTitle,
    phone,
    email,
    contactAccuracyScore,
    linkedinUrl,
    jobFunction,
    managementLevel,
  } = row;

  const [showModal, hideModal] = useModal(() => {
    const deleteHandler = () => {
      handleDeleteContact(companyId, id);
      hideModal();
    };

    return (
      <ConfirmModal
        name={'Delete Company Contact'}
        textAccessBtn="Delete"
        description={'Are you sure you want to delete the Company Contact?'}
        accessHandler={deleteHandler}
        cancelHandler={hideModal}
        onClose={hideModal}
        hideCloseIcon
      />
    );
  });

  const userName = getFullName(firstName, lastName);

  return (
    <TableRow>
      <TableRowCell isNoAddBorder isAddWeight>
        {userName}
      </TableRowCell>
      <TableRowCell>{jobTitle}</TableRowCell>

      <TableRowCell>{jobFunction || 'N/A'}</TableRowCell>
      <TableRowCell>{managementLevel || 'N/A'}</TableRowCell>

      <TableRowCell>
        {phone ? (
          <ContactLink href={`tel:${phone}`}>{phone}</ContactLink>
        ) : (
          <CropText width={200} text="No phone" />
        )}
      </TableRowCell>

      <TableRowCell>
        {email ? (
          <ContactLink href={`mailto:${email}`}>{email}</ContactLink>
        ) : (
          <CropText width={200} text="No email" />
        )}
      </TableRowCell>

      <TableRowCell>
        {linkedinUrl ? (
          <SocialLink href={parseLink(linkedinUrl)} target="_blank">
            <ReactSVG src={LinkedInIcon} />
          </SocialLink>
        ) : (
          <SocialLinkEmpty>-</SocialLinkEmpty>
        )}
      </TableRowCell>

      <TableRowCell>{contactAccuracyScore}</TableRowCell>

      <TableRowCell>
        <Center>
          <EditLink to={`/edit-contact-company/${companyId}/${id}`}>Edit</EditLink>
          <DelLink onClick={showModal}>Delete</DelLink>
        </Center>
      </TableRowCell>
    </TableRow>
  );
};

const CompanyContactsTable = ({
  rows,
  headCells,
  company,
  handleDeleteContact,
}: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${nameWidth}px` }} />
          <col span={1} style={{ width: `${jobTitleWidth}px` }} />
          <col span={1} style={{ width: `${jobFunctionWidth}px` }} />
          <col span={1} style={{ width: `${managementLevelWidth}px` }} />
          <col span={1} style={{ width: `${phoneWidth}px` }} />
          <col span={1} style={{ width: `${emailWidth}px` }} />
          <col span={1} style={{ width: `${linkedinWidth}px` }} />
          <col span={1} style={{ width: `${scoreWidth}px` }} />
          <col span={1} style={{ width: `${actionWidth}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            {headCells.map(item => (
              <TableHeadCell key={item}>{item}</TableHeadCell>
            ))}
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(item => (
            <TableRowWrapper
              key={item.id}
              row={item}
              company={company}
              handleDeleteContact={handleDeleteContact}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  margin-bottom: 30px;
`;

const Table = styled.table``;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;
const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td<{ isAddWeight?: boolean; isNoAddBorder?: boolean }>`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }

  ${({ isAddWeight }) => isAddWeight && 'font-weight: 500;'}
  ${({ isNoAddBorder }) => isNoAddBorder && 'border-bottom: none;'}
`;

export const ContactLink = styled.a`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

export const SocialLink = styled.a`
  display: block;
  width: 20px;
  margin-left: 12px;
  & > div {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const SocialLinkEmpty = styled.p`
  margin-left: 20px;
`;

const EditLink = styled(Link)`
  color: var(--blue);
`;

const DelLink = styled.a`
  margin-left: 12px;
  color: var(--red);
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export default CompanyContactsTable;
