import * as api from './api';
import { AddCompanyMembersReq, Company, SubscriptionCompany } from 'interfaces/companies';
import { RequestProps } from 'interfaces/common';
import * as actions from './actions';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import axios from 'axios';
import { fetchErrorHandler } from 'utils/errorHandlers';
import * as userApi from '../user/api';
import { UserChangeCanvaReq } from 'interfaces/user';

export const fetchCompany = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchCompany(id)
    .then(company => {
      dispatch(actions.setCompany(company));
    })
    .catch(error => {
      throw error;
    });
};

export const fetchCompanyUpdate = ({ id, data }) => async (dispatch: Dispatch) => {
  return api
    .fetchCompanyUpdate(id, data)
    .then(company => {
      Toast.success('Company successfully updated');
      dispatch(actions.setCompany(company));
    })
    .catch(error => {
      fetchErrorHandler(
        error,
        data => data?.errors?.[0].constraints?.companyWebsiteExist,
      );
      throw error;
    });
};

export const fetchCompanyCreate = (data: Company) => async (dispatch: Dispatch) => {
  return api
    .fetchCompanyCreate(data)
    .then(company => {
      Toast.success('Company successfully created');
      dispatch(actions.setCompany(company));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchCompaniesSearch = ({
  page,
  q,
  limit,
  location,
  revenue,
  jobTitle,
}: RequestProps) => async (dispatch: Dispatch) => {
  return api
    .fetchCompaniesSearch(page, q, limit, location, revenue, jobTitle)
    .then(companies => {
      dispatch(actions.setCompanies(companies));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchSuggestCompaniesSearch = ({ page, q, limit }: RequestProps) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCompaniesSearch(page, q, limit)
    .then(companies => {
      dispatch(actions.setSuggestCompanies(companies));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchCompanyDestroy = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchCompanyDestroy(id)
    .then(() => {
      dispatch(actions.removeCompany(id));
    })
    .catch(() => {
      Toast.error('Error delete company');
    });
};

export const fetchFileUpload = ({ file, id }: { file: File; id: string }) => async () => {
  return api
    .fetchFileUpload({ file, id })
    .then(() => {
      return Toast.success('File successfully uploaded');
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchCompanyContacts = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchCompanyContacts(id)
    .then(contacts => {
      dispatch(actions.setContacts(contacts));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchCompanyContactCreate = ({ companyId, data }) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCompanyContactCreate(companyId, data)
    .then(contact => {
      Toast.success('Contact successfully created');
      dispatch(actions.setContact(contact));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchCompanyContactsUpdate = ({ companyId, contactId, data }) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCompanyContactsUpdate(companyId, contactId, data)
    .then(contact => {
      Toast.success('Contact successfully updated');
      dispatch(actions.setContact(contact));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchCompanyContactsDelete = ({ companyId, contactId }) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCompanyContactsDelete(companyId, contactId)
    .then(() => {
      dispatch(actions.deleteContact(contactId));
    })
    .catch(() => Toast.error('Error delete company contact'));
};

export const fetchCompanyLogoUpload = ({ companyId, file }) => async () => {
  return api
    .fetchCompanyLogoUpload(companyId, file)
    .then(() => {
      Toast.success('File successfully uploaded');
    })
    .catch(() => Toast.error('Error upload file'));
};

export const fetchCompanyNews = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchCompanyNews(id)
    .then(news => {
      dispatch(actions.setCompanyNews(news));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchCompanyMembersList = (teamId: string) => async (dispatch: Dispatch) => {
  try {
    const data = await api.fetchCompanyMembersList(teamId);
    dispatch(actions.getTeamMembers(data));
  } catch (error) {
    console.log(error);
  }
};

export const fetchCompanyMembersAdd = (param: AddCompanyMembersReq) => async () => {
  try {
    await api.fetchCompanyMembersAdd(param);

    Toast.success('Email sent successfully');
  } catch (error) {
    if (axios.isAxiosError(error)) {
      error.response?.data.message === 'Validation failed' &&
        Toast.error('Email must be a valid email');
    }
  }
};

export const fetchCompanyMembersInvite = (teamId: string) => async () => {
  try {
    const link = await api.fetchCompanyMembersInvite(teamId);

    Toast.success('Invite link was generated successfully');

    return link;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCompanyMembersDelete = (id: string) => async (dispatch: Dispatch) => {
  try {
    await api.fetchCompanyMembersDelete(id);
    dispatch(actions.deleteTeamMembers(id));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      Toast.error(error.response?.data.message);
    }
  }
};

export const fetchCompanySubscription = (companyId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCompanySubscription(companyId)
    .then(subscription => dispatch(actions.setSubscription(subscription)))
    .catch(() => Toast.error('Error get subscription'));
};

export const fetchCompanySubscriptionUpdate = (
  companyId: string,
  data: SubscriptionCompany,
) => async (dispatch: Dispatch) => {
  return api
    .fetchCompanySubscriptionUpdate(companyId, data)
    .then(subscription => {
      dispatch(actions.setSubscription(subscription));
      Toast.success('Subscription successfully updated');
    })
    .catch(() => Toast.error('Error update subscription'));
};

export const fetchCompanySubscriptionDelete = (companyId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCompanySubscriptionDelete(companyId)
    .then(subscription => {
      dispatch(actions.setSubscription(subscription));
      Toast.success('Subscription successfully removed');
    })
    .catch(() => Toast.error('Error delete subscription'));
};

export const fetchCompanyMemberUpdate = ({ id, data }) => async (dispatch: Dispatch) => {
  return userApi
    .fetchUserUpdate(id, data)
    .then(user => {
      dispatch(actions.setCompanyMember(user));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchCompanyMemberChangeCanva = (params: UserChangeCanvaReq) => async (
  dispatch: Dispatch,
) => {
  return userApi
    .fetchUserChangeCanva(params)
    .then(() => {
      dispatch(actions.setCompanyMemberIsCanva(params));
    })
    .catch(err => {
      fetchErrorHandler(err);
    });
};

export const fetchSocialImpactLog = (companyId: string) => async (dispatch: Dispatch) => {
  return api
    .fetchSocialImpactLog(companyId)
    .then(data => {
      dispatch(actions.setCompanySocialImpactLog(data));
    })
    .catch(fetchErrorHandler);
};

export const fetchcompaniesInfo = () => async (dispatch: Dispatch) => {
  return api
    .getCompaniesInfo()
    .then(response => {
      dispatch(actions.setSearchCompanies(response));
    })
    .catch(() => {});
};

// export const fetchSearchJobTitle = () => async (dispatch: Dispatch) => {
//   return api
//     .fetchSearchJobTitle()
//     .then(response => {
//       dispatch(actions.setSearchJobTitles(response));
//     })
//     .catch(() => {});
// };

export const fetchSearchJobTitle = (q: string) => async (dispatch: Dispatch) => {
  return api
    .fetchSearchJobTitle(q)
    .then(data => dispatch(actions.setSearchJobTitles(data)))
    .catch(() => Toast.error('Error search jobTitle'));
};

export const fetchImportCompanies = (data: any) => async (dispatch: Dispatch) => {
  return api
    .fetchImportCompanies(data)
    .then(res => {
      Toast.success('Companies successfully created');
      // dispatch(actions.setCompany(company));
    })
    .catch(fetchErrorHandler);
};

export const fetchGetImportTask = () => async () => {
  return api.fetchGetImportTask().catch(fetchErrorHandler);
};
