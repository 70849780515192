import { TableSortHeaderCell } from 'components/UI/table';
import UILink from 'components/UI/UILink';
import useSortStatus from 'hooks/common/useSortStatus';
import { MonthlyReportData } from 'interfaces/monthlyReport';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { numberFormat } from 'utils/formatters';

type Row = NonNullable<MonthlyReportData['companySearchLog']>;
interface Props {
  rows: Row;
  reportId: string;
}

interface TableRowProps {
  row: Row[0];
  reportId: string;
}

const userTableWidth = {
  column1: 360,
  column2: 360,
  column3: 360,
} as const;

const { column1, column2, column3 } = userTableWidth;

const TableRowWrapper = ({ row, reportId }: TableRowProps) => {
  const { companyId, name, website, searchCount } = row;
  const { push } = useHistory();

  return (
    <TableRow>
      <TableRowCell bold>
        <UILink
          onClick={() =>
            push(`/company/${companyId}?fromPage=${`/monthly-report/detail/${reportId}`}`)
          }
        >
          {name}
        </UILink>
      </TableRowCell>
      <TableRowCell>
        <UILink
          href={/^http(s)?:/.test(website) ? website : `http://${website}`}
          target="_blank"
        >
          {website}
        </UILink>
      </TableRowCell>
      <TableRowCell>{numberFormat(searchCount)}</TableRowCell>
      <TableRowCell />
    </TableRow>
  );
};

const MonthlyReportTop50CompaniesSearchedTable = ({ rows, reportId }: Props) => {
  const { data, sortStatus, handleSwitch } = useSortStatus(rows);

  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${column1}px` }} />
          <col span={1} style={{ width: `${column2}px` }} />
          <col span={1} style={{ width: `${column3}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            <TableSortHeaderCell
              colName="name"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Company Name
            </TableSortHeaderCell>
            <TableHeadCell>Website</TableHeadCell>
            <TableSortHeaderCell
              colName="searchCount"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Number of times it appeared in the search
            </TableSortHeaderCell>
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRowWrapper key={index} row={item} reportId={reportId} />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  width: 100%;
  position: relative;
  max-height: 600px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  background: #f4f4f4;
  position: sticky;
  top: 0;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableRow = styled.tr`
  height: 100%;
  background-color: #fff;
`;

const TableRowCell = styled.td<{ bold?: boolean }>`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  ${({ bold }) => bold && 'font-weight: 500;'}

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

export default MonthlyReportTop50CompaniesSearchedTable;
