import React from 'react';

import { UIActionButton } from 'components/UI';
import { ActionButtonProps as InjectedProps } from 'interfaces/common';
import UploadIcon from 'assets/images/icons/upload.svg';

interface BaseProps {
  uploadHandler: React.MouseEventHandler<HTMLButtonElement>;
}

type Props = BaseProps & Partial<InjectedProps>;

const ExportButton = ({
  uploadHandler,
  name = 'Export to Excel',
  icon = UploadIcon,
  ...rest
}: Props) => (
  <UIActionButton {...rest} handleClick={uploadHandler} icon={icon} name={name} />
);

export default ExportButton;
