import { Title } from 'components/ProfileView/AffinityForm';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import React, { useState, useEffect } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import styled from 'styled-components';
import DownloadTemplate from 'components/DownloadTemplate';
import UploadFileArea from 'components/UploadFileArea';
import {
  downloadImportTemplate,
  fetchUploadCompaniesXLSX,
} from 'store/ducks/companies/api';
import Toast from 'services/Toast';
import { fetchErrorHandler } from 'utils/errorHandlers';
import { fileExtensionValidator, lessThanMB } from 'utils/validation';
import { fetchGetImportTask } from 'store/ducks/companies/operations';
import { useOperation } from 'hooks/common';
import { ReactSVG } from 'react-svg';
import warnError from 'assets/images/icons/warning-error-yellow.svg';
import infoBlue from 'assets/images/icons/info-blue.svg';
import { ExportResults } from 'components/ExportResults';
import { useModal } from 'hooks/common';
import Moment from 'moment';
import 'moment-timezone';
import moment from 'moment-timezone';

interface Props extends InjectedProps {
  handleAccess: (values) => void;
  upLoading: boolean;
}

const CompaniesModal = ({ onClose, upLoading, handleAccess, ...modalProps }: Props) => {
  useLockBodyScroll();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isloadings, setIsloadings] = useState(false);
  const [isShowDone, setIsShowDone] = useState(true);
  const [onSubscriptionUpdate] = useOperation(fetchGetImportTask);
  const [importMsg, setImportMsg] = useState('');
  const [importCode, setImportCode] = useState('');

  let taskId = '';
  // const hideDone = () => {
  //   setIsShowDone(false);
  // };
  useEffect(() => {
    onSubscriptionUpdate('').then(value => {
      const dateRegex = /\b\d{1,2}\/\d{1,2}\/\d{4}\b/;
      const match = value['msg'].match(dateRegex);
      if (value['msg'] && value['msg'].length > 0 && match) {
        const parts = value['msg'].split('on ');
        const p = parts[1].split(' is');
        const times = p[0];
        const utcTime = Moment.utc(times);
        const centralTime = moment(utcTime).tz('America/Chicago');
        const formattedTime = centralTime.format('MM/DD/YYYY HH:mm:ss');
        const updatedString = value['msg'].replace(
          /\d{1,2}\/\d{1,2}\/\d{4} \d{2}:\d{2}:\d{2}/,
          formattedTime,
        );
        value['msg'] = updatedString;
      }

      setImportMsg(value['msg']);
      setImportCode(value['code']);
      // setImportCode('progress');
      // setImportMsg(
      //   'The import for records uploaded on 3/28/2023 16:52:31 is in process.',
      // );
      if (value['code'] === 'progress') {
        setIsDisabled(true);
      }
      if (value['code'] === 'done') {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        taskId = value['taskId'];
      }
    });
  }, []);

  const [showExportResultsModal, hideExportResultsModal] = useModal(
    () => (
      <ExportResults onClose={hideExportResultsModal} taskId={taskId} isCompany={true} />
    ),
    [],
  );
  return (
    <UIModal
      onClose={onClose}
      hideCloseIcon={true}
      {...modalProps}
      propsBodyCss={{ padding: '40px 60px' }}
      style={{
        width: 'max-content',
        maxWidth: '1129px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Title
        style={{
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '145%',
          fontFamily: 'Rubik',
          color: '#122434',
          fontStyle: 'normal',
          marginBottom: '30px',
        }}
      >
        Import or Update Companies
      </Title>
      {importCode === 'progress' && (
        <EinContent
          style={{ backgroundColor: '#fff3cd', color: '#856404', border: '#ffeeba' }}
        >
          <ReactSVG style={{ marginRight: 16 }} src={warnError} />
          {importMsg}
        </EinContent>
      )}
      {isShowDone && importCode === 'done' && (
        <EinContent style={{ background: '#E9F9FF' }}>
          <ReactSVG style={{ marginRight: 16 }} src={infoBlue} />
          {importMsg} &nbsp;&nbsp;
          <span
            style={{
              color: '#2BAEE0',
              fontSize: '12px',
              fontWeight: 500,
              cursor: 'pointer',
            }}
            onClick={showExportResultsModal}
          >
            See results
          </span>
          {/* <ReactSVG style={{ marginLeft: '262px' }} src={closeBig} onClick={hideDone} /> */}
        </EinContent>
      )}
      {importCode === 'error' && (
        <EinContent style={{ background: '#F2DAD4' }}>
          <ReactSVG style={{ marginRight: 16 }} src={warnError} />
          {importMsg}
        </EinContent>
      )}
      <Form
        onSubmit={values => {
          upLoading = true;
          handleAccess(values);
          // upLoading = false;
        }}
        initialValues={{ variantForm: 'Advanced' }}
        render={({ handleSubmit, values, form }: FormRenderProps<any>) => {
          return (
            <>
              {/* <Field
                name="action"
                label={
                  <div
                    style={{
                      fontFamily: 'Rubik',
                      fontStyle: 'normal',
                      fontSize: '12px',
                      lineHeight: '145%',
                      color: '#122434',
                    }}
                  >
                    Import or Update Companies
                  </div>
                }
                options={[
                  { value: 'create', label: 'Import Companies' },
                  { value: 'update', label: 'Update Companies' },
                ]}
                component={FieldSingleSelect}
                validate={required}
              /> */}
              <LimitWidthAndCenter>
                <UploadFileArea
                  name="Upload Excel List"
                  isloading={isloadings}
                  acceptValidator={fileExtensionValidator(
                    ['xlsx'],
                    'Only Excel files are allowed.',
                  )}
                  fileSizeValidator={lessThanMB(1000)}
                  areaHeight={203}
                  desc="You may upload 1 Excel file per time. Only Excel files are allowed. Only 10000 records can be uploaded."
                  descWidth={60}
                  filelist={
                    values.files?.map(item => ({
                      label: item.name,
                      value: item.name,
                    })) || []
                  }
                  onUpload={file => {
                    // console.log(file, 'file');
                    if (!file.length) return;
                    setIsloadings(true);
                    fetchUploadCompaniesXLSX(file[0])
                      .then(value => {
                        form.change('files', file);
                        form.change('rows', value);
                        Toast.success('Uploaded successfully');
                        setIsDisabled(false);
                        if (importCode === 'progress') {
                          setIsDisabled(true);
                        }
                      })
                      .catch(fetchErrorHandler)
                      .finally(() => {
                        setIsloadings(false);
                      });
                  }}
                  onDeleteFile={() => {
                    form.change('files', []);
                    setIsDisabled(true);
                    upLoading = false;
                  }}
                />
                <DownloadTemplate fetchFile={downloadImportTemplate} />
                <div style={{ height: '35px' }}></div>
                <Buttons>
                  <Button title="Cancel" handleClick={onClose} modifiers="secondary" />
                  <Button
                    title="Submit"
                    handleClick={handleSubmit}
                    modifiers="primary"
                    disabled={isDisabled}
                    isLoading={upLoading}
                  />
                </Buttons>
              </LimitWidthAndCenter>
            </>
          );
        }}
      />
    </UIModal>
  );
};
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Button = styled(UIButton)`
  width: 128px;
`;

const LimitWidthAndCenter = styled.div<{ isDisabled?: boolean }>`
  width: 720px;
  margin: 0 auto;
  ${({ isDisabled }) => isDisabled && 'width: 100%;'}
`;
const EinContent = styled.div`
  background: #dbf2fb;
  border-radius: 6px;
  margin-bottom: 12px;
  height: 66px;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-left: 24px;
`;
export default CompaniesModal;
