import { TeamStoreData } from 'interfaces/team';
import { ActionType, createReducer } from 'typesafe-actions';

import * as types from './types';
import * as actions from './actions';

type RootAction = ActionType<typeof actions>;

export default createReducer<TeamStoreData, RootAction>({
  teams: {
    items: [],
    links: {},
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
  teamsSuggested: [],
  subscription: undefined,
  teamMembers: [],
  news: [],
  contacts: [],
  payPalGivingFundCanUpdate: false,
  payPalGivingFundIfConfirm: false,
  impactGenome: [],
})
  .handleType([types.SET_TEAMS], (state, action) => ({
    ...state,
    teams: action.payload,
  }))
  .handleType([types.SET_TEAM], (state, action) => {
    const team = action.payload;
    if (state.teams.items.find(({ id }) => id === team.id))
      return {
        ...state,
        teams: {
          ...state.teams,
          items: state.teams.items.map(item => {
            if (item.id !== team.id) return item;

            return {
              ...item,
              ...team,
            };
          }),
        },
      };

    return {
      ...state,
      teams: {
        ...state.teams,
        items: [team, ...state.teams.items],
      },
    };
  })
  .handleType([types.SET_SUBSCRIPTION], (state, action) => ({
    ...state,
    subscription: action.payload,
  }))
  .handleType([types.DELETE_SUBSCRIPTION], state => ({
    ...state,
    subscription: undefined,
  }))
  .handleType([types.SET_TEAMS_SUGGESTED], (state, action) => ({
    ...state,
    teamsSuggested: action.payload,
  }))
  .handleType([types.DELETE_TEAM], (state, action) => ({
    ...state,
    teams: {
      ...state.teams,
      items: state.teams.items.filter(item => item.id !== action.payload),
    },
  }))
  .handleType(types.GET_TEAM_MEMBERS, (state, action) => {
    return {
      ...state,
      teamMembers: action.payload,
    };
  })
  .handleType([types.DELETE_TEAM_MEMBERS], (state, action) => {
    return {
      ...state,
      teamMembers: state.teamMembers.filter(item => item.id !== action.payload),
    };
  })
  .handleType([types.SET_TEAM_NEWS], (state, action) => ({
    ...state,
    news: action.payload,
  }))
  .handleType([types.SET_CONTACTS], (state, action) => ({
    ...state,
    contacts: action.payload,
  }))
  .handleType([types.SET_CONTACT], (state, action) => {
    const contact = action.payload;
    if (state.contacts.find(({ id }) => id === contact.id))
      return {
        ...state,
        contacts: state.contacts.map(item => {
          if (item.id !== contact.id) return item;

          return {
            ...item,
            ...contact,
          };
        }),
      };

    return {
      ...state,
      contacts: [contact, ...state.contacts],
    };
  })
  .handleType([types.DELETE_CONTACT], (state, action) => ({
    ...state,
    contacts: state.contacts.filter(item => item.id !== action.payload),
  }))
  .handleType([types.SET_TEAM_MEMBER], (state, action) => ({
    ...state,
    teamMembers: state.teamMembers.map(item =>
      item.id === action.payload.id ? action.payload : item,
    ),
  }))
  .handleType([types.SET_TEAM_MEMBER_ISCANVA], (state, action) => ({
    ...state,
    teamMembers: state.teamMembers.map(item => {
      if (item.id === action.payload.userId)
        return { ...item, isCanva: action.payload.isCanva };
      return item;
    }),
  }))
  .handleType([types.SET_TEAM_PAYPALGIVINGFUND_CANUPDATE], (state, action) => ({
    ...state,
    payPalGivingFundCanUpdate: action.payload,
  }))
  .handleType([types.SET_TEAM_PAYPALGIVINGFUND_IFCONFIRM], (state, action) => ({
    ...state,
    payPalGivingFundIfConfirm: action.payload,
  }))
  .handleType([types.SET_IMPACT_GENOME], (state, action) => ({
    ...state,
    impactGenome: action.payload,
  }));
