/* eslint-disable react-hooks/exhaustive-deps */
import Jumbotron from 'components/Jumbotron';
import { useOperation } from 'hooks/common';
import { TeamData } from 'interfaces/team';
import { TeamWrapper } from 'layouts';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchROIList } from 'store/ducks/roi/operations';
import { getROIList } from 'store/ducks/roi/selectors';
import { fetchTeam } from 'store/ducks/team/operations';
import { getTeamById } from 'store/ducks/team/selectors';
import { StateProps } from 'store/interfaces';
import ROIView from '../components/ROIView';

const ROI = () => {
  const [onfetchTeam, isLoading] = useOperation(fetchTeam);
  const [onFetchROIList] = useOperation(fetchROIList);

  const params: { teamId: string } = useParams();

  const team = useSelector<StateProps, TeamData | undefined>(getTeamById(params.teamId));
  const { items, meta } = useSelector(getROIList);

  useEffect(() => {
    onfetchTeam(params.teamId);
    onFetchROIList({ page: 1, teamId: params.teamId });
  }, [params.teamId]);

  return (
    <TeamWrapper
      teamId={params.teamId}
      isLoading={isLoading}
      header={<Jumbotron name={team?.organizationName || ''} back backPath="/teams" />}
    >
      {team && (
        <ROIView
          team={team}
          pricingList={items}
          pricingMeta={meta}
          handleFetchROIList={onFetchROIList}
        />
      )}
    </TeamWrapper>
  );
};

export default ROI;
