import React, { useState } from 'react';
import styled from 'styled-components';
import { format, isValid } from 'date-fns';
import { News } from 'interfaces/companies';
import { ReactSVG } from 'react-svg';
import AddIcon from 'assets/images/icons/add-icon.svg';
import DeleteIcon from 'assets/images/icons/delete-icon.svg';
import EditIcon from 'assets/images/icons/edit.svg';
import { useModal } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
import EditTeamNews from 'pages/news/EditTeamNews';
import { NewsData } from 'interfaces/news';

export interface Props {
  news: News[];
  teamId: string;
  onNewsCreate: (param: Partial<NewsData>) => void;
  onNewsUpdate: (param: Partial<NewsData>) => void;
  onNewsDelete: (id: string) => void;
}

const ProfileSideBar = ({
  news,
  teamId,
  onNewsCreate,
  onNewsUpdate,
  onNewsDelete,
}: Props) => {
  const [currentNew, setCurrentNew] = useState<News | undefined>(undefined);

  const [showEditModal, hideEditModal] = useModal(() => {
    const onSubmit = newObj => {
      hideEditModal();
      newObj.id ? onNewsUpdate(newObj) : onNewsCreate(newObj);
    };

    return (
      <EditTeamNews
        model={currentNew || { teamId, pageDate: new Date().toISOString() }}
        onClose={hideEditModal}
        onSubmit={onSubmit}
      />
    );
  }, [currentNew]);

  const [showModal, hideModal] = useModal(() => {
    const deleteHandler = () => {
      if (!currentNew?.id) return;

      hideModal();
      onNewsDelete(currentNew.id);
    };

    return (
      <ConfirmModal
        name={'Delete news'}
        textAccessBtn="Delete"
        description={'Are you sure you want to delete the news?'}
        accessHandler={deleteHandler}
        cancelHandler={hideModal}
        onClose={hideModal}
        hideCloseIcon
      />
    );
  }, [currentNew]);

  return (
    <Root>
      {news && (
        <>
          <Title>
            <div>News</div>

            <LinkGroup>
              <IconWrapper>
                <ReactSVG
                  src={AddIcon}
                  onClick={() => {
                    setCurrentNew(undefined);
                    showEditModal();
                  }}
                />
              </IconWrapper>
            </LinkGroup>
          </Title>

          <ul>
            {news.map((news: News) => {
              const {
                id,
                imageUrl,
                url,
                title,
                pageDate,
                content,
                author,
                siteFrom,
              } = news;
              const date =
                pageDate &&
                isValid(new Date(pageDate)) &&
                format(new Date(pageDate), 'MMM dd, yyyy');
              return (
                <NewsItem key={id}>
                  <ImgWrapper>
                    <NewsImage
                      style={{
                        backgroundImage: `url(${imageUrl})`,
                      }}
                    />
                  </ImgWrapper>
                  <NewsInfo>
                    <NewsTitle href={url} target="_blank">
                      {title}
                    </NewsTitle>
                    <NewsContent>{content}</NewsContent>
                    <NewsContentGray> {author && `By ${author}`}</NewsContentGray>
                    <NewsContentGray>{date || '-'}</NewsContentGray>
                    <FlexBetween>
                      <NewsContent>{siteFrom && `News from ${siteFrom}`}</NewsContent>
                      <FlexBetween>
                        <IconWrapper>
                          <ReactSVG
                            src={DeleteIcon}
                            onClick={() => {
                              setCurrentNew(news);
                              showModal();
                            }}
                          />
                        </IconWrapper>
                        <div style={{ padding: '5px' }}></div>
                        <IconWrapper>
                          <ReactSVG
                            src={EditIcon}
                            onClick={() => {
                              setCurrentNew(news);
                              showEditModal();
                            }}
                          />
                        </IconWrapper>
                      </FlexBetween>
                    </FlexBetween>
                  </NewsInfo>
                </NewsItem>
              );
            })}
          </ul>
        </>
      )}
    </Root>
  );
};

const NewsInfo = styled.div``;

const ImgWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  min-width: 72px;
  height: 187px;
  border-radius: 8px;
  overflow: hidden;
`;

const NewsItem = styled.li`
  font-size: 12px;
  line-height: 150%;
  &:not(:last-child) {
    padding-bottom: 12px;
    margin-bottom: 16px;
    border-bottom: 1px solid #e8e8e8;
  }
`;

const NewsImage = styled.div`
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-size: cover;
`;

const NewsTitle = styled.a`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
`;

const NewsContent = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
`;

const NewsContentGray = styled(NewsContent)`
  color: var(--darkGray);
`;

const Root = styled.div`
  padding: 32px 40px;
  width: 100%;
  max-width: 366px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div:first-child {
    display: flex;
    align-items: center;
  }
`;
const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconWrapper = styled.div`
  cursor: pointer;

  & svg {
    path {
      stroke: var(--blue);
    }
  }
`;

const LinkGroup = styled.div`
  display: flex;
  align-items: center;

  & > div:nth-child(1) {
    margin-right: 12px;
  }
`;

export default ProfileSideBar;
