import { PAGE_WIDTH } from 'constants/common';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  title: ReactNode;
  maxWidth?: string;
}

const TotalRow: FC<Props> = ({ title, maxWidth, children }) => {
  return (
    <Row maxWidth={maxWidth}>
      <Total>{title}</Total>
      {children}
    </Row>
  );
};

const Row = styled.section<{ maxWidth?: string }>`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  max-width: ${({ maxWidth = `${PAGE_WIDTH.NORMAL}px` }) => maxWidth};
  width: 100%;
`;

const Total = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  font-family: Rubik;
  font-style: normal;
  margin-right: auto;
  display: flex;
  align-items: center;
`;

export default TotalRow;
