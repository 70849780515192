import { UIModal } from 'components/UI';
import React, { useState } from 'react';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import styled from 'styled-components';
import CloseCircleGraySVG from 'assets/images/icons/close-circle-gray.svg';
import { ReactSVG } from 'react-svg';
import { useLockBodyScroll } from 'hooks/common';

interface Props extends InjectedProps {
  fileUrl?: string;
}

const PDFPreviewModal = ({ onClose, fileUrl }: Props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  useLockBodyScroll();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <UIModal
      onClose={onClose}
      propsBodyCss={{ padding: '0' }}
      className="pdf-preview-modal"
      hideCloseIcon
      style={{ width: 'fit-content', position: 'relative' }}
    >
      <CloseIcon src={CloseCircleGraySVG} onClick={onClose} />
      <Root>
        <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        <PaginationWrapper>
          <PaginationButton
            type="button"
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            ‹
          </PaginationButton>
          <PaginationText>
            {pageNumber} of {numPages}
          </PaginationText>
          <PaginationButton
            type="button"
            disabled={pageNumber >= (numPages || 1)}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            ›
          </PaginationButton>
        </PaginationWrapper>
      </Root>
    </UIModal>
  );
};

const PaginationWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
  background: white;
  opacity: 0;
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  border-radius: 4px;
  display: flex;
  width: fit-content;
  span {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
`;

const PaginationButton = styled.button`
  width: 44px;
  height: 44px;
  background: white;
  border: 0;
  font: inherit;
  font-size: 0.8em;
  border-radius: 4px;

  &:hover {
    background-color: #e6e6e6;
  }

  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const PaginationText = styled.span`
  padding: 0 0.5em;
`;

const Root = styled.div`
  :hover ${PaginationWrapper} {
    opacity: 1;
  }
  .annotationLayer {
    display: none;
  }
`;

const CloseIcon = styled(ReactSVG)`
  position: absolute;
  right: -8px;
  top: -8px;
  z-index: 1;
  cursor: pointer;
`;

export default PDFPreviewModal;
