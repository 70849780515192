import { ActionButtonProps } from 'interfaces/common';
import React from 'react';
import { ReactSVG } from 'react-svg';
import styled, { CSSProp } from 'styled-components';
import { UISpinner } from '.';

const UIActionButton = ({
  handleClick,
  icon,
  name,
  iconCss,
  isloading,
  className,
}: ActionButtonProps) => {
  return (
    <Root onClick={handleClick} className={className}>
      <Icon css={iconCss} src={icon} />
      {isloading && <Spinner />}
      <Text isloading={isloading}>{name}</Text>
    </Root>
  );
};

const Root = styled.button`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  transition: 0.5s ease;
  position: relative;

  &:hover {
    text-shadow: 0 0 0.01px var(--black), 0 0 0.01px var(--black);
  }
`;

const Icon = styled(ReactSVG)<{ css?: CSSProp }>`
  margin-right: 12px;

  ${({ css }) => css}
`;

const Text = styled.span<{ isloading?: boolean }>`
  ${({ isloading }) => isloading && 'opacity: 0.5'};
`;

const Spinner = styled(UISpinner)`
  position: absolute;
  left: 50%;

  svg {
    height: 20px;
    width: 20px;
  }
`;

export default UIActionButton;
