import { createSelector } from 'reselect';
import {
  IndustriesProps,
  MissionProps,
  StateProps,
  StatesProps,
  CRSFocus,
  IndustriesTreeProps,
  NaicsCodeTreeProps,
  SdgGoalsProps,
  RevenueProps,
} from 'store/interfaces';
import { Company, CompanyContact } from 'interfaces/companies';
import { values } from 'lodash';
import { TreeData } from 'components/TreeSelect/TreeSelect';

export const getCompanies = (state: StateProps) => state.companies.companies.items;
export const getCompaniesMeta = (state: StateProps) => state.companies.companies.meta;
export const getCompanyContacts = (state: StateProps) => state.companies.contacts;
export const getCompanyNews = state => state.companies.companyNews;
export const getAdditionalInfo = (state: StateProps) => state.user.additionalInfo;
export const getOrganizationsInfo = (state: StateProps) =>
  state.user.searchOrganizationsInfo;
export const getCompaniesInfo = (state: StateProps) =>
  state.companies.searchCompaniesInfo;
export const getSuggestCompanies = (state: StateProps) =>
  state.companies.suggestCompanies.items;
export const getCompanySocialImpactLog = (state: StateProps) =>
  state.companies.socialImpactLog;

export const getCompanyById = (companyId: string) =>
  createSelector([getCompanies], companies =>
    companies.find(({ id }: Company) => companyId === id),
  );

export const getContactById = (contactId?: string) =>
  createSelector([getCompanyContacts], contacts =>
    contacts.find(({ id }: CompanyContact) => contactId === id),
  );

export const getAdditionalInfoSelect = () =>
  createSelector([getAdditionalInfo], obj => {
    return {
      ...obj,
      missions: obj.missions.map((item: MissionProps) => ({
        value: item.Id,
        label: item.Mission,
      })),
      industries: obj.industries?.map((item: IndustriesProps) => ({
        value: item.Name,
        label: item.Name,
      })),
      industriesTree: (function coverTree(list: IndustriesTreeProps[]): TreeData {
        return list.map(item =>
          item.children
            ? {
                label: item.name,
                value: item.name,
                children: coverTree(item.children),
              }
            : { label: item.name, value: item.name },
        );
      })(obj.industriesTree) as TreeData,
      countries: obj.countries.map(item => ({
        value: item.country,
        label: item.country,
      })),
      states: obj.states
        .filter((item: StatesProps) => !!item.state)
        .map((item: StatesProps) => ({
          value: item.state,
          label: item.state,
        })),
      crsFocus: obj.crsFocus.map((item: CRSFocus) => ({
        value: item.id,
        label: item.name,
      })),
      cites: obj.cites.map(item => ({ value: item.city, label: item.city })),
      naicsCode: obj.naicsCodeTree
        .map(item => item.children)
        .flat(1)
        .map(item => ({
          label: `${item.code} ${item.name}`,
          value: item.code,
        })),
      primaryNaicsCode: obj.naicsCodeTree.map(item => ({
        label: `${item.code} ${item.name}`,
        value: item.code,
      })),
      naicsCodeTree: (function coverTree(list: NaicsCodeTreeProps[]): TreeData {
        return list.map(item =>
          item.children
            ? {
                label: `${item.code} ${item.name}`,
                value: item.code,
                children: coverTree(item.children),
              }
            : { label: `${item.code} ${item.name}`, value: item.code },
        );
      })(obj.naicsCodeTree) as TreeData,
    };
  });

export const getCompanyMembers = state =>
  values(state.companies.companyMembers).map(member => {
    return {
      ...member,
      linkedIn: null,
    };
  });

export const getOrganizations = () =>
  createSelector([getOrganizationsInfo], obj => {
    return {
      ...obj,
      location: obj.location.map((item: string) => ({
        label: item,
        value: item,
      })),
      missionFocus: obj.missionFocus.map((item: string) => ({
        label: item,
        value: item,
      })),
      sdgGoals: (function coverTree(list: SdgGoalsProps[]): TreeData {
        return list.map(item =>
          item.children
            ? {
                label: `${item.label}`,
                value: item.value,
                children: coverTree(item.children),
              }
            : { label: `${item.label}`, value: item.value },
        );
      })(obj.sdgGoals) as TreeData,
    };
  });

export const getCompaniesFomat = () =>
  createSelector([getCompaniesInfo], obj => {
    return {
      ...obj,
      location: obj.location.map((item: string) => ({
        label: item,
        value: item,
      })),
      revenue: (function coverTree(list: RevenueProps[]): TreeData {
        return list.map(item =>
          item.children
            ? {
                label: `${item.label}`,
                value: item.value,
                children: coverTree(item.children),
              }
            : { label: `${item.label}`, value: item.value },
        );
      })(obj.revenue) as TreeData,
    };
  });

export const getCompanySubscription = (state: StateProps) => state.companies.subscription;
export const getSearchJobTitles = (state: StateProps) => state.companies.searchJobTitles;
