import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import SuccessIcon from 'assets/images/toast/success.svg';
import InfoIcon from 'assets/images/toast/info.svg';
import WarnIcon from 'assets/images/toast/warn.svg';
import ErrorIcon from 'assets/images/toast/error.svg';

const Msg = (title: string, icon: string, message: string) => (
  <Message>
    <Icon src={icon} alt={title} />
    <div>
      <Title>{title}</Title>
      <Text dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, '<br/>') }} />
    </div>
  </Message>
);

const Message = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
`;

const Text = styled.p`
  font-size: 12px;
  line-height: 150%;
`;

const getToast = (type: string, message: string) => {
  switch (type) {
    case 'success':
      return Msg('Well done!', SuccessIcon, message);
    case 'info':
      return Msg('Info!', InfoIcon, message);
    case 'error':
      return Msg('Error!', ErrorIcon, message);
    case 'warn':
      return Msg('Warning!', WarnIcon, message);

    default:
  }
};

class Toast {
  success = (message: string) => toast.success(getToast('success', message));

  info = (message: string) => toast.info(getToast('info', message));

  error = (message?: string) =>
    toast.error(getToast('error', message || 'Something went wrong'));

  warn = (message: string) => toast.warn(getToast('warn', message));
}

export default new Toast();
