import { ROIMeltwaterData } from 'interfaces/roi';
import React from 'react';
import styled from 'styled-components';

interface Props {
  data?: ROIMeltwaterData['analyticsData']['top_keyphrases']['keyphrases'];
}

const RandomTextCard = ({ data }: Props) => {
  return (
    <Root>
      <Text1>{data?.[0]?.keyphrase}</Text1>
      <Text2>{data?.[1]?.keyphrase}</Text2>
      <Text3>{data?.[2]?.keyphrase}</Text3>
      <Text4>{data?.[3]?.keyphrase}</Text4>
      <Text5>{data?.[4]?.keyphrase}</Text5>
      <Text6>{data?.[5]?.keyphrase}</Text6>
      <Text7>{data?.[6]?.keyphrase}</Text7>
    </Root>
  );
};

const Root = styled.div`
  width: 568px;
  height: 282px;
  position: relative;
`;

const Text = styled.div`
  color: #2baee0;
  position: absolute;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

const Text1 = styled(Text)`
  top: 150px;
  left: 270px;
  font-size: 52px;
  width: 263px;
`;
const Text2 = styled(Text)`
  top: 58px;
  left: 36px;
  font-size: 40px;
  width: 212px;
`;
const Text3 = styled(Text)`
  top: -12px;
  left: 300px;
  font-size: 36px;
  width: 280px;
`;
const Text4 = styled(Text)`
  top: 245px;
  left: 69px;
  font-size: 24px;
  width: 195px;
`;
const Text5 = styled(Text)`
  top: 100px;
  left: 342px;
  font-size: 20px;
  width: 220px;
`;
const Text6 = styled(Text)`
  top: 194px;
  left: 43px;
  font-size: 16px;
  width: 178px;
`;
const Text7 = styled(Text)`
  top: 14px;
  left: 59px;
  font-size: 12px;
  width: 220px;
`;

export default RandomTextCard;
