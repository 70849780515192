import React from 'react';
import { UIRadioBtn } from 'components/UI';
import styled from 'styled-components';
import { SelectOption } from 'components/UI/interfaces';

interface Props {
  options: SelectOption[];
  onChange: (value?: string) => void;
  value: string;
  disabled?: boolean;
}

const RadioButtons = ({ value, onChange, options, disabled }: Props) => {
  return (
    <Root>
      <CheckboxList>
        {options.map(item => (
          <RadioBtn
            key={item.value}
            disabled={disabled}
            isChecked={value === item.value}
            label={item.label}
            handleCheck={() => onChange(item.value)}
          />
        ))}
      </CheckboxList>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  position: relative;
`;

const CheckboxList = styled.div`
  width: 100%;
  max-width: 200px;
`;

const RadioBtn = styled(UIRadioBtn)`
  margin-bottom: 8px;
`;

export default RadioButtons;
