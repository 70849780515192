import * as api from './api';
import StorageService from 'services/Storage';
import Toast from 'services/Toast';
import { AuthData } from 'interfaces/auth';
import * as actions from './actions';
import { Dispatch } from 'redux';

export const fetchSignIn = (payload: AuthData) => async (dispatch: Dispatch) => {
  return api
    .fetchSignIn(payload)
    .then(response => {
      const { accessToken, user } = response;
      StorageService.setItem('accessToken', accessToken);
      dispatch(actions.setAdmin(user));
    })
    .catch(() => {
      Toast.error('Incorrect password or email');
    });
};

export const CurrentAdminGet = () => async (dispatch: Dispatch) => {
  return api
    .getCurrentAdmin()
    .then(response => {
      dispatch(actions.setAdmin(response));
    })
    .catch(() => {});
};

export const handleLogout = () => async (dispatch: Dispatch) => {
  dispatch(actions.logout());
  return StorageService.removeItem('accessToken');
};

export const fetchUserToken = (id: string) => async () => {
  return api.fetchUserToken(id).catch(() => {
    Toast.error('Error get user token');
    return null;
  });
};
