/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { formatDataForChart } from 'utils/formatters';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CircleChart from 'components/CircleChart';
import { CircleChartDataProps } from 'interfaces/common';

export interface PrimaryInformationProps {
  genders?: Record<string, number>;
  income?: Record<string, number>;
  age?: Record<string, number>;
  ethnicity?: Record<string, number>;
}

const PrimaryInformation = ({
  genders,
  income,
  age,
  ethnicity,
}: PrimaryInformationProps) => {
  const renderTab = (data: CircleChartDataProps[]) => {
    return <CircleChart data={data} />;
  };

  const gendersData = useMemo(() => genders && formatDataForChart(genders), [
    JSON.stringify(genders),
  ]);

  const incomeData = useMemo(() => income && formatDataForChart(income), [
    JSON.stringify(income),
  ]);

  const ageData = useMemo(() => age && formatDataForChart(age), [JSON.stringify(age)]);

  const ethnicityData = useMemo(() => ethnicity && formatDataForChart(ethnicity), [
    JSON.stringify(genders),
  ]);

  return (
    <Root>
      <Tabs>
        <TabList>
          {gendersData && <Tab>Gender</Tab>}
          {incomeData && <Tab>Income</Tab>}
          {ageData && <Tab>Age</Tab>}
          {ethnicityData && <Tab>Ethnicity</Tab>}
        </TabList>

        {gendersData && <TabPanel>{renderTab(gendersData)}</TabPanel>}
        {incomeData && <TabPanel>{renderTab(incomeData)}</TabPanel>}
        {ageData && <TabPanel>{renderTab(ageData)}</TabPanel>}
        {ethnicityData && <TabPanel>{renderTab(ethnicityData)}</TabPanel>}
      </Tabs>
    </Root>
  );
};

const Root = styled.div`
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 32px;

  & .react-tabs {
  }

  & .react-tabs__tab-list {
    display: flex;
    margin: 32px 40px;
    border-bottom: 1px solid #e8e8e8;
  }

  & .react-tabs__tab {
    display: flex;
    justify-content: center;
    flex: 1;
    font-size: 12px;
    line-height: 150%;
    color: var(--darkGray);
    position: relative;
    padding-bottom: 12px;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      bottom: -4px;
      width: 100%;
      height: 4px;
      background: transparent;
    }
    &:hover {
      color: var(--blue);
      &:after {
        background: var(--blue);
      }
    }
  }

  & .react-tabs__tab--selected {
    color: var(--blue);
    &:after {
      background: var(--blue);
    }
  }
`;

export default PrimaryInformation;
