/* eslint-disable react-hooks/exhaustive-deps */
import Jumbotron from 'components/Jumbotron';
import { DashBoard } from 'components/roi/dashboard';
import { UIButton, UISpinner } from 'components/UI';
import { useOperation } from 'hooks/common';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { fetchROI, fetchROIMeltwaterData } from 'store/ducks/roi/operations';
import { getROI, getROIMeltwaterData } from 'store/ducks/roi/selectors';
import styled from 'styled-components';
import { format } from 'date-fns';

const ROITrackingDashboard = () => {
  const params = useParams<{ roiId: string }>();
  const { go } = useHistory();

  const [onFetchROI] = useOperation(fetchROI);
  const [onFetchROIMeltwaterData] = useOperation(fetchROIMeltwaterData);

  const roi = useSelector(getROI);
  const meltwaterData = useSelector(getROIMeltwaterData);

  useEffect(() => {
    onFetchROI(params.roiId);
    onFetchROIMeltwaterData(params.roiId);
  }, []);

  return (
    <ContentBlockWrapper
      header={
        <Jumbotron
          name={
            <JumbotronTitleWrapper>
              {roi?.name} Tracking Dashboard
              {roi?.startDate &&
                roi.endDate &&
                ` (${format(new Date(roi.startDate), 'yyyy-MM-dd')} - ${format(
                  new Date(roi.endDate),
                  'yyyy-MM-dd',
                )})`}
            </JumbotronTitleWrapper>
          }
        />
      }
    >
      {!meltwaterData ? (
        <Pending>{meltwaterData === '' ? 'Pending...' : <UISpinner />}</Pending>
      ) : (
        <DashBoard meltwaterData={meltwaterData} />
      )}

      <Button
        title="Back"
        modifiers="third"
        handleClick={() => {
          go(-1);
          window.scrollTo(0, 0);
        }}
      />
    </ContentBlockWrapper>
  );
};

const JumbotronTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled(UIButton)`
  padding: 10px 24px;
  margin-top: 24px;
`;

const Pending = styled.div`
  display: flex;
  justify-content: center;
  height: 300px;
  align-items: center;
  font-size: 48px;
  opacity: 0.5;
`;

export default ROITrackingDashboard;
