import { Field } from 'components/FinalForm';
import {
  FieldSingleSelect,
  FieldTextArea,
  FieldTextInput,
  FiledMultiSelectWithCheckBoxes,
  MaskedMoneyTextInput,
} from 'components/FormFields';
import { formatValueEnum } from 'components/UI/interfaces';
import arrayMutators from 'final-form-arrays';
import { ROIData } from 'interfaces/roi';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useHistory } from 'react-router';
import Toast from 'services/Toast';
import { uploadFile } from 'store/ducks/utility/api';
import styled from 'styled-components';
import { Button, ButtonGroup, FieldDoubleRow, FieldWrapper, Title } from './styled';
import CloseIcon from 'assets/images/icons/close.svg';
import PlusIcon from 'assets/images/icons/plus.svg';
import { UIButton } from 'components/UI';
import { ReactSVG } from 'react-svg';
import UploadFileArea from 'components/UploadFileArea';
import { getFilenameByPath } from 'utils/formatters';
import { StewardshipElements, StewardshipItems } from 'constants/roi';
import { FormArrayDeleteIcon, FormLabel } from 'components/UI/form';
import { integerValidator } from 'utils/validation';

interface Props {
  roi: ROIData;
  disabled?: boolean;
  onSubmit: (values: ROIData) => void;
  onGenerate: (values: ROIData) => void;
  onRevert: () => void;
}

const ImpactReportForm = ({ onSubmit, roi, disabled, onGenerate, onRevert }: Props) => {
  const { go } = useHistory();

  const [isLogoUploadLoading, setIsLogoUploadLoading] = useState(false);
  const [isImageUploadLoding, setIsImageUploadLoading] = useState(false);

  return (
    <Form
      initialValues={roi}
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={values => {
        onSubmit(values);
      }}
      render={({ handleSubmit, form, values }) => (
        <>
          <Title>Fundraising</Title>
          <FieldDoubleRow>
            <Field
              name="fundraising.totalFundsRaisedFromConsumers"
              label="Total Funds Raised from Consumers"
              component={MaskedMoneyTextInput}
              disabled={disabled}
            />
            <Field
              name="fundraising.totalFundsRaisedFromEmployees"
              label="Total Funds Raised from Employees"
              component={MaskedMoneyTextInput}
              disabled={disabled}
            />
          </FieldDoubleRow>
          <FieldDoubleRow>
            <Field
              name="fundraising.totalFundsRaisedFromCompany"
              label="Total Funds Raised from Company"
              component={MaskedMoneyTextInput}
              disabled={disabled}
            />
            <Field
              name="fundraising.totalUnderwritingOrAdditionalFunds"
              label="Total Underwriting or Additional Funds"
              component={MaskedMoneyTextInput}
              disabled={disabled}
            />
          </FieldDoubleRow>
          <FieldDoubleRow>
            <Field
              name="fundraising.totalNumberOfDonorsEngaged"
              label="Total Number of Donors Engaged"
              component={FieldTextInput}
              validate={integerValidator}
              disabled={disabled}
            />
          </FieldDoubleRow>

          <Title>Ambassadors</Title>
          <FieldDoubleRow>
            <Field
              name="ambassadors.didYouHaveAnyAmbassadors"
              label="Did you have any ambassadors, executives or influencers involved in this partnership, event or campaign?"
              component={FieldSingleSelect}
              disabled={disabled}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              onFieldChange={v => {
                if (v) return;
                form.change('ambassadors', {
                  didYouHaveAnyAmbassadors: v,
                });
              }}
            />
          </FieldDoubleRow>
          {values.ambassadors?.didYouHaveAnyAmbassadors && (
            <>
              <FieldDoubleRow>
                <Field
                  name="ambassadors.howInvolvedWereExecutiveChampionsInThisPartnership"
                  label="How involved were executive champions in this partnership?  "
                  component={FieldSingleSelect}
                  disabled={disabled}
                  options={[
                    { label: 'No involvement', value: 'No involvement' },
                    { label: 'Somewhat involved', value: 'Somewhat involved' },
                    { label: 'Very involved', value: 'Very involved' },
                  ]}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="ambassadors.howInvolvedWereCelebritiesInThisPartnership"
                  label="How involved were celebrities in this partnership?"
                  component={FieldSingleSelect}
                  disabled={disabled}
                  options={[
                    { label: 'No involvement', value: 'No involvement' },
                    { label: 'Somewhat involved', value: 'Somewhat involved' },
                    { label: 'Very involved', value: 'Very involved' },
                  ]}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="ambassadors.howInvolvedWereEmployeesInThisPartnership"
                  label="How involved were employees in this partnership? "
                  component={FieldSingleSelect}
                  disabled={disabled}
                  options={[
                    { label: 'No involvement', value: 'No involvement' },
                    { label: 'Somewhat involved', value: 'Somewhat involved' },
                    { label: 'Very involved', value: 'Very involved' },
                  ]}
                />
              </FieldDoubleRow>
            </>
          )}

          <Title>Stewardship</Title>
          <FieldDoubleRow>
            <Field
              name="stewardship.DidYourPartnerSupportYouWithAnyOfTheFollowingOpportunities"
              label="What kind of stewardship elements did you afford to this partner?  "
              component={FieldSingleSelect}
              disabled={disabled}
              options={StewardshipElements}
              onFieldChange={(value: typeof StewardshipElements[number]['value']) => {
                switch (value) {
                  case 'Basic': {
                    const value: typeof StewardshipItems[number]['value'][] = [
                      'Annual Kick-Off',
                      'Monthly Partner Newsletter',
                      'Partner Toolkit',
                      'Annual Partner Satisfaction Survey',
                      'Annual Review Meeting',
                      'Basic Impact Reporting Push Notification',
                    ];
                    form.change('stewardship.stewardshipitems', value);
                    break;
                  }
                  case 'Intermediate': {
                    const value: typeof StewardshipItems[number]['value'][] = [
                      'Annual Kick-Off',
                      'Monthly Partner Newsletter',
                      'Growth Opportunities Request',
                      'Quarterly Partner Meeting',
                      'Partner Toolkit',
                      'Partner Appreciation Event',
                      'Annual Partner Satisfaction Survey',
                      'Annual Review Meeting',
                      'ROI Impact Reporting Push Notification',
                    ];
                    form.change('stewardship.stewardshipitems', value);
                    break;
                  }
                  case 'Premium': {
                    const value: typeof StewardshipItems[number]['value'][] = [
                      'Annual Kick-Off',
                      'Monthly Partner Newsletter',
                      'Partner Feature in Monthly Partner Newsletter',
                      'Growth Opportunities Request',
                      'Monthly Partner Meeting',
                      'Partner Toolkit',
                      'VIP Event',
                      'Speaking Engagement at VIP Event',
                      'Partner Appreciation Event',
                      'Annual Partner Satisfaction Survey',
                      'Annual Review Meeting',
                      'ROI Impact Reporting Push Notification',
                      'Personalized Executive Thank You',
                      'Additional Custom Stewardship Activation',
                    ];
                    form.change('stewardship.stewardshipitems', value);
                    break;
                  }
                  case 'Custom':
                    form.change('stewardship.stewardshipitems', null);
                    break;
                }
              }}
            />
          </FieldDoubleRow>
          <FieldDoubleRow>
            <Field
              name="stewardship.stewardshipitems"
              label="Select applied custom stewardship items"
              component={FiledMultiSelectWithCheckBoxes}
              disabled={disabled}
              formatValue={formatValueEnum.BASE_ARRAY}
              options={StewardshipItems}
            />
          </FieldDoubleRow>

          <Title>Partner Activation</Title>
          <FieldDoubleRow>
            <FormLabel>
              Did your partner support you with any of the following opportunities:
            </FormLabel>
          </FieldDoubleRow>
          <FieldDoubleRow>
            <Field
              name="partnerActivation.accessToAgencies"
              label="Access to agencies, vendors or other portfolio brands"
              component={FieldSingleSelect}
              disabled={disabled}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
            />
            <Field
              name="partnerActivation.engagementFromTheirAmbassadors"
              label="Engagement from their ambassadors, executives, or influencers"
              component={FieldSingleSelect}
              disabled={disabled}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
            />
          </FieldDoubleRow>
          <FieldDoubleRow>
            <Field
              name="partnerActivation.paidMedia.isPaidMedia"
              label="Paid Media"
              component={FieldSingleSelect}
              disabled={disabled}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              onFieldChange={v => {
                if (v) return;
                form.change('partnerActivation', {
                  ...values.partnerActivation,
                  paidMedia: {
                    isPaidMedia: v,
                  },
                });
              }}
            />
            <Field
              name="partnerActivation.ownedMedia.isOwnedMedia"
              label="Owned Media"
              component={FieldSingleSelect}
              disabled={disabled}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              onFieldChange={v => {
                if (v) return;
                form.change('partnerActivation', {
                  ...values.partnerActivation,
                  ownedMedia: {
                    isOwnedMedia: v,
                  },
                });
              }}
            />
          </FieldDoubleRow>

          {values.partnerActivation?.paidMedia?.isPaidMedia && (
            <>
              <Title>Paid Media</Title>
              <FieldDoubleRow>
                <Field
                  name="partnerActivation.paidMedia.numberOfPrintPublicationImpressionsnumberOfPrintPublicationImpressions"
                  label="Number of print publication impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
                <Field
                  name="partnerActivation.paidMedia.numberOfDigitalImpressionsnumberOfDigitalImpressions"
                  label="Number of digital impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="partnerActivation.paidMedia.numberOfTelevisionImpressionsnumberOfTelevisionImpressions"
                  label="Number of television impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
                <Field
                  name="partnerActivation.paidMedia.numberOfRadioImpressionsnumberOfRadioImpressions"
                  label="Number of radio impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="partnerActivation.paidMedia.numberOfOutOfHomeImpressionsnumberOfOutOfHomeImpressions"
                  label="Number of out-of-home impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
                <Field
                  name="partnerActivation.paidMedia.totalSpentOnPrintMediatotalSpentOnPrintMedia"
                  label="Total spent on print media"
                  component={MaskedMoneyTextInput}
                  disabled={disabled}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="partnerActivation.paidMedia.totalSpentOnDigitalMediatotalSpentOnDigitalMedia"
                  label="Total spent on digital media"
                  component={MaskedMoneyTextInput}
                  disabled={disabled}
                />
                <Field
                  name="partnerActivation.paidMedia.totalSpentOnTelevisiontotalSpentOnTelevision"
                  label="Total spent on television"
                  component={MaskedMoneyTextInput}
                  disabled={disabled}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="partnerActivation.paidMedia.totalSpentOnRadiototalSpentOnRadio"
                  label="Total spent on radio"
                  component={MaskedMoneyTextInput}
                  disabled={disabled}
                />
                <Field
                  name="partnerActivation.paidMedia.totalSpentOnOutOfHomeImpressionstotalSpentOnOutOfHomeImpressions"
                  label="Total spent on out-of-home impressions"
                  component={MaskedMoneyTextInput}
                  disabled={disabled}
                />
              </FieldDoubleRow>
            </>
          )}

          {values.partnerActivation?.ownedMedia?.isOwnedMedia && (
            <>
              <Title>Owned Media</Title>
              <FieldDoubleRow>
                <Field
                  name="partnerActivation.ownedMedia.numberOfMagazineImpressionsnumberOfMagazineImpressions"
                  label="Number of magazine impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
                <Field
                  name="partnerActivation.ownedMedia.numberOfWebsiteImpressionsnumberOfWebsiteImpressions"
                  label="Number of website impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="partnerActivation.ownedMedia.numberOfInOfficeImpressionsnumberOfInOfficeImpressions"
                  label="Number of in-office impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
                <Field
                  name="partnerActivation.ownedMedia.numberOfECommunicationsImpressionsnumberOfECommunicationsImpressions"
                  label="Number of e-communications impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="partnerActivation.ownedMedia.numberOfCollateralImpressionsnumberOfCollateralImpressions"
                  label="Number of collateral impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
                <Field
                  name="partnerActivation.ownedMedia.numberOfInStoreImpressionsnumberOfInStoreImpressions"
                  label="Number of in-store impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="partnerActivation.ownedMedia.numberOfEventParticipantsnumberOfEventParticipants"
                  label="Number of event participants"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
                <Field
                  name="partnerActivation.ownedMedia.numberOfEmployeeImpressionsnumberOfEmployeeImpressions"
                  label="Number of employee impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
              </FieldDoubleRow>
            </>
          )}

          <Title>
            Impact
            <AddImpactButton
              modifiers="third"
              title={
                <AddImpactButtonTitle>
                  <ReactSVG src={PlusIcon} />
                  Add
                </AddImpactButtonTitle>
              }
              handleClick={() =>
                (!values.impact?.impact || values.impact.impact.length < 3) &&
                form.mutators.push('impact.impact', undefined)
              }
            />
          </Title>

          <FieldWrapper>
            <FieldArray name="impact.impact">
              {({ fields }) =>
                fields.map((name, index) => (
                  <div key={index}>
                    <Field
                      name={`${name}`}
                      label="Please describe how the funds and support generated through this partnership were used to support your mission."
                      component={FieldTextArea}
                      disabled={disabled}
                    />
                    <DeleteBtn src={CloseIcon} onClick={() => fields.remove(index)} />
                  </div>
                ))
              }
            </FieldArray>
          </FieldWrapper>

          <Title>Paid Media</Title>
          <FieldDoubleRow>
            <Field
              name="paidMedia.isPaidMedia"
              label="Did you support this partnership, campaign or event with a paid media spend?"
              component={FieldSingleSelect}
              disabled={disabled}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              onFieldChange={v => {
                if (v) return;
                form.change('paidMedia', {
                  isPaidMedia: v,
                });
              }}
            />
          </FieldDoubleRow>
          {values.paidMedia?.isPaidMedia && (
            <>
              <FieldDoubleRow>
                <Field
                  name="paidMedia.numberOfPrintPublicationImpressionsnumberOfPrintPublicationImpressions"
                  label="Number of print publication impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
                <Field
                  name="paidMedia.numberOfDigitalImpressionsnumberOfDigitalImpressions"
                  label="Number of digital impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="paidMedia.numberOfTelevisionImpressionsnumberOfTelevisionImpressions"
                  label="Number of television impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
                <Field
                  name="paidMedia.numberOfRadioImpressionsnumberOfRadioImpressions"
                  label="Number of radio impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="paidMedia.numberOfOutOfHomeImpressionsnumberOfOutOfHomeImpressions"
                  label="Number of out-of-home impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
                <Field
                  name="paidMedia.totalSpentOnPrintMediatotalSpentOnPrintMedia"
                  label="Total spent on print media"
                  component={MaskedMoneyTextInput}
                  disabled={disabled}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="paidMedia.totalSpentOnDigitalMediatotalSpentOnDigitalMedia"
                  label="Total spent on digital media"
                  component={MaskedMoneyTextInput}
                  disabled={disabled}
                />
                <Field
                  name="paidMedia.totalSpentOnTelevisiontotalSpentOnTelevision"
                  label="Total spent on television"
                  component={MaskedMoneyTextInput}
                  disabled={disabled}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="paidMedia.totalSpentOnRadiototalSpentOnRadio"
                  label="Total spent on radio"
                  component={MaskedMoneyTextInput}
                  disabled={disabled}
                />
                <Field
                  name="paidMedia.totalSpentOnOutOfHomeImpressionstotalSpentOnOutOfHomeImpressions"
                  label="Total spent on out-of-home impressions"
                  component={MaskedMoneyTextInput}
                  disabled={disabled}
                />
              </FieldDoubleRow>
            </>
          )}

          <Title>Owned Media</Title>
          <FieldDoubleRow>
            <Field
              name="ownedMedia.isOwnedMedia"
              label="Did you support this partnership, campaign or event with a paid media spend?"
              component={FieldSingleSelect}
              disabled={disabled}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              onFieldChange={v => {
                if (v) return;
                form.change('ownedMedia', {
                  isOwnedMedia: v,
                });
              }}
            />
          </FieldDoubleRow>
          {values.ownedMedia?.isOwnedMedia && (
            <>
              <FieldDoubleRow>
                <Field
                  name="ownedMedia.numberOfMagazineImpressionsnumberOfMagazineImpressions"
                  label="Number of magazine impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
                <Field
                  name="ownedMedia.numberOfWebsiteImpressionsnumberOfWebsiteImpressions"
                  label="Number of website impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="ownedMedia.numberOfInOfficeImpressionsnumberOfInOfficeImpressions"
                  label="Number of in-office impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
                <Field
                  name="ownedMedia.numberOfECommunicationsImpressionsnumberOfECommunicationsImpressions"
                  label="Number of e-communications impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="ownedMedia.numberOfCollateralImpressionsnumberOfCollateralImpressions"
                  label="Number of collateral impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
                <Field
                  name="ownedMedia.numberOfInStoreImpressionsnumberOfInStoreImpressions"
                  label="Number of in-store impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
              </FieldDoubleRow>
              <FieldDoubleRow>
                <Field
                  name="ownedMedia.numberOfEventParticipantsnumberOfEventParticipants"
                  label="Number of event participants"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
                <Field
                  name="ownedMedia.numberOfEmployeeImpressionsnumberOfEmployeeImpressions"
                  label="Number of employee impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
              </FieldDoubleRow>
            </>
          )}

          <Title>Social Media</Title>
          <FieldDoubleRow>
            <Field
              name="socialMedia.wouldYouLikeToSubmitAdditionalSocialMediaMetricsForThisPartnership"
              label="Would you like to submit additional social media metrics for this partnership?"
              component={FieldSingleSelect}
              disabled={disabled}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              onFieldChange={v => {
                if (v) return;
                form.change('socialMedia', {
                  wouldYouLikeToSubmitAdditionalSocialMediaMetricsForThisPartnership: v,
                });
              }}
            />
          </FieldDoubleRow>
          {values.socialMedia
            ?.wouldYouLikeToSubmitAdditionalSocialMediaMetricsForThisPartnership && (
            <>
              <FieldWrapper>
                <Field
                  name="socialMedia.totalNumberOfAdditionalSocialMediaImpressions"
                  label="Additional social media impressions"
                  component={FieldTextInput}
                  validate={integerValidator}
                  disabled={disabled}
                />
              </FieldWrapper>
            </>
          )}

          <Title>Customize Report</Title>
          <FieldWrapper>
            <UploadFileArea
              name="Upload"
              disabled={disabled}
              accept=".jpg,.jpeg,.png"
              isloading={isLogoUploadLoading}
              inputId="impact-repot-form-upload-logo"
              filelist={
                values.customizeReport?.logoKey
                  ? [
                      {
                        label: getFilenameByPath(values.customizeReport.logoKey),
                        value: values.customizeReport.logoKey,
                      },
                    ]
                  : undefined
              }
              desc="Upload a logo that represents your partnership. File size limit is 5 Mb. Only jpg, png files allowed. The recommended image size is 83 by 83 pixels."
              onUpload={files => {
                if (!files.length) return;

                setIsLogoUploadLoading(true);
                uploadFile(files[0], 'roi-report').then(v => {
                  if (v.status === 'success') {
                    form.change('customizeReport', {
                      ...values.customizeReport,
                      logoKey: v.fileKey,
                    });
                    setIsLogoUploadLoading(false);
                    return Toast.success('file uploaded successfully');
                  }
                  setIsLogoUploadLoading(false);
                  Toast.error('Error upload file');
                });
              }}
              onDeleteFile={fileKey => {
                form.change('customizeReport', {
                  ...values.customizeReport,
                  logoKey:
                    fileKey === values.customizeReport?.logoKey
                      ? null
                      : values.customizeReport?.logoKey,
                });
              }}
            />
            <UploadFileArea
              name="Upload"
              disabled={disabled}
              accept=".jpg,.jpeg,.png"
              isloading={isImageUploadLoding}
              inputId="impact-repot-form-upload-image"
              filelist={
                values.customizeReport?.imageKey
                  ? [
                      {
                        label: getFilenameByPath(values.customizeReport.imageKey),
                        value: values.customizeReport.imageKey,
                      },
                    ]
                  : undefined
              }
              desc="Upload an image that represents your partnership. File size limit is 5 Mb. Only jpg, png files allowed. The recommended image size is 1280 by 522 pixels."
              onUpload={files => {
                if (!files.length) return;

                setIsImageUploadLoading(true);
                uploadFile(files[0], 'roi-report').then(v => {
                  if (v.status === 'success') {
                    form.change('customizeReport', {
                      ...values.customizeReport,
                      imageKey: v.fileKey,
                    });
                    setIsImageUploadLoading(false);
                    return Toast.success('file uploaded successfully');
                  }
                  setIsImageUploadLoading(false);
                  Toast.error('Error upload file');
                });
              }}
              onDeleteFile={fileKey => {
                form.change('customizeReport', {
                  ...values.customizeReport,
                  imageKey:
                    fileKey === values.customizeReport?.imageKey
                      ? null
                      : values.customizeReport?.imageKey,
                });
              }}
            />
          </FieldWrapper>

          <ButtonGroup>
            <Button title="Cancel" modifiers="secondary" handleClick={() => go(-1)} />
            <Button
              title="Save"
              modifiers="secondary"
              handleClick={handleSubmit}
              disabled={disabled}
            />
            {!roi.calculation && (
              <Button
                title="Generate Impact Report"
                modifiers="primary"
                disabled={disabled}
                handleClick={() => {
                  onGenerate(values);
                  window.scrollTo(0, 0);
                }}
              />
            )}
            {roi.calculation && (
              <Button title="Revert" modifiers="primary" handleClick={onRevert} />
            )}
          </ButtonGroup>
        </>
      )}
    />
  );
};

const AddImpactButton = styled(UIButton)`
  padding: 6px 16px;
  margin-left: 16px;
`;

const AddImpactButtonTitle = styled.div`
  display: flex;
`;

const DeleteBtn = styled(FormArrayDeleteIcon)`
  position: relative;
  top: -90px;
  right: -780px;
`;

export default ImpactReportForm;
