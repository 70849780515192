import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface StyledProps {
  width: string | number;
}

interface BaseProps {
  text: ReactNode;
}

type Props = BaseProps & StyledProps;

const CropText = ({ width, text, ...rest }: Props) => {
  return (
    <Root width={width} {...rest}>
      {text}
    </Root>
  );
};

const Root = styled.span<StyledProps>`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ width }) => `width: ${typeof width === 'number' ? `${width}px` : width}`}
`;

export default CropText;
