import RatioInput from 'components/inputs/RatioInput';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import FieldInput from '../FieldInput';

const FieldRatioInput = (props: FieldRenderProps<string>) => {
  return <FieldInput {...props} renderInput={props => <RatioInput {...props} />} />;
};

export default FieldRatioInput;
