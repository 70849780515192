import React from 'react';
import styled, { CSSProp } from 'styled-components';
import { UISpinnerProps } from './interfaces';
import Loader from 'react-loader-spinner';

const UISpinner = ({
  width = 50,
  height = 50,
  className,
  color = '#2BAEE0',
  secondaryColor = 'rgba(212, 201, 236, 0.4)',
  propsCss,
}: UISpinnerProps) => {
  return (
    <Wrapper className={className} css={propsCss}>
      <Loader
        type="Oval"
        color={color}
        secondaryColor={secondaryColor}
        height={height}
        width={width}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ css?: CSSProp }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ css }) => css}
`;

export default UISpinner;
