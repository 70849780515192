import React from 'react';
import { ReactSVG } from 'react-svg';
import { ToastContainer } from 'react-toastify';
import CloseIcon from 'assets/images/icons/close-toast.svg';

const CustomToast = () => {
  return (
    <ToastContainer
      closeButton={() => <ReactSVG src={CloseIcon} />}
      autoClose={6000}
      hideProgressBar
      draggablePercent={60}
      newestOnTop
    />
  );
};

export default CustomToast;
