import { CampaignStoreData } from 'interfaces/campaign';

export interface CampaignProps {
  campaign: CampaignStoreData;
}

export const getEinSuggest = (state: CampaignProps) =>
  state.campaign.einSuggest.data?.map((item: any) => ({
    ...item,
    name: `${item.name}, ${item.city} ${item.state} (PPGF ID: ${item.ein})`,
  }));
