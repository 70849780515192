/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { formatDataForChart } from 'utils/formatters';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CircleChart from 'components/CircleChart';
import { CircleChartDataProps } from 'interfaces/common';

export interface SecondaryInformationProps {
  affiliation?: Record<string, number>;
  nonprofit?: Record<string, number>;
  purchase?: Record<string, number>;
  brands?: Record<string, number>;
  interests?: Record<string, number>;
}

const SecondaryInformation = ({
  affiliation,
  nonprofit,
  purchase,
  brands,
  interests,
}: SecondaryInformationProps) => {
  const renderTab = (data: CircleChartDataProps[]) => {
    return <CircleChart data={data} />;
  };

  const affiliationData = useMemo(() => affiliation && formatDataForChart(affiliation), [
    JSON.stringify(affiliation),
  ]);

  const nonprofitData = useMemo(() => nonprofit && formatDataForChart(nonprofit), [
    JSON.stringify(nonprofit),
  ]);

  const purchaseData = useMemo(() => purchase && formatDataForChart(purchase), [
    JSON.stringify(purchase),
  ]);

  const brandsData = useMemo(() => brands && formatDataForChart(brands), [
    JSON.stringify(brands),
  ]);

  const interestsData = useMemo(() => interests && formatDataForChart(interests), [
    JSON.stringify(interests),
  ]);

  return (
    <Root>
      <Tabs>
        <TabList>
          {affiliationData && <Tab>Affiliation</Tab>}
          {nonprofitData && <Tab>Nonprofit</Tab>}
          {purchaseData && <Tab>Purchase</Tab>}
          {brandsData && <Tab>Brands</Tab>}
          {interestsData && <Tab>Interests</Tab>}
        </TabList>

        {affiliationData && <TabPanel>{renderTab(affiliationData)}</TabPanel>}
        {nonprofitData && <TabPanel>{renderTab(nonprofitData)}</TabPanel>}
        {purchaseData && <TabPanel>{renderTab(purchaseData)}</TabPanel>}
        {brandsData && <TabPanel>{renderTab(brandsData)}</TabPanel>}
        {interestsData && <TabPanel>{renderTab(interestsData)}</TabPanel>}
      </Tabs>
    </Root>
  );
};

const Root = styled.div`
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 32px;

  & .react-tabs {
  }

  & .react-tabs__tab-list {
    display: flex;
    margin: 32px 40px;
    border-bottom: 1px solid #e8e8e8;
  }

  & .react-tabs__tab {
    display: flex;
    justify-content: center;
    flex: 1;
    font-size: 12px;
    line-height: 150%;
    color: var(--darkGray);
    position: relative;
    padding-bottom: 12px;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      bottom: -4px;
      width: 100%;
      height: 4px;
      background: transparent;
    }
    &:hover {
      color: var(--blue);
      &:after {
        background: var(--blue);
      }
    }
  }

  & .react-tabs__tab--selected {
    color: var(--blue);
    &:after {
      background: var(--blue);
    }
  }
`;

export default SecondaryInformation;
