/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';

import { Link, useHistory, useRouteMatch } from 'react-router-dom';

interface Props<T extends string> {
  list: { value: T; label: string }[];
  queryKey: string;
  activeTab?: T;
  defaultTab?: string;
}

const NavigationBar = <T extends string>({
  list,
  queryKey,
  activeTab,
  defaultTab,
}: Props<T>) => {
  const { push } = useHistory();
  const parsed = queryString.parse(window.location.search);
  const activeQuery = parsed?.[queryKey] || defaultTab;
  const { url } = useRouteMatch();

  const getParams = useCallback(
    (value: string | undefined | number) => queryString.stringify({ [queryKey]: value }),
    [],
  );

  useEffect(() => {
    if (activeTab && !parsed?.[queryKey]) {
      const params = getParams(activeTab);

      push(`${url}?${params}`);
    }
  }, []);

  return (
    <Root>
      <List>
        {list.map(({ label, value }, index) => {
          const params = getParams(value);

          return (
            <Item key={index}>
              <StyledLink $isActive={activeQuery === value} to={`${url}?${params}`}>
                {label}
              </StyledLink>
            </Item>
          );
        })}
      </List>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  width: 100%;
`;

const List = styled.ul`
  display: flex;
  height: 69px;
  width: 100%;
  background: #fff;
  border-radius: 6px;
  padding: 0 24px;
`;

const Item = styled.li`
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 22px;
  }
`;

const StyledLink = styled(Link)<{
  $isActive: boolean;
}>`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 155%;
  border-bottom: 3px solid transparent;
  transition: border-color 0.5s ease, color 0.5s ease;

  ${({ $isActive }) =>
    $isActive &&
    `
    color: var(--blue);
    border-color: var(--blue);
  `}

  &:hover {
    color: var(--blue);
    border-color: var(--blue);
  }
`;

export default NavigationBar;
