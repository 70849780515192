import axios from 'axios';
import Toast from 'services/Toast';

const fetchErrorHandler = <T>(error: T, getMessage?: (resData: any) => string) => {
  if (!axios.isAxiosError(error)) return;

  const message = getMessage?.(error.response?.data) || error.response?.data.message;
  if (message) Toast.error(message);
  throw error;
};

export { fetchErrorHandler };
