import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { UIButton, UILoadAvatar } from 'components/UI';
import { Link } from 'react-router-dom';

export const Root = styled.div`
  width: 100%;
  max-width: 1096px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f2f2f2;
  padding: 40px;
  align-items: center;
`;

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const LoadImage = styled(UILoadAvatar)`
  border-radius: 8px;
  width: 100px;
  min-width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 26px;
  background-color: #fff;
`;

export const ImageEmpty = styled.div`
  border-radius: 8px;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 26px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CompanyTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
`;

export const CompanyTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CompanyType = styled.p`
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 750px;
`;

export const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SectionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContactMoreLink = styled(Link)`
  font-size: 12px;
  line-height: 150%;
  color: var(--blue);
  &:hover {
    text-decoration: underline;
  }
`;

export const SocialLink = styled.a`
  display: block;
  width: 20px;
  margin-right: 12px;
  & > div {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Body = styled.div`
  background: #fff;
  display: flex;
`;

export const Content = styled.div`
  padding: 32px 40px;
  border-right: 1px solid #ebebeb;
  width: 100%;
  max-width: 730px;
`;

export const SectionTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
`;

export const SectionSubTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 16px;
`;

export const DescriptionWrapper = styled.div`
  margin-bottom: 48px;
  position: relative;
  & .descriptionSeeMore {
    font-size: 16px;
    line-height: 155%;
  }
  & .my-anchor-css-class {
    position: absolute;
    left: 0;
    bottom: -24px;
    color: var(--blue);
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Description = styled.pre`
  white-space: pre-wrap;
  word-break: break-word;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const ContactsInner = styled.div`
  display: flex;
  margin-bottom: 15px;
  & > div:first-child {
    margin-right: 10px;
  }
`;

export const ContactsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 18px;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 50px;
`;

export const ContactsItem = styled.div`
  display: flex;
  align-items: center;
`;

export const ContactsLink = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;

export const ContactsIcon = styled(ReactSVG)`
  margin-right: 10px;
`;

export const TickerWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

export const TickerItem = styled.div`
  display: flex;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 15px 26px;
`;

export const TickerTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
`;

export const TickerDesc = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
`;

export const ReportedWrapper = styled.div`
  width: 100%;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 32px;
`;

export const ReportedItem = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13px 0;
  &:first-child {
    border-right: 1px solid #e8e8e8;
  }
`;

export const ReportedRow = styled.div`
  display: flex;
  &:not(:first-child) {
    border-top: 1px solid #e8e8e8;
  }
`;

export const ReportedTitle = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  margin-bottom: 4px;
`;

export const ReportedValue = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
`;

export const DemographicsDesc = styled.p`
  font-size: 16px;
  line-height: 155%;
  margin-bottom: 32px;
`;

export const ImpactSection = styled.div`
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 24px;
`;

export const PartnershipLink = styled.a`
  display: inline-block;
  color: var(--blue);
  margin-bottom: 32px;
  &:hover {
    text-decoration: underline;
  }
`;

export const CharitablePartners = styled(ImpactSection)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  & > p {
    width: 50%;
  }
`;

export const AlignmentList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 112px);
  gap: 16px;
  margin-bottom: 32px;
`;

export const AlignmentItem = styled.img`
  width: 112px;
  height: 112px;
`;

export const ImpactRow = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

export const ImpactRowInner = styled.div`
  width: 50%;
  &:first-child {
    margin-right: 24px;
  }
`;

export const ImpactValue = styled.p`
  font-size: 16px;
  line-height: 155%;
  display: flex;
  align-items: center;
  &:not(:nth-last-child(n - 2)) {
    margin-bottom: 12px;
  }
  &:before {
    content: '';
    width: 6px;
    height: 6px;
    background: var(--blue);
    border-radius: 100%;
    margin-right: 8px;
    min-width: 6px;
  }
`;

export const EmptyAvatar = styled(ReactSVG)`
  & svg {
    width: 48px;
    height: 48px;
    & path {
      fill: var(--darkGray);
    }
  }
`;

export const EditBtn = styled(UIButton)`
  width: 120px;
`;

export const EmptyAlignment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;
  width: 112px;
  height: 112px;
  background: #f2f2f2;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  text-align: center;
  margin-bottom: 20px;
`;

export const EmployeeValue = styled.p`
  font-size: 16px;
  line-height: 155%;
  margin-bottom: 40px;
`;
