/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { BrowserRouter, Switch } from 'react-router-dom';
import { AuthStatuses } from 'interfaces/auth';
import { selectAuthStatus } from 'store/ducks/auth/selectors';
import { useOperation } from 'hooks/common';
import { StateProps } from 'store/interfaces';
import { AuthorizedRoutes, UnauthorizedRoutes } from './components';
import { CurrentAdminGet } from 'store/ducks/auth/operations';
import { additionalInfoGet } from 'store/ducks/user/operations';
import { UISpinner } from 'components/UI';

function AppRouter() {
  const [getCurrentUser, isLoading] = useOperation(CurrentAdminGet);
  const [getAdditionalInfo] = useOperation(additionalInfoGet);
  const authStatus = useSelector<StateProps, string>(selectAuthStatus);

  useEffect(() => {
    getCurrentUser(undefined);
    if (authStatus === AuthStatuses.AUTHOREZED) {
      getAdditionalInfo(undefined);
    }
  }, [getCurrentUser, authStatus]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <BrowserRouter>
      <Switch>
        {authStatus === AuthStatuses.AUTHOREZED ? (
          <AuthorizedRoutes />
        ) : (
          <UnauthorizedRoutes />
        )}
      </Switch>
    </BrowserRouter>
  );
}

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default AppRouter;
