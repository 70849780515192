import StorageService from 'services/Storage';
import { fetch } from 'services/ApiService';
import { UserChangeStatusReq, User, Users, UserChangeCanvaReq } from 'interfaces/user';
import { clientApiUrl } from 'services/ApiService';
import { paramsToQueryString } from 'utils';
import { PasswordData } from 'interfaces/user';
import { AdditionalInfo, searchOrganizationsInfo } from 'interfaces/auth';

export const fetchUsers = () => fetch<User[]>('get', `users`);

export const fetchUsersSearch = (page: number, q?: string, limit = 12) =>
  fetch<Users>(
    'get',
    `users/suggested${paramsToQueryString({
      page,
      limit,
      q,
    })}`,
  );

export const fetchUsersSearchTS = (page: number, q?: string, limit = 12) =>
  fetch<Users>(
    'get',
    `users/suggestedTS${paramsToQueryString({
      page,
      limit,
      q,
    })}`,
  );

export const fetchUser = (id: string) => fetch<User>('get', `users/${id}`);

export const getAdditionalInfo = () => {
  const options = {
    host: clientApiUrl,
  };

  return fetch<AdditionalInfo>('get', 'user/additional_info', options);
};

export const getOrganizationInfo = () => {
  const options = {
    host: clientApiUrl,
  };

  return fetch<searchOrganizationsInfo>('get', 'admin/teams/filterInfo', options);
};

export const fetchUserUpdate = (id: string, data: User) =>
  fetch<User>('patch', `users/${id}`, {
    payload: data,
  });

export const fetchUserCreate = (data: User) =>
  fetch<User>('post', `users`, {
    payload: data,
  });

export const fetchChangePassword = (id: string, data: PasswordData) =>
  fetch('post', `users/${id}/change_password`, {
    payload: data,
  });

export const fetchUploadUserAvatar = (file: File) => {
  const data = new FormData() as any;
  data.append('file', file, file?.name);

  const options = {
    host: clientApiUrl,
    payload: data,
  };

  const headers = {
    Authorization: `Bearer ${StorageService.getAccessToken()}`,
    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
  };

  return fetch('put', 'user/avatar_upload', options, headers);
};

export const fetchUserDelete = (userId: string) =>
  fetch<User>('delete', `users/${userId}`);

export const fetchUserSendEmail = (email: string) =>
  fetch('post', 'users/members', { payload: { email } });

export const fetchUserSetAsOwner = (id: string) =>
  fetch<User>('patch', `users/setAsOwner/${id}`);

export const fetchUserChangeStatus = (params: UserChangeStatusReq) =>
  fetch<User>('post', 'users/changeStatus', { payload: params });

export const fetchCreateOktaUser = (userId: string) =>
  fetch('post', `canva/create-okta-user/${userId}`, { host: clientApiUrl });

export const fetchUserChangeCanva = ({ userId, isCanva }: UserChangeCanvaReq) =>
  fetch('post', `users/${userId}/changeCanva`, { payload: { isCanva } });
