/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

export interface UISuggestPlacesProps {
  handleSelect: (val: string) => void;
  value: string | undefined;
  error: boolean;
}

const UISuggestPlaces = ({ handleSelect, value, error }: UISuggestPlacesProps) => {
  const [inputValue, onChangeInputValue] = useState<string>(value || '');

  useEffect(() => {
    if (!inputValue) {
      handleSelect('');
    }
  }, [inputValue]);

  const onSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        getLatLng(results[0]);
        onChangeInputValue(results[0].formatted_address);
        handleSelect(results[0].formatted_address);
      })
      .catch(error => console.error('Error', error));
  };

  return (
    <PlacesAutocomplete
      value={inputValue}
      onChange={address => onChangeInputValue(address)}
      onSelect={onSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <Inner>
          <Input
            {...getInputProps({
              placeholder: 'Search address ...',
            })}
            open={suggestions.length > 0}
            error={error}
          />
          {suggestions.length > 0 && (
            <ListWrapper open={suggestions.length > 0}>
              {suggestions.map(suggestion => {
                return (
                  <Item {...getSuggestionItemProps(suggestion)} key={suggestion.index}>
                    {suggestion.description}
                  </Item>
                );
              })}
            </ListWrapper>
          )}
        </Inner>
      )}
    </PlacesAutocomplete>
  );
};

const Inner = styled.div`
  width: 100%;
`;

const Input = styled.input<{ open?: boolean; error?: boolean }>`
  width: 100%;
  color: var(--black);
  font-size: 16px;
  line-height: 155%;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 16px;
  outline: none;

  &:focus {
    border: 1px solid #2baee0;
  }

  &:-webkit-input-placeholder {
    color: var(--darkGray);
  }

  &:-moz-placeholder {
    color: var(--darkGray);
  }

  &:-ms-input-placeholder {
    color: var(--darkGray);
  }

  &:-moz-placeholder {
    color: var(--darkGray);
  }

  ${({ open }) =>
    open && `border-radius: 8px 8px 0 0; border-bottom: 1px solid transparent;`}

  ${({ error }) =>
    error &&
    `
      background: #FFF2F2;
      border: 1px solid var(--red);
      &:focus{
        border: 1px solid var(--red);
      }
    `}
`;

const ListWrapper = styled.div<{ open?: boolean }>`
  max-width: 100%;
  max-height: 180px;
  background: #ffffff;
  z-index: 9;
  width: 100%;
  top: 65px;
  left: 0;
  overflow-y: auto;
  border: 1px solid #2baee0;
  border-top: 0;
  border-radius: 0px 0px 8px 8px;
  position: absolute;

  ${({ open }) => open && `position: absolute;`}
`;

const Item = styled.div`
  font-size: 16px;
  line-height: 150%;
  padding: 10px 16px;
  transition: all 0.2s ease;
  &:hover {
    cursor: pointer;
    background: #e9f9ff;
  }
`;

export default UISuggestPlaces;
