/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useOperation } from 'hooks/common';
import { headTableContacts } from 'constants/company';
import {
  fetchCompany,
  fetchCompanyContactsDelete,
} from 'store/ducks/companies/operations';
import { getCompanyById } from 'store/ducks/companies/selectors';
import { fetchCompanyContacts } from 'store/ducks/companies/operations';
import { getCompanyContacts } from 'store/ducks/companies/selectors';
import Jumbotron from 'components/Jumbotron';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import ContactsTable from './ContactsTable';
import { StateProps } from 'store/interfaces';
import { CompanyContact, Company } from 'interfaces/companies';

const Contacts = () => {
  const [onFetchContacts, isLoading] = useOperation(fetchCompanyContacts);
  const [onFetchCompany] = useOperation(fetchCompany);
  const [onFetchDeleteCompanyContact] = useOperation(fetchCompanyContactsDelete);
  const params: { companyId: string } = useParams();
  const contacts = useSelector<StateProps, CompanyContact[]>(getCompanyContacts);
  const company = useSelector<StateProps, Company | undefined>(
    getCompanyById(params.companyId),
  );

  useEffect(() => {
    onFetchContacts(params.companyId);
    onFetchCompany(params.companyId);
  }, [params]);

  return (
    <ContentBlockWrapper
      loading={isLoading}
      header={<Jumbotron name="Company Contacts" back />}
    >
      {contacts && (
        <>
          <ContactsCount>{contacts.length} contacts</ContactsCount>
          {company && (
            <ContactsTable
              rows={[...contacts]}
              headCells={headTableContacts}
              company={company}
              handleDeleteContact={(companyId: string, contactId: string) =>
                onFetchDeleteCompanyContact({ companyId, contactId })
              }
            />
          )}
        </>
      )}
    </ContentBlockWrapper>
  );
};

const ContactsCount = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 16px;
`;

export default Contacts;
