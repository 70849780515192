import { useMemo, useState } from 'react';
import { getValueByObj } from 'utils';

export interface SortStatus {
  name: string;
  status: '' | 'ASC' | 'DESC';
}

interface Options {
  isSort: boolean;
}

const STATUS_LIST: SortStatus['status'][] = ['', 'ASC', 'DESC'];

const DEFAULT_OPTIONS: Options = {
  isSort: true,
};

const compareValue = (a, b, sortType: 'ASC' | 'DESC'): -1 | 1 | 0 => {
  switch (sortType) {
    case 'ASC':
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    case 'DESC':
      if (a < b) return 1;
      if (a > b) return -1;
      return 0;
  }
};

const convertSortValue = (value): number | string => {
  if (typeof value === 'string') {
    // numeric string to number
    if (/^\d+$/.test(value)) {
      return Number(value);
    }
    // not case sensitive
    return value.toLowerCase();
  }

  return value;
};

const useSortStatus = <T>(rows: T[], options: Options = DEFAULT_OPTIONS) => {
  const [sortStatus, setSortStatus] = useState<SortStatus>({ name: '', status: '' });

  const data = useMemo(() => {
    if (!rows) return [];
    if (!options.isSort) return [];
    if (!sortStatus.name) return rows;

    switch (sortStatus.status) {
      case 'ASC':
        return [...rows].sort((a, b) => {
          const aValue = convertSortValue(getValueByObj(a, sortStatus.name)) || '';
          const bValue = convertSortValue(getValueByObj(b, sortStatus.name)) || '';

          return compareValue(aValue, bValue, 'ASC');
        });
      case 'DESC':
        return [...rows].sort((a, b) => {
          const aValue = convertSortValue(getValueByObj(a, sortStatus.name)) || '';
          const bValue = convertSortValue(getValueByObj(b, sortStatus.name)) || '';

          return compareValue(aValue, bValue, 'DESC');
        });
      default:
        return rows;
    }
  }, [rows, sortStatus, options]);

  const handleSwitch = (colName: string) => {
    if (sortStatus.name !== colName) {
      return setSortStatus({ name: colName, status: STATUS_LIST[1] });
    }
    const i = STATUS_LIST.indexOf(sortStatus.status);
    const status = i >= STATUS_LIST.length - 1 ? STATUS_LIST[0] : STATUS_LIST[i + 1];
    setSortStatus({ name: colName, status });
  };

  return { sortStatus, setSortStatus, handleSwitch, data };
};

export default useSortStatus;
