import { ConfirmModal } from 'components/Modals';
import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

interface Props {
  when: boolean;
  shouldBlockNavigation: (location: Location) => boolean;
  content: string;
  title: string;
}

export const RouteLeavingPrompt = ({
  when,
  shouldBlockNavigation,
  content,
  title,
}: Props) => {
  const [modalVisible, updateModalVisible] = useState(false);
  const [lastLocation, updateLastLocation] = useState<Location | undefined>();
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);
  const { push } = useHistory();

  const showModal = (location: Location) => {
    updateModalVisible(true);
    updateLastLocation(location);
  };

  const closeModal = (callback?: () => void) => {
    updateModalVisible(false);
    if (callback) callback();
  };

  const handleBlockedNavigation = (nextLocation: Location) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true);
      }
    });
  };

  useEffect(() => {
    if (confirmedNavigation) {
      if (lastLocation) push(`${lastLocation.pathname}${lastLocation.search}`);
      updateConfirmedNavigation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {modalVisible && (
        <ConfirmModal
          onClose={() => closeModal()}
          accessHandler={() => closeModal()}
          cancelHandler={handleConfirmNavigationClick}
          name={title}
          description={content}
          textAccessBtn="Cancel"
          textCancelBtn="OK"
        />
      )}
    </>
  );
};

export default RouteLeavingPrompt;
