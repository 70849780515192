import { EinListRes } from 'interfaces/campaign';
import { clientApiUrl, fetch } from 'services/ApiService';
import { paramsToQueryString } from 'utils';

export const fetchEinList = (organizationName: string) =>
  fetch<EinListRes>(
    'get',
    `campaign/judgeEin/getList${paramsToQueryString({
      organizationName: organizationName,
    })}`,
    { host: clientApiUrl },
  );

export const fetchChangePpgf = (params: { paypalGivingFundId: string; teamId: string }) =>
  fetch<{ status: number; data: {}; msg: string }>(
    'get',
    `campaign/judgeEin/changePpgf${paramsToQueryString(params)}`,
    { host: clientApiUrl },
  );
