import { CropText, UIButton, UICheckbox } from 'components/UI';
import React from 'react';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import {
  getFullName,
  capitalizeText,
  getRelativeTime,
  cropHttps,
} from 'utils/formatters';
import { useModal, useOperation, useFileUrl } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
import { useSelector } from 'react-redux';
import { StateProps } from 'store/interfaces';
import { User } from 'interfaces/user';
import { getCurrentAdmin } from 'store/ducks/auth/selectors';
import { fetchUserToken } from 'store/ducks/auth/operations';
import { userSiteUrl } from 'services/ApiService';
import { Dispatch } from 'redux';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'constants/date';

interface Props {
  rows: User[];
  headCells: string[];
  showCanvaColumn: boolean;
  onChangeUser: (user: User) => void;
  deleteMemberOperation: (id: string) => (dispatch: Dispatch) => Promise<void>;
}

interface TableRowProps {
  row: User;
  showCanvaColumn: Props['showCanvaColumn'];
  onChangeUser: Props['onChangeUser'];
  deleteMemberOperation: Props['deleteMemberOperation'];
}

const userTableWidth = {
  logoColumnWidth: 52,
  nameColumnWidth: 138,
  emailColumnWidth: 150,
  linkedinColumnWidth: 150,
  roleColumnWidth: 108,
  lastLoginColumnWidth: 110,
  canvaColumnWidth: 110,
} as const;

const {
  logoColumnWidth,
  nameColumnWidth,
  emailColumnWidth,
  linkedinColumnWidth,
  roleColumnWidth,
  lastLoginColumnWidth,
  canvaColumnWidth,
} = userTableWidth;

const TableRowWrapper = ({
  row,
  showCanvaColumn,
  deleteMemberOperation,
  onChangeUser,
}: TableRowProps) => {
  const currentLoginUser = useSelector<StateProps, User>(getCurrentAdmin);
  const {
    id,
    lastName,
    firstName,
    linkedinLink,
    avatarKey,
    email,
    role,
    isCanva,
    loggedInAt,
  } = row;
  const { fileUrl } = useFileUrl({ imgKey: avatarKey });
  const [deleteTeamMembers, , isDeleting] = useOperation(deleteMemberOperation);
  const [onFetchUserToken] = useOperation(fetchUserToken);

  const lastLogin = loggedInAt ? format(new Date(loggedInAt), DATE_FORMAT.BACKEND) : null;

  const [showModal, hideModal] = useModal(() => {
    const deleteHandler = () => {
      deleteTeamMembers(id);
      hideModal();
    };

    return (
      <ConfirmModal
        name={'Delete user'}
        textAccessBtn="Delete"
        description={'Are you sure you want to delete the user?'}
        accessHandler={deleteHandler}
        cancelHandler={hideModal}
        onClose={hideModal}
        hideCloseIcon
      />
    );
  });

  const toUserSiteByToken = (id: string) => {
    onFetchUserToken(id).then(value => {
      if (!value) return;

      window.open(`${userSiteUrl}?accessToken=${value.accessToken}`);
    });
  };

  const formattedLastLogin = lastLogin ? getRelativeTime(lastLogin) : '-';
  const capitalizeRole = capitalizeText(role);

  const userName = getFullName(firstName, lastName);

  return (
    <TableRow>
      <TableRowCell isNoAddBorder>
        <Avatar round name={userName} src={fileUrl} size="40" />
      </TableRowCell>
      <TableRowCell isAddWeight>
        <CropText width={nameColumnWidth - 20} text={userName} />
      </TableRowCell>
      <TableRowCell>
        <CropText width={emailColumnWidth - 20} text={email} />
      </TableRowCell>
      <TableRowCell>
        {
          <LinkedInWrapper width={linkedinColumnWidth - 10}>
            {linkedinLink ? (
              <LinkedInLink href={linkedinLink} target="_blank">
                {cropHttps(linkedinLink)}
              </LinkedInLink>
            ) : (
              '-'
            )}
          </LinkedInWrapper>
        }
      </TableRowCell>
      <TableRowCell>{capitalizeRole}</TableRowCell>
      <TableRowCell isAddWeight>{formattedLastLogin}</TableRowCell>
      {showCanvaColumn && (
        <TableRowCell>
          <CheckBox
            check={isCanva}
            handleClick={() => onChangeUser({ ...row, isCanva: !isCanva })}
          />
        </TableRowCell>
      )}
      <TableRowCell>
        <Actions>
          <ActionButton
            handleClick={() => toUserSiteByToken(id)}
            modifiers="blue"
            title="Access"
          />
          {currentLoginUser.id !== id && (
            <ActionButton
              isLoading={isDeleting}
              handleClick={showModal}
              modifiers="danger"
              title="Delete"
            />
          )}
        </Actions>
      </TableRowCell>
    </TableRow>
  );
};

const UserTable = ({
  rows,
  headCells,
  deleteMemberOperation,
  onChangeUser,
  showCanvaColumn,
}: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${logoColumnWidth}px` }} />
          <col span={1} style={{ width: `${nameColumnWidth}px` }} />
          <col span={1} style={{ width: `${emailColumnWidth}px` }} />
          <col span={1} style={{ width: `${linkedinColumnWidth}px` }} />
          <col span={1} style={{ width: `${roleColumnWidth}px` }} />
          <col span={1} style={{ width: `${lastLoginColumnWidth}px` }} />
          {showCanvaColumn && <col span={1} style={{ width: `${canvaColumnWidth}px` }} />}
        </colgroup>
        <TableHead>
          <TableHeadRow>
            {headCells
              .filter(item => (showCanvaColumn ? true : item !== 'Canva License'))
              .map((item, index) => (
                <TableHeadCell colSpan={index === 0 ? 2 : undefined} key={index}>
                  {item}
                </TableHeadCell>
              ))}
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(item => (
            <TableRowWrapper
              key={item.id}
              row={item}
              showCanvaColumn={showCanvaColumn}
              deleteMemberOperation={deleteMemberOperation}
              onChangeUser={onChangeUser}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const LinkedInWrapper = styled.div<{ width?: string | number }>`
  display: block;
  ${({ width }) => width && `width: ${width}px;`}
`;

const LinkedInLink = styled.a`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

const TableWrapper = styled.div`
  overflow: auto;
  height: 444px;
  margin: 24px;
  position: relative;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;
const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td<{ isAddWeight?: boolean; isNoAddBorder?: boolean }>`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }

  ${({ isAddWeight }) => isAddWeight && 'font-weight: 500;'}
  ${({ isNoAddBorder }) => isNoAddBorder && 'border-bottom: none;'}
`;

const Actions = styled.div`
  display: flex;
`;

const ActionButton = styled(UIButton)`
  width: 76px;
  height: 36px;
  padding: 0;
  font-size: 12px;
  &:not(:first-child) {
    margin-left: 8px;
  }
`;

const CheckBox = styled(UICheckbox)`
  justify-content: center;
`;

export default UserTable;
