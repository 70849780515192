import React from 'react';
import styled from 'styled-components';
import { TableSortHeaderCell } from 'components/UI/table';
import { SortStatus } from 'hooks/common/useSortStatus';
import { SocialImpactLogItem } from 'interfaces/companies';
import { format } from 'date-fns';
import { getFullName } from 'utils/formatters';

interface Props {
  rows: SocialImpactLogItem[];
  sortStatus: SortStatus;
  onSort: (colName: string) => void;
}

interface TableRowProps {
  row: Props['rows'][0];
}

const userTableWidth = {
  requestDateWidth: 170,
  requestorWidth: 170,
  typeofInvestmentWidth: 180,
  csrFocusWidth: 240,
  sdgGoalsWidth: 150,
} as const;

const {
  requestDateWidth,
  requestorWidth,
  // typeofInvestmentWidth,
  csrFocusWidth,
  sdgGoalsWidth,
} = userTableWidth;

const TableRowWrapper = ({ row }: TableRowProps) => {
  const {
    createdAt,
    user,
    // typesOfInvestmentOld,
    // typesOfInvestment,
    csrFocusOld,
    csrFocus,
    sdgGoalsOld,
    sdgGoals,
  } = row;
  return (
    <TableRow>
      <TableRowCell>{format(new Date(createdAt), 'MMM dd, yyyy')}</TableRowCell>
      <TableRowCell>{getFullName(user.firstName, user.lastName)}</TableRowCell>
      {/* <TableRowCell>
        {(typesOfInvestmentOld || []).map(item => item.title).join(', ')}
      </TableRowCell>
      <TableRowCell>
        {(typesOfInvestment || []).map(item => item.title).join(', ')}
      </TableRowCell> */}
      <TableRowCell>{csrFocusOld.join(', ')}</TableRowCell>
      <TableRowCell>{csrFocus.join(', ')}</TableRowCell>
      <TableRowCell>{sdgGoalsOld.join(', ')}</TableRowCell>
      <TableRowCell>{sdgGoals.join(', ')}</TableRowCell>
    </TableRow>
  );
};

const SocialImpactDataLogTable = ({ rows, sortStatus, onSort }: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${requestDateWidth}px` }} />
          <col span={1} style={{ width: `${requestorWidth}px` }} />
          {/* <col span={1} style={{ width: `${typeofInvestmentWidth}px` }} /> */}
          {/* <col span={1} style={{ width: `${typeofInvestmentWidth}px` }} /> */}
          <col span={1} style={{ width: `${csrFocusWidth}px` }} />
          <col span={1} style={{ width: `${csrFocusWidth}px` }} />
          <col span={1} style={{ width: `${sdgGoalsWidth}px` }} />
          <col span={1} style={{ width: `${sdgGoalsWidth}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            <TableSortHeaderCell
              colName="createdAt"
              sortStatus={sortStatus}
              onClick={onSort}
            >
              Request Date
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="user.firstName"
              sortStatus={sortStatus}
              onClick={onSort}
            >
              Requestor
            </TableSortHeaderCell>
            <TableHeadCell style={{ display: 'none' }}>
              Type of Investment (Old)
            </TableHeadCell>
            <TableHeadCell style={{ display: 'none' }}>
              Type of Investment <span style={{ color: 'var(--blue)' }}>(New)</span>
            </TableHeadCell>
            <TableHeadCell>CSR Focus (Old)</TableHeadCell>
            <TableHeadCell>
              CSR Focus <span style={{ color: 'var(--blue)' }}>(New)</span>
            </TableHeadCell>
            <TableHeadCell>SDG Goals (Old)</TableHeadCell>
            <TableHeadCell>
              SDG Goals <span style={{ color: 'var(--blue)' }}>(New)</span>
            </TableHeadCell>
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(item => (
            <TableRowWrapper key={item.id} row={item} />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  height: 590px;
  overflow: auto;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  background: #f4f4f4;
  position: sticky;
  top: 0;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td`
  padding: 12px 4px;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

export default SocialImpactDataLogTable;
