import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { getFullName } from 'utils/formatters';
import { CropText } from 'components/UI';
import LinkedInIcon from 'assets/images/icons/linkedIn.svg';
import { parseLink } from 'utils';
import { Link } from 'react-router-dom';
import { useModal } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
import { TeamContact, TeamData } from 'interfaces/team';

interface Props {
  rows: TeamContact[];
  headCells: string[];
  team: TeamData;
  handleDeleteContact: (teamId: string, contactId: string) => void;
}

interface TableRowProps {
  row: TeamContact;
  team: TeamData;
  handleDeleteContact: (teamId: string, contactId: string) => void;
}

const userTableWidth = {
  column1: 263,
  column2: 250,
  column3: 120,
  column4: 100,
  column5: 213,
  column6: 120,
  column7: 120,
} as const;

const { column1, column2, column3, column4, column5, column6, column7 } = userTableWidth;

const TableRowWrapper = ({ row, handleDeleteContact }: TableRowProps) => {
  const {
    id,
    teamId,
    firstName,
    lastName,
    jobTitle,
    phone,
    email,
    contactAccuracyScore,
    linkedinUrl,
  } = row;

  const [showModal, hideModal] = useModal(() => {
    const deleteHandler = () => {
      handleDeleteContact(teamId, id);
      hideModal();
    };

    return (
      <ConfirmModal
        name={'Delete Organization Contact'}
        textAccessBtn="Delete"
        description={'Are you sure you want to delete the Organization Contact?'}
        accessHandler={deleteHandler}
        cancelHandler={hideModal}
        onClose={hideModal}
        hideCloseIcon
      />
    );
  });

  const userName = getFullName(firstName, lastName);

  return (
    <TableRow>
      <TableRowCell isNoAddBorder isAddWeight>
        {userName}
      </TableRowCell>
      <TableRowCell>{jobTitle}</TableRowCell>

      <TableRowCell>
        {phone ? (
          <ContactLink href={`tel:${phone}`}>{phone}</ContactLink>
        ) : (
          <CropText width={200} text="No phone" />
        )}
      </TableRowCell>

      <TableRowCell>
        {linkedinUrl ? (
          <SocialLink href={parseLink(linkedinUrl)} target="_blank">
            <ReactSVG src={LinkedInIcon} />
          </SocialLink>
        ) : (
          <SocialLinkEmpty>-</SocialLinkEmpty>
        )}
      </TableRowCell>

      <TableRowCell>
        {email ? (
          <ContactLink href={`mailto:${email}`}>{email}</ContactLink>
        ) : (
          <CropText width={200} text="No email" />
        )}
      </TableRowCell>

      <TableRowCell>{contactAccuracyScore}</TableRowCell>

      <TableRowCell>
        <Center>
          <EditLink to={`/edit-contact-team/${teamId}/${id}`}>Edit</EditLink>
          <DelLink onClick={showModal}>Delete</DelLink>
        </Center>
      </TableRowCell>
    </TableRow>
  );
};

const UserTable = ({ rows, headCells, team, handleDeleteContact }: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${column1}px` }} />
          <col span={1} style={{ width: `${column2}px` }} />
          <col span={1} style={{ width: `${column3}px` }} />
          <col span={1} style={{ width: `${column4}px` }} />
          <col span={1} style={{ width: `${column5}px` }} />
          <col span={1} style={{ width: `${column6}px` }} />
          <col span={1} style={{ width: `${column7}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            {headCells.map(item => (
              <TableHeadCell key={item}>{item}</TableHeadCell>
            ))}
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(item => (
            <TableRowWrapper
              key={item.id}
              row={item}
              team={team}
              handleDeleteContact={handleDeleteContact}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  margin-bottom: 30px;
`;

const Table = styled.table`
  width: 1096px;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;
const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td<{ isAddWeight?: boolean; isNoAddBorder?: boolean }>`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }

  ${({ isAddWeight }) => isAddWeight && 'font-weight: 500;'}
  ${({ isNoAddBorder }) => isNoAddBorder && 'border-bottom: none;'}
`;

export const ContactLink = styled.a`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

export const SocialLink = styled.a`
  display: block;
  width: 20px;
  margin-left: 12px;
  & > div {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const SocialLinkEmpty = styled.p`
  margin-left: 20px;
`;

const EditLink = styled(Link)`
  color: var(--blue);
`;

const DelLink = styled.a`
  margin-left: 12px;
  color: var(--red);
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export default UserTable;
