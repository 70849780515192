import { PricingStoreData } from 'interfaces/pricing';
import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as types from './types';

type RootAction = ActionType<typeof actions>;

export default createReducer<PricingStoreData, RootAction>({
  pricingList: {
    items: [],
    meta: {
      currentPage: '0',
      itemCount: 0,
      itemsPerPage: '0',
      totalItems: 0,
      totalPages: 0,
    },
  },
  pricing: undefined,
  report: undefined,
  adminNotes: [],
})
  .handleType([types.SET_PRICING_LIST], (state, action) => ({
    ...state,
    pricingList: action.payload,
  }))
  .handleType([types.SET_CATEGORY], (state, action) => ({
    ...state,
    pricingList: {
      ...state.pricingList,
      items: state.pricingList.items.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, categorize: action.payload.categorize };
        }
        return item;
      }),
    },
  }))
  .handleType([types.SET_VALUATION_SAVE], (state, action) => ({
    ...state,
    pricingList: {
      ...state.pricingList,
      items: state.pricingList.items.map(item => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      }),
    },
  }))
  .handleType([types.SET_PRICING], (state, action) => ({
    ...state,
    pricing: action.payload,
  }))
  .handleType([types.DELETE_VALUATION], (state, action) => ({
    ...state,
    pricingList: {
      ...state.pricingList,
      items: state.pricingList.items.filter(item => item.id !== action.payload),
    },
  }))
  .handleType([types.ADD_PRICING], (state, action) => ({
    ...state,
    pricingList: {
      ...state.pricingList,
      items: [action.payload, ...state.pricingList.items],
    },
  }))
  .handleType([types.SET_REPORT], (state, action) => ({
    ...state,
    report: action.payload,
  }))
  .handleType([types.SET_REPORT_FILE], (state, action) => {
    if (!state.report || state.report.id !== action.payload.id) return state;

    return {
      ...state,
      report: { ...state.report, file: action.payload.fileKey },
    };
  })
  .handleType([types.SET_ADMINNOTE_LIST], (state, action) => ({
    ...state,
    adminNotes: action.payload,
  }))
  .handleType([types.ADD_ADMINNOTE], (state, action) => ({
    ...state,
    adminNotes: [action.payload, ...state.adminNotes],
  }));
