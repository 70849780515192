import { UIActionText, UIButton, UISwitch } from 'components/UI';
import styled from 'styled-components';

export const FieldWrapper = styled.div`
  max-width: 764px;
  position: relative;
`;
export const FieldDoubleRow = styled.div`
  display: flex;
  & > div {
    width: 374px;
  }

  & > div:first-child {
    margin-right: 16px;
  }
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  margin-bottom: 24px;
  display: flex;
`;

export const ButtonGroup = styled.div`
  display: flex;
  & > button:not(:first-child) {
    margin-left: 18px;
  }
`;

export const RightButtonGroup = styled.div`
  margin-left: auto;
`;

export const Button = styled(UIButton)`
  padding: 10px 24px;
  font-size: 16px;
`;

export const Asterisk = styled.span.attrs(() => ({ children: '*' }))`
  color: var(--red);
`;

export const ActionText = styled(UIActionText)`
  margin-bottom: 24px;
`;

export const AddCompany = styled.div`
  position: absolute;
  right: -170px;
  top: 30px;
`;

export const AddUser = styled.div`
  padding-top: 32px;
`;

export const ViewStewardshipPlan = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-bottom: -24px;
`;

export const Switch = styled(UISwitch)`
  margin-left: 32px;
`;
