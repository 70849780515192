import { fetch } from 'services/ApiService';
import { AuthData, AuthResponseData, UserTokenData } from 'interfaces/auth';
import { User } from 'interfaces/user';

export const fetchSignIn = (data: AuthData) =>
  fetch<AuthResponseData>(
    'post',
    `sign_in`,
    {
      payload: data,
    },
    {},
  );

export const getCurrentAdmin = () => fetch<User>('get', '');

export const fetchUserToken = (id: string) =>
  fetch<UserTokenData>('get', `getUserToken/${id}`);
