import React from 'react';
import styled, { CSSProp } from 'styled-components';
import { UITextFieldProps } from './interfaces';

interface UITextAreaProps extends UITextFieldProps<HTMLTextAreaElement> {
  maxLength?: number;
  height?: string | number;
  className?: string;
}

function UITextArea({
  onBlur,
  onFocus,
  onChange,
  value,
  placeholder,
  height = 110,
  maxLength,
  disabled = false,
  className,
  error,
}: UITextAreaProps) {
  return (
    <Textarea
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={onBlur}
      onFocus={onFocus}
      style={{ height: height }}
      maxLength={maxLength && maxLength}
      disabled={disabled}
      className={className}
      error={error}
    />
  );
}

const Textarea = styled.textarea<{ css?: CSSProp; disabled?: boolean; error?: boolean }>`
  width: 100%;
  color: var(--black);
  font-size: 16px;
  line-height: 155%;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 14px;
  resize: none;
  outline: none;

  &:focus {
    border: 1px solid #2baee0;
  }

  &:-webkit-input-placeholder {
    color: var(--darkGray);
  }

  &:-moz-placeholder {
    color: var(--darkGray);
  }

  &:-ms-input-placeholder {
    color: var(--darkGray);
  }

  &:-moz-placeholder {
    color: var(--darkGray);
  }

  ${({ disabled }) =>
    disabled &&
    `
      background: #F9F9F9;
      color: var(--gray);
      border: 1px solid transparent;
      &:focus{
        border: 1px solid transparent;
      }
    `}


  ${({ error }) =>
    error &&
    `
      background: #FFF2F2;
      border: 1px solid var(--red);
      &:focus{
        border: 1px solid var(--red);
      }
    `}

  ${({ css }) => css}
`;

export default UITextArea;
