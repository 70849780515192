/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MainWrapper } from 'layouts';
import { useOperation } from 'hooks/common';
import { useParams } from 'react-router-dom';
import { debounce, isObject } from 'lodash';
import {
  fetchCompanyUpdate,
  fetchCompany,
  fetchFileUpload,
  fetchSuggestCompaniesSearch,
  fetchCompanyLogoUpload,
} from 'store/ducks/companies/operations';
import { StateProps, CRSFocus, AdditionalInfoProps } from 'store/interfaces';
import {
  getAdditionalInfoSelect,
  getCompanyById,
  getSuggestCompanies,
} from 'store/ducks/companies/selectors';
import { getCrsFocus } from 'store/ducks/user/selectors';
import { Company } from 'interfaces/companies';
import CompanyProfileForm from 'components/CompanyProfileForm';
import Jumbotron from 'components/Jumbotron';
import History from 'services/History';

const EditCompany = () => {
  const [onfetchCompany] = useOperation(fetchCompany);
  const [onFileUpload] = useOperation(fetchFileUpload);
  const [onSearchCompanies] = useOperation(fetchSuggestCompaniesSearch);

  const [updateCompanies] = useOperation(fetchCompanyUpdate);
  const [onCompanyLogoUpload] = useOperation(fetchCompanyLogoUpload);
  const params: { companyId: string } = useParams();

  const company = useSelector<StateProps, Company | undefined>(
    getCompanyById(params.companyId),
  );
  const additionalInfo = useSelector<StateProps, AdditionalInfoProps>(
    getAdditionalInfoSelect(),
  );

  const crsFocus = useSelector<StateProps, CRSFocus[]>(getCrsFocus);

  const companiesSearchList = useSelector<StateProps, Company[]>(getSuggestCompanies);

  useEffect(() => {
    onfetchCompany(params.companyId);
  }, [params.companyId]);

  const onSubmit = useCallback((values: Company) => {
    return updateCompanies({
      id: params.companyId,
      data: {
        ...values,
      },
    }).then(() => {
      History.back();
    });
  }, []);

  const onUploadDocument = useCallback(async (file: File) => {
    return onFileUpload({ file, id: params.companyId });
  }, []);

  const initialValues = useMemo(() => {
    if (isObject(company)) {
      return company;
    }
  }, [company]);

  const handleSearchCompanies = (val: string) => {
    return onSearchCompanies({ q: val, page: 1, limit: 12 });
  };

  const handleSearchCompaniesDebounce = useCallback(
    debounce(handleSearchCompanies, 800),
    [],
  );

  const handleCompanyLogoUpload = async (file: File) =>
    await onCompanyLogoUpload({ file, companyId: params.companyId });

  return (
    <MainWrapper withOutNav header={<Jumbotron name="Company" back />}>
      {initialValues && (
        <CompanyProfileForm
          initialValues={initialValues}
          crsFocus={crsFocus}
          industryTree={additionalInfo.industriesTree}
          naicsCodeTree={additionalInfo.naicsCodeTree}
          onSubmit={onSubmit}
          handleUploadDocument={onUploadDocument}
          companiesSearchList={companiesSearchList}
          handleGetCompanies={handleSearchCompaniesDebounce}
          handleUploadLogo={handleCompanyLogoUpload}
        />
      )}
    </MainWrapper>
  );
};

export default EditCompany;
