/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTTPRequestStatusEnum } from 'constants/common';
import { FieldValidator } from 'final-form';
import queryString from 'query-string';

export const composeValidators = <FieldValue>(
  ...validators: FieldValidator<FieldValue>[]
) => (...args: Parameters<FieldValidator<FieldValue>>) =>
  validators.reduce((error, validator) => error || validator(...args), undefined);

export const callOperationAsync = async <TResponse>(
  operation: any,
  loaderToggler: Function = () => {},
  loadHandler: Function = () => {},
): Promise<TResponse> => {
  let result;

  try {
    loaderToggler(true);
    loadHandler(true);
    result = await operation();
  } finally {
    if (result?.message !== HTTPRequestStatusEnum.RE_REQUEST) {
      loaderToggler(false);
      loadHandler(false);
    }
  }

  return result;
};

export const queryToObject = queryString => {
  const pairsString = queryString[0] === '?' ? queryString.slice(1) : queryString;
  const pairs = pairsString.split('&').map(str => str.split('=').map(decodeURIComponent));
  return pairs.reduce((acc, [key, value]) => (key ? { ...acc, [key]: value } : acc), {});
};

export const getURLWithQueryParams = (base, params) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value as any)}`)
    .join('&');

  return `${base}?${query}`;
};

export function paramsToQueryString(hash) {
  // console.log(hash, '下载接口');
  const params = queryString.stringify(hash, {
    arrayFormat: 'bracket',
  });

  return params ? `?${params}` : '';
}
export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
export const identityField = (value: any) => value;

export const parseLink = (link: string) => {
  const re = new RegExp('^(http|https)://', 'i');
  const isHttp = re.test(link);
  if (!isHttp) {
    return `https://${link}`;
  }
  return link;
};

export function transformArrayForOptions(array: Array<string> = [], sp = '') {
  const toUpperCase = (text: string) =>
    text[0].toUpperCase() + text.slice(1).replace(/_/g, ' ');

  return array.map(item => ({
    value: item,
    label: sp
      ? item
          .split(sp)
          .map(i => toUpperCase(i))
          .join(sp)
      : toUpperCase(item),
  }));
}

export function transformEnumForOptions(enums: { [key: string]: string }) {
  return transformArrayForOptions(Object.values(enums));
}

export function getUTCAsLocalDate(dateString: string) {
  const b = dateString.split(/\D/).map(num => Number(num));
  return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
}

export const decodeBase64 = (str: string) => decodeURIComponent(window.atob(str));

export const getValueByObj = (obj, key) => key.split('.').reduce((a, b) => a[b], obj);
