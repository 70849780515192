import React from 'react';
import { WrapperProps } from 'interfaces/common';
import styled from 'styled-components';

function AuthFormWrapper({ children, className }: WrapperProps) {
  return <Root className={className}>{children}</Root>;
}

const Root = styled.div`
  width: 100%;
  max-width: 454px;
  background: #fff;
  border-radius: 6px;
  padding: 40px;
`;

export default AuthFormWrapper;
