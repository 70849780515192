import React from 'react';
import { UIModalProps } from 'components/UI/interfaces';
import { UIModal } from 'components/UI';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { useFileUrl } from 'hooks/common';
import styled from 'styled-components';

interface Props {
  imgKey: string;
}

const ViewImageModal = ({ onClose, imgKey, ...modalProps }: Props & UIModalProps) => {
  useLockBodyScroll();
  const { fileUrl } = useFileUrl({ imgKey });

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '50px 50px 32px' }}
      style={{
        width: 'max-content',
        maxWidth: '800px',
      }}
    >
      <Image src={fileUrl} />
    </UIModal>
  );
};

const Image = styled.img`
  width: unset;
  max-width: 100%;
`;

export default ViewImageModal;
