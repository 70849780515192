/* eslint-disable react-hooks/exhaustive-deps */
import Jumbotron from 'components/Jumbotron';
import { UIAlert, UIButton } from 'components/UI';
import { ValuationStatusEnum } from 'constants/pricing';
import { useOperation } from 'hooks/common';
import { MainWrapper } from 'layouts';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  fetchGenerateReport,
  fetchPricing,
  fetchRevertReport,
  fetchSaveValuation,
} from 'store/ducks/pricing/operations';
import { getPricing } from 'store/ducks/pricing/selectors';
import styled from 'styled-components';
import ValuationForm from '../components/ValuationForm';

const PricingEdit: FC = () => {
  const params = useParams<{ teamId: string; valuationId: string }>();
  const { push } = useHistory();

  const [onFetchSaveValuation] = useOperation(fetchSaveValuation);
  const [onFetchPricing, , isLoading] = useOperation(fetchPricing);
  const [onFetchGenerateReport, , isGenerateLoading] = useOperation(fetchGenerateReport);
  const [onFetchRevertReport] = useOperation(fetchRevertReport);

  const pricing = useSelector(getPricing);

  useEffect(() => {
    onFetchPricing(params.valuationId);
  }, []);

  return (
    <MainWrapper
      withOutNav
      isLoading={isLoading}
      header={
        <Jumbotron
          name={pricing?.name || ''}
          back
          backPath={`/pricing/view/${params.teamId}`}
        >
          {pricing?.status === ValuationStatusEnum['PendingAdminReview'] && (
            <Btn
              isLoading={isGenerateLoading}
              modifiers="primary"
              title="Generate Valuation Report"
              handleClick={() =>
                onFetchGenerateReport(params.valuationId).then(() =>
                  push(`/pricing/view/${params.teamId}`),
                )
              }
            />
          )}
        </Jumbotron>
      }
    >
      <Body>
        <NavigationLinkBar>
          <NavigationLink active>Basic Info</NavigationLink>
          {pricing?.status === ValuationStatusEnum.Completed && (
            <NavigationLink
              onClick={() =>
                push(`/pricing/report/${params.teamId}/${params.valuationId}`)
              }
            >
              Valuation Report
            </NavigationLink>
          )}
          <ActionGroup>
            <RevertBtn
              title="Revert"
              modifiers="danger"
              handleClick={() => {
                onFetchRevertReport(params.valuationId).then(() => {
                  push(`/pricing/view/${params.teamId}`);
                });
              }}
            />
          </ActionGroup>
        </NavigationLinkBar>
        <Alert type="info">
          All inputs should be specific to what you are intending to value (i.e. If for
          the entire organization, input data points for your collective base of assets.
          If you are valuing a specific event or property, input ONLY data points specific
          to that property.
        </Alert>
        {pricing && (
          <ValuationForm pricing={pricing} handleSaveValuation={onFetchSaveValuation} />
        )}
      </Body>
    </MainWrapper>
  );
};

const Body = styled.div`
  max-width: 1096px;
`;

const Btn = styled(UIButton)`
  width: 242px;
`;

const Alert = styled(UIAlert)`
  margin-bottom: 16px;
`;

const NavigationLinkBar = styled.div`
  height: 69px;
  background: #fff;
  border-radius: 6px;
  padding: 22px 24px;
  margin-bottom: 24px;
  display: flex;
`;

const NavigationLink = styled.div<{ active?: boolean }>`
  font-size: 16px;
  line-height: 155%;
  margin-right: 20px;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    font-weight: 500;
    color: #2baee0; 
  `}

  &:hover {
    color: #2baee0;
  }
`;

const ActionGroup = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const RevertBtn = styled(UIButton)`
  padding: 0;
  margin-right: 24px;
  height: 46px;
  width: 104px;
  font-size: 16px;
`;

export default PricingEdit;
