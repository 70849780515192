/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { MainWrapper } from 'layouts';
import { useOperation } from 'hooks/common';
import { fetchUserCreate, fetchUserSendEmail } from 'store/ducks/user/operations';
import { User } from 'interfaces/user';
import UserProfileForm from 'components/UserProfileForm';
import Jumbotron from 'components/Jumbotron';
import { validateBackendErrors } from 'utils/validation';
import History from 'services/History';
import { fetchTeamsSuggested } from 'store/ducks/team/operations';
import { getTeamsSuggested } from 'store/ducks/team/selectors';
import { TeamData } from 'interfaces/team';
import { StateProps } from 'store/interfaces';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

const NewUser = () => {
  const [createUserInfo] = useOperation(fetchUserCreate);
  const [onSearchTeams] = useOperation(fetchTeamsSuggested);
  const [onUserSendEmail] = useOperation(fetchUserSendEmail);
  const teamsSearchList = useSelector<StateProps, TeamData[]>(getTeamsSuggested);

  const onSubmit = useCallback((values: User) => {
    return createUserInfo(values)
      .then(() => {
        onUserSendEmail(values.email);
        History.back();
      })
      .catch(error => {
        return validateBackendErrors(error);
      });
  }, []);

  const handleSearchTeams = (val: string) => {
    return onSearchTeams(val);
  };

  const handleSearchTeamsDebounce = useCallback(debounce(handleSearchTeams, 800), []);

  return (
    <MainWrapper withOutNav header={<Jumbotron name="New User" back />}>
      <UserProfileForm
        onSubmit={onSubmit}
        teamsSearchList={teamsSearchList}
        handleGetTeams={handleSearchTeamsDebounce}
      />
    </MainWrapper>
  );
};

export default NewUser;
